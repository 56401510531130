import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { RouteParam, ServiceBase } from '../service-base';

// Modèles
import { Deposit } from 'src/app/@shared/models/banking/deposit';

const CLASS_NAME = 'Deposit';
const ENDPOINT = `/deposits/`;
const ROUTES: RouteParam[] = [
    { key: 'cash', path: '{id}/cash/' },
    { key: 'deposit', path: '{id}/deposit/' },
]

@Injectable({
    providedIn: 'root'
})
export class DepositService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addRoutes(ROUTES);
    }

    public cash(deposit: Deposit): void {
        let url = this.routes.cash.replace('{id}', deposit.id.toString());
        this.update(deposit, {
            url: url
        });
    }

    public deposit(deposit: Deposit): void {
        let url = this.routes.deposit.replace('{id}', deposit.id.toString());
        return this.update(deposit, {
            url: url
        });
    }

}

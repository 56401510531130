import { ModelBase } from "../abstract/model-base.model";

export class Account extends ModelBase {

    static override CLASS_NAME: string = 'Account';

    private _account!: string;
    private _balance!: number;
    private _collective!: boolean;
    private _currency!: string;
    private _generalAccount!: Account;
    private _hasEntryLine!: boolean;
    private _initialBalance!: number;
    private _label!: string;
    private _remainingInitialBalance!: number;
    private _resultAccountSuggestions!: Account[];
    private _subAccountPrefix!: string;

    public override toString(): string {
        let output = `${this._account} - ${this._label}`;
        if (!!this._generalAccount) {
            output += ` (${this._generalAccount.toString()})`;
        }
        return output;
    }

    public get account(): string {
        return this._account;
    }

    public set account(value: any) {
        this._setValue('_account', value, 'string');
    }

    public get balance(): number {
        return this._balance;
    }

    public set balance(value: any) {
        this._setValue('_balance', value, 'float');
    }

    public get collective(): boolean {
        return this._collective;
    }

    public set collective(value: any) {
        this._setValue('_collective', value, 'boolean');
    }

    public get currency(): string {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'string');
    }

    public get generalAccount(): Account {
        return this._generalAccount;
    }

    public set generalAccount(value: any) {
        this._setValue('_generalAccount', value, 'Account');
    }

    public get hasEntryLine(): boolean {
        return this._hasEntryLine;
    }

    public set hasEntryLine(value: any) {
        this._setValue('_hasEntryLine', value, 'boolean');
    }

    public get initialBalance(): number {
        return this._initialBalance;
    }

    public set initialBalance(value: any) {
        this._setValue('_initialBalance', value, 'float');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get remainingInitialBalance(): number {
        return this._remainingInitialBalance;
    }

    public set remainingInitialBalance(value: any) {
        this._setValue('_remainingInitialBalance', value, 'float');
    }

    public get resultAccountSuggestions(): Account[] {
        return this._resultAccountSuggestions;
    }

    public set resultAccountSuggestions(value: any) {
        this._setValue('_resultAccountSuggestions', value, 'Account');
    }

    public get subAccountPrefix(): string {
        return this._subAccountPrefix;
    }

    public set subAccountPrefix(value: any) {
        this._setValue('_subAccountPrefix', value, 'string');
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';
import { QueryOptionsParam } from 'src/app/@shared/utilities/odata/query-params';
import { SaveOptions } from '../../interfaces/save-options';

// Modèles
import { ModelBase } from 'src/app/@shared/models/abstract/model-base.model';
import { BankAccountLine } from 'src/app/@shared/models/banking/bank-account-line.model';

const CLASS_NAME = 'BankAccountLine';
const ENDPOINT = `/bank-account-lines/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'client-payments', path: 'client-payments/', childClass: 'SalesInvoicePayment' },
    { key: 'find-rule', path: 'find-rule/', childClass: 'BankAccountRule', resultCardinality: 'one' },
    { key: 'vendor-payments', path: 'vendor-payments/', childClass: 'PurchaseInvoicePayment' },
    { key: 'tgc-declarations', path: 'tgc-declarations/', childClass: 'TgcDeclaration' },
]
const ROUTES: RouteParam[] = [
    { key: 'acceptDuplicates', path: 'accept-duplicates/' },
    { key: 'askForVerification', path: '{id}/ask-for-verification/' },
    { key: 'deleteDuplicates', path: 'delete-duplicates/' },
    { key: 'justify', path: '{id}/justify/' },
    { key: 'massJustify', path: 'mass-justify/' },
    { key: 'massUnjustify', path: 'mass-unjustify/' },
    { key: 'recordOverpayment', path: '{id}/record-overpayment/' },
    { key: 'unjustify', path: '{id}/unjustify/' },
    { key: 'verificationDone', path: '{id}/verification-done/' },
    { key: 'writeOff', path: '{id}/write-off/' },
]

@Injectable({
    providedIn: 'root'
})
export class BankAccountLineService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public askForVerification$(line: BankAccountLine): Observable<BankAccountLine> {
        return this.update$(line, {
            url: this.routes.askForVerification.replace('{id}', line.id.toString())
        });
    }

    public override createRelated(instance: ModelBase, parent: BankAccountLine, navName: string, options?: SaveOptions | undefined): void {
        if (navName === 'payments') {
            if (parent.type.code === 'vendor_payment' || parent.type.code === 'vendor_reimbursement') {
                navName = 'vendor-payments';
            } else {
                navName = 'client-payments';
            }
        }
        return super.createRelated(instance, parent, navName, options);
    }

    public override getRelated$(parent: BankAccountLine, navName: string, options?: QueryOptionsParam | undefined): Observable<any> {
        if (navName === 'payments') {
            if (parent.type?.code === 'vendor_payment' || parent.type?.code === 'vendor_reimbursement') {
                navName = 'vendor-payments';
            } else if (parent.type?.code === 'client_payment' || parent.type?.code === 'client_reimbursement') {
                navName = 'client-payments';
            } else {
                return of([]);
            }
        }
        return super.getRelated$(parent, navName, options);
    }

    public justify$(line: BankAccountLine): Observable<BankAccountLine> {
        return this.update$(line, {
            url: this.routes.justify.replace('{id}', line.id.toString())
        });
    }

    public massAcceptDuplicates$(transactions: BankAccountLine[]): Observable<null> {
        return this.massUpdate$(transactions, {
            url: this.routes.acceptDuplicates
        });
    }

    public massDeleteDuplicates$(transactions: BankAccountLine[]): Observable<null> {
        return this.massDelete$(transactions, {
            url: this.routes.deleteDuplicates
        });
    }

    public massJustify$(transactions: BankAccountLine[]): Observable<null> {
        return this.massUpdate$(transactions, {
            url: this.routes.massJustify
        });
    }

    public massUnjustify$(transactions: BankAccountLine[]): Observable<null> {
        return this.massUpdate$(transactions, {
            url: this.routes.massUnjustify
        });
    }

    public recordOverpayment$(line: BankAccountLine): Observable<BankAccountLine> {
        return this.update$(line, {
            url: this.routes.recordOverpayment.replace('{id}', line.id.toString())
        });
    }

    public unjustify$(line: BankAccountLine): Observable<BankAccountLine> {
        return this.update$(line, {
            url: this.routes.unjustify.replace('{id}', line.id.toString())
        });
    }

    public verificationDone$(line: BankAccountLine): Observable<BankAccountLine> {
        return this.update$(line, {
            url: this.routes.verificationDone.replace('{id}', line.id.toString())
        });
    }

    public writeOff$(line: BankAccountLine): Observable<BankAccountLine> {
        return this.update$(line, {
            url: this.routes.writeOff.replace('{id}', line.id.toString())
        });
    }
}

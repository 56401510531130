import { ModelBase } from "../../abstract/model-base.model";
import { Product } from "../../product/product.model";
import { DiscountType } from "../../sales/discount-type.model";
import { Tax } from "../../core/tax.model";
import { TaxDetail } from "../../core/taxDetail.model";
import { Unit } from "../../core/unit.model";

export abstract class InvoiceItemBase extends ModelBase {

    private _amount!: number;
    private _discount!: number;
    private _discountAmount!: number;
    private _discountType!: DiscountType;
    private _grossAmount!: number;
    private _index!: number;
    private _netAmount!: number;
    private _price!: number;
    private _product!: Product;
    private _productDescription!: string;
    private _productName!: string;
    private _productReference!: string;
    private _quantity!: number;
    private _tax!: Tax;
    private _taxAmount!: number;
    private _taxDetail!: TaxDetail;
    private _unit!: Unit;

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get discount(): number {
        return this._discount;
    }

    public set discount(value: any) {
        this._setValue('_discount', value, 'float');
    }

    public get discountAmount(): number {
        return this._discountAmount;
    }

    public set discountAmount(value: any) {
        this._setValue('_discountAmount', value, 'float');
    }

    public get discountType(): DiscountType {
        return this._discountType;
    }

    public set discountType(value: any) {
        this._setValue('_discountType', value, 'DiscountType');
    }

    public get grossAmount(): number {
        return this._grossAmount;
    }

    public set grossAmount(value: any) {
        this._setValue('_grossAmount', value, 'float');
    }

    public get index(): number {
        return this._index;
    }

    public set index(value: any) {
        this._setValue('_index', value, 'integer');
    }

    public get netAmount(): number {
        return this._netAmount;
    }

    public set netAmount(value: any) {
        this._setValue('_netAmount', value, 'float');
    }

    public get price(): number {
        return this._price;
    }

    public set price(value: any) {
        this._setValue('_price', value, 'float');
    }

    public get product(): Product {
        return this._product;
    }

    public set product(value: any) {
        this._setValue('_product', value, 'Product');
    }

    public get productDescription(): string {
        return this._productDescription;
    }

    public set productDescription(value: any) {
        this._setValue('_productDescription', value, 'string');
    }

    public get productName(): string {
        return this._productName;
    }

    public set productName(value: any) {
        this._setValue('_productName', value, 'string');
    }

    public get productReference(): string {
        return this._productReference;
    }

    public set productReference(value: any) {
        this._setValue('_productReference', value, 'string');
    }

    public get quantity(): number {
        return this._quantity;
    }

    public set quantity(value: any) {
        this._setValue('_quantity', value, 'float');
    }

    public get tax(): Tax {
        return this._tax;
    }

    public set tax(value: any) {
        this._setValue('_tax', value, 'Tax');
    }

    public get taxAmount(): number {
        return this._taxAmount;
    }

    public set taxAmount(value: any) {
        this._setValue('_taxAmount', value, 'float');
    }

    public get taxDetail(): TaxDetail {
        return this._taxDetail;
    }

    public set taxDetail(value: any) {
        this._setValue('_taxDetail', value, 'TaxDetail');
    }

    public get unit(): Unit {
        return this._unit;
    }

    public set unit(value: any) {
        this._setValue('_unit', value, 'Unit');
    }


}
import { ModelBase } from "../abstract/model-base.model";
import { Feature } from "./feature.model";
import { Quota } from "./quota.model";

export class PackageContent extends ModelBase {

    static override CLASS_NAME: string = 'PackageContent';

    private _feature!: Feature;
    private _quotas!: Quota[];

    constructor(data: any) {
        super(data);
        this._quotas = data['quotas'] || [];
    }

    public getQuota(quotaField: string): number | undefined | null {
        let quota: Quota | undefined = this.quotas.find((q) => q.field === quotaField);
        if (!quota) {
            // Le quota n'est pas défini -> pas de limite
            return undefined;
        } else {
            return quota.quota;
        }
    }

    public get feature(): Feature {
        return this._feature;
    }

    public set feature(value: any) {
        this._setValue('_feature', value, 'Feature');
    }

    public get quotas(): Quota[] {
        return this._quotas;
    }

    public set quotas(value: any) {
        this._setValue('_quotas', value, 'Quota');
    }
}

import { Injectable } from '@angular/core';

// Services
import { RouteParam, ServiceBase } from '../service-base';


const CLASS_NAME = 'File';
const ENDPOINT = `/files/`;
const ROUTES: RouteParam[] = [
    { key: 'downloadFile', path: '{id}/$value/' },
]

@Injectable({
    providedIn: 'root'
})
export class FileService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addRoutes(ROUTES);
    }
}

<div class="flex justify-content-between align-items-center p-3">
    <!-- Logo -->
    <div class="h-full flex align-items-center pl-1">

        <!-- Menu ouvert -->
        @if (appService.menuOpen) {

        <div class="h-3rem py-1" (click)="onClickLogo()">
            <img class="h-full" src="{{ appService.logoSrc }}" alt="Logo" />
        </div>

        <!-- Bouton pour fermer le menu -->
        <p-button type="button" icon="pi pi-angle-left" size="large" class="ml-3" [rounded]="true" [text]="true"
            (click)="appService.toggleMenu()"></p-button>

        <!-- Menu fermé -->
        } @else {

        <div class="h-3rem py-1" (click)="onClickLogo()">
            <img class="h-full" src="assets/images/big-icon.png" alt="Logo" />
        </div>

        <!-- Bouton pour ouvrir le menu -->
        <p-button type="button" icon="pi pi-angle-right" size="large" class="ml-3" [rounded]="true" [text]="true"
            (click)="appService.toggleMenu()"></p-button>
        }
    </div>

    @if (!Environment.production) {
        <!-- Message pour ignaler que c'est un environnement de TEST -->
        <div class="bg-red-50 border-2 border-round-xl p-2 text-2xl text-red-500 font-medium">Environnement de test</div>
    }
    <!-- Éléments du menu -->
    <div>
        <!-- Liste des organisations -->
        <p-button type="button" [label]="client?.commercialName" [text]="true" icon="pi pi-angle-down" iconPos="right"
            (click)="clientMenu.toggle($event)"></p-button>

        @if (!!appService.selectedClient) {
        <!-- Lien vers les paramètres -->
        <a routerLink="settings" class="mr-2"><p-button icon="pi pi-cog" [rounded]="true" [text]="true"></p-button></a>
        }

        <!-- Avatar de l'utilisateur -->
        <p-avatar image="{{appService.user?.avatar}}" label="{{appService.user?.avatarLabel}}"
            [style]="{ 'background-color': appService.user?.avatarLabelColor, color: '#ffffff' }" styleClass="mx-2"
            size="large" shape="circle"></p-avatar>

        <!-- Nom et menu utilisateur -->
        <p-button type="button" [label]="appService.user?.fullName!" [text]="true" icon="pi pi-angle-down"
            iconPos="right" (click)="userMenu.toggle($event)"></p-button>

        <!-- Invitations -->
        @if (invites.length > 0) {
        <p-button icon="pi pi-inbox" [badge]="invites.length.toString()" badgeClass="p-badge-danger" [rounded]="true"
            [text]="true" (click)="op.toggle($event)"></p-button>
        }

        <!-- Changement de thème -->
        <p-button icon="pi {{ appService.switchThemeIcon }}" [rounded]="true" [text]="true"
            (click)="onClickTheme()"></p-button>
    </div>
</div>

<p-menu #clientMenu [popup]="true" [model]="clientMenuItems"></p-menu>
<p-menu #userMenu [popup]="true" [model]="userMenuItems"></p-menu>

<p-overlayPanel #op>
    <ng-template pTemplate="content">
        @for (invite of invites; track invite.id; let last = $last) {
        <div class="flex align-items-center py-3">
            <div class="mr-3">
                <p-avatar image="{{invite.creationUser.avatar}}" label="{{invite.creationUser.avatarLabel}}"
                    [style]="{ 'background-color': invite.creationUser.avatarLabelColor, color: '#ffffff' }"
                    size="large" shape="circle"></p-avatar>
            </div>
            <div class="flex flex-column">
                <div class="mb-3">{{ invite.creationUser.fullName }} vous invite à rejoindre la société {{
                    invite.client.commercialName }}</div>
                <div class="flex align-item-center justify-content-between">
                    <div class="h-3rem">
                        @if (!!invite.client.logo) {
                        <p-image src="{{invite.client.logo}}" alt="Logo" height="100%"></p-image>
                        }
                    </div>
                    <div class="ml-3">
                        <p-button class="mr-2" icon="pi pi-check" severity="success" [outlined]="true"
                            pTooltip="Accepter l'invitation" tooltipPosition="bottom" [rounded]="true"
                            (click)="onClickAcceptInvite(invite)"></p-button>
                        <p-button icon="pi pi-times" severity="danger" [outlined]="true" [rounded]="true"
                            pTooltip="Refuser l'invitation" tooltipPosition="bottom"
                            (click)="onClickDeclineInvite(invite)"></p-button>
                    </div>
                </div>
            </div>
        </div>

        @if (!last) {
        <p-divider></p-divider>
        }
        }
    </ng-template>
</p-overlayPanel>

<p-dialog header="Connexion Bfor Scan" [modal]="true" [(visible)]="qrCodeVisible" [style]="{ width: '25rem' }">
    <!-- Texte d'explication -->
    <span class="p-text-secondary block mb-5">Scannez ce QR code sur l'application pour vous connecter.</span>
    <!-- Image du QR code en base 64 -->
    <img class="w-full" src="{{ qrCodeSrc }}" alt="QR Code" />
</p-dialog>
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { SubscribeFormComponent } from '../../components/subscribe-form/subscribe-form.component';

@Component({
    selector: 'app-subscribe',
    standalone: true,
    imports: [
        CommonModule,
        SubscribeFormComponent
    ],
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent extends PageBaseComponent {

}

export interface SorterParam {
    descending?: boolean;
    emptyFirst?: boolean;
    property: string;
}


export class Sorter {
    static readonly OPERATOR = {
        ASC: '',
        DESC: ' desc'
    };
    static readonly SEPARATOR: string = ',';
    static readonly URL_KEY: string = '$orderby=';

    descending: boolean;
    emptyFirst: boolean;
    property: string;

    constructor(params: SorterParam) {
        this.property = params.property;
        this.descending = !!params.descending ? params.descending : false;
        this.emptyFirst = !!params.emptyFirst ? params.emptyFirst : false;
    }

    public toString(): string {
        let url = this.property;
        if (this.descending) {
            url += Sorter.OPERATOR.DESC;
        }

        return url;
    }

    static toUrlString(sorters: Sorter[]): string {
        let url: string = Sorter.URL_KEY;

        // Concatenate sorters
        for (let key in sorters) {
            url += sorters[key].toString();
            url += Sorter.SEPARATOR;
        }
        // Remove last separator
        url = url.substring(0, url.length - 1);

        return url;
    }
}

import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'OrganizationSize';
const ENDPOINT = `/organization-sizes/`;

@Injectable({
    providedIn: 'root'
})
export class OrganizationSizeService extends ServiceBase {

    protected override useCache = true;

    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { ResetPasswordFormComponent } from '../../components/reset-password-form/reset-password-form.component';

@Component({
    selector: 'app-reset-password',
    standalone: true,
    imports: [
        CommonModule,
        ResetPasswordFormComponent
    ],
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends PageBaseComponent {

}

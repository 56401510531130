export const environment = {
    production: false,
    serverUrl: '/api',
    powensClient: '40274772',
    powensDomain: 'bfor-sandbox',
    powensRedirection: 'https://dev.bfor.nc',
    firebaseConfig: {
        apiKey: "AIzaSyC2-dzR35MxPlEXZa-p0B2q-imj--6ZqtM",
        authDomain: "bfor-scan-6f78e.firebaseapp.com",
        projectId: "bfor-scan-6f78e",
        storageBucket: "bfor-scan-6f78e.appspot.com",
        messagingSenderId: "239677059771",
        appId: "1:239677059771:web:e04296572b29ae14512f9e",
        measurementId: "G-BCW5608SB3"
    }
};

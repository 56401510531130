import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'AccountingSettings';
const ENDPOINT = `/accounting-settings/`;

@Injectable({
    providedIn: 'root'
})
export class AccountingSettingsService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

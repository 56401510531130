import * as moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { Bank } from './bank.model';
import { Currency } from '../core/currency.model';
import { Partner } from '../partner/partner.model';
import { SalesInvoicePayment } from '../sales/invoice/sales-invoice-payment.model';
import { PurchaseInvoicePayment } from '../purchasing/invoice/purchase-invoice-payment.model';
import { InvoicePaymentBase } from '../invoicing/abstract/invoice-payment-base.model';
import { LegacyPayment } from '../invoicing/legacy-payment.model';
import tagAccents from "src/assets/themes/biifor/components/tag";

export class Cheque extends ModelBase {

    static override CLASS_NAME: string = 'Cheque';

    private _amount!: number;
    private _bank!: Bank;
    private _currency!: Currency;
    private _issueDate!: moment.Moment;
    private _legacyPayment!: LegacyPayment;
    private _number!: string;
    private _partner!: Partner;
    private _potentialPurchaseInvoicePayments!: PurchaseInvoicePayment[];
    private _potentialSalesInvoicePayments!: SalesInvoicePayment[];
    private _purchaseInvoicePayments!: PurchaseInvoicePayment[];
    private _sales!: boolean;
    private _salesInvoicePayments!: SalesInvoicePayment[];
    private _amountMatched!: number;
    private _amountUnmatched!: number;

    constructor(data: any) {
        super(data);
        if (!this._purchaseInvoicePayments) {
            this._purchaseInvoicePayments = [];
        }
        if (!this._potentialPurchaseInvoicePayments) {
            this._potentialPurchaseInvoicePayments = [];
        }
        if (!this._salesInvoicePayments) {
            this._salesInvoicePayments = [];
        }
        if (!this._potentialSalesInvoicePayments) {
            this._potentialSalesInvoicePayments = [];
        }
    }

    public static statusSeverity(status: string): string {
        switch (true) {
            case (status === 'cashed'):
            case (status === 'deposited'):
                return 'success';
            case (status === 'posted'):
                return 'primary';
            case (status === 'new'):
                return 'warn';
            default:
                return '';
        }
    }

    public static statusLabel(status: string): string {
        switch (true) {
            case (status === 'cashed'):
                return 'Encaissé';
            case (status === 'deposited'):
                return 'Déposé';
            case (status === 'posted'):
                return 'Validé';
            case (status === 'new'):
                return 'Nouveau';
            default:
                return '';
        }
    }

    public static tagAccent(status: string): Object | undefined {
        switch (true) {
            case (status === 'cashed'):
                return tagAccents;
            default:
                return undefined;
        }
    }

    public override toString(): string {
        let label = this.bank.commercialName;
        if (this.number && this.number.length > 0) {
            label += ' - ' + this.number;
        }

        return label;
    }

    public get allPayments(): InvoicePaymentBase[] {
        let allPayments: InvoicePaymentBase[] = [];
        allPayments = allPayments.concat(this.purchaseInvoicePayments);
        allPayments = allPayments.concat(this.salesInvoicePayments);
        return allPayments;
    }

    public get allPotentialPayments(): InvoicePaymentBase[] {
        let allPayments: InvoicePaymentBase[] = [];
        allPayments = allPayments.concat(this.potentialPurchaseInvoicePayments);
        allPayments = allPayments.concat(this.potentialSalesInvoicePayments);
        return allPayments;
    }

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get amountMatched(): number {
        return this._amountMatched;
    }

    public set amountMatched(value: any) {
        this._setValue('_amountMatched', value, 'float');
    }

    public get amountUnmatched(): number {
        return this._amountUnmatched;
    }

    public set amountUnmatched(value: any) {
        this._setValue('_amountUnmatched', value, 'float');
    }

    public get bank(): Bank {
        return this._bank;
    }

    public set bank(value: any) {
        this._setValue('_bank', value, 'Bank');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get issueDate(): moment.Moment {
        return this._issueDate;
    }

    public set issueDate(value: any) {
        this._setValue('_issueDate', value, 'date');
    }

    public get legacyPayment(): LegacyPayment {
        return this._legacyPayment;
    }

    public set legacyPayment(value: any) {
        this._setValue('_legacyPayment', value, 'LegacyPayment');
    }

    public get number(): string {
        return this._number;
    }

    public set number(value: any) {
        this._setValue('_number', value, 'string');
    }

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

    public get purchaseInvoicePayments(): PurchaseInvoicePayment[] {
        return this._purchaseInvoicePayments;
    }

    public set purchaseInvoicePayments(value: any) {
        this._setValue('_purchaseInvoicePayments', value, 'PurchaseInvoicePayment');
    }

    public get potentialPurchaseInvoicePayments(): PurchaseInvoicePayment[] {
        return this._potentialPurchaseInvoicePayments;
    }

    public set potentialPurchaseInvoicePayments(value: any) {
        this._setValue('_potentialPurchaseInvoicePayments', value, 'PurchaseInvoicePayment');
    }

    public get potentialSalesInvoicePayments(): SalesInvoicePayment[] {
        return this._potentialSalesInvoicePayments;
    }

    public set potentialSalesInvoicePayments(value: any) {
        this._setValue('_potentialSalesInvoicePayments', value, 'SalesInvoicePayment');
    }

    public get sales(): boolean {
        return this._sales;
    }

    public set sales(value: any) {
        this._setValue('_sales', value, 'boolean');
    }

    public get salesInvoicePayments(): SalesInvoicePayment[] {
        return this._salesInvoicePayments;
    }

    public set salesInvoicePayments(value: any) {
        this._setValue('_salesInvoicePayments', value, 'SalesInvoicePayment');
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusSeverity(): string {
        switch (true) {
            case (this.hasStatus('cashed')):
                return Cheque.statusSeverity('cashed');
            case (this.hasStatus('deposited')):
                return Cheque.statusSeverity('deposited');
            case (this.hasStatus('posted')):
                return Cheque.statusSeverity('posted');
            case (this.hasStatus('new')):
                return Cheque.statusSeverity('new');
            default:
                return Cheque.statusSeverity('');
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusLabel(): string {
        switch (true) {
            case (this.hasStatus('cashed')):
                return Cheque.statusLabel('cashed');
            case (this.hasStatus('deposited')):
                return Cheque.statusLabel('deposited');
            case (this.hasStatus('posted')):
                return Cheque.statusLabel('posted');
            case (this.hasStatus('new')):
                return Cheque.statusLabel('new');
            default:
                return '';
        }
    }

    public get tagAccent(): Object | undefined {
        switch (true) {
            case (this.hasStatus('cashed')):
                return Cheque.tagAccent('cashed');
            case (this.hasStatus('deposited')):
                return Cheque.tagAccent('deposited');
            case (this.hasStatus('posted')):
                return Cheque.tagAccent('posted');
            case (this.hasStatus('new')):
                return Cheque.tagAccent('new');
            default:
                return Cheque.tagAccent('');
        }
    }

}

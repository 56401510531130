import * as moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { BankAccount } from './bank-account.model';
import { Cheque } from './cheque.model';
import { Currency } from '../core/currency.model';

export class Deposit extends ModelBase {

    static override CLASS_NAME: string = 'Deposit';

    private _amount!: number;
    private _bankAccount!: BankAccount;
    private _cheques!: Cheque[];
    private _currency!: Currency;
    private _depositDate!: moment.Moment;

    constructor(data: any) {
        super(data);
        if (!this._cheques) {
            this._cheques = [];
        }
    }

    public static statusColor(status: string): string {
        switch (true) {
            case (status === 'cashed'):
                return 'success';
            case (status === 'deposited'):
                return 'primary';
            case (status === 'new'):
                return 'danger';
            default:
                return '';
        }
    }

    public static statusLabel(status: string): string {
        switch (true) {
            case (status === 'cashed'):
                return 'Encaissée';
            case (status === 'deposited'):
                return 'Déposée';
            case (status === 'new'):
                return 'Ouverte';
            default:
                return '';
        }
    }

    public override toString(): string {
        return `${this.bankAccount.label} - ${this.amount} ${this.currency.code}`;
    }

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get bankAccount(): BankAccount {
        return this._bankAccount;
    }

    public set bankAccount(value: any) {
        this._setValue('_bankAccount', value, 'BankAccount');
    }

    public get cheques(): Cheque[] {
        return this._cheques;
    }

    public set cheques(value: any) {
        this._setValue('_cheques', value, 'Cheque');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get depositDate(): moment.Moment {
        return this._depositDate;
    }

    public set depositDate(value: any) {
        this._setValue('_depositDate', value, 'date');
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusColor(): string {
        switch (true) {
            case (this.hasStatus('cashed')):
                return Deposit.statusColor('cashed');
            case (this.hasStatus('deposited')):
                return Deposit.statusColor('deposited');
            case (this.hasStatus('new')):
                return Deposit.statusColor('new');
            default:
                return Deposit.statusColor('');
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusLabel(): string {
        switch (true) {
            case (this.hasStatus('cashed')):
                return Deposit.statusLabel('cashed');
            case (this.hasStatus('deposited')):
                return Deposit.statusLabel('deposited');
            case (this.hasStatus('new')):
                return Deposit.statusLabel('new');
            default:
                return '';
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { MessageServiceBroker } from '../message.service';
import { RouteParam, ServiceBase } from '../service-base';


const CLASS_NAME = 'File';
const ENDPOINT = `/files/`;
const ROUTES: RouteParam[] = [
    { key: 'downloadFile', path: '{id}/$value/' },
]

@Injectable({
    providedIn: 'root'
})
export class FileService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addRoutes(ROUTES);
    }
}

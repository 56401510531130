import { ModelBase } from '../abstract/model-base.model';
import { Country } from '../core/country.model';

export class Bank extends ModelBase {

    static override CLASS_NAME: string = 'Bank';

    private _bic!: string;
    private _commercialName!: string;
    private _country!: Country;
    private _name!: string;

    public get bic(): string {
        return this._bic;
    }

    public set bic(value: any) {
        this._setValue('_bic', value, 'string');
    }

    public get commercialName(): string {
        return this._commercialName;
    }

    public set commercialName(value: any) {
        this._setValue('_commercialName', value, 'string');
    }

    public get country(): Country {
        return this._country;
    }

    public set country(value: any) {
        this._setValue('_country', value, 'Country');
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: any) {
        this._setValue('_name', value, 'string');
    }

}

import { SalesInvoiceBase } from '../abstract/sales-invoice-base.model';
import { SalesInvoiceAllocation } from './sales-invoice-allocation.model';
import { SalesInvoiceItem } from './sales-invoice-item.model';
import { SalesInvoiceTax } from './sales-invoice-tax.model';

export class SalesInvoice extends SalesInvoiceBase {

    static override CLASS_NAME: string = 'SalesInvoice';

    private _allocations!: SalesInvoiceAllocation[];
    private _downpaymentsNetAmount!: number;
    private _generalConditions!: string;
    private _items!: SalesInvoiceItem[];
    private _taxes!: SalesInvoiceTax[];

    constructor(data: any) {
        super(data);
        this._allocations = this._allocations || [];
        this._items = this._items || [];
        this._taxes = this._taxes || [];
    }

    public get allocations(): SalesInvoiceAllocation[] {
        return this._allocations;
    }

    public set allocations(value: any) {
        this._setValue('_allocations', value, 'SalesInvoiceAllocation');
    }

    public get downpaymentsNetAmount(): number {
        return this._downpaymentsNetAmount;
    }

    public set downpaymentsNetAmount(value: number) {
        this._setValue('_downpaymentsNetAmount', value, 'float');
    }

    public get generalConditions(): string {
        return this._generalConditions;
    }

    public set generalConditions(value: string) {
        this._setValue('_generalConditions', value, 'string');
    }

    public get items(): SalesInvoiceItem[] {
        return this._items;
    }

    public set items(value: any) {
        this._setValue('_items', value, 'SalesInvoiceItem');
    }

    public get taxes(): SalesInvoiceTax[] {
        return this._taxes;
    }

    public set taxes(value: any) {
        this._setValue('_taxes', value, 'SalesInvoiceTax');
    }
}

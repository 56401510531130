import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, map, Observable, of } from 'rxjs';

// Modèles
import { ModelBase } from 'src/app/@shared/models/abstract/model-base.model';
import { GeneralFactory } from 'src/app/@shared/models/factories/general-factory';
import { Currency } from 'src/app/@shared/models/core/currency.model';
import { Partner } from 'src/app/@shared/models/partner/partner.model';
import { Product } from 'src/app/@shared/models/product/product.model';

// Services
import { MessageServiceBroker } from '../message.service';
import { RouteParam, ServiceBase } from '../service-base';

// Utils
import { QueryOptions } from 'src/app/@shared/utilities/odata/query-params';
import { Filter } from 'src/app/@shared/utilities/models/filter';


const CLASS_NAME = 'Product';
const ENDPOINT = `/products/`;
const ROUTES: RouteParam[] = [
    { key: 'pricing', path: '{id}/pricing/' },
]

@Injectable({
    providedIn: 'root'
})
export class ProductService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addRoutes(ROUTES);
    }

    /**
     * Appel des données de prix pour un article pour un client dans une devise donnée
     * @param product L'article
     * @param partner Le client
     * @param currency La devise cible
     * @returns Un objet pricing
     */
    public pricing$(product: Product, partner: Partner, currency: Currency): Observable<any> {
        // Indicateur d'activité
        this._reading$.next(true);

        let url = this.routes.pricing.replace('{id}', product.id.toString());
        let queryOptions = new QueryOptions(url, {
            filters: [
                new Filter({
                    property: 'partner',
                    comparator: Filter.COMPARATOR.EQ,
                    value: partner.id
                }),
                new Filter({
                    property: 'currency',
                    comparator: Filter.COMPARATOR.EQ,
                    value: currency.code
                })
            ]
        });
        let queryUrl = queryOptions.toUrlString();
        return this.httpClient.get(queryUrl).pipe(
            finalize(() => {
                this._reading$.next(false);
            }),
            map((result: any) => {
                // Instanciation de l'objet lu
                let instance = (GeneralFactory.get('Pricing', result) as ModelBase);
                return instance;
            }),
            catchError(
                (error: any) => {
                    // Manage error
                    this.handleError(error);
                    return of(false);
                }
            )
        );
    }
}

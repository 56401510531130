import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'Tax';
const ENDPOINT = `/taxes/`;

@Injectable({
    providedIn: 'root'
})
export class TaxesService extends ServiceBase {

    protected override useCache = true;

    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

import { InvoiceBase } from "../invoicing/abstract/invoice-base.model";
import { InvoicePaymentBase } from "../invoicing/abstract/invoice-payment-base.model";

export class OverpaymentReimbursement extends InvoicePaymentBase {

    static override CLASS_NAME: string = 'OverpaymentReimbursement';

    protected override _invoice!: InvoiceBase;
    protected override _creditNote!: InvoiceBase;
    public override get creditNote(): InvoiceBase {
        throw new Error("Method not implemented.");
    }
    public override set creditNote(value: InvoiceBase) {
        throw new Error("Method not implemented.");
    }
    public override get invoice(): InvoiceBase {
        throw new Error("Method not implemented.");
    }
    public override set invoice(value: InvoiceBase) {
        throw new Error("Method not implemented.");
    }
}
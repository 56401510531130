import { Component, Injector, inject } from '@angular/core';

import { ConfirmationService, PrimeTemplate } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { PrimeNG } from 'primeng/config';

import { ConfirmationServiceBroker } from './@core/services/confirmation.service';
import { DialogConfigService } from './@core/services/dialog-config.service';
import { MessageServiceBroker } from './@core/services/message.service';
import { AppService } from './@core/services/app.service';
import { RouterOutlet } from '@angular/router';
import { Toast } from 'primeng/toast';
import { ConfirmDialog } from 'primeng/confirmdialog';

export let AppInjector: Injector;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        ConfirmationService,
        DialogService,
        DynamicDialogRef,
        MessageService,
    ],
    imports: [RouterOutlet, Toast, PrimeTemplate, ConfirmDialog]
})
export class AppComponent {
    private _injector = inject(Injector);
    private _primeng = inject(PrimeNG);
    private _confirmationService = inject(ConfirmationService);
    private _confirmationServiceBroker = inject(ConfirmationServiceBroker);
    private _dialogService = inject(DialogService);
    private _dialogConfigService = inject(DialogConfigService);
    private _messageService = inject(MessageService);
    private _messageServiceBroker = inject(MessageServiceBroker);
    private _dialogRef = inject(DynamicDialogRef);
    private _appService = inject(AppService);


    public appReady: boolean = false;

    constructor() {
        AppInjector = this._injector;
    }

    ngOnInit() {
        this._primeng.ripple.set(true);

        // Souscription générale aux demandes de confirmation de l'application
        this._confirmationServiceBroker.confirmation$.subscribe(confirmation => {
            if (!Object.keys(confirmation).length) return;
            this._confirmationService.confirm(confirmation);
        });

        // Souscription générale aux ouvertures de modales
        this._dialogConfigService.dialogConfig$.subscribe(config => {
            // Config vide
            if (!config || !Object.keys(config).length) return;

            this._dialogRef = this._dialogService.open(config.component, config.config);
            this._dialogConfigService.setDialogRef(config.config.data.uuid, this._dialogRef);
        });

        // Souscription générale aux message de l'application
        this._messageServiceBroker.messages$.subscribe(messages => {
            this._messageService.addAll(messages);
        });

        // Indiquer que l'application est prête après le chargement des données initiales
        this._appService.clientsLoaded$.subscribe(() => {
            this.appReady = true;
            const loadingDiv = document.getElementById('globalLoading');
            // Suppression de l'élément de chargement
            if (loadingDiv) loadingDiv.remove();
        });
    }
}

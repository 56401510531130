@if (!!onboarding && !onboarding.skipped && progress < 100) { <p-card [style]="{ width: '25rem', overflow: 'hidden' }"
    styleClass="border-1 border-blue-100">
    <ng-template #header>
        <div class="bg-blue-50 px-3 py-5 flex align-items-center justify-content-between">
            <span class="text-xl text-900">Utilisez Biifor à son maximum !</span>
            <p-button [text]="true" [rounded]="true" icon="pi pi-chevron-{{showSteps ? 'up' : 'down'}}"
                (onClick)="toggleSteps()" />
        </div>
        <p-progressBar [value]="progress" [showValue]="false" [style]="{ height: '0.5rem' }" mode="determinate"
            [color]="progressBarColor" />
    </ng-template>
    @if (showSteps) {
    <p-accordion [(value)]="active">
        @for (step of onboarding!.steps; track step.code) {
        <p-accordion-panel [disabled]="!!step.authorization && !appService.user?.hasAuhtorization(step.authorization)"
            [value]="step.code">
            <p-accordion-header>
                <ng-template #toggleicon let-active="active">
                    @if (step.done) {
                    <i class="pi pi-check-circle" style="color: var(--green-500)"></i>
                    } @else if (active) {
                    <i class="pi pi-chevron-up"></i>
                    } @else {
                    <i class="pi pi-chevron-down"></i>
                    }
                </ng-template>
                <div class="flex align-items-center justify-content-start">
                    {{ step.title }}
                </div>
            </p-accordion-header>
            <p-accordion-content>
                <p class="m-0">{{ step.description }}</p>
                <div class="flex gap-2 flex-column mt-3">
                    @if (!step.done) {
                    <p-button label="Passer l'étape" severity="secondary" class="w-full" [outlined]="true"
                        styleClass="w-full" [rounded]="true" (onClick)="onClickSkipStep(step)" />
                    }
                    @if (step.code === 'import_purchase_invoice') {
                    <p-fileUpload #fu mode="basic" accept="application/pdf,image/jpeg,image/png" chooseLabel="Importer une dépense"
                        chooseIcon="pi pi-cloud-upload" styleClass="w-full" chooseStyleClass="p-button-rounded w-full" [multiple]="true"
                        [auto]="true" [customUpload]="true"
                        (uploadHandler)="onUpload($event);fu.clear()"></p-fileUpload>
                    } @else {
                    <p-button [label]="step.actionLabel" class="w-full" styleClass="w-full" [rounded]="true"
                        (onClick)="onClickAction(step)" />
                    }
                </div>
            </p-accordion-content>
        </p-accordion-panel>
        }
    </p-accordion>
    } @else {
    <div class="flex align-items-center justify-content-between">
        <span>{{ stepsDone }} étapes sur {{ stepsTotal }} complétées</span>
        <p-button label="Ignorer" [rounded]="true" [outlined]="true" (onClick)="onClickSkipOnboarding()" />
    </div>
    }
    </p-card>
    }
import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'InvoiceContact';
const ENDPOINT = `/invoice-contacts/`;

@Injectable({
    providedIn: 'root'
})
export class InvoiceContactService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'Country';
const ENDPOINT = `/countries/`;

@Injectable({
    providedIn: 'root'
})
export class CountryService extends ServiceBase {

    protected override useCache = true;

    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { BankAccount } from 'src/app/@shared/models/banking/bank-account.model';
import { BankAccountRule } from 'src/app/@shared/models/banking/bank-account-rule.model';

const CLASS_NAME = 'BankAccount';
const ENDPOINT = `/bank-accounts/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'duplicates', path: 'duplicates/', childClass: 'BankAccountLine' },
    { key: 'rules', path: 'rules/', childClass: 'BankAccountRule' },
    { key: 'transactions', path: 'transactions/', childClass: 'BankAccountLine' },
]
const ROUTES: RouteParam[] = [
    { key: 'acceptDuplicates', path: '{id}/accept-duplicates/' },
    { key: 'deleteDuplicates', path: '{id}/delete-duplicates/' },
    { key: 'transactionsUpload', path: '{id}/upload-transactions/' },
]

@Injectable({
    providedIn: 'root'
})
export class BankAccountService extends ServiceBase {

    protected _createdRule$: Subject<BankAccountRule> = new Subject<BankAccountRule>();
    public readonly createdRule$ = this._createdRule$.asObservable();

    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public createRule(bankAccount: BankAccount, rule: BankAccountRule): void {
        this.createRelated$(rule, bankAccount, 'rules').subscribe(
            rule => this._createdRule$.next(rule)
        );
    }

    public uploadTransactions$(bankAccount: BankAccount, file: File): Observable<any> {
        let url = this.routes.transactionsUpload.replace('{id}', bankAccount.id.toString());
        return this.uploadFile$(file, url);
    }
}

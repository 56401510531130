import { ModelBase } from '../abstract/model-base.model';
import { OnboardingStep } from './step.model';

export class Onboarding extends ModelBase {

    static override CLASS_NAME: string = 'Onboarding';

    private _skipped!: boolean;
    private _steps!: OnboardingStep[];

    public get skipped(): boolean {
        return this._skipped;
    }

    public set skipped(value: any) {
        this._setValue('_skipped', value, 'boolean');
    }

    public get steps(): OnboardingStep[] {
        return this._steps;
    }

    public set steps(value: any) {
        this._setValue('_steps', value, 'OnboardingStep');

        // Tri des steps par ordre croissant
        this._steps.sort((a, b) => a.order - b.order);
    }
}

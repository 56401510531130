import { Component } from '@angular/core';

// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { BankAccountBalancesComponent } from 'src/app/@shared/components/widgets/bank-account-balances/bank-account-balances.component';
import { ChargesWidgetComponent } from 'src/app/@shared/components/widgets/charges-widget/charges-widget.component';
import { MonthlySummaryWidgetComponent } from 'src/app/@shared/components/widgets/monthly-summary-widget/monthly-summary-widget.component';
import { OnboardingCardComponent } from 'src/app/@shared/components/onboarding/onboarding-card/onboarding-card.component';
import { PartnerDebtsWidgetComponent } from 'src/app/@shared/components/widgets/partner-debts-widget/partner-debts-widget.component';
import { DsoWidgetComponent } from 'src/app/@shared/components/widgets/dso-widget/dso-widget.component';

@Component({
    selector: 'app-dashboard',
    imports: [
        BankAccountBalancesComponent,
        ChargesWidgetComponent,
        DsoWidgetComponent,
        MonthlySummaryWidgetComponent,
        OnboardingCardComponent,
        PartnerDebtsWidgetComponent
    ],
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends PageBaseComponent {

    public onPurchaseInvoiceUploaded(): void {
        // Navigation vers la liste des factures d'achat
        this.router.navigate(['/purchasing/invoices']);
    }

}

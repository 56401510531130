import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';

import { NgApexchartsModule, ApexNonAxisChartSeries, ApexChart } from 'ng-apexcharts';

import { CardModule } from 'primeng/card';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

// Composants
import { BaseComponent } from '../../abstract/base.component';

// Modèles
import { Account } from 'src/app/@shared/models/accounting/account.model';
import { AccountingPeriod } from 'src/app/@shared/models/accounting/accounting-period.model';
import { KpiCharges } from 'src/app/@shared/models/kpi/charges.model';

// Utils
import { Filter } from 'src/app/@shared/utilities/models/filter';
import { Sorter } from 'src/app/@shared/utilities/models/sorter';


@Component({
    selector: 'app-charges-widget',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        NgApexchartsModule,
        CardModule,
        DropdownModule,
        FileUploadModule,
        OverlayPanelModule,
        ProgressSpinnerModule,
    ],
    templateUrl: './charges-widget.component.html',
    styleUrls: ['./charges-widget.component.scss']
})
export class ChargesWidgetComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    public chart: ApexChart = {
        type: 'pie',
        height: 426,
        events: {
            dataPointSelection: this.onClickChart.bind(this),
            dataPointMouseEnter: function (event) {
                event.currentTarget.style.cursor = "pointer";
            },
            legendClick: this.onLegendClick.bind(this)
        }
    };
    public chartColors: string[] = [
        '#4C89FF', // --blue-400
        '#25C685', // --green-400
        '#FFBC25', // --yellow-400
        '#FF6163', // --red-400
        '#8A59FF', // --purple-400
        '#00D4FF', // --cyan-400
        '#9ca3af', // --gray-400
        '#FF4FE3', // --pink-400
        '#FF8A34', // --orange-400
    ];
    public chartLabels: string[] = [];
    public chartSeries: ApexNonAxisChartSeries = [];

    public selectedPeriod: AccountingPeriod | undefined;

    public override loading: boolean = true;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _kpis: KpiCharges[] = [];
    public get kpis(): KpiCharges[] {
        return this._kpis;
    }
    public set kpis(kpis: KpiCharges[]) {
        let emptyArray: KpiCharges[] = [];
        this._kpis = emptyArray.concat(kpis);
        this._buildChartData();
    }

    private _accountingPeriods: AccountingPeriod[] = [];
    public get accountingPeriods(): AccountingPeriod[] {
        return this._accountingPeriods;
    }
    public set accountingPeriods(accountingPeriods: AccountingPeriod[]) {
        let emptyArray: AccountingPeriod[] = []
        this._accountingPeriods = emptyArray.concat(accountingPeriods);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public onChangeAccountingPeriod(event: DropdownChangeEvent) {
        this._loadKpis(event.value);
    }

    public onClickChart(event: any, chart?: any, options?: any) {
        let account = this.kpis[options.dataPointIndex].account;
        this._navigateToEvents(this.selectedPeriod!.dateFrom, this.selectedPeriod!.dateTo, account);
    }

    public onLegendClick(chartContext: any, seriesIndex: number, config: any) {
        let account = this.kpis[seriesIndex].account;
        this._navigateToEvents(this.selectedPeriod!.dateFrom, this.selectedPeriod!.dateTo, account);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override subscribeToObservables(): void {
        this.subscriptions.add(
            this.appService.clientSelected$.subscribe(
                client => {
                    if (!!client) {
                        this._loadAccountingPeriods()
                    }
                }
            )
        );
    }

    protected override uploadFile$(file: File): Observable<any> {
        return this.purchaseInvoiceService.upload$(file);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _buildChartData() {
        let labels = [];
        let series = [];
        for (let i = 0; i < this.kpis.length; i++) {
            const element = this.kpis[i];
            labels.push(this.kpis[i].account.label);
            series.push(this.kpis[i].balance);
        }
        this.chartLabels = labels;
        this.chartSeries = series;
    }

    private _loadAccountingPeriods() {
        this.accountingPeriodService.getList$({
            sorters: [
                new Sorter({
                    property: 'dateFrom',
                    descending: true
                })
            ]
        }).subscribe(accountingPeriods => {
            this.accountingPeriods = accountingPeriods;
            if (!!accountingPeriods[0]) {
                this._loadKpis(accountingPeriods[0]);
                // Sélection de la première période pour le dropdown
                this.selectedPeriod = accountingPeriods[0];
            }
        });
    }

    private _loadKpis(accountingPeriod: AccountingPeriod) {
        let filter = new Filter({
            filters: [
                new Filter({
                    property: 'dateFrom',
                    value: accountingPeriod.dateFrom,
                }),
                new Filter({
                    property: 'dateTo',
                    value: accountingPeriod.dateTo,
                })
            ]
        });
        this.loading = true;
        this.kpiService.getRelated$(this.appService.selectedClient, 'charges', {
            filters: [filter]
        }).subscribe(
            kpis => {
                this.kpis = kpis;
                this.loading = false;
            }
        );
    }

    private _navigateToEvents(dateFrom: moment.Moment, dateTo: moment.Moment, account: Account) {
        if (!account.id) return;
        let filter: Filter = new Filter({
            filters: [
                new Filter({
                    property: 'dateFrom',
                    value: dateFrom.format('YYYY-MM-DD')
                }),
                new Filter({
                    property: 'dateTo',
                    value: dateTo.format('YYYY-MM-DD')
                }),
                new Filter({
                    property: 'account',
                    value: account.account
                }),
                new Filter({
                    property: 'charges',
                    value: 'true'
                })
            ]
        });
        this.router.navigate(
            ['/reports/business-events'],
            {
                queryParams: {
                    $filter: Filter.toUrlString([filter], false)
                }
            }
        );
    }

}

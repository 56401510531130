import { ModelBase } from '../abstract/model-base.model';
import { Account } from './account.model';
import { AccountEntry } from './account-entry.model';
import { TransactionDirection } from '../banking/transaction-direction.model';
import moment from 'moment';

export class AccountEntryLine extends ModelBase {

    static override CLASS_NAME: string = 'AccountEntryLine';

    private _account!: Account;
    private _accountEntry!: AccountEntry;
    private _amount!: number;
    private _amountOrg!: number;
    private _auxiliaryAccount!: Account;
    private _debitCredit!: TransactionDirection;
    private _label!: string;
    private _lettering!: string;
    private _letteringDate!: moment.Moment;
    private _number!: number;

    public get account(): Account {
        return this._account;
    }

    public set account(value: any) {
        this._setValue('_account', value, 'Account');
    }

    public get accountEntry(): AccountEntry {
        return this._accountEntry;
    }

    public set accountEntry(value: any) {
        this._setValue('_accountEntry', value, 'AccountEntry');
    }

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get amountOrg(): number {
        return this._amountOrg;
    }

    public set amountOrg(value: any) {
        this._setValue('_amountOrg', value, 'float');
    }

    public get auxiliaryAccount(): Account {
        return this._auxiliaryAccount;
    }

    public set auxiliaryAccount(value: any) {
        this._setValue('_auxiliaryAccount', value, 'Account');
    }

    public get debitCredit(): TransactionDirection {
        return this._debitCredit;
    }

    public set debitCredit(value: any) {
        this._setValue('_debitCredit', value, 'TransactionDirection');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get lettering(): string {
        return this._lettering;
    }

    public set lettering(value: any) {
        this._setValue('_lettering', value, 'string');
    }

    public get letteringDate(): moment.Moment {
        return this._letteringDate;
    }

    public set letteringDate(value: any) {
        this._setValue('_letteringDate', value, 'date');
    }

    public get number(): number {
        return this._number;
    }

    public set number(value: any) {
        this._setValue('_number', value, 'integer');
    }

}

import moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { Account } from '../accounting/account.model';
import { BankAccountLine } from './bank-account-line.model';
import { Bank } from './bank.model';
import { Currency } from '../core/currency.model';
import { Journal } from '../accounting/journal.model';

import { PowensSyncErrorEnum, PowensSyncErrorDetails } from '../../enums/powensErrors.enum';

export class BankAccount extends ModelBase {

    static override CLASS_NAME: string = 'BankAccount';

    private _account!: Account;
    private _accountNumber!: string;
    private _bank!: Bank;
    private _balance!: number;
    private _comingBalance!: number;
    private _currency!: Currency;
    private _dashboard!: boolean;
    private _hasDuplicateTransactions!: boolean;
    private _iban!: string;
    private _initialBalance!: number;
    private _initialBalanceDate!: moment.Moment;
    private _journal!: Journal;
    private _journalCode!: string;
    private _label!: string;
    private _lines!: BankAccountLine[];
    private _unjustifiedLinesCount!: number;

    // Agrégation bancaire
    private _aggregated!: boolean;
    private _aggregationConnectionId!: number;
    private _aggregationDeleted!: boolean;
    private _aggregationDisabled!: boolean;
    private _aggregationError!: string;
    private _aggregationErrorMessage!: string;
    private _aggregationLastUpdate!: moment.Moment;
    private _aggregationNextTry!: moment.Moment;
    private _aggregationSoftDeleted!: boolean;
    private _aggregationState!: string;
    private _aggregationSyncing!: boolean;

    public override toString(): string {
        let label = '';
        if (!!this.bank) {
            label = `${this.bank.commercialName} - ${this.label}`;
        } else {
            label = `${this.label}`;
        }

        if (this.iban && this.iban.length > 0) {
            label += ` - ${this.iban}`;
        }

        return label;
    }

    public get account(): Account {
        return this._account;
    }

    public set account(value: any) {
        this._setValue('_account', value, 'Account');
    }

    public get accountNumber(): string {
        return this._accountNumber;
    }

    public set accountNumber(value: any) {
        this._setValue('_accountNumber', value, 'string');
    }

    public get aggregated(): boolean {
        return this._aggregated;
    }

    public set aggregated(value: any) {
        this._setValue('_aggregated', value, 'boolean');
    }

    public get aggregationConnectionId(): number {
        return this._aggregationConnectionId;
    }

    public set aggregationConnectionId(value: any) {
        this._setValue('_aggregationConnectionId', value, 'integer');
    }

    public get aggregationDeleted(): boolean {
        return this._aggregationDeleted;
    }

    public set aggregationDeleted(value: any) {
        this._setValue('_aggregationDeleted', value, 'boolean');
    }

    public get aggregationDisabled(): boolean {
        return this._aggregationDisabled;
    }

    public set aggregationDisabled(value: any) {
        this._setValue('_aggregationDisabled', value, 'boolean');
    }

    public get aggregationError(): string {
        return this._aggregationError;
    }

    public set aggregationError(value: any) {
        this._setValue('_aggregationError', value, 'string');
    }

    public get aggregationErrorDisplay(): string {
        const errorInfo = PowensSyncErrorDetails[this.aggregationState as PowensSyncErrorEnum];
        return errorInfo ? errorInfo.userMessage : 'Une erreur est survenue lors de la synchronisation de votre compte bancaire. Si le problème persiste, veuillez contacter le support.';
    }

    public get aggregationErrorMessage(): string {
        return this._aggregationErrorMessage;
    }

    public set aggregationErrorMessage(value: any) {
        this._setValue('_aggregationErrorMessage', value, 'string');
    }

    public get aggregationHasIssue(): boolean {
        return this.aggregationError !== null || this.aggregationErrorMessage !== null;
    }

    public get aggregationLastUpdate(): moment.Moment {
        return this._aggregationLastUpdate;
    }

    public set aggregationLastUpdate(value: any) {
        this._setValue('_aggregationLastUpdate', value, 'datetime');
    }

    public get aggregationNextTry(): moment.Moment {
        return this._aggregationNextTry;
    }

    public set aggregationNextTry(value: any) {
        this._setValue('_aggregationNextTry', value, 'datetime');
    }

    public get aggregationSoftDeleted(): boolean {
        return this._aggregationSoftDeleted;
    }

    public set aggregationSoftDeleted(value: any) {
        this._setValue('_aggregationSoftDeleted', value, 'boolean');
    }

    public get aggregationState(): string {
        return this._aggregationState;
    }

    public set aggregationState(value: any) {
        this._setValue('_aggregationState', value, 'string');
    }

    public get aggregationSyncing(): boolean {
        return this._aggregationSyncing;
    }

    public set aggregationSyncing(value: any) {
        this._setValue('_aggregationSyncing', value, 'boolean');
    }

    public get bank(): Bank {
        return this._bank;
    }

    public set bank(value: any) {
        this._setValue('_bank', value, 'Bank');
    }

    public get balance(): number {
        return this._balance;
    }

    public set balance(value: any) {
        this._setValue('_balance', value, 'float');
    }

    public get comingBalance(): number {
        return this._comingBalance;
    }

    public set comingBalance(value: any) {
        this._setValue('_comingBalance', value, 'float');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get dashboard(): boolean {
        return this._dashboard;
    }

    public set dashboard(value: any) {
        this._setValue('_dashboard', value, 'boolean');
    }

    public get hasDuplicateTransactions(): boolean {
        return this._hasDuplicateTransactions;
    }

    public set hasDuplicateTransactions(value: any) {
        this._setValue('_hasDuplicateTransactions', value, 'boolean');
    }

    public get iban(): string {
        return this._iban;
    }

    public set iban(value: any) {
        this._setValue('_iban', value, 'string');
    }

    public get initialBalance(): number {
        return this._initialBalance;
    }

    public set initialBalance(value: any) {
        this._setValue('_initialBalance', value, 'float');
    }

    public get initialBalanceDate(): moment.Moment {
        return this._initialBalanceDate;
    }

    public set initialBalanceDate(value: any) {
        this._setValue('_initialBalanceDate', value, 'date');
    }

    public get journal(): Journal {
        return this._journal;
    }

    public set journal(value: any) {
        this._setValue('_journal', value, 'Journal');
    }

    public get journalCode(): string {
        return this._journalCode;
    }

    public set journalCode(value: any) {
        this._setValue('_journalCode', value, 'string');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get lines(): BankAccountLine[] {
        return this._lines;
    }

    public set lines(value: any) {
        this._setValue('_lines', value, 'BankAccountLine');
    }

    public get unjustifiedLinesCount(): number {
        return this._unjustifiedLinesCount;
    }

    public set unjustifiedLinesCount(value: any) {
        this._setValue('_unjustifiedLinesCount', value, 'integer');
    }

}

import { Component, inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

// Composants
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';

// Modèles
import { Invite } from 'src/app/@shared/models/client/invite.model';
import { InviteFormComponent } from "../invite-form/invite-form.component";
import { Profile } from 'src/app/@shared/models/authorization/profile.model';

@Component({
    selector: 'app-invite-dialog',
    imports: [
    InviteFormComponent
],
    templateUrl: './invite-dialog.component.html',
    styleUrl: './invite-dialog.component.scss'
})
export class InviteDialogComponent extends BaseComponent {

    private _config = inject(DynamicDialogConfig);
    private _ref = inject(DynamicDialogRef);

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Données
    ----------------------------------------------------------------------------------------*/

    private _invite!: Invite;
    public get invite(): Invite {
        return this._invite;
    }
    public set invite(value: Invite) {
        this._invite = value;
    }

    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    constructor() {
        super();

        this.invite = Invite.init();
        if (this._config.data.profile) {
            let profile: Profile = new Profile({code: this._config.data.profile});
            this.invite.profile = profile;
        }
    }

    public onInviteSaved(): void {
        this._ref.close();
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

}

import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

// Services
import { AppService } from '../app.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { CredentialsService } from '../../authentication/services/credentials.service';
import { Logger } from '../logger/logger.service';

// Modèles
import { Client } from 'src/app/@shared/models/client/client.model';
import { User } from 'src/app/@shared/models/user/user.model';

// Services
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';
import { ClientService } from '../client/client.service';

const CLASS_NAME = 'User';
const ENDPOINT = `/users/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'avatar', path: 'avatar/', childClass: 'File' },
]
const ROUTES: RouteParam[] = [
    { key: 'logout', path: '{id}/logout/' },
    { key: 'me', path: 'me/' },
]
const LOGGER = new Logger('User service');

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {
    private _appService = inject(AppService);
    private _authenticationService = inject(AuthenticationService);
    private _credentialsService = inject(CredentialsService);
    private _clientService = inject(ClientService);
    private _router = inject(Router);


    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    /**
     * Appel des données de l'utilisateur connecté
     */
    getMe$(): Observable<boolean> {
        LOGGER.debug('Chargement des données utilisateur');
        return this._authenticationService.refreshToken$(
            this._credentialsService.credentials!.refresh
        ).pipe(
            catchError(response => {
                if (response.error?.code === 'token_not_valid') {
                    this._authenticationService.logout$().subscribe(() => {
                        // Reouverture de la page de connexion
                        this._router.navigate(['/login']);
                    });
                }
                throw response;
            }),
            switchMap(() => this.httpClient.get<any>(this.routes.me)),
            switchMap((data: any) => {
                let user = new User(data);
                this._appService.user = user;

                LOGGER.debug(`Données utilisateur ${user.fullName} chargées`);

                // Chargement du client sélectionné
                return this.loadSelectedClient$().pipe(
                    map((client) => {
                        // Mise à jour du client sélectionné dans AppService
                        this._appService.selectedClient = client;
                        this.loadClients();
                    })
                );
            }),
            map(() => { return true })
        )
    }

    public loadClients$(): Observable<Client[]> {
        return this._clientService.getMine$().pipe(
            map((clients: Client[]) => {
                this._appService.clients = clients;
                return clients;
            })
        );
    }

    public loadClients(): void {
        this.loadClients$().subscribe();
    }

    public loadSelectedClient$(): Observable<Client | null> {
        LOGGER.debug('Chargement du dernier dossier client sélectionné');
        return this._clientService.loadSelected$();
    }

    /**
     * Chargement des données de l'utilisateur
     *
     * @returns boolean Retourne vrai si l'utilisateur est connecté
     */
    public loadUserData$(): Observable<boolean> {
        if (this._credentialsService.isAuthenticated()) {
            // Utilisateur connecté
            return this.getMe$().pipe(
                map(() => true),
                catchError(error => {
                    LOGGER.error('Erreur lors du chargement des données utilisateur', error);
                    return of(false);
                })
            );
        } else {
            // Utilisateur non connecté
            return of(false);
        }
    }

    public loadUserData(): void {
        this.loadUserData$().subscribe();
    }

    public logout$(): Observable<any> {
        return this.httpClient.post(this.routes.logout.replace('{id}', this._appService.user?.id.toString()), {}).pipe(
            switchMap(() => this._authenticationService.logout$())
        );
    }

    public uploadAvatar$(user: User, file: File): Observable<any> {
        let url = this.routes['single'].replace('{id}', user.id.toString()) + this.navigations['avatar'].path;
        return this.uploadFile$(file, url);
    }
}

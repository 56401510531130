import { ModelBase } from "../../abstract/model-base.model";
import { Tax } from "../../core/tax.model";
import { TaxDetail } from "../../core/taxDetail.model";

export abstract class InvoiceTaxBase extends ModelBase {

    private _amount!: number;
    private _base!: number;
    private _tax!: Tax;
    private _taxDetail!: TaxDetail;

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        const hasChanged = this._setValue('_amount', value, 'float');
        if (hasChanged) {
            this.calculateBase();
        }
    }

    public get base(): number {
        return this._base;
    }

    public set base(value: any) {
        const hasChanged = this._setValue('_base', value, 'float');
        if (hasChanged) {
            this.calculateAmount();
        }
    }

    public get isComplete(): boolean {
        return !!this.tax && !!this.base && (!!this.amount || !this.tax?.rate && !!this.taxDetail);
    }

    public get tax(): Tax {
        return this._tax;
    }

    public set tax(value: any) {
        const hasChanged = this._setValue('_tax', value, 'Tax');
        if (hasChanged) {
            this.calculateAmount();
        }
    }

    public get taxDetail(): TaxDetail {
        return this._taxDetail;
    }

    public set taxDetail(value: any) {
        this._setValue('_taxDetail', value, 'TaxDetail');
    }

    protected calculateAmount() {
        if (!this.loaded) return;
        if (!!this.tax?.rate && !!this.base) {
            this._amount = this.base * this.tax.rate / 100;
        }
    }

    protected calculateBase() {
        if (!this.loaded) return;
        if (!!this.tax?.rate && !!this.amount) {
            this._base = this.amount / this.tax.rate * 100;
        }
    }

}
import { InvoicePaymentBase } from "../../invoicing/abstract/invoice-payment-base.model";
import { SalesInvoice } from "./sales-invoice.model";


export class SalesInvoicePayment extends InvoicePaymentBase {

    static override CLASS_NAME: string = 'SalesInvoicePayment';

    protected override _creditNote!: SalesInvoice;
    protected override _invoice!: SalesInvoice;

    public override get creditNote(): SalesInvoice {
        return this._creditNote;
    }

    public override set creditNote(value: any) {
        this._setValue('_creditNote', value, 'SalesInvoice');
    }

    public override get invoice(): SalesInvoice {
        return this._invoice;
    }

    public override set invoice(value: any) {
        this._setValue('_invoice', value, 'SalesInvoice');
    }

}

import { ModelBase } from '../abstract/model-base.model';

export class Question extends ModelBase {

    static override CLASS_NAME: string = 'Question';

    private _answer!: string;
    private _question!: string;
    private _title!: string;

    public get answer(): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this._answer, 'text/html');
        const images = doc.querySelectorAll('img');

        images.forEach(img => {
            const src = img.getAttribute('src');
            if (src) {
                const url = new URL(src);
                url.search = '';
                img.setAttribute('src', url.toString());
            }
        });

        return doc.body.innerHTML;
    }

    public set answer(value: any) {
        this._setValue('_answer', value, 'string');
    }

    public get question(): string {
        return this._question;
    }

    public set question(value: any) {
        this._setValue('_question', value, 'string');
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: any) {
        this._setValue('_title', value, 'string');
    }
}

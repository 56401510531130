import { ModelBase } from '../abstract/model-base.model';

export class PartnerGroup extends ModelBase {

    static override CLASS_NAME: string = 'PartnerGroup';

    private _label!: string;

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get label(): string {
        return this._label;
    }
}

import { Component } from '@angular/core';


// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { ResetPasswordFormComponent } from '../../components/reset-password-form/reset-password-form.component';
import { LegalFooterComponent } from "../../../legal/components/legal-footer/legal-footer.component";

@Component({
    selector: 'app-reset-password',
    imports: [
    ResetPasswordFormComponent,
    LegalFooterComponent
],
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends PageBaseComponent {

}

import { ModelBase } from "../abstract/model-base.model";
import { AccountEntryType } from "./account-entry-type.model";
import { Journal } from "./journal.model";

export class ClientAccountEntryType extends ModelBase {

    static override CLASS_NAME: string = 'ClientAccountEntryType';

    private _type!: AccountEntryType;
    private _journal!: Journal;

    public get journal(): Journal {
        return this._journal;
    }

    public set journal(value: any) {
        this._setValue('_journal', value, 'Journal');
    }

    public get type(): AccountEntryType {
        return this._type;
    }

    public set type(value: any) {
        this._setValue('_type', value, 'AccountEntryType');
    }

}

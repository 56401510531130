@for (invite of invites; track invite.id; let last = $last) {
<div class="flex align-items-center justify-content-around py-3">
    <div class="mr-3">
        <p-avatar [image]="invite.creationUser.avatar" [label]="invite.creationUser.avatarLabel"
            [style]="{ 'background-color': invite.creationUser.avatarLabelColor, color: '#ffffff' }" size="large"
            shape="circle"></p-avatar>
    </div>
    <div class="flex flex-column">
        <div class="mb-3">{{ invite.creationUser.fullName }} vous invite à rejoindre la société {{
            invite.client.commercialName }}</div>
        <div class="flex align-item-center justify-content-between">
            <div class="h-3rem">
                @if (!!invite.client.logo) {
                <p-image [src]="invite.client.logo" alt="Logo" height="100%"></p-image>
                }
            </div>
            <div class="ml-3">
                <p-button class="mr-2" icon="pi pi-check" severity="success" [outlined]="true"
                    pTooltip="Accepter l'invitation" tooltipPosition="bottom" [rounded]="true"
                    (click)="onClickAcceptInvite(invite)"></p-button>
                <p-button icon="pi pi-times" severity="danger" [outlined]="true" [rounded]="true"
                    pTooltip="Refuser l'invitation" tooltipPosition="bottom"
                    (click)="onClickDeclineInvite(invite)"></p-button>
            </div>
        </div>
    </div>
</div>

@if (!last) {
<p-divider></p-divider>
}
}
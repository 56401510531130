import moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { Package } from './package.model';
import { Currency } from '../core/currency.model';
import { FeaturesEnum } from '../../enums/features.enum';
import { QuotasEnum } from '../../enums/quotas.enum';
import { Quota } from './quota.model';

export class Subscription extends ModelBase {

    static override CLASS_NAME: string = 'Subscription';

    private _annual!: boolean;
    private _cancelled!: boolean;
    private _currency!: Currency;
    private _dateFrom!: moment.Moment;
    private _dateTo!: moment.Moment;
    private _package!: Package;
    private _price!: number;
    // Quotas
    private _totalOcrFilesCount!: number;
    private _totalOcrPagesCount!: number;
    private _totalSalesInvoicesCount!: number;
    private _totalSalesInvoicesNetAmount!: number;

    public getQuota(quotaField: string): number | undefined | null {
        let featureName: string = '';
        switch (quotaField) {
            case QuotasEnum.Ocr:
                featureName = FeaturesEnum.Ocr;
                break;
            case QuotasEnum.SalesInvoiceAmount:
            case QuotasEnum.SalesInvoiceCount:
                featureName = FeaturesEnum.SalesInvoices;
                break;
            default:
                throw new Error(`Quota ${quotaField} not found`);
        }

        // Recherche dans le package des features
        let feature = this.package.getFeature(featureName);
        if (!feature) {
            // La feature ne fait pas partie du package
            return null;
        }

        // Recherche dans les quotas de la feature
        return feature.getQuota(quotaField);
    }

    public get annual(): boolean {
        return this._annual;
    }

    public set annual(value: any) {
        this._setValue('_annual', value, 'boolean');
    }

    public get cancelled(): boolean {
        return this._cancelled;
    }

    public set cancelled(value: any) {
        this._setValue('_cancelled', value, 'boolean');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get dateFrom(): moment.Moment {
        return this._dateFrom;
    }

    public set dateFrom(value: any) {
        this._setValue('_dateFrom', value, 'date');
    }

    public get dateTo(): moment.Moment {
        return this._dateTo;
    }

    public set dateTo(value: any) {
        this._setValue('_dateTo', value, 'date');
    }

    public get package(): Package {
        return this._package;
    }

    public set package(value: any) {
        this._setValue('_package', value, 'Package');
    }

    public get price(): number {
        return this._price;
    }

    public set price(value: any) {
        this._setValue('_price', value, 'float');
    }

    public get totalOcrFilesCount(): number {
        return this._totalOcrFilesCount;
    }

    public set totalOcrFilesCount(value: any) {
        this._setValue('_totalOcrFilesCount', value, 'integer');
    }

    public get totalOcrPagesCount(): number {
        return this._totalOcrPagesCount;
    }

    public set totalOcrPagesCount(value: any) {
        this._setValue('_totalOcrPagesCount', value, 'integer');
    }

    public get totalSalesInvoicesCount(): number {
        return this._totalSalesInvoicesCount;
    }

    public set totalSalesInvoicesCount(value: any) {
        this._setValue('_totalSalesInvoicesCount', value, 'integer');
    }

    public get totalSalesInvoicesNetAmount(): number {
        return this._totalSalesInvoicesNetAmount;
    }

    public set totalSalesInvoicesNetAmount(value: any) {
        this._setValue('_totalSalesInvoicesNetAmount', value, 'float');
    }
}

import moment from "moment";
import { ModelBase } from "../../abstract/model-base.model";
import { Currency } from "../../core/currency.model";
import { DiscountType } from "../../sales/discount-type.model";
import { InvoiceOrigin } from "../invoice-origin.model";
import { InvoiceType } from "../invoice-type.model";
import { LegacyPayment } from "../legacy-payment.model";
import { Partner } from "../../partner/partner.model";
import { PaymentMethod } from "../../core/payment-method.model";
import { Shareholder } from "../../client/shareholder.model";

import { StatusEnum } from "src/app/@shared/enums/status.enum";
import { InvoiceTypeEnum } from "src/app/@shared/enums/invoice-type.enum";
import tagAccents from "src/assets/themes/biifor/components/tag";

export abstract class InvoiceBase extends ModelBase {

    private _accountEntryId!: number;
    private _accountEntryProfitLossId!: number;
    private _amount!: number;
    private _amountDue!: number;
    private _amountDueOrg!: number;
    private _comment!: string;
    private _creationDate!: moment.Moment;
    private _currency!: Currency;
    private _currencyOrg!: Currency;
    private _discount!: number;
    private _discountType!: DiscountType;
    private _dueDate!: moment.Moment;
    private _exchangeRate!: number;
    private _grossAmount!: number;
    private _grossAmountOrg!: number;
    private _headerOnly!: boolean;
    private _isExpense!: boolean;
    private _issueDate!: moment.Moment;
    private _label!: string;
    private _legacyPayment!: LegacyPayment;
    private _manageTax!: boolean;
    private _manualExchangeRate!: boolean;
    private _netAmount!: number;
    private _ocrResult!: object;
    private _origin!: InvoiceOrigin;
    private _partner!: Partner;
    private _paymentDate!: moment.Moment;
    private _paymentMethod!: PaymentMethod;
    private _paymentReference!: string;
    private _reference!: string;
    private _shareholder!: Shareholder;
    private _taxAmount!: number;
    private _type!: InvoiceType;

    public static ribbonColor(severity: 'success' | 'secondary' | 'info' | 'warn' | 'danger' | 'contrast' | undefined): string {
        switch (true) {
            case (severity === 'contrast'):
                return 'rgba(31, 41, 55, 1)';
            case (severity === 'danger'):
                return 'rgba(215, 49, 57, 1)';
            case (severity === 'secondary'):
                return 'rgba(108, 117, 125, 1)';
            case (severity === 'success'):
                return 'rgba(25, 176, 115, 1)';
            case (severity === 'warn'):
                return 'rgba(241, 167, 0, 1)';
            case (severity === 'info'):
            default:
                return 'rgba(0, 87, 255, 1)';
        }
    }

    public static statusSeverity(status: string): 'success' | 'secondary' | 'info' | 'warn' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (status === 'draft'):
                return 'warn';
            case (status === 'draftInt'):
                return 'secondary';
            case (status === 'cancelled'):
                return 'secondary';
            case (status === 'paid'):
            case (status === 'partially_paid'):
                return 'success';
            case (status === 'sent'):
            case (status === 'validated'):
                return undefined;
            case (status === 'validatedInt'):
                return 'warn';
            default:
                return undefined;
        }
    }

    public static statusLabel(status: string): string {
        switch (true) {
            case (status === 'draft'):
                return 'À traiter';
            case (status === 'draftInt'):
                return 'Brouillon';
            case (status === 'cancelled'):
                return 'Annulée';
            case (status === 'paid'):
                return 'Payée';
            case (status === 'partially_paid'):
                return 'Part. payée';
            case (status === 'validated'):
                return 'Validée';
            case (status === 'validatedInt'):
                return 'Finalisée';
            case (status === 'sent'):
                return 'Envoyée';
            default:
                return '';
        }
    }

    public static tagAccent(status: string): Object | undefined {
        switch (true) {
            case (status === 'cancelled'):
            case (status === 'paid'):
            case (status === 'validatedInt'):
                return tagAccents;
            default:
                return undefined;
        }
    }

    public get isCreditNote(): boolean {
        return this._type.code === 'credit_note';
    }

    /**
     * Méthode pour voir si la facture est interne ou externe
     */
    public get isInternal(): boolean {
        return this.origin.code === 'internal';
    }

    public get isLate(): boolean {
        return !this.hasStatus(StatusEnum.Draft) && !this.hasStatus(StatusEnum.Paid) && this.amountDue > 0 && this.dueDate.isBefore(moment());
    }

    public get lateText(): string {
        return 'En retard de ' + this.dueDate.fromNow(true);
    }


    /**
     * Couleur de ruban selon le statut de la facture
     */
    public get ribbonColor(): string {
        return InvoiceBase.ribbonColor(this.statusSeverity);
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusSeverity(): 'success' | 'secondary' | 'info' | 'warn' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (this.hasStatus('cancelled')):
                return InvoiceBase.statusSeverity('cancelled');
            case (this.hasStatus('draft')):
                return this.isInternal ? InvoiceBase.statusSeverity('draftInt') : InvoiceBase.statusSeverity('draft');
            case (this.hasStatus('paid')):
                return InvoiceBase.statusSeverity('paid');
            case (this.hasStatus('partially_paid')):
                return InvoiceBase.statusSeverity('partially_paid');
            case (this.hasStatus('sent')):
                return InvoiceBase.statusSeverity('sent');
            case (this.hasStatus('validated')):
                return this.isInternal ? InvoiceBase.statusSeverity('validatedInt') : InvoiceBase.statusSeverity('validated');
            default:
                return InvoiceBase.statusSeverity('');
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusLabel(): string {
        switch (true) {
            case (this.hasStatus('cancelled')):
                return InvoiceBase.statusLabel('cancelled');
            case (this.hasStatus('draft')):
                return this.isInternal ? InvoiceBase.statusLabel('draftInt') : InvoiceBase.statusLabel('draft');
            case (this.hasStatus('paid')):
                return InvoiceBase.statusLabel('paid');
            case (this.hasStatus('partially_paid')):
                return InvoiceBase.statusLabel('partially_paid');
            case (this.hasStatus('sent')):
                return InvoiceBase.statusLabel('sent');
            case (this.hasStatus('validated')):
                return this.isInternal ? InvoiceBase.statusLabel('validatedInt') : InvoiceBase.statusLabel('validated');
            default:
                return '';
        }
    }

    public get tagAccent(): Object | undefined {
        switch (true) {
            case (this.hasStatus('cancelled')):
                return InvoiceBase.tagAccent('cancelled');
            case (this.hasStatus('draft')):
                return InvoiceBase.tagAccent('draft');
            case (this.hasStatus('paid')):
                return InvoiceBase.tagAccent('paid');
            case (this.hasStatus('partially_paid')):
                return InvoiceBase.tagAccent('partially_paid');
            case (this.hasStatus('sent')):
                return InvoiceBase.tagAccent('sent');
            case (this.hasStatus('validated')):
                return this.isInternal ? InvoiceBase.tagAccent('validatedInt') : InvoiceBase.tagAccent('validated');
            default:
                return undefined;
        }
    }

    public get title(): string {
        let title = '';
        if (!!this.reference) {
            let invoiceTypeLabel = this.isCreditNote ? 'Avoir' : 'Facture';
            title = invoiceTypeLabel + ' ' + this.reference
        } else if (this.isCreditNote) {
            title = 'Nouvel avoir';
        } else {
            title = 'Nouvelle facture';
        }
        return title;
    }

    public override toString(): string {
        return `${this.partner.name} - ${this.isCreditNote ? 'Avoir' : 'Facture'} ${this.reference} du ${this.issueDate.format('DD/MM/YYYY')} - ${this.label}`;
    }

    /**
     * Getters et setters
     */

    public get accountEntryId(): number {
        return this._accountEntryId;
    }

    public set accountEntryId(value: any) {
        this._setValue('_accountEntryId', value, 'integer');
    }

    public get accountEntryProfitLossId(): number {
        return this._accountEntryProfitLossId;
    }

    public set accountEntryProfitLossId(value: any) {
        this._setValue('_accountEntryProfitLossId', value, 'integer');
    }

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get amountDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.amount;
        } else {
            return this.amount;
        }
    }

    public get amountDue(): number {
        return this._amountDue;
    }

    public set amountDue(value: any) {
        this._setValue('_amountDue', value, 'float');
    }

    public get amountDueDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.amountDue;
        } else {
            return this.amountDue;
        }
    }

    public get amountDueOrg(): number {
        return this._amountDueOrg;
    }

    public set amountDueOrg(value: any) {
        this._setValue('_amountDueOrg', value, 'float');
    }

    public get amountDueOrgDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.amountDueOrg;
        } else {
            return this.amountDueOrg;
        }
    }

    public abstract get allocations();

    public abstract set allocations(value: any);

    public get comment(): string {
        return this._comment;
    }

    public set comment(value: any) {
        this._setValue('_comment', value, 'string');
    }

    public get creationDate(): moment.Moment {
        return this._creationDate;
    }

    public set creationDate(value: any) {
        this._setValue('_creationDate', value, 'date');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get currencyOrg(): Currency {
        return this._currencyOrg;
    }

    public set currencyOrg(value: any) {
        this._setValue('_currencyOrg', value, 'Currency');
    }

    public get discount(): number {
        return this._discount;
    }

    public set discount(value: any) {
        this._setValue('_discount', value, 'float');
    }

    public get discountType(): DiscountType {
        return this._discountType;
    }

    public set discountType(value: any) {
        this._setValue('_discountType', value, 'DiscountType');
    }

    public get dueDate(): moment.Moment {
        return this._dueDate;
    }

    public set dueDate(value: any) {
        this._setValue('_dueDate', value, 'date');
    }

    public get exchangeRate(): number {
        return this._exchangeRate;
    }

    public set exchangeRate(value: any) {
        let newValue = parseFloat(value) * 100000 / 100000;
        if (this.loaded && newValue !== this._exchangeRate) {
            // Modification survient après que l'objet a été instancié
            this.hasChanged = true;
        }
        this._exchangeRate = newValue;
    }

    public get grossAmount(): number {
        return this._grossAmount;
    }

    public set grossAmount(value: any) {
        this._setValue('_grossAmount', value, 'float');
        if (this._headerOnly) {
            this.netAmount = this.grossAmount - this.taxAmount;
        }
    }

    public get grossAmountDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.grossAmount;
        } else {
            return this.grossAmount;
        }
    }

    public get grossAmountOrg(): number {
        return this._grossAmountOrg;
    }

    public set grossAmountOrg(value: any) {
        this._setValue('_grossAmountOrg', value, 'float');
    }

    public get grossAmountOrgDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.grossAmountOrg;
        } else {
            return this.grossAmountOrg;
        }
    }

    public get hasOcrResult(): boolean {
        return Object.keys(this.ocrResult).length > 0;
    }

    public get headerOnly(): boolean {
        return this._headerOnly;
    }

    public set headerOnly(value: any) {
        this._setValue('_headerOnly', value, 'boolean');
    }

    public get isExpense(): boolean {
        return this._isExpense;
    }

    public set isExpense(value: any) {
        this._setValue('_isExpense', value, 'boolean');
    }

    public get issueDate(): moment.Moment {
        return this._issueDate;
    }

    public set issueDate(value: any) {
        this._setValue('_issueDate', value, 'date');
    }

    public abstract get items();

    public abstract set items(value: any);

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get legacyPayment(): LegacyPayment {
        return this._legacyPayment;
    }

    public set legacyPayment(value: any) {
        this._setValue('_legacyPayment', value, 'LegacyPayment');
    }

    public get manageTax(): boolean {
        return this._manageTax;
    }

    public set manageTax(value: any) {
        this._setValue('_manageTax', value, 'boolean');
    }

    public get manualExchangeRate(): boolean {
        return this._manualExchangeRate;
    }

    public set manualExchangeRate(value: any) {
        this._setValue('_manualExchangeRate', value, 'boolean');
    }

    public get netAmount(): number {
        return this._netAmount;
    }

    public set netAmount(value: any) {
        this._setValue('_netAmount', value, 'float');
    }

    public get netAmountDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.netAmount;
        } else {
            return this.netAmount;
        }
    }

    public get ocrResult(): object {
        return this._ocrResult;
    }

    public set ocrResult(value: any) {
        this._setValue('_ocrResult', value, 'json');
    }

    public get origin(): InvoiceOrigin {
        return this._origin;
    }

    public set origin(value: any) {
        this._setValue('_origin', value, 'InvoiceOrigin');
    }

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

    public get paymentDate(): moment.Moment {
        return this._paymentDate;
    }

    public set paymentDate(value: any) {
        this._setValue('_paymentDate', value, 'date');
    }

    public get paymentMethod(): PaymentMethod {
        return this._paymentMethod;
    }

    public set paymentMethod(value: any) {
        this._setValue('_paymentMethod', value, 'PaymentMethod');
    }

    public get paymentReference(): string {
        return this._paymentReference;
    }

    public set paymentReference(value: any) {
        this._setValue('_paymentReference', value, 'string');
    }

    public get reference(): string {
        return this._reference;
    }

    public set reference(value: any) {
        this._setValue('_reference', value, 'string');
    }

    public get shareholder(): Shareholder {
        return this._shareholder;
    }

    public set shareholder(value: any) {
        this._setValue('_shareholder', value, 'Shareholder');
    }

    public abstract get taxes();

    public abstract set taxes(value: any);

    public get taxAmount(): number {
        return this._taxAmount;
    }

    public set taxAmount(value: any) {
        this._setValue('_taxAmount', value, 'float');
        if (this._headerOnly) {
            this.netAmount = this.grossAmount - this.taxAmount;
        }
    }

    public get taxAmountDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.taxAmount;
        } else {
            return this.taxAmount;
        }
    }

    public get type(): InvoiceType {
        return this._type;
    }

    public set type(value: any) {
        this._setValue('_type', value, 'InvoiceType');
    }

}
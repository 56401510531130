import { ModelBase } from "../abstract/model-base.model";
import { Profile } from "../authorization/profile.model";
import { User } from "../user/user.model";
import { Client } from "./client.model";

export class Invite extends ModelBase {

    static override CLASS_NAME: string = 'Invite';

    private _client!: Client;
    private _creationUser!: User;
    private _emailAddress!: string;
    private _profile!: Profile;
    private _user!: User;

    public get client(): Client {
        return this._client;
    }

    public set client(value: Client) {
        this._setValue('_client', value, 'Client');
    }

    public get creationUser(): User {
        return this._creationUser;
    }

    public set creationUser(value: User) {
        this._setValue('_creationUser', value, 'User');
    }

    public get emailAddress(): string {
        return this._emailAddress;
    }

    public set emailAddress(value: string) {
        this._setValue('_emailAddress', value, 'string');
    }

    public get profile(): Profile {
        return this._profile;
    }

    public set profile(value: Profile) {
        this._setValue('_profile', value, 'Profile');
    }

    public get user(): User {
        return this._user;
    }

    public set user(value: User) {
        this._setValue('_user', value, 'User');
    }

}
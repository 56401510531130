import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'Subscription';
const ENDPOINT = `/subscriptions/`;

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

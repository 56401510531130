import { ModelBase } from '../abstract/model-base.model';

export class OnboardingStep extends ModelBase {

    static override CLASS_NAME: string = 'OnboardingStep';

    private _authorization!: string;
    private _code!: string;
    private _done!: boolean;
    private _order!: number;
    private _skipped!: boolean;

    public get actionLabel(): string {
        switch (this.code) {
            case 'connect_bank_account':
                return 'Ajouter un compte';
            case 'create_quotation':
                return 'Créer un devis';
            case 'create_sales_invoice':
                return 'Créer une facture';
            case 'import_purchase_invoice':
                return 'Importer une facture d\'achat';
            case 'import_chart_of_accounts':
                return 'Importer un plan comptable';
            case 'install_mobile_app':
                return 'C\'est fait !';
            case 'invite_accountant':
                return 'Lancer l\'invitation';
            default:
                return '';
        }
    }

    public get authorization(): string {
        return this._authorization;
    }

    public set authorization(value: any) {
        this._setValue('_authorization', value, 'string');
    }

    public get code(): string {
        return this._code;
    }

    public set code(value: any) {
        this._setValue('_code', value, 'string');
    }

    public get description(): string {
        switch (this.code) {
            case 'connect_bank_account':
                return 'Connectez un compte bancaire pour synchroniser automatiquement vos transactions.';
            case 'create_quotation':
                return 'Faites une proposition commerciale à vos clients en un clin d\'œil.';
            case 'create_sales_invoice':
                return 'Facturez vos clients en quelques clics.';
            case 'import_purchase_invoice':
                return 'Importez une facture d\'achat de vos fournisseurs ou une dépense personnelle.';
            case 'import_chart_of_accounts':
                return 'Importez le plan comptable de votre entreprise afin de catégoriser au mieux vos ventes et vos achats.';
            case 'install_mobile_app':
                return 'Installez l\'application mobile Biifor Scan depuis l\'AppStore ou Google Play pour prendre vos factures et dépenses en photo directement depuis votre smartphone.';
            case 'invite_accountant':
                return 'Collaborez directement avec votre comptable sur Biifor, pour un suivi encore plus précis.';
            default:
                return '';
        }
    }

    public get done(): boolean {
        return this._done;
    }

    public set done(value: any) {
        this._setValue('_done', value, 'boolean');
    }

    public get order(): number {
        return this._order;
    }

    public set order(value: any) {
        this._setValue('_order', value, 'integer');
    }

    public get skipped(): boolean {
        return this._skipped;
    }

    public set skipped(value: any) {
        this._setValue('_skipped', value, 'boolean');
    }

    public get title(): string {
        switch (this.code) {
            case 'connect_bank_account':
                return 'Connexion bancaire';
            case 'create_quotation':
                return 'Devis';
            case 'create_sales_invoice':
                return 'Facturation';
            case 'import_purchase_invoice':
                return 'Dépenses';
            case 'import_chart_of_accounts':
                return 'Plan comptable';
            case 'install_mobile_app':
                return 'Application mobile';
            case 'invite_accountant':
                return 'Inviter mon expert-comptable';
            default:
                return '';
        }
    }
}

import { AppConfigModel } from "../abstract/app-config.model";
import { AccountingMethod } from "../accounting/accounting-method.model";

export class TaxationType extends AppConfigModel {

    static override CLASS_NAME: string = 'TaxationType';

    readonly accrualAccounting!: boolean;
    readonly cashAccounting!: boolean;
    readonly defaultAccountingMethod!: AccountingMethod;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import moment from 'moment';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';

// Composants
import { FormBaseComponent } from 'src/app/@shared/components/abstract/form-base.component';

// Modèles
import { Account } from 'src/app/@shared/models/accounting/account.model';
import { Bank } from 'src/app/@shared/models/banking/bank.model';
import { BankAccount } from 'src/app/@shared/models/banking/bank-account.model';
import { Currency } from 'src/app/@shared/models/core/currency.model';

// Utils
import { Filter } from 'src/app/@shared/utilities/models/filter';
import { Sorter } from 'src/app/@shared/utilities/models/sorter';

@Component({
    selector: 'app-bank-account-form',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ButtonModule,
        CalendarModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        InputNumberModule,
        InputSwitchModule,
        InputTextModule,
        OverlayPanelModule,
    ],
    templateUrl: './bank-account-form.component.html',
    styleUrls: ['./bank-account-form.component.scss']
})
export class BankAccountFormComponent extends FormBaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    @Output() bankAccountSaved: EventEmitter<BankAccount> = new EventEmitter<BankAccount>();

    public showAccountingData: boolean = false;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _accounts: Account[] = [];
    public get accounts(): Account[] {
        return this._accounts;
    }
    public set accounts(accounts: Account[]) {
        let emptyArray: Account[] = [];
        this._accounts = emptyArray.concat(accounts);
    }

    private _banks: Bank[] = [];
    public get banks(): Bank[] {
        return this._banks;
    }
    public set banks(banks: Bank[]) {
        let emptyArray: Bank[] = [];
        this._banks = emptyArray.concat(banks);
    }

    private _bankAccount!: BankAccount;
    public get bankAccount(): BankAccount {
        return this._bankAccount;
    }
    @Input()
    public set bankAccount(bankAccount: BankAccount) {
        this._bankAccount = bankAccount;
        if (!!bankAccount) {
            this._mapToForm();
        } else {
            this.clearForm();
        }
    }

    private _currency!: Currency | null;
    public get currency(): Currency | null {
        return this._currency;
    }
    public set currency(currency: Currency | null) {
        this._currency = currency;
    }

    private _currencies: Currency[] = [];
    public get currencies(): Currency[] {
        return this._currencies;
    }
    public set currencies(currencies: Currency[]) {
        let emptyArray: Currency[] = [];
        this._currencies = emptyArray.concat(currencies);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public onChangeCurrency(event: any) {
        this.form.get('initialBalance')!.setValue(0);
        if (!!event.value){
            this.currency = event.value!;
            this.form.get('initialBalance')!.enable();
        } else {
            this.currency = null;
            this.form.get('initialBalance')!.disable();
        }
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override buildForm(): void {
        this.form = this.formBuilder.group({
            bank: [null, Validators.required],
            label: ['', Validators.required],
            iban: [''],
            initialBalance: [0, Validators.required],
            initialBalanceDate: [new Date(), Validators.required],
            currency: [null, Validators.required],
            dashboard: [false],
            account: [null],
            accountNumber: [''],
            journalCode: [''],
        })
    }

    protected override loadData(): void {
        this._loadBanks();
        this._loadCurrencies();
        this._loadAccounts();
    }

    protected override save() {
        this._mapFromForm();
        this.bankAccountService.save$(this.bankAccount).subscribe(bankAccount => {
            this.bankAccountSaved.emit(bankAccount);
            this.form.markAsPristine();
        });
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _loadAccounts(): void {
        let filter1 = new Filter({
            property: 'bankAccount',
            comparator: Filter.COMPARATOR.EQ,
            value: null
        });
        let filter2 = new Filter({
            property: 'account',
            comparator: Filter.COMPARATOR.StartsWith,
            value: '512'
        });
        let sorter = new Sorter({
            property: 'account'
        });
        this.accountService.getList$({
            filters: [filter1, filter2],
            sorters: [sorter]
        }).subscribe(accounts => {
            this.accounts = accounts;
        });
    }

    private _loadBanks(): void {
        let filter = new Filter({
            property: 'country',
            comparator: Filter.COMPARATOR.EQ,
            value :'NC'
        });
        let sorter = new Sorter({
            property: 'commercialName'
        });
        this.bankService.getList$({
            filters: [filter],
            sorters: [sorter]
        }).subscribe(banks => this.banks = banks);
    }

    private _loadCurrencies(): void {
        let sorter = new Sorter({
            property: 'code'
        })
        this.currencyService.getList$({
            sorters: [sorter]
        }).subscribe(currencies => this.currencies = currencies);
    }

    private _mapFromForm(): void {
        this.bankAccount.account = this.form.get('account')!.value;
        this.bankAccount.accountNumber = this.form.get('accountNumber')!.value;
        this.bankAccount.bank = this.form.get('bank')!.value;
        this.bankAccount.currency = this.form.get('currency')!.value;
        this.bankAccount.dashboard = this.form.get('dashboard')!.value;
        this.bankAccount.iban = this.form.get('iban')!.value;
        this.bankAccount.initialBalance = this.form.get('initialBalance')!.value;
        this.bankAccount.initialBalanceDate = moment(this.form.get('initialBalanceDate')!.value);
        this.bankAccount.journalCode = this.form.get('journalCode')!.value;
        this.bankAccount.label = this.form.get('label')!.value;
    }

    private _mapToForm(): void {
        this.form.setValue({
            label: !!this.bankAccount.label ? this.bankAccount.label : '',
            iban: !!this.bankAccount.iban ? this.bankAccount.iban : '',
            bank: !!this.bankAccount.bank ? this.bankAccount.bank : null,
            initialBalance: !!this.bankAccount.initialBalance ? this.bankAccount.initialBalance : 0,
            initialBalanceDate: !!this.bankAccount.initialBalanceDate ? this.bankAccount.initialBalanceDate.toDate() : new Date(),
            currency: !!this.bankAccount.currency ? this.bankAccount.currency : null,
            dashboard: !!this.bankAccount.dashboard,
            account: !!this.bankAccount.account ? this.bankAccount.account : null,
            accountNumber: '',
            journalCode: ''
        });

        if (this.bankAccount.new) {
            this.form.get('currency')?.setValue(this.appService.selectedClient?.currency);
            this.form.get('dashboard')?.setValue(true);
            this.form.get('bank')!.enable();
            this.form.get('currency')!.enable();
            this.form.get('iban')!.enable();
            this.form.get('account')!.enable();
        } else {
            this.form.get('iban')!.disable();
            this.form.get('bank')!.disable();
            this.form.get('currency')!.disable();
            this.form.get('account')!.disable();
        }
    }

}

import { Person } from "../person/abstract/person.model";
import { Partner } from "./partner.model";

export class PartnerContact extends Person {

    static override CLASS_NAME: string = 'PartnerContact';

    private _partner!: Partner;
    private _primary!: boolean;
    private _role!: string;

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

    public get primary(): boolean {
        return this._primary;
    }

    public set primary(value: any) {
        this._setValue('_primary', value, 'boolean');
    }

    public get role(): string {
        return this._role;
    }

    public set role(value: any) {
        this._setValue('_role', value, 'string');
    }

}
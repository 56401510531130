import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Services
import { RouteParam, ServiceBase } from '../service-base';
import { MessageServiceBroker } from '../message.service';

// Modèles
import { Invite } from 'src/app/@shared/models/client/invite.model';

// Utils
import { QueryOptionsParam } from 'src/app/@shared/utilities/odata/query-params';

const CLASS_NAME = 'Invite';
const ENDPOINT = `/invites/`;
const ROUTES: RouteParam[] = [
    { key: 'accept', path: '{id}/accept/' },
    { key: 'mine', path: 'mine/' },
    { key: 'refuse', path: '{id}/refuse/' },
]

@Injectable({
    providedIn: 'root'
})
export class InviteService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addRoutes(ROUTES);
    }

    public accept$(invite: Invite): Observable<Invite> {
        let url = this.routes.accept.replace('{id}', invite.id.toString());
        return this.update$(invite, {
            url: url
        });
    }

    public getMine$(): Observable<Invite[]> {
        let options: QueryOptionsParam = { url: this.routes.mine };
        return this.getList$(options);
    }

    public refuse$(invite: Invite): Observable<Invite> {
        let url = this.routes.refuse.replace('{id}', invite.id.toString());
        return this.update$(invite, {
            url: url
        });
    }
}

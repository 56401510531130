import { AppConfigModel } from "../abstract/app-config.model";
import { PackageContent } from "./package-content.model";

export class Package extends AppConfigModel {

    static override CLASS_NAME: string = 'Package';

    readonly description!: string;
    readonly monthlyPriceXpf!: number;
    readonly annualPriceXpf!: number;

    private _features!: PackageContent[];

    public getFeature(featureCode: string): PackageContent | null {
        return this._features.find((f) => f.feature.code === featureCode) ||
            null;
    }

    public hasFeature(featureCode: string): boolean {
        return this._features.some((f) => f.feature.code === featureCode);
    }

    public get features(): PackageContent[] {
        return this._features;
    }

    public set features(value: any) {
        this._features = [];
        for (let i = 0; i < value.length; i++) {
            this._features.push(new PackageContent(value[i]));
        }
    }
}

import { Component } from '@angular/core';


import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { LoginFormComponent } from '../../components/login-form/login-form.component';
import { LegalFooterComponent } from "../../../legal/components/legal-footer/legal-footer.component";

@Component({
    selector: 'app-login',
    imports: [
    LoginFormComponent,
    LegalFooterComponent
],
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends PageBaseComponent { }

import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";

export class KpiDso extends ModelBase {

    private _currency!: string;
    private _months!: KpiDsoMonth[];

    public get currency(): string {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'string');
    }

    public get months(): KpiDsoMonth[] {
        return this._months;
    }

    public set months(value: any) {
        this._setValue('_months', value, 'KpiDsoMonth');
    }

}

export class KpiDsoMonth extends ModelBase {

    private _averageSales!: boolean;
    private _dateFrom!: moment.Moment;
    private _dateTo!: moment.Moment;
    private _label!: string;
    private _labelShort!: string;
    private _outstandingReceivables!: number;
    private _preciseDso!: number;
    private _simplifiedDso!: number;
    private _totalPayments!: number;
    private _totalSales!: number;
    private _weightedPaymentDays!: number;

    public get averageSales(): boolean {
        return this._averageSales;
    }

    public set averageSales(value: any) {
        this._setValue('_averageSales', value, 'boolean');
    }

    public get dateFrom(): moment.Moment {
        return this._dateFrom;
    }

    public set dateFrom(value: any) {
        this._setValue('_dateFrom', value, 'date');
    }

    public get dateTo(): moment.Moment {
        return this._dateTo;
    }

    public set dateTo(value: any) {
        this._setValue('_dateTo', value, 'date');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get labelShort(): string {
        return this._labelShort;
    }

    public set labelShort(value: any) {
        this._setValue('_labelShort', value, 'string');
    }

    public get outstandingReceivables(): number {
        return this._outstandingReceivables;
    }

    public set outstandingReceivables(value: any) {
        this._setValue('_outstandingReceivables', value, 'float');
    }

    public get preciseDso(): number {
        return this._preciseDso;
    }

    public set preciseDso(value: any) {
        this._setValue('_preciseDso', value, 'float');
    }

    public get simplifiedDso(): number {
        return this._simplifiedDso;
    }

    public set simplifiedDso(value: any) {
        this._setValue('_simplifiedDso', value, 'float');
    }

    public get totalPayments(): number {
        return this._totalPayments;
    }

    public set totalPayments(value: any) {
        this._setValue('_totalPayments', value, 'float');
    }

    public get totalSales(): number {
        return this._totalSales;
    }

    public set totalSales(value: any) {
        this._setValue('_totalSales', value, 'float');
    }

    public get weightedPaymentDays(): number {
        return this._weightedPaymentDays;
    }

    public set weightedPaymentDays(value: any) {
        this._setValue('_weightedPaymentDays', value, 'float');
    }

}
import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";
import { AccountEntryExportFormat } from "./account-entry-export-format.model";

export class AccountEntryExport extends ModelBase {

    static override CLASS_NAME: string = 'AccountEntryExport';

    private _attachments!: boolean;
    private _dateFrom!: moment.Moment;
    private _dateTo!: moment.Moment;
    private _extracted!: boolean;
    private _format!: AccountEntryExportFormat;
    private _journals!: string[];
    private _reversed!: boolean;

    public get attachments(): boolean {
        return this._attachments;
    }

    public set attachments(value: any) {
        this._setValue('_attachments', value, 'boolean');
    }

    public get dateFrom(): moment.Moment {
        return this._dateFrom;
    }

    public set dateFrom(value: any) {
        this._setValue('_dateFrom', value, 'date');
    }

    public get dateTo(): moment.Moment {
        return this._dateTo;
    }

    public set dateTo(value: any) {
        this._setValue('_dateTo', value, 'date');
    }

    public get extracted(): boolean {
        return this._extracted;
    }

    public set extracted(value: any) {
        this._setValue('_extracted', value, 'boolean');
    }

    public get format(): AccountEntryExportFormat {
        return this._format;
    }

    public set format(value: any) {
        this._setValue('_format', value, 'AccountEntryExportFormat');
    }

    public get journals(): string[] {
        return this._journals;
    }

    public set journals(value: any) {
        this._setValue('_journals', value, 'string');
    }

    public get journalsDisp(): string {
        return this.journals.join(', ');
    }

    public get reversed(): boolean {
        return this._reversed;
    }

    public set reversed(value: any) {
        this._setValue('_reversed', value, 'boolean');
    }

}

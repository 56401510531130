import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'EcoTaxSector';
const ENDPOINT = `/eco-tax-sectors/`;

@Injectable({
    providedIn: 'root'
})
export class EcoTaxSectorService extends ServiceBase {

    protected override useCache = true;

    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { HeaderComponent } from '../../components/header/header.component';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { AppService } from 'src/app/@core/services/app.service';

@Component({
    selector: 'app-shell',
    imports: [
        CommonModule,
        RouterModule,
        BreadcrumbModule,
        HeaderComponent,
        SideMenuComponent,
        BlockUIModule,
        ProgressSpinnerModule,
    ],
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
    appService = inject(AppService);


    public get mainContentClass(): string {
        return !!this.appService.selectedClient ? this.appService.menuOpen ? 'menu-open' : 'menu-closed' : 'no-menu';
    }

    public get menuWidthClass(): string {
        return this.appService.menuOpen ? 'menu-open' : 'menu-closed';
    }
}

export class BinarySearch {
    /**
     * Method that performs a binary search within an array
     *
     * array and needle can be either Object or "simple" variable. needle can be the object property's value
     *
     * When using Objects, property must be filled so the method knows what key
     * has to be compared
     *
     * When using Objects, property can be a method. Object methods will be evaluated and the
     * find() method will compare results
     * @param {any[]} array An array of any elements, Object or not
     * @param {any} needle The element to search. Must be same type as array elements
     * @param {string} property Mandatory when using objects. Indicates what property has to be compared
     */
    static find(array: any[], needle: any, property: string | null = null) {
        let start = 0,
            end = array.length - 1;

        // Iterate while start not meets end
        while (start <= end) {
            // Find the mid index
            let middle = Math.floor((start + end) / 2);

            if (!property) {
                // We are looking for a value inside an array of values
                // If element is present at mid, return True
                if (array[middle] === needle) {
                    return { found: true, index: middle };
                } else if (array[middle] < needle) {
                    start = middle + 1;
                } else {
                    end = middle - 1;
                }
            } else {
                // Evaluate property/method to get simple values to compare
                let value1 =
                    typeof array[middle][property] === 'function' ? array[middle][property]() : array[middle][property];
                let value2 =
                    typeof needle === 'object'
                        ? typeof needle[property] === 'function'
                            ? needle[property]()
                            : needle[property]
                        : needle;
                if (value1 === value2) {
                    return { found: true, index: middle };
                } else if (value1 < value2) {
                    start = middle + 1;
                } else {
                    end = middle - 1;
                }
            }
        }

        return { found: false, index: start };
    }
}

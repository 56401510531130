<div class="w-full">
    <p-card>
        <ng-template pTemplate="header">
            <div class="flex align-items-center justify-content-between p-3">
                <div class="flex align-items-center col-9">
                    <h2 class="mr-3">Vos dépenses principales</h2>
                    <p-button icon="pi pi-info-circle" type="button" [rounded]="true" [text]="true"
                        (click)="op.toggle($event)"></p-button>
                </div>
                <div class="col-3">
                    <p-select [options]="accountingPeriods" [(ngModel)]="selectedPeriod"
                        (onChange)="onChangeAccountingPeriod($event)">
                        <ng-template let-period pTemplate="selectedItem">
                            @if (!!period) {
                            <span>{{period.dateFrom | date:'shortDate'}} - {{period.dateTo | date:'shortDate'}}</span>
                            }
                        </ng-template>
                        <ng-template let-period pTemplate="item">
                            <span>{{period.dateFrom | date:'shortDate'}} - {{period.dateTo | date:'shortDate'}}</span>
                        </ng-template>
                    </p-select>
                </div>
            </div>
        </ng-template>
        @if (!loading) {
        @if (chartSeries.length > 0) {
        <div>
            <apx-chart [chart]="chart" [colors]="chartColors" [series]="chartSeries" [labels]="chartLabels"></apx-chart>
        </div>
        } @else {
        <div class="flex flex-column align-items-center justify-content-center" style="height: 428px;">
            <!-- Message indiquant qu'il n'y a pas de compte -->
            <div class="text-xl mb-5">Aucune dépense enregistrée</div>
            <!-- Bouton pour ajouter un premier compte -->
            <p-fileUpload #fu mode="basic" accept="application/pdf,image/jpeg,image/png"
                chooseLabel="Ajouter votre première facture" chooseIcon="pi pi-cloud-upload"
                styleClass="p-button p-button-raised p-button-rounded" [multiple]="true" [auto]="true"
                [customUpload]="true" (uploadHandler)="onUpload($event);fu.clear()"></p-fileUpload>
        </div>
        }
        } @else {
        <div class="p-3 flex align-items-center justify-content-center" style="height: 428px;">
            <p-progressSpinner></p-progressSpinner>
        </div>
        }
    </p-card>
</div>

<p-popover #op>
    <div class="w-25rem">
        <p>
            Cet indicateur ne prend en compte que les factures d'achat qui ont été validées.
        </p>
    </div>
</p-popover>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, ServiceBase } from '../service-base';

const CLASS_NAME = 'BankAccountLineType';
const ENDPOINT = `/bank-account-line-types/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'accounts', path: 'accounts/', childClass: 'Account' },
]

@Injectable({
    providedIn: 'root'
})
export class BankAccountLineTypeService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
    }
}

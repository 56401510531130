import moment from "moment";
import { ModelBase } from "../../abstract/model-base.model";
import { PaymentMethod } from "../../core/payment-method.model";
import { InvoiceBase } from "./invoice-base.model";
import { AccountEntry } from "../../accounting/account-entry.model";
import { BankAccount } from "../../banking/bank-account.model";
import { BankAccountLine } from "../../banking/bank-account-line.model";
import { Cheque } from "../../banking/cheque.model";
import { Currency } from "../../core/currency.model";
import { Overpayment } from "../../banking/overpayment.model";

export abstract class InvoicePaymentBase extends ModelBase {

    protected abstract _invoice: InvoiceBase;
    protected abstract _creditNote: InvoiceBase;
    private _accountEntry!: AccountEntry;
    private _amount!: number;
    private _amountOrg!: number;
    private _automatic!: boolean;
    private _bankAccount!: BankAccount;
    private _bankAccountLine!: BankAccountLine;
    private _cheque!: Cheque;
    private _currency!: Currency;
    private _currencyOrg!: Currency;
    private _date!: moment.Moment;
    private _method!: PaymentMethod;
    private _overpayment!: Overpayment;
    private _reference!: string;

    public abstract get creditNote(): InvoiceBase;
    public abstract set creditNote(value: any);
    public abstract get invoice(): InvoiceBase;
    public abstract set invoice(value: any);

    public get accountEntry(): AccountEntry {
        return this._accountEntry;
    }

    public set accountEntry(value: any) {
        this._setValue('_accountEntry', value, 'AccountEntry');
    }

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get amountOrg(): number {
        return this._amountOrg;
    }

    public set amountOrg(value: any) {
        this._setValue('_amountOrg', value, 'float');
    }

    public get automatic(): boolean {
        return this._automatic;
    }

    public set automatic(value: any) {
        this._setValue('_automatic', value, 'boolean');
    }

    public get bankAccount(): BankAccount {
        return this._bankAccount;
    }

    public set bankAccount(value: any) {
        this._setValue('_bankAccount', value, 'BankAccount');
    }

    public get bankAccountLine(): BankAccountLine {
        return this._bankAccountLine;
    }

    public set bankAccountLine(value: any) {
        this._setValue('_bankAccountLine', value, 'BankAccountLine');
    }

    public get cheque(): Cheque {
        return this._cheque;
    }

    public set cheque(value: any) {
        this._setValue('_cheque', value, 'Cheque');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get currencyOrg(): Currency {
        return this._currencyOrg;
    }

    public set currencyOrg(value: any) {
        this._setValue('_currencyOrg', value, 'Currency');
    }

    public get date(): moment.Moment {
        return this._date;
    }

    public set date(value: any) {
        this._setValue('_date', value, 'date');
    }

    public get method(): PaymentMethod {
        return this._method;
    }

    public set method(value: any) {
        this._setValue('_method', value, 'PaymentMethod');
    }

    public get overpayment(): Overpayment {
        return this._overpayment;
    }

    public set overpayment(value: Overpayment) {
        this._setValue('_overpayment', value, 'Overpayment');
    }

    public get reference(): string {
        return this._reference;
    }

    public set reference(value: any) {
        this._setValue('_reference', value, 'string');
    }

}
import { Component } from '@angular/core';


// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { SubscribeFormComponent } from '../../components/subscribe-form/subscribe-form.component';
import { LegalFooterComponent } from "../../../legal/components/legal-footer/legal-footer.component";

@Component({
    selector: 'app-subscribe',
    imports: [
    SubscribeFormComponent,
    LegalFooterComponent
],
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent extends PageBaseComponent {

}

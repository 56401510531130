<!-- ÉTAPE 1 : REMPLISSAGE DU FORMULAIRE -->
@if (!showSuccessMessage) {
  <div class="h-29rem flex flex-column">
    <div class="text-xl font-medium mb-3">
      Mot de passe perdu
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-start">
      <div class="text-sm mb-6">
        Saisir un email valide pour recevoir un lien de renouvellement de votre de passe.
      </div>
      <div class="flex flex-column justify-content-between h-15rem">
        <form (ngSubmit)="reset()" [formGroup]="form" novalidate class="flex flex-column">
          <!-- Adresse email -->
          <input pInputText class="mb-4" type="email" formControlName="email" placeholder="Adresse email"
            required />
          <!-- validation -->
          <div class="grid grid-nogutter">
            <div class="col-6">
              <p-button styleClass="w-full" type="submit" label="Envoyer lien" icon="pi pi-arrow-right"
              [rounded]="true" iconPos="right" [disabled]="form.invalid || loading"></p-button>
            </div>
          </div>
        </form>
        <!-- Retour connexion -->
        <div class="grid">
          <div class="col">
            <p-button styleClass="w-full surface-900 text-0 border-900" type="button"
            label="Revenir à la connexion" [rounded]="true" routerLink="/login"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
}

<!-- ÉTAPE 2 : MESSAGE POUR UTILISATEUR -->
@if (showSuccessMessage) {
  <div class="h-29rem flex flex-column justify-content-start">
    <div class="text-xl font-medium mb-3">
      Consultez vos emails
    </div>
    <div class="flex flex-column justify-content-between h-20rem">
      <div class="text-sm">
        Si un compte existe pour l'adresse email renseignée vous allez recevoir un
        message avec un lien permettant de définir un nouveau mot de passe.
      </div>
      <!-- Retour connexion -->
      <div class="grid">
        <div class="col">
          <button pButton class="w-full surface-900 text-0 border-900" type="button"
          label="Revenir à la connexion" routerLink="/login"></button>
        </div>
      </div>
    </div>
  </div>
}
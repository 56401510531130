import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { Email } from 'src/app/@shared/models/messaging/email.model';

const CLASS_NAME = 'Email';
const ENDPOINT = `/emails/`;

const NAVIGATIONS: NavigationParam[] = [
    { key: 'attachments', path: 'attachments/', childClass: 'File' },
]
const ROUTES: RouteParam[] = [
    { key: 'send', path: '{id}/send/' },
]


@Injectable({
    providedIn: 'root'
})
export class EmailService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public send$(email: Email): Observable<Email> {
        let url = this.routes.send.replace('{id}', email.id.toString());
        return this.update$(email, {
            url: url
        });
    }
}

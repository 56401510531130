import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";

export class KpiMonthlySummary extends ModelBase {

    private _currency!: string;
    private _totalIn!: number;
    private _totalOut!: number;
    private _months!: KpiMonthSummary[];

    public get currency(): string {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'string');
    }

    public get totalIn(): number {
        return this._totalIn;
    }

    public set totalIn(value: any) {
        this._setValue('_totalIn', value, 'float');
    }

    public get totalOut(): number {
        return this._totalOut;
    }

    public set totalOut(value: any) {
        this._setValue('_totalOut', value, 'float');
    }

    public get months(): KpiMonthSummary[] {
        return this._months;
    }

    public set months(value: any) {
        this._setValue('_months', value, 'KpiMonthSummary');
    }

}

export class KpiMonthSummary extends ModelBase {

    private _dateFrom!: moment.Moment;
    private _dateTo!: moment.Moment;
    private _in!: number;
    private _out!: number;
    private _label!: string;
    private _labelShort!: string;

    public get dateFrom(): moment.Moment {
        return this._dateFrom;
    }

    public set dateFrom(value: any) {
        this._setValue('_dateFrom', value, 'date');
    }

    public get dateTo(): moment.Moment {
        return this._dateTo;
    }

    public set dateTo(value: any) {
        this._setValue('_dateTo', value, 'date');
    }

    public get in(): number {
        return this._in;
    }

    public set in(value: any) {
        this._setValue('_in', value, 'float');
    }

    public get out(): number {
        return this._out;
    }

    public set out(value: any) {
        this._setValue('_out', value, 'float');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get labelShort(): string {
        return this._labelShort;
    }

    public set labelShort(value: any) {
        this._setValue('_labelShort', value, 'string');
    }

}
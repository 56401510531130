import { ModelBase } from "../abstract/model-base.model";

export class KpiPartnerDebts extends ModelBase {

    private _currency!: string;
    private _ongoing!: number;
    private _ongoingCount!: number;
    private _late1!: number;
    private _late1Count!: number;
    private _late2!: number;
    private _late2Count!: number;
    private _late3!: number;
    private _late3Count!: number;
    private _late4!: number;
    private _late4Count!: number;
    private _total!: number;

    public get currency(): string {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'string');
    }
    public get ongoing(): number {
        return this._ongoing;
    }

    public set ongoing(value: any) {
        this._setValue('_ongoing', value, 'float');
    }

    public get ongoingCount(): number {
        return this._ongoingCount;
    }

    public set ongoingCount(value: any) {
        this._setValue('_ongoingCount', value, 'integer');
    }

    public get lateAll(): number {
        return this.late1 + this.late2 + this.late3 + this.late4;
    }

    public get late1(): number {
        return this._late1;
    }

    public set late1(value: any) {
        this._setValue('_late1', value, 'float');
    }

    public get late1Count(): number {
        return this._late1Count;
    }

    public set late1Count(value: any) {
        this._setValue('_late1Count', value, 'integer');
    }

    public get late2(): number {
        return this._late2;
    }

    public set late2(value: any) {
        this._setValue('_late2', value, 'float');
    }

    public get late2Count(): number {
        return this._late2Count;
    }

    public set late2Count(value: any) {
        this._setValue('_late2Count', value, 'integer');
    }

    public get late3(): number {
        return this._late3;
    }

    public set late3(value: any) {
        this._setValue('_late3', value, 'float');
    }

    public get late3Count(): number {
        return this._late3Count;
    }

    public set late3Count(value: any) {
        this._setValue('_late3Count', value, 'integer');
    }

    public get late4(): number {
        return this._late4;
    }

    public set late4(value: any) {
        this._setValue('_late4', value, 'float');
    }

    public get late4Count(): number {
        return this._late4Count;
    }

    public set late4Count(value: any) {
        this._setValue('_late4Count', value, 'integer');
    }

    public get total(): number {
        return this._total;
    }

    public set total(value: any) {
        this._setValue('_total', value, 'float');
    }

    public get totalAllCount(): number {
        return this.ongoingCount + this.late1Count + this.late2Count + this.late3Count + this.late4Count;
    }

    public get totalLateCount(): number {
        return this.late1Count + this.late2Count + this.late3Count + this.late4Count;
    }


}
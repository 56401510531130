import moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { ClientContact } from '../client/contact.model';
import { EmailReceipient } from './email-receipient.model';
import { EmailEvent } from './email-event.model';
import { File } from '../file/file.model';
import { Partner } from '../partner/partner.model';
import { PartnerContact } from '../partner/contact.model';
import { User } from '../user/user.model';

export class Email extends ModelBase {

    static override CLASS_NAME: string = 'Email';

    // Destinataires
    private _toEmails!: string[];
    private _toExternal!: PartnerContact[];
    private _toInternal!: ClientContact[];
    private _ccEmails!: string[];
    private _ccExternal!: PartnerContact[];
    private _ccInternal!: ClientContact[];
    private _bccEmails!: string[];
    private _bccExternal!: PartnerContact[];
    private _bccInternal!: ClientContact[];
    private _sendMeCopy!: boolean;
    // Contenu
    private _subject!: string;
    private _content!: string;
    private _attachments!: File[];
    // Évènements
    private _events!: EmailEvent[];
    // Clés étrangères
    private _partner!: Partner;
    private _sender!: User;

    constructor(data: any) {
        super(data);
        this._attachments = this._attachments || [];
        this._toEmails = this._toEmails || [];
        this._toExternal = this._toExternal || [];
        this._toInternal = this._toInternal || [];
        this._ccEmails = this._ccEmails || [];
        this._ccExternal = this._ccExternal || [];
        this._ccInternal = this._ccInternal || [];
        this._bccEmails = this._bccEmails || [];
        this._bccExternal = this._bccExternal || [];
        this._bccInternal = this._bccInternal || [];
    }

    public get attachments(): File[] {
        return this._attachments;
    }

    public set attachments(value: any) {
        this._setValue('_attachments', value, 'File');
    }

    public get bccEmails(): string[] {
        return this._bccEmails;
    }

    public set bccEmails(value: any) {
        this._setValue('_bccEmails', value, 'string');
    }

    public get bccExternal(): PartnerContact[] {
        return this._bccExternal;
    }

    public set bccExternal(value: any) {
        this._setValue('_bccExternal', value, 'PartnerContact');
    }

    public get bccInternal(): ClientContact[] {
        return this._bccInternal;
    }

    public set bccInternal(value: any) {
        this._setValue('_bccInternal', value, 'ClientContact');
    }

    public get ccEmails(): string[] {
        return this._ccEmails;
    }

    public set ccEmails(value: any) {
        this._setValue('_ccEmails', value, 'string');
    }

    public get ccExternal(): PartnerContact[] {
        return this._ccExternal;
    }

    public set ccExternal(value: any) {
        this._setValue('_ccExternal', value, 'PartnerContact');
    }

    public get ccInternal(): ClientContact[] {
        return this._ccInternal;
    }

    public set ccInternal(value: any) {
        this._setValue('_ccInternal', value, 'ClientContact');
    }

    public get content(): string {
        return this._content;
    }

    public set content(value: any) {
        this._setValue('_content', value, 'string');
    }

    public get events(): EmailEvent[] {
        return this._events;
    }

    public set events(value: any) {
        this._setValue('_events', value, 'EmailEvent');
    }

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

    public get receipients(): EmailReceipient[] {
        let receipients: EmailReceipient[] = [];
        this.toEmails.forEach((email: string) => {
            receipients.push(new EmailReceipient({ email: email }));
        });
        this.toExternal.forEach((contact: PartnerContact) => {
            receipients.push(new EmailReceipient({ email: contact.email, fullName: contact.fullName }));
        });
        this.toInternal.forEach((contact: ClientContact) => {
            receipients.push(new EmailReceipient({ email: contact.email, fullName: contact.fullName }));
        });
        return receipients;
    }

    public get recipientsCount(): number {
        return (
            this.toEmails.length + this.toExternal.length + this.toInternal.length +
            this.ccEmails.length + this.ccExternal.length + this.ccInternal.length +
            this.bccEmails.length + this.bccExternal.length + this.bccInternal.length
        );
    }

    public get recipientsCountDisplay(): string {
        let count: number = this.recipientsCount;
        return count
            ? count + ' destinataire' + (count > 1 ? 's' : '')
            : 'Aucun destinataire';
    }

    public get sender(): User {
        return this._sender;
    }

    public set sender(value: any) {
        this._setValue('_sender', value, 'User');
    }

    public get sentDate(): moment.Moment | null{
        // On prend la date de l'évènement 'request'
        let event = this.events.find((event: EmailEvent) => event.event === 'request');
        return event ? event.eventDatetime : null;
    }

    public get sendMeCopy(): boolean {
        return this._sendMeCopy;
    }

    public set sendMeCopy(value: any) {
        this._setValue('_sendMeCopy', value, 'boolean');
    }

    public get subject(): string {
        return this._subject;
    }

    public set subject(value: any) {
        this._setValue('_subject', value, 'string');
    }

    public get toEmails(): string[] {
        return this._toEmails;
    }

    public set toEmails(value: any) {
        this._setValue('_toEmails', value, 'string');
    }

    public get toExternal(): PartnerContact[] {
        return this._toExternal;
    }

    public set toExternal(value: any) {
        this._setValue('_toExternal', value, 'PartnerContact');
    }

    public get toInternal(): ClientContact[] {
        return this._toInternal;
    }

    public set toInternal(value: any) {
        this._setValue('_toInternal', value, 'ClientContact');
    }
}

import { Component } from '@angular/core';


// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { LostPasswordFormComponent } from '../../components/lost-password-form/lost-password-form.component';
import { LegalFooterComponent } from "../../../legal/components/legal-footer/legal-footer.component";

@Component({
    selector: 'app-lost-password',
    imports: [
    LostPasswordFormComponent,
    LegalFooterComponent
],
    templateUrl: './lost-password.component.html',
    styleUrls: ['./lost-password.component.scss']
})
export class LostPasswordComponent extends PageBaseComponent {

}

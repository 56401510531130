import { ModelBase } from "../abstract/model-base.model";
import { Country } from "../core/country.model";

export class City extends ModelBase {

    static override CLASS_NAME: string = 'City';

    private _country!: Country;
    private _nameReal!: string;
    private _zipCode!: string;

    public get country(): Country {
        return this._country;
    }

    public set country(value: any) {
        this._setValue('_country', value, 'Country');
    }

    public get nameReal(): string {
        return this._nameReal;
    }

    public set nameReal(value: any) {
        this._setValue('_nameReal', value, 'string');
    }

    public get zipCode(): string {
        return this._zipCode;
    }

    public set zipCode(value: any) {
        this._setValue('_zipCode', value, 'string');
    }

}
export enum PackagesEnum {
    Business = 'business',
    BusinessPlus = 'business+',
    Free = 'free',
    Lite = 'lite',
    Ocr = 'ocr',
    Premium = 'premium',
    Pro = 'pro',
    ProPlus = 'pro+',
    Trial = 'trial',
}
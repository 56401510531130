import { ModelBase } from '../abstract/model-base.model';
import { Address } from '../address/address.model';
import { ClientContact } from './contact.model';
import { Currency } from '../core/currency.model';
import { Country } from '../core/country.model';
import { Timezone } from '../core/timezone.model';
import { TaxationSettings } from '../taxation/taxation-settings.model';
import { AccountingSettings } from '../accounting/accounting-settings.model';
import { SalesSettings } from '../sales/sales-settings.model';
import { AccountingPeriod } from '../accounting/accounting-period.model';
import { OrganizationActivity } from '../legal-entity/organization-activity.model';
import { Organization } from '../legal-entity/organization.model';
import { Subscription } from '../subscription/subscription.model';
import { OrganizationSize } from '../legal-entity/organization-size.model';

export class Client extends ModelBase {

    static override CLASS_NAME: string = 'Client';

    private _address!: Address;
    private _commercialName!: string;
    private _contacts!: ClientContact[];
    private _country!: Country;
    private _currency!: Currency;
    private _demo!: boolean;
    private _identifier!: string;
    private _logo!: string;
    private _mainActivity!: OrganizationActivity;
    private _name!: string;
    private _organization!: Organization;
    private _size!: OrganizationSize;
    private _subscription!: Subscription;
    private _timezone!: Timezone;
    // Paramétrages
    private _accountingPeriods: AccountingPeriod[] = [];
    private _accountingSettings!: AccountingSettings;
    private _salesSettings!: SalesSettings;
    private _taxationSettings!: TaxationSettings;
    // Consentements
    private _cguAccepted!: boolean;
    private _cgvAccepted!: boolean;
    private _privacyPolicyAccepted!: boolean;
    // Autres
    public deletePhrase: string | null = null;
    public resetPhrase: string | null = null;

    static override init(): any {
        let instance = super.init();
        instance.accountingPeriods = [];
        instance.accountingSettings = AccountingSettings.init();
        instance.salesSettings = SalesSettings.init();
        instance.taxationSettings = TaxationSettings.init();
        return instance;
    }

    public hasFeature(feature: string): boolean {
        return this.subscription.hasFeature(feature);
    }

    public get isCaledonian(): boolean {
        return this._country.alpha2 === 'NC';
    }

    public get accountingPeriods(): AccountingPeriod[] {
        return this._accountingPeriods;
    }

    public set accountingPeriods(value: any) {
        this._setValue('_accountingPeriods', value, 'AccountingPeriod');
    }

    public get accountingSettings(): AccountingSettings {
        return this._accountingSettings;
    }

    public set accountingSettings(value: any) {
        this._setValue('_accountingSettings', value, 'AccountingSettings');
    }

    public get address(): Address {
        return this._address;
    }

    public set address(value: any) {
        this._setValue('_address', value, 'Address');
    }

    public get cguAccepted(): boolean {
        return this._cguAccepted;
    }

    public set cguAccepted(value: any) {
        this._setValue('_cguAccepted', value, 'boolean');
    }

    public get cgvAccepted(): boolean {
        return this._cgvAccepted;
    }

    public set cgvAccepted(value: any) {
        this._setValue('_cgvAccepted', value, 'boolean');
    }

    public get commercialName(): string {
        return this._commercialName;
    }

    public set commercialName(value: any) {
        this._setValue('_commercialName', value, 'string');
    }

    public get contacts(): ClientContact[] {
        return this._contacts;
    }

    public set contacts(value: any) {
        this._setValue('_contacts', value, 'PartnerContact');
    }

    public get country(): Country {
        return this._country;
    }

    public set country(value: any) {
        this._setValue('_country', value, 'Country');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get demo(): boolean {
        return this._demo;
    }

    public set demo(value: any) {
        this._setValue('_demo', value, 'boolean');
    }

    public get identifier(): string {
        return this._identifier;
    }

    public set identifier(value: any) {
        this._setValue('_identifier', value, 'string');
    }

    public get logo(): string {
        return this._logo;
    }

    public set logo(value: any) {
        this._setValue('_logo', value, 'string');
    }

    public get mainActivity(): OrganizationActivity {
        return this._mainActivity;
    }

    public set mainActivity(value: any) {
        this._setValue('_mainActivity', value, 'OrganizationActivity');
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: any) {
        this._setValue('_name', value, 'string');
    }

    public get organization(): Organization {
        return this._organization;
    }

    public set organization(value: any) {
        this._setValue('_organization', value, 'Organization');
    }

    public get privacyPolicyAccepted(): boolean {
        return this._privacyPolicyAccepted;
    }

    public set privacyPolicyAccepted(value: any) {
        this._setValue('_privacyPolicyAccepted', value, 'boolean');
    }

    public get salesSettings(): SalesSettings {
        return this._salesSettings;
    }

    public set salesSettings(value: any) {
        this._setValue('_salesSettings', value, 'SalesSettings');
    }

    public get size(): OrganizationSize {
        return this._size;
    }

    public set size(value: any) {
        this._setValue('_size', value, 'OrganizationSize');
    }

    public get subscription(): Subscription {
        return this._subscription;
    }

    public set subscription(value: any) {
        this._setValue('_subscription', value, 'Subscription');
    }

    public get taxationSettings(): TaxationSettings {
        return this._taxationSettings;
    }

    public set taxationSettings(value: any) {
        this._setValue('_taxationSettings', value, 'TaxationSettings');
    }

    public get timezone(): Timezone {
        return this._timezone;
    }

    public set timezone(value: any) {
        this._setValue('_timezone', value, 'Timezone');
    }
}

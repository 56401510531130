import { Address } from "../../address/address.model";
import { PaymentMethod } from "../../core/payment-method.model";
import { InvoiceBase } from "../../invoicing/abstract/invoice-base.model";
import { Email } from "../../messaging/email.model";
import { InvoiceContact } from "../contact.model";
import { SalesDocumentTemplate } from "../template.model";

export abstract class SalesInvoiceBase extends InvoiceBase {

    private _bankDetails!: string;
    private _billingAddress!: Address;
    private _chequeLabel!: string;
    private _contact!: InvoiceContact;
    private _deliveryAddress!: Address;
    private _draftEmail!: Email;
    private _ecoTaxGrossAmount!: number;
    private _ecoTaxNetAmount!: number;
    private _logo!: string;
    private _note!: string;
    private _paymentMethods!: PaymentMethod[];
    private _sentEmails!: Email[];
    private _template!: SalesDocumentTemplate;
    private _useDeliveryAddress!: boolean;
    private _vendorAddress!: Address;

    public hasPaymentMethod(paymentMethod: string): boolean {
        return this.paymentMethods.some((pm: PaymentMethod) => pm.code === paymentMethod);
    }

    public get bankDetails(): string {
        return this._bankDetails;
    }

    public set bankDetails(value: any) {
        this._setValue('_bankDetails', value, 'string');
    }

    public get billingAddress(): Address {
        return this._billingAddress;
    }

    public set billingAddress(value: any) {
        this._setValue('_billingAddress', value, 'Address');
    }

    public get chequeLabel(): string {
        return this._chequeLabel;
    }

    public set chequeLabel(value: any) {
        this._setValue('_chequeLabel', value, 'string');
    }

    public get contact(): InvoiceContact {
        return this._contact;
    }

    public set contact(value: any) {
        this._setValue('_contact', value, 'InvoiceContact');
    }

    public get deliveryAddress(): Address {
        return this._deliveryAddress;
    }

    public set deliveryAddress(value: any) {
        this._setValue('_deliveryAddress', value, 'Address');
    }

    public get draftEmail(): Email {
        return this._draftEmail;
    }

    public set draftEmail(value: any) {
        this._setValue('_draftEmail', value, 'Email');
    }

    public get ecoTaxGrossAmount(): number {
        return this._ecoTaxGrossAmount;
    }

    public set ecoTaxGrossAmount(value: any) {
        this._setValue('_ecoTaxGrossAmount', value, 'float');
    }

    public get ecoTaxNetAmount(): number {
        return this._ecoTaxNetAmount;
    }

    public set ecoTaxNetAmount(value: any) {
        this._setValue('_ecoTaxNetAmount', value, 'float');
    }

    public get logo(): string {
        return this._logo;
    }

    public set logo(value: any) {
        this._setValue('_logo', value, 'string');
    }

    public get note(): string {
        return this._note;
    }

    public set note(value: any) {
        this._setValue('_note', value, 'string');
    }

    public get paymentMethods(): PaymentMethod[] {
        return this._paymentMethods;
    }

    public set paymentMethods(value: any) {
        this._setValue('_paymentMethods', value, 'PaymentMethod');
    }

    public get sentEmails(): Email[] {
        return this._sentEmails;
    }

    public set sentEmails(value: any) {
        this._setValue('_sentEmails', value, 'Email');
    }

    public get template(): SalesDocumentTemplate {
        return this._template;
    }

    public set template(value: any) {
        this._setValue('_template', value, 'SalesDocumentTemplate');
    }

    public get useDeliveryAddress(): boolean {
        return this._useDeliveryAddress;
    }

    public set useDeliveryAddress(value: any) {
        this._setValue('_useDeliveryAddress', value, 'boolean');
    }

    public get vendorAddress(): Address {
        return this._vendorAddress;
    }

    public set vendorAddress(value: any) {
        this._setValue('_vendorAddress', value, 'Address');
    }

}
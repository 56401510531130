import { Injectable } from '@angular/core';

// Services
import { NavigationParam, ServiceBase } from '../service-base';

const CLASS_NAME = 'KpiCharges';
const ENDPOINT = `/kpi/client/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'bank-account-balances', path: 'bank-account-balances/', childClass: 'KpiBankAccountBalances', resultCardinality: 'one' },
    { key: 'business-events', path: 'business-events/', childClass: 'BusinessEvent' },
    { key: 'charges', path: 'charges/', childClass: 'KpiCharges' },
    { key: 'dso', path: 'dso/', childClass: 'KpiDso', resultCardinality: 'one' },
    { key: 'monthly-summary', path: 'monthly-summary/', childClass: 'KpiMonthlySummary', resultCardinality: 'one' },
    { key: 'purchasing-debts', path: 'purchasing-debts/', childClass: 'KpiPartnerDebts', resultCardinality: 'one' },
    { key: 'sales-debts', path: 'sales-debts/', childClass: 'KpiPartnerDebts', resultCardinality: 'one' },
]

@Injectable({
    providedIn: 'root'
})
export class KpiService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
    }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { LoginFormComponent } from '../../components/login-form/login-form.component';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [CommonModule, LoginFormComponent],
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends PageBaseComponent {}

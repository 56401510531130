import { ModelBase } from '../abstract/model-base.model';
import { Country } from '../core/country.model';

export class Organization extends ModelBase {

    static override CLASS_NAME: string = 'Organization';

    private _acronym!: string;
    private _country!: Country;
    private _identifier!: string;
    private _name!: string;

    public get acronym(): string {
        return this._acronym;
    }

    public set acronym(value: any) {
        this._setValue('_acronym', value, 'string');
    }

    public get country(): Country {
        return this._country;
    }

    public set country(value: any) {
        this._setValue('_country', value, 'Country');
    }

    public get identifier(): string {
        return this._identifier;
    }

    public set identifier(value: any) {
        this._setValue('_identifier', value, 'string');
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: any) {
        this._setValue('_name', value, 'string');
    }
}

import { Routes, Route } from '@angular/router';

import { AuthenticationGuard } from 'src/app/@core/authentication/guards/authentication.guard';
import { ShellComponent } from '../../pages/shell/shell.component';

/**
 * Provides helper methods to create routes.
 */
export class Shell {
    /**
     * Creates routes using the shell component and authentication.
     * @param routes The routes to add.
     * @return The new route using shell as the base.
     */
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            component: ShellComponent,
            children: routes,
            canActivate: [AuthenticationGuard],
            // Reuse ShellPage instance when navigating between child views
            data: { reuse: true }
        };
    }
}

import { AppConfigModel } from "../abstract/app-config.model";

export class TransactionDirection extends AppConfigModel {

    static override CLASS_NAME: string = 'TransactionDirection';

    public get businessLabel(): string {
        return this.code === 'debit' ? 'Dépense' : 'Recette';
    }
}

import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

// Injector
import { AppInjector } from 'src/app/app.module';

// Components
import { BaseComponent } from './base.component';

// Services
import { Logger } from 'src/app/@core/services/logger/logger.service';
import { MessageServiceBroker } from 'src/app/@core/services/message.service';

// Modèles
import { Account } from '../../models/accounting/account.model';

const LOGGER = new Logger('FormBaseComponent');

@Component({
    selector: 'b4cash-form-base',
    template: `
        NO UI TO BE FOUND HERE!
    `
})
export abstract class FormBaseComponent extends BaseComponent {
    // Common services
    public formBuilder: FormBuilder;
    public messageService: MessageServiceBroker;
    // Default form group
    public form: FormGroup;

    constructor() {
        super();
        // Services
        this.formBuilder = AppInjector.get(FormBuilder);
        this.messageService = AppInjector.get(MessageServiceBroker);
        // Form group init
        this.form = this.formBuilder.group({});
        // Build form
        this.buildForm();
    }

    public override ngOnInit() {
        this.registerFilterObservables();
        super.ngOnInit();
        this.initForm();
        this.manageFieldEnabilities();
    }

    protected archive() {
        LOGGER.debug('archive() method is not defined in controller ' + this.constructor.name);
    }

    /**
     * Assignation des erreur au formulaire
     * @param errors
     */
    protected assignErrors(errors: any) {
        for (var key in errors) {
            try {
                // Error concerning data error related to a single field
                // Erreur concernant un champ
                this.form.get(key)?.setErrors({
                    shortText: errors[key].shortText,
                    longText: errors[key].longText
                });
            } catch (_) {
                LOGGER.warn(key + ' ne peut pas être géré avec l\'assignation d\'erreur par défaut, veuillez redéfinir la méthode');
            }
        }
    }

    protected buildForm() {
        LOGGER.debug('buildForm() method is not defined in controller ' + this.constructor.name);
    }

    /**
     * Réinitialisation du formulaire
     */
    protected clearForm() {
        this.form.reset();
    }

    /**
     * Méthode par défaut de suppression de l'objet, doit être redéfinie dans les classes filles
     */
    protected delete() {
        LOGGER.debug('delete() method is not defined in controller ' + this.constructor.name);
    }

    /**
     * Récupère le message d'erreur long associé à un élément du formulaire
     * @param controlName
     */
    public getErrorLongText(controlName: string): string {
        return this.getErrorMessage(controlName, 'long_text');
    }

    /**
     * Récupère le message d'erreur demandé associé à un élément du formulaire
     * @param controlName Le contrôle
     * @param key Message court/long
     * @return The required message
     */
    protected getErrorMessage(controlName: string, key: string): string {
        return this.form.get(controlName)?.getError(key) || '';
    }

    /**
     * Récupère le message d'erreur court associé à un élément du formulaire
     * @param controlName
     */
    public getErrorShortText(controlName: string): string {
        return this.getErrorMessage(controlName, 'short_text');
    }

    /**
     * Gestion des commandes clavier sur Mac
     * @param event Évènement
     * @param commandKey Touche de commande
     */
    protected handleKeyEvents(event: any, commandKey: any) {
        // MetaKey documentation
        // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/metaKey
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (commandKey && charCode === 's') {
            // Action on Cmd + S
            event.preventDefault();
            this.save();
        } else if (commandKey && charCode === 'f') {
            // Action on Cmd + F
            event.preventDefault();
            this.toggleSearchVisibility();
        }
    }

    protected initForm() {}

    /**
     * Dit si le contrôle de formulaire est invalide
     * @param filedName The field to check
     * @returns True si l'élément est invalide
     */
    public isFormFieldInvalid(filedName: string): boolean {
        try {
            return !!this.form.get(filedName)?.invalid;
        } catch (_) {
            return false;
        }
    }

    protected manageFieldEnabilities() {}

    /**
     * Gestion des champs de formulaire en fonction du paramétrage
     * @param formControl L'élément à gérer
     * @param visibility La visibilité à appliquer
     */
    protected manageFormField(formControl: FormControl, visibility: string) {
        // Enable?
        if (visibility === 'read_only') {
            formControl.disable();
        } else {
            formControl.enable();
        }
        // Required?
        if (visibility === 'required') {
            formControl.setValidators(Validators.required);
        } else {
            formControl.setValidators([]);
            formControl.updateValueAndValidity();
        }
    }

    /**
     * Event triggered when user clicks on the archive button
     */
    public onClickArchive() {
        this.archive();
    }

    /**
     * L'utilisateur clique sur Supprimer
     */
    public onClickDelete() {
        this.delete();
    }

    /**
     * L'utilisateur clique sur Sauvegarder
     */
    public onClickSave() {
        this.save();
    }

    public onAccountSelected(account: Account, field: string) {
        this.selectAccount(account, field);
    }

    /**
     * À chaque pression sur une touche
     * @param event
     */
    public onKeyDown(event: any) {
        // Detect platform
        if (navigator.platform.match('Mac')) {
            this.handleKeyEvents(event, event.metaKey);
        } else {
            this.handleKeyEvents(event, event.ctrlKey);
        }
    }

    protected registerFilterObservables() {}

    protected save() {
        LOGGER.debug('save() method is not defined in controller ' + this.constructor.name);
    }

    protected selectAccount(account: Account, field: string) {
        this.form.get(field)!.setValue(account);
    }

    protected override subscribeToObservables() {
        this.subscriptions.add(
            this.messageService.details$.subscribe(details => {
                this.assignErrors(details);
            })
        );
    }

    /**
     * Abstract definition
     */
    protected toggleSearchVisibility() {}
}

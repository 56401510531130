import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { BankAccountRule } from 'src/app/@shared/models/banking/bank-account-rule.model';

const CLASS_NAME = 'BankAccountRule';
const ENDPOINT = `/bank-account-rules/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'matches', path: 'matches/', childClass: 'BankAccountLine' },
]
const ROUTES: RouteParam[] = [
    { key: 'activate', path: '{id}/activate/' },
    { key: 'apply', path: '{id}/apply/' },
    { key: 'deactivate', path: '{id}/deactivate/' },
]

@Injectable({
    providedIn: 'root'
})
export class BankAccountRuleService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public activateRule$(rule: BankAccountRule): Observable<BankAccountRule> {
        let url = this.routes.activate.replace('{id}', rule.id.toString());
        return this.update$(rule, {
            url: url
        });
    }

    public applyRule$(rule: BankAccountRule): Observable<BankAccountRule> {
        let url = this.routes.apply.replace('{id}', rule.id.toString());
        return this.update$(rule, {
            url: url
        });
    }

    public deactivateRule$(rule: BankAccountRule): Observable<BankAccountRule> {
        let url = this.routes.deactivate.replace('{id}', rule.id.toString());
        return this.update$(rule, {
            url: url
        });
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { PurchaseInvoice } from 'src/app/@shared/models/purchasing/invoice/purchase-invoice.model';


const CLASS_NAME = 'PurchaseInvoice';
const ENDPOINT = `/purchase-invoices/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'attachments', path: 'attachments/', childClass: 'File' },
    { key: 'payments', path: 'payments/', childClass: 'PurchaseInvoicePayment' },
]
const ROUTES: RouteParam[] = [
    { key: 'downloadFile', path: '{id}/pdf/' },
    { key: 'upload', path: 'upload/' },
    { key: 'validate', path: '{id}/validate/' },
    { key: 'writeOff', path: '{id}/write-off/' },
]

@Injectable({
    providedIn: 'root'
})
export class PurchaseInvoiceService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public upload$(file: File): Observable<any> {
        return this.uploadFile$(file, this.routes.upload);
    }

    public writeOff(invoice: PurchaseInvoice): void {
        this.update(invoice, {
            url: this.routes.writeOff.replace('{id}', invoice.id.toString())
        });
    }

}

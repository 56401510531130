import { Component, inject } from '@angular/core';


// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';

// Services
import { AuthenticationService } from 'src/app/@core/authentication/services/authentication.service';

@Component({
    selector: 'app-verify-email',
    imports: [],
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent extends PageBaseComponent {
    private _authenticationService = inject(AuthenticationService);


    message: string = '';

    override ngOnInit(): void {
        super.ngOnInit();

        this._verifyEmail();
    }

    private _verifyEmail(): void {
        let payload = {
            'email': this.queryParams.get('email'),
            'user_id': this.queryParams.get('user_id'),
            'timestamp': this.queryParams.get('timestamp'),
            'signature': this.queryParams.get('signature')
        };

        this._authenticationService.verifyEmail$(payload).subscribe(
            () => {
                this.message = `Votre email a été validé. Vous allez être redirigé  dans 5 secondes.`;
                this.userService.loadUserData();
                setTimeout(() => {
                    this.router.navigate(['/user/profile'])
                }, 5000);
            }
        );

    }

}

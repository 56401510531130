import { ModelBase } from '../abstract/model-base.model';
import { Account } from '../accounting/account.model';
import { Address } from '../address/address.model';
import { Country } from '../core/country.model';
import { Currency } from '../core/currency.model';
import { PartnerContact } from './contact.model';
import { PartnerGroup } from './partner-group.model';
import { PartnerIdentifier } from './partner-identifier.model';
import { PartnerType } from './partner-type.model';
import { PaymentTerm } from '../invoicing/payment-term.model';
import { Organization } from '../legal-entity/organization.model';

export class Partner extends ModelBase {

    static override CLASS_NAME: string = 'Partner';

    private _name!: string;
    private _type!: PartnerType;
    private _country!: Country;
    private _organization!: Organization;
    // Client data
    private _autoProductAccount!: boolean;
    private _isClient!: boolean;
    private _clientGroup!: PartnerGroup;
    private _clientNumber!: string;
    private _myVendorNumber!: string;
    private _clientAccount!: Account;
    private _clientInitialBalance!: number;
    private _productAccount!: Account;
    private _clientPaymentTerm?: PaymentTerm;
    private _clientCurrency!: Currency;
    private _productAccountSuggestions!: Account[];
    // Vendor data
    private _autoChargeAccount!: boolean;
    private _isVendor!: boolean;
    private _vendorGroup!: PartnerGroup;
    private _vendorNumber!: string;
    private _vendorAccount!: Account;
    private _vendorInitialBalance!: number;
    private _chargeAccount!: Account;
    private _vendorPaymentTerm?: PaymentTerm;
    private _vendorCurrency!: Currency;
    private _chargeAccountSuggestions!: Account[];
    // Contacts et adresse
    private _address!: Address;
    private _contacts!: PartnerContact[];
    // Fiscalité
    private _taxExempt!: boolean;
    // Identifiants
    private _identifiers!: PartnerIdentifier[];

    constructor(data: any) {
        super(data);
        this._contacts = this._contacts || [];
        this._identifiers = this._identifiers || [];
        this._chargeAccountSuggestions = this._chargeAccountSuggestions || [];
        this._productAccountSuggestions = this._productAccountSuggestions || [];
    }

    public override toString(): string {
        return this._name;
    }

    public get isCaledonian(): boolean {
        return this._country?.alpha2 === 'NC';
    }

    public get isOrganization(): boolean {
        return this.type?.code === 'organization';
    }

    public get primaryContact(): PartnerContact | undefined {
        return this._contacts.find(c => c.primary);
    }

    public get remainingBalance(): number {
        return this.clientAccount?.remainingInitialBalance || 0 + this.vendorAccount?.remainingInitialBalance || 0;
    }

    public get address(): Address {
        return this._address;
    }

    public set address(value: any) {
        this._setValue('_address', value, 'Address');
    }

    public get autoChargeAccount(): boolean {
        return this._autoChargeAccount;
    }

    public set autoChargeAccount(value: any) {
        this._setValue('_autoChargeAccount', value, 'boolean');
    }

    public get autoProductAccount(): boolean {
        return this._autoProductAccount;
    }

    public set autoProductAccount(value: any) {
        this._setValue('_autoProductAccount', value, 'boolean');
    }

    public get chargeAccount(): Account {
        return this._chargeAccount;
    }

    public set chargeAccount(value: any) {
        this._setValue('_chargeAccount', value, 'Account');
    }

    public get chargeAccountSuggestions(): Account[] {
        return this._chargeAccountSuggestions;
    }

    public set chargeAccountSuggestions(value: any) {
        this._setValue('_chargeAccountSuggestions', value, 'Account');
    }

    public get clientAccount(): Account {
        return this._clientAccount;
    }

    public set clientAccount(value: any) {
        this._setValue('_clientAccount', value, 'Account');
    }

    public get clientCurrency(): Currency {
        return this._clientCurrency;
    }

    public set clientCurrency(value: any) {
        this._setValue('_clientCurrency', value, 'Currency');
    }

    public get clientGroup(): PartnerGroup {
        return this._clientGroup;
    }

    public set clientGroup(value: any) {
        this._setValue('_clientGroup', value, 'PartnerGroup');
    }

    public get clientInitialBalance(): number {
        return this._clientInitialBalance;
    }

    public set clientInitialBalance(value: any) {
        this._setValue('_clientInitialBalance', value, 'float');
    }

    public get clientNumber(): string {
        return this._clientNumber;
    }

    public set clientNumber(value: any) {
        this._setValue('_clientNumber', value, 'string');
    }

    public get clientPaymentTerm(): PaymentTerm | undefined {
        return this._clientPaymentTerm;
    }

    public set clientPaymentTerm(value: any) {
        this._setValue('_clientPaymentTerm', value, 'PaymentTerm');
    }

    public get contacts(): PartnerContact[] {
        return this._contacts;
    }

    public set contacts(value: any) {
        this._setValue('_contacts', value, 'PartnerContact');
    }

    public get country(): Country {
        return this._country;
    }

    public set country(value: any) {
        this._setValue('_country', value, 'Country');
    }

    public get identifiers(): PartnerIdentifier[] {
        return this._identifiers;
    }

    public set identifiers(value: any) {
        this._setValue('_identifiers', value, 'PartnerIdentifier');
    }

    public get isClient(): boolean {
        return this._isClient;
    }

    public set isClient(value: any) {
        this._setValue('_isClient', value, 'boolean');
    }

    public get isVendor(): boolean {
        return this._isVendor;
    }

    public set isVendor(value: any) {
        this._setValue('_isVendor', value, 'boolean');
    }

    public get myVendorNumber(): string {
        return this._myVendorNumber;
    }

    public set myVendorNumber(value: any) {
        this._setValue('_myVendorNumber', value, 'string');
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: any) {
        this._setValue('_name', value, 'string');
    }

    public get organization(): Organization {
        return this._organization;
    }

    public set organization(value: any) {
        this._setValue('_organization', value, 'Organization');
    }

    public get productAccount(): Account {
        return this._productAccount;
    }

    public set productAccount(value: any) {
        this._setValue('_productAccount', value, 'Account');
    }

    public get productAccountSuggestions(): Account[] {
        return this._productAccountSuggestions;
    }

    public set productAccountSuggestions(value: any) {
        this._setValue('_productAccountSuggestions', value, 'Account');
    }

    public get taxExempt(): boolean {
        return this._taxExempt;
    }

    public set taxExempt(value: any) {
        this._setValue('_taxExempt', value, 'boolean');
    }

    public set type(value: any) {
        this._setValue('_type', value, 'PartnerType');
    }

    public get type(): PartnerType {
        return this._type;
    }

    public get vendorAccount(): Account {
        return this._vendorAccount;
    }

    public set vendorAccount(value: any) {
        this._setValue('_vendorAccount', value, 'Account');
    }

    public get vendorCurrency(): Currency {
        return this._vendorCurrency;
    }

    public set vendorCurrency(value: any) {
        this._setValue('_vendorCurrency', value, 'Currency');
    }

    public get vendorGroup(): PartnerGroup {
        return this._vendorGroup;
    }

    public set vendorGroup(value: any) {
        this._setValue('_vendorGroup', value, 'PartnerGroup');
    }

    public get vendorInitialBalance(): number {
        return this._vendorInitialBalance;
    }

    public set vendorInitialBalance(value: any) {
        this._setValue('_vendorInitialBalance', value, 'float');
    }

    public get vendorNumber(): string {
        return this._vendorNumber;
    }

    public set vendorNumber(value: any) {
        this._setValue('_vendorNumber', value, 'string');
    }

    public get vendorPaymentTerm(): PaymentTerm | undefined {
        return this._vendorPaymentTerm;
    }

    public set vendorPaymentTerm(value: any) {
        this._setValue('_vendorPaymentTerm', value, 'PaymentTerm');
    }
}

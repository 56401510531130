import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { Partner } from 'src/app/@shared/models/partner/partner.model';

const CLASS_NAME = 'Partner';
const ENDPOINT = `/partners/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'addresses', path: 'addresses/', childClass: 'Address' },
    { key: 'contacts', path: 'contacts/', childClass: 'PartnerContact' },
]
const ROUTES: RouteParam[] = [
    { key: 'merge', path: '{id}/merge/' },
]

@Injectable({
    providedIn: 'root'
})
export class PartnerService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public merge$(sourcePartner: Partner, targetPartner: Partner): Observable<any> {
        return this.update$(sourcePartner, {
            select: false,
            url: this.routes.merge.replace('{id}', targetPartner.id.toString())
        });
    }
}

import { InvoiceBase } from '../../invoicing/abstract/invoice-base.model';
import { PurchaseInvoiceAllocation } from './purchase-invoice-allocation.model';
import { PurchaseInvoiceItem } from './purchase-invoice-item.model';
import { PurchaseInvoiceTax } from './purchase-invoice-tax.model';

export class PurchaseInvoice extends InvoiceBase {

    static override CLASS_NAME: string = 'PurchaseInvoice';

    private _allocations!: PurchaseInvoiceAllocation[];
    private _items!: PurchaseInvoiceItem[];
    private _taxes!: PurchaseInvoiceTax[];

    constructor(data: any) {
        super(data);
        this._allocations = this._allocations || [];
        this._items = this._items || [];
        this._taxes = this._taxes || [];
    }

    public get allocations(): PurchaseInvoiceAllocation[] {
        return this._allocations;
    }

    public set allocations(value: any) {
        this._setValue('_allocations', value, 'PurchaseInvoiceAllocation');
    }

    public get items(): PurchaseInvoiceItem[] {
        return this._items;
    }

    public set items(value: any) {
        this._setValue('_items', value, 'PurchaseInvoiceItem');
    }

    public get taxes(): PurchaseInvoiceTax[] {
        return this._taxes;
    }

    public set taxes(value: any) {
        this._setValue('_taxes', value, 'PurchaseInvoiceTax');
    }
}

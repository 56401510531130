<div class="h-full flex flex-column align-items-center justify-content-around">

    <div *ngIf="appService.isPortrait() || !appService.isPhone">
        <img class="logo" src="{{ appService.logoSrc }}" />
    </div>

    <div>
        {{ message }}
    </div>

</div>

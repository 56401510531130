import { Account } from "../accounting/account.model";
import { Person } from "../person/abstract/person.model";

export class Shareholder extends Person {

    static override CLASS_NAME: string = 'Shareholder';

    private _account!: Account;

    public get account(): Account {
        return this._account;
    }

    public set account(value: any) {
        this._setValue('_account', value, 'Account');
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, ServiceBase } from '../service-base';

const CLASS_NAME = 'KpiCharges';
const ENDPOINT = `/kpi/client/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'bank-account-balances', path: 'bank-account-balances/', childClass: 'KpiBankAccountBalances' },
    { key: 'business-events', path: 'business-events/', childClass: 'BusinessEvent' },
    { key: 'charges', path: 'charges/', childClass: 'KpiCharges' },
    { key: 'monthly-summary', path: 'monthly-summary/', childClass: 'KpiMonthlySummary' },
    { key: 'purchasing-debts', path: 'purchasing-debts/', childClass: 'KpiPartnerDebts' },
    { key: 'sales-debts', path: 'sales-debts/', childClass: 'KpiPartnerDebts' },
]

@Injectable({
    providedIn: 'root'
})
export class KpiService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
    }
}

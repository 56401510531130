import { InvoicePaymentBase } from "../../invoicing/abstract/invoice-payment-base.model";
import { PurchaseInvoice } from "./purchase-invoice.model";


export class PurchaseInvoicePayment extends InvoicePaymentBase {

    static override CLASS_NAME: string = 'PurchaseInvoicePayment';

    protected override _creditNote!: PurchaseInvoice;
    protected override _invoice!: PurchaseInvoice;

    public override get creditNote(): PurchaseInvoice {
        return this._creditNote;
    }

    public override set creditNote(value: any) {
        this._setValue('_creditNote', value, 'PurchaseInvoice');
    }

    public override get invoice(): PurchaseInvoice {
        return this._invoice;
    }

    public override set invoice(value: any) {
        this._setValue('_invoice', value, 'PurchaseInvoice');
    }

}

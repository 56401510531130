import * as moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { AccountEntry } from '../accounting/account-entry.model';
import { BankAccountLineAllocation } from './bank-account-line-allocation.model';
import { BankAccountLineType } from './bank-account-line-type.model';
import { Currency } from '../core/currency.model';
import { Deposit } from './deposit';
import { Employee } from '../human-resources/employee.model';
import { InvoicePaymentBase } from '../invoicing/abstract/invoice-payment-base.model';
import { LegacyPayment } from '../invoicing/legacy-payment.model';
import { Loan } from './loan.model';
import { PaymentMethod } from '../core/payment-method.model';
import { Partner } from '../partner/partner.model';
import { Shareholder } from '../client/shareholder.model';
import { BankAccountLineTax } from './bank-account-line-tax.model';
import { BankAccountRule } from './bank-account-rule.model';
import { Overpayment } from './overpayment.model';
import { OverpaymentReimbursement } from './overpayment-reimbursement.model';

export class BankAccountLine extends ModelBase {

    static override CLASS_NAME: string = 'BankAccountLine';

    private _accountEntry!: AccountEntry;
    private _accountEntryProfitLoss!: AccountEntry;
    private _allocations!: BankAccountLineAllocation[];
    private _amount!: number;
    private _amountCurrency!: number;
    private _amountSigned!: number;
    private _amountUnmatched!: number;
    private _appliedRule!: BankAccountRule;
    private _bankAccountId!: number;
    private _coming!: boolean;
    private _comment!: string;
    private _credit!: number;
    private _currency!: Currency;
    private _customLabel!: string;
    private _debit!: number;
    private _deposit!: Deposit;
    private _duplicate!: boolean;
    private _employee!: Employee;
    private _exchangeRate!: number;
    private _justified!: boolean;
    private _legacyPayment!: LegacyPayment;
    private _lineHash!: string;
    private _loan!: Loan;
    private _manageTax!: boolean;
    private _operationDate!: moment.Moment;
    private _operationDetails!: string;
    private _operationNature!: string;
    private _origin!: string;
    private _originalCurrency!: Currency;
    private _overpayment!: Overpayment;
    private _overpaymentReimbursements!: OverpaymentReimbursement[];
    private _partner!: Partner;
    private _paymentMethod!: PaymentMethod;
    private _payments!: InvoicePaymentBase[];
    private _potentialOverpaymentReimbursements!: OverpaymentReimbursement[];
    private _potentialPayments!: InvoicePaymentBase[];
    private _shareholder!: Shareholder;
    private _taxAmount!: number;
    private _taxes!: BankAccountLineTax[];
    private _type!: BankAccountLineType;
    private _valueDate!: moment.Moment;

    constructor(data: any) {
        super(data);
        this._allocations = this._allocations || [];
        this._taxes = this._taxes || [];
        this.overpaymentReimbursements = data['overpaymentReimbursements'] || [];
        this.payments = data['payments'] || [];
        this.potentialOverpaymentReimbursements = data['potentialOverpaymentReimbursements'] || [];
        this.potentialPayments = data['potentialPayments'] || [];
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public getAmountClass(): string {
        if (this.amountSigned > 0) {
            return 'text-green-500';
        } else {
            return 'text-red-500';
        }
    }

    public filterPotentialPayments(): void {
        if (this._potentialPayments.length === 0) return;

        const errorMargin = 100;
        const payments = this._potentialPayments;
        const transactionAmount = this._amountUnmatched;
        const results: InvoicePaymentBase[] = [];

        function _combinaisonsRecursives(index: number, currentAmount: number, selectedPayments: InvoicePaymentBase[]) {
            if (Math.abs(currentAmount - transactionAmount) <= errorMargin) {
                results.push(...selectedPayments);
            }

            if (currentAmount >= transactionAmount - errorMargin || index === payments.length) {
                return;
            }

            // Inclure la facture actuelle dans la combinaison
            _combinaisonsRecursives(index + 1, currentAmount + payments[index].invoice.amountDueOrg, [...selectedPayments, payments[index]]);

            // Exclure la facture actuelle de la combinaison
            _combinaisonsRecursives(index + 1, currentAmount, selectedPayments);
        }

        _combinaisonsRecursives(0, 0, []);

        // Trier les résultats en fonction de l'écart par rapport à la transaction
        results.sort((a, b) => { return b.amount - a.amount });

        this.potentialPayments = results;
    }

    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/

    public get accountEntry(): AccountEntry {
        return this._accountEntry;
    }

    public set accountEntry(value: any) {
        this._setValue('_accountEntry', value, 'AccountEntry');
    }

    public get accountEntryProfitLoss(): AccountEntry {
        return this._accountEntryProfitLoss;
    }

    public set accountEntryProfitLoss(value: any) {
        this._setValue('_accountEntryProfitLoss', value, 'AccountEntry');
    }

    public get allocations(): BankAccountLineAllocation[] {
        return this._allocations;
    }

    public set allocations(value: any) {
        this._setValue('_allocations', value, 'BankAccountLineAllocation');
    }

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get amountCurrency(): number {
        return this._amountCurrency;
    }

    public set amountCurrency(value: any) {
        this._setValue('_amountCurrency', value, 'float');
    }

    public get amountSigned(): number {
        return this._amountSigned;
    }

    public set amountSigned(value: any) {
        this._setValue('_amountSigned', value, 'float');
    }

    public get amountUnmatched(): number {
        return this._amountUnmatched;
    }

    public set amountUnmatched(value: any) {
        this._setValue('_amountUnmatched', value, 'float');
    }

    public get appliedRule(): BankAccountRule {
        return this._appliedRule;
    }

    public set appliedRule(value: any) {
        this._setValue('_appliedRule', value, 'BankAccountRule');
    }

    public get bankAccountId(): number {
        return this._bankAccountId;
    }

    public set bankAccountId(value: any) {
        this._setValue('_bankAccountId', value, 'integer');
    }

    public get coming(): boolean {
        return this._coming;
    }

    public set coming(value: any) {
        this._setValue('_coming', value, 'boolean');
    }

    public get comment(): any {
        return this._comment;
    }

    public set comment(value: any) {
        this._setValue('_comment', value, 'string');
    }

    public get credit(): number {
        return this._credit;
    }

    public set credit(value: any) {
        this._setValue('_credit', value, 'float');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get customLabel(): string {
        return this._customLabel;
    }

    public set customLabel(value: any) {
        this._setValue('_customLabel', value, 'string');
    }

    public get debit(): number {
        return this._debit;
    }

    public set debit(value: any) {
        this._setValue('_debit', value, 'float');
    }

    public get deposit(): Deposit {
        return this._deposit;
    }

    public set deposit(value: any) {
        this._setValue('_deposit', value, 'Deposit');
    }

    public set duplicate(value: any) {
        this._setValue('_duplicate', value, 'boolean');
    }

    public get employee(): Employee {
        return this._employee;
    }

    public set employee(value: any) {
        this._setValue('_employee', value, 'Loan');
    }

    public get exchangeRate(): number {
        return this._exchangeRate;
    }

    public set exchangeRate(value: any) {
        this._setValue('_exchangeRate', value, 'float', { precision: 5 });
    }

    public get fullLabel(): string {
        let label = this._operationNature || '';
        if (this._customLabel) {
            label += ' - ' + this._customLabel;
        }
        return label;
    }

    public get justified(): boolean {
        return this._justified;
    }

    public set justified(value: any) {
        this._setValue('_justified', value, 'boolean');
    }

    public get legacyPayment(): LegacyPayment {
        return this._legacyPayment;
    }

    public set legacyPayment(value: any) {
        this._setValue('_legacyPayment', value, 'LegacyPayment');
    }

    public get lineHash(): string {
        return this._lineHash;
    }

    public set lineHash(value: any) {
        this._setValue('_lineHash', value, 'string');
    }

    public get loan(): Loan {
        return this._loan;
    }

    public set loan(value: any) {
        this._setValue('_loan', value, 'Employee');
    }

    public get manageTax(): boolean {
        return this._manageTax;
    }

    public set manageTax(value: any) {
        this._setValue('_manageTax', value, 'boolean');
    }

    public get operationDate(): moment.Moment {
        return this._operationDate;
    }

    public set operationDate(value: any) {
        this._setValue('_operationDate', value, 'date');
    }

    public get operationDetails(): string {
        return this._operationDetails;
    }

    public set operationDetails(value: any) {
        this._setValue('_operationDetails', value, 'string');
    }

    public get operationNature(): string {
        return this._operationNature;
    }

    public set operationNature(value: any) {
        this._setValue('_operationNature', value, 'string');
    }

    public get origin(): any {
        return this._origin;
    }

    public set origin(value: any) {
        this._setValue('_origin', value, 'string');
    }

    public get originalCurrency(): Currency {
        return this._originalCurrency;
    }

    public set originalCurrency(value: any) {
        this._setValue('_originalCurrency', value, 'Currency');
    }

    public get overpayment(): Overpayment {
        return this._overpayment;
    }

    public set overpayment(value: any) {
        this._setValue('_overpayment', value, 'Overpayment');
    }

    public get overpaymentReimbursements(): OverpaymentReimbursement[] {
        return this._overpaymentReimbursements;
    }

    public set overpaymentReimbursements(value: any) {
        this._setValue('_overpaymentReimbursements', value, 'OverpaymentReimbursement');
    }

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

    public get paymentMethod(): PaymentMethod {
        return this._paymentMethod;
    }

    public set paymentMethod(value: any) {
        this._setValue('_paymentMethod', value, 'PaymentMethod');
    }

    public get payments(): InvoicePaymentBase[] {
        return this._payments;
    }

    public set payments(value: any) {
        if (!this._type) return;

        if (this.type.code == 'vendor_payment' || this.type.code == 'vendor_reimbursement') {
            this._setValue('_payments', value, 'PurchaseInvoicePayment');
        } else if (this.type.code == 'client_payment' || this.type.code == 'client_reimbursement') {
            this._setValue('_payments', value, 'SalesInvoicePayment');
        }
    }

    public get potentialOverpaymentReimbursements(): OverpaymentReimbursement[] {
        return this._potentialOverpaymentReimbursements;
    }

    public set potentialOverpaymentReimbursements(value: any) {
        this._setValue('_potentialOverpaymentReimbursements', value, 'OverpaymentReimbursement');
    }

    public get potentialPayments(): InvoicePaymentBase[] {
        return this._potentialPayments;
    }

    public set potentialPayments(value: any) {
        if (!this._type) {
            this._potentialPayments = [];
        } else if (this.type.code == 'vendor_payment' || this.type.code == 'vendor_reimbursement') {
            this._setValue('_potentialPayments', value, 'PurchaseInvoicePayment');
        } else if (this.type.code == 'client_payment' || this.type.code == 'client_reimbursement') {
            this._setValue('_potentialPayments', value, 'SalesInvoicePayment');
        } else {
            this._potentialPayments = [];
        }
    }

    public get shareholder(): Shareholder {
        return this._shareholder;
    }

    public set shareholder(value: any) {
        this._setValue('_shareholder', value, 'Shareholder');
    }

    public get taxAmount(): number {
        return this._taxAmount;
    }

    public set taxAmount(value: any) {
        this._setValue('_taxAmount', value, 'float');
    }

    public get taxes(): BankAccountLineTax[] {
        return this._taxes;
    }

    public set taxes(value: any) {
        this._setValue('_taxes', value, 'BankAccountLineTax');
    }

    public get type(): BankAccountLineType {
        return this._type;
    }

    public set type(value: any) {
        this._setValue('_type', value, 'BankAccountLineType');
    }

    public get valueDate(): moment.Moment {
        return this._valueDate;
    }

    public set valueDate(value: any) {
        this._setValue('_valueDate', value, 'date');
    }

    public get duplicate(): boolean {
        return this._duplicate;
    }

}

import { Injectable } from '@angular/core';

// Services
import { RouteParam, ServiceBase } from '../service-base';

const CLASS_NAME = 'AccountEntryExport';
const ENDPOINT = `/account-entry-exports/`;

@Injectable({
    providedIn: 'root'
})
export class AccountEntryExportService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, map, Observable } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { Partner } from 'src/app/@shared/models/partner/partner.model';
import { QueryOptions, QueryOptionsParam } from 'src/app/@shared/utilities/odata/query-params';

const CLASS_NAME = 'Partner';
const ENDPOINT = `/partners/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'addresses', path: 'addresses/', childClass: 'Address' },
    { key: 'contacts', path: 'contacts/', childClass: 'PartnerContact' },
    { key: 'client-account-statement', path: 'client-account-statement/', childClass: 'AccountStatement', resultCardinality: 'one' },
    { key: 'vendor-account-statement', path: 'vendor-account-statement/', childClass: 'AccountStatement', resultCardinality: 'one' },
]
const ROUTES: RouteParam[] = [
    { key: 'merge', path: '{id}/merge/' },
    { key: 'clientAccountStatement', path: '{id}/client-account-statement/$value/' },
    { key: 'vendorAccountStatement', path: '{id}/vendor-account-statement/$value/' },
]

@Injectable({
    providedIn: 'root'
})
export class PartnerService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public downloadStatement$(partner: Partner, client: boolean = true, optionsIn?: QueryOptionsParam): Observable<Blob> {
        let route = client ? 'clientAccountStatement' : 'vendorAccountStatement';
        let url = this.routes[route].replace('{id}', partner.id.toString());
        let options = new QueryOptions(url, optionsIn);
        let queryUrl = options.toUrlString();
        if (optionsIn?.activityIndicator) {
            this._downloading$.next(true)
        }
        return this.httpClient.get(queryUrl, {
            responseType: 'blob'
        }).pipe(
            finalize(() => {
                if (optionsIn?.activityIndicator) {
                    this._downloading$.next(false)
                }
            }),
            map(response => { return response })
        );
    }

    public merge$(sourcePartner: Partner, targetPartner: Partner): Observable<any> {
        return this.update$(sourcePartner, {
            select: false,
            url: this.routes.merge.replace('{id}', targetPartner.id.toString())
        });
    }
}

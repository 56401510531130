import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';

// Components
import { BaseComponent } from './base.component';

@Component({
    selector: 'b4cash-page-base',
    template: `
        NO UI TO BE FOUND HERE!
    `,
})
export class PageBaseComponent extends BaseComponent {

    /**
     * Indique si la page est une page de paramètres
     *
     * Par défaut, une page n'est pas une page de paramètres,
     * mais cette propriété peut être surchargée dans les classes filles
     *
     * Si une page est une page de paramètres, le fil d'ariane sera construit
     * de manière différente ; la première étape sera la page de paramètres
     */
    protected settingsPage: boolean = false;

    // Premier élément du fil d'ariane, en fonction de la page
    private _homeBreadcrumbItem: MenuItem = { icon: 'pi pi-home', routerLink: '/home/dashboard' };
    private _settingsBreadcrumbItem: MenuItem = { icon: 'pi pi-cog', routerLink: '/settings' };

    override ngOnInit() {
        // Call parent method
        super.ngOnInit();
        // Construction du menu de navigation
        this.appService.homeBreadcrumbItem = this.settingsPage ? this._settingsBreadcrumbItem : this._homeBreadcrumbItem;
        setTimeout(() => this.buildBreadcrumbs(), 1);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this._clearBreadcrumbs();
    }

    protected buildBreadcrumbs(): void {
        this._clearBreadcrumbs();
    }

    protected setTitle(title: string) {
        this.titleService.setTitle(title);
    }

    private _clearBreadcrumbs(): void {
        this.appService.breadcrumbItems = [];
    }
}

import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'PartnerType';
const ENDPOINT = `/partner-types/`;

@Injectable({
    providedIn: 'root'
})
export class PartnerTypeService extends ServiceBase {

    protected override useCache = true;

    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

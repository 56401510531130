import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

// Injector
import { AppInjector } from 'src/app/app.component';

// Components
import { BaseComponent } from './base.component';

// Services
import { Logger } from 'src/app/@core/services/logger/logger.service';
import { MessageServiceBroker } from 'src/app/@core/services/message.service';

// Modèles
import { Account } from '../../models/accounting/account.model';

const LOGGER = new Logger('FormBaseComponent');

@Component({
    selector: 'b4cash-form-base',
    template: `
        NO UI TO BE FOUND HERE!
    `,
    standalone: false
})
export abstract class FormBaseComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    // Common services
    public formBuilder: FormBuilder;
    public messageService: MessageServiceBroker;
    // Default form group
    public form: FormGroup;
    // Erreur du formulaire
    public serverErrors: any = {};

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    constructor() {
        super();
        // Services
        this.formBuilder = AppInjector.get(FormBuilder);
        this.messageService = AppInjector.get(MessageServiceBroker);
        // Form group init
        this.form = this.formBuilder.group({});
        // Build form
        this.buildForm();
    }

    public override ngOnInit() {
        this.registerFilterObservables();
        super.ngOnInit();
        this.initForm();
        this.manageFieldEnabilities();
    }

    /**
     * Récupère le message d'erreur long associé à un élément du formulaire
     * @param controlName
     */
    public getErrorLongText(controlName: string): string {
        return this.getErrorMessage(controlName, 'longText');
    }

    /**
     * Récupère le message d'erreur court associé à un élément du formulaire
     * @param controlName
     */
    public getErrorShortText(controlName: string): string {
        return this.getErrorMessage(controlName, 'shortText');
    }

    /**
     * Détection de l'existance d'une erreur pour le champ demandé,
     * que l'erreur soit détectée dans le formulaire ou renvoyée par le serveur
     */

    public hasError(fieldName: string): boolean {
        return this.isFormFieldInvalid(fieldName) || this.hasServerError(fieldName);
    }

    /**
     * Détection de l'existance d'une erreur pour le champ demandé,
     * erreur renvoyée par le serveur
     */
    public hasServerError(fieldName: string): boolean {
        return !!this.serverErrors[fieldName];
    }

    /**
     * Dit si le contrôle de formulaire est invalide
     * @param filedName The field to check
     * @returns True si l'élément est invalide
     */
    public isFormFieldInvalid(filedName: string): boolean {
        try {
            return !!this.form.get(filedName)?.invalid;
        } catch (_) {
            return false;
        }
    }

    /**
     * L'utilisateur clique sur Supprimer
     */
    public onClickDelete() {
        this.delete();
    }

    /**
     * L'utilisateur clique sur Sauvegarder
     */
    public onClickSave() {
        this.save();
    }

    public onAccountSelected(account: Account, field: string) {
        this.selectAccount(account, field);
    }

    /**
     * À chaque pression sur une touche
     * @param event
     */
    public onKeyDown(event: any) {
        // Detect platform
        if (navigator.platform.match('Mac')) {
            this.handleKeyEvents(event, event.metaKey);
        } else {
            this.handleKeyEvents(event, event.ctrlKey);
        }
    }


    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected archive() {
        LOGGER.debug('archive() method is not defined in controller ' + this.constructor.name);
    }

    /**
     * Assignation des erreurs au formulaire
     * @param errors
     */
    protected assignErrors(errors: any) {
        for (var key in errors) {
            try {
                // Erreur concernant un champ
                this.form.get(key)?.setErrors({
                    shortText: errors[key].shortText,
                    longText: errors[key].longText
                });
            } catch (_) {
                LOGGER.warn(key + ' ne peut pas être géré avec l\'assignation d\'erreur par défaut, veuillez redéfinir la méthode');
            }
        }
    }

    protected buildForm() {
        LOGGER.debug('buildForm() method is not defined in controller ' + this.constructor.name);
    }

    /**
     * Réinitialisation des erreurs
     */

    protected clearErrors() {
        this.serverErrors = {};
        this.form.setErrors(null);
    }

    /**
     * Réinitialisation du formulaire
     */
    protected clearForm() {
        this.form.reset();
    }

    /**
     * Méthode par défaut de suppression de l'objet, doit être redéfinie dans les classes filles
     */
    protected delete() {
        LOGGER.debug('delete() method is not defined in controller ' + this.constructor.name);
    }

    /**
     * Récupère le message d'erreur demandé associé à un élément du formulaire
     * @param controlName Le contrôle
     * @param key Message court/long
     * @return The required message
     */
    protected getErrorMessage(controlName: string, key: string): string {
        return this.form.get(controlName)?.getError(key) || this.serverErrors[controlName]?.[key] || '';
    }

    /**
     * Gestion des commandes clavier sur Mac
     * @param event Évènement
     * @param commandKey Touche de commande
     */
    protected handleKeyEvents(event: any, commandKey: any) {
        // MetaKey documentation
        // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/metaKey
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if (commandKey && charCode === 's') {
            // Action on Cmd + S
            event.preventDefault();
            this.save();
        } else if (commandKey && charCode === 'f') {
            // Action on Cmd + F
            event.preventDefault();
            this.toggleSearchVisibility();
        }
    }

    protected initForm() {}

    protected manageFieldEnabilities() {}

    /**
     * Gestion des champs de formulaire en fonction du paramétrage
     * @param formControl L'élément à gérer
     * @param visibility La visibilité à appliquer
     */
    protected manageFormField(formControl: FormControl, visibility: string) {
        // Enable?
        if (visibility === 'read_only') {
            formControl.disable();
        } else {
            formControl.enable();
        }
        // Required?
        if (visibility === 'required') {
            formControl.setValidators(Validators.required);
        } else {
            formControl.setValidators([]);
            formControl.updateValueAndValidity();
        }
    }
    protected registerFilterObservables() {}

    protected save() {
        LOGGER.debug('save() method is not defined in controller ' + this.constructor.name);
    }

    protected selectAccount(account: Account, field: string) {
        this.form.get(field)!.setValue(account);
    }

    /**
     * Abstract definition
     */
    protected toggleSearchVisibility() {}

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { LostPasswordFormComponent } from '../../components/lost-password-form/lost-password-form.component';

@Component({
    selector: 'app-lost-password',
    standalone: true,
    imports: [
        CommonModule,
        LostPasswordFormComponent,
    ],
    templateUrl: './lost-password.component.html',
    styleUrls: ['./lost-password.component.scss']
})
export class LostPasswordComponent extends PageBaseComponent {

}

import { ModelBase } from '../abstract/model-base.model';
import { BankAccountLineAllocation } from './bank-account-line-allocation.model';
import { BankAccountRuleAllocation } from './bank-account-line-rule-allocation.model';
import { BankAccountLineType } from './bank-account-line-type.model';
import { Employee } from '../human-resources/employee.model';
import { Loan } from './loan.model';
import { NumericComparator } from '../core/numeric-comparator.model';
import { PaymentMethod } from '../core/payment-method.model';
import { Partner } from '../partner/partner.model';
import { Shareholder } from '../client/shareholder.model';
import { TransactionDirection } from './transaction-direction.model';

export class BankAccountRule extends ModelBase {

    static override CLASS_NAME: string = 'BankAccountRule';

    private _active!: boolean;
    private _allocations!: BankAccountRuleAllocation[];
    private _amountMax!: number;
    private _amountMin!: number;
    private _comment!: string;
    private _comparator!: NumericComparator;
    private _customLabel!: string;
    private _debitCredit!: TransactionDirection;
    private _detailsKeywords!: string;
    private _employee!: Employee;
    private _label!: string;
    private _loan!: Loan;
    private _natureKeywords!: string;
    private _partner!: Partner;
    private _paymentMethod!: PaymentMethod;
    private _priority!: number;
    private _shareholder!: Shareholder;
    private _type!: BankAccountLineType;

    constructor(data: any) {
        super(data);
        this._allocations = this._allocations || [];
    }

    public get active(): boolean {
        return this._active;
    }

    public set active(value: any) {
        this._setValue('_active', value, 'boolean');
    }

    public get allocations(): BankAccountLineAllocation[] {
        return this._allocations;
    }

    public set allocations(value: any) {
        this._setValue('_allocations', value, 'BankAccountRuleAllocation');
    }

    public get amountMax(): number {
        return this._amountMax;
    }

    public set amountMax(value: any) {
        this._setValue('_amountMax', value, 'float');
    }

    public get amountMin(): number {
        return this._amountMin;
    }

    public set amountMin(value: any) {
        this._setValue('_amountMin', value, 'float');
    }

    public get comment(): any {
        return this._comment;
    }

    public set comment(value: any) {
        this._setValue('_comment', value, 'string');
    }

    public get comparator(): NumericComparator {
        return this._comparator;
    }

    public set comparator(value: any) {
        this._setValue('_comparator', value, 'NumericComparator');
    }

    public get customLabel(): string {
        return this._customLabel;
    }

    public set customLabel(value: any) {
        this._setValue('_customLabel', value, 'string');
    }

    public get debitCredit(): TransactionDirection {
        return this._debitCredit;
    }

    public set debitCredit(value: any) {
        this._setValue('_debitCredit', value, 'TransactionDirection');
    }

    public get detailsKeywords(): string {
        return this._detailsKeywords;
    }

    public set detailsKeywords(value: any) {
        this._setValue('_detailsKeywords', value, 'string');
    }

    public get employee(): Employee {
        return this._employee;
    }

    public set employee(value: any) {
        this._setValue('_employee', value, 'Loan');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get loan(): Loan {
        return this._loan;
    }

    public set loan(value: any) {
        this._setValue('_loan', value, 'Employee');
    }

    public get natureKeywords(): string {
        return this._natureKeywords;
    }

    public set natureKeywords(value: any) {
        this._setValue('_natureKeywords', value, 'string');
    }

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

    public get paymentMethod(): PaymentMethod {
        return this._paymentMethod;
    }

    public set paymentMethod(value: any) {
        this._setValue('_paymentMethod', value, 'PaymentMethod');
    }

    public get priority(): number {
        return this._priority;
    }

    public set priority(value: any) {
        this._setValue('_priority', value, 'integer');
    }

    public get shareholder(): Shareholder {
        return this._shareholder;
    }

    public set shareholder(value: any) {
        this._setValue('_shareholder', value, 'Shareholder');
    }

    public get type(): BankAccountLineType {
        return this._type;
    }

    public set type(value: any) {
        this._setValue('_type', value, 'BankAccountLineType');
    }

}

<div class="h-full w-full flex flex-column justify-content-between overflow-hidden">

    <!-- Menu fonctionnel -->
    <ul class="list-none m-0 px-0 py-3">
        @for (menuItem of menuItems; track menuItem.section) {
        @if (menuItem.showSection) {
        <!-- Section -->
        <li>
            <a class="menu-section-title flex justify-content-between" routerLinkActive="active-link"
                [routerLink]="appService.menuOpen && !!menuItem.subItems ? null : menuItem.link"
                (click)="onClickSectionTitle(menuItem.section)"
                (mouseover)="onHoverSectionTitle(menuItem.section, $event)">
                <div class="with-items" [ngClass]="menuItem.ngClass" [pTooltip]="getTooltip(menuItem)"
                    tooltipPosition="right">
                    <i class="fa-solid" [ngClass]="menuItem.icon"></i>
                    <span class="flex-grow-1">{{ menuItem.title }}</span>
                    @if (menuItem.subItems) {
                    <i class="pi" [ngClass]="menuItem.showSubItems ? 'pi-angle-up' : 'pi-angle-down'"></i>
                    }
                </div>
            </a>

            <!-- Liens internes à la section -->
            @if (appService.menuOpen && menuItem.showSubItems) {
            @for (subItem of menuItem.subItems; track subItem.link) {
            <a class="menu-item" [routerLink]="subItem.link" routerLinkActive="active-link"
                [target]="subItem.newTab ? '_blank' : '_self'">
                <div [ngClass]="subItem.ngClass" class="flex align-items-center justify-content-between"
                    (mouseenter)="subItem.hover = true" (mouseleave)="subItem.hover = false">
                    <span>{{ subItem.title }}</span>
                    @if (subItem.locked) {
                    <i class="fa-solid fa-lock text-sm"></i>
                    } @else if (!!subItem.action && subItem.hover &&
                    this.appService.user?.hasAuhtorization(subItem.authorization)) {
                    <i [ngClass]="subItem.actionIcon" [pTooltip]="subItem.actionTooltip"
                        (click)="subItem.action(); stopPropagation($event); preventDefault($event)"></i>
                    }
                </div>
            </a>
            }
            }
        </li>
        }
        }
    </ul>

    <!-- Menu de l'aide -->
    <ul class="list-none m-0 px-0 py-3">
        @for (menuItem of helpMenuItems; track menuItem.section) {
        @if (menuItem.showSection) {
        <!-- Section -->
        <li>
            <a class="menu-section-title flex justify-content-between" routerLinkActive="active-link"
                [routerLink]="appService.menuOpen && !!menuItem.subItems ? null : menuItem.link"
                (click)="onClickSectionTitle(menuItem.section)"
                (mouseover)="onHoverSectionTitle(menuItem.section, $event)">
                <div class="with-items" [ngClass]="menuItem.ngClass" [pTooltip]="getTooltip(menuItem)"
                    tooltipPosition="right">
                    <i class="fa-solid" [ngClass]="menuItem.icon"></i>
                    <span class="flex-grow-1">{{ menuItem.title }}</span>
                    @if (menuItem.subItems) {
                    <i class="pi" [ngClass]="menuItem.showSubItems ? 'pi-angle-up' : 'pi-angle-down'"></i>
                    }
                </div>
            </a>

            <!-- Liens internes à la section -->
            @if (appService.menuOpen && menuItem.showSubItems) {
            @for (subItem of menuItem.subItems; track subItem.link) {
            <a class="menu-item" [routerLink]="subItem.link" routerLinkActive="active-link"
                [target]="subItem.newTab ? '_blank' : '_self'">
                <div [ngClass]="subItem.ngClass">{{ subItem.title }}
                    @if (subItem.locked) {
                    <i class="fa-solid fa-lock text-sm ml-2"></i>
                    }
                </div>
            </a>
            }
            }
        </li>
        }
        }
    </ul>
</div>

<p-tieredMenu #tieredMenu [model]="tieredMenuItems" [popup]="true" appendTo="body" styleClass="ml-7">
    <ng-template #item let-subItem>
        <a [routerLink]="subItem.link" routerLinkActive="active-link" [target]="subItem.newTab ? '_blank' : '_self'">
            <div class="px-3 py-2" [ngClass]="subItem.ngClass">{{ subItem.title }}
                @if (subItem.locked) {
                <i class="fa-solid fa-lock text-sm ml-2"></i>
                }
            </div>
        </a>
    </ng-template>
</p-tieredMenu>
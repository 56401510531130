import { User } from '../../user/user.model';

export abstract class Person extends User {

    private _user!: User;

    public get user(): User {
        return this._user;
    }

    public set user(value: any) {
        this._setValue('_user', value, 'User');
    }


}

<div class="h-29rem">
    <div class="text-xl font-medium mb-3">
        Se connecter
    </div>
    <form (ngSubmit)="login()" [formGroup]="form" novalidate class="flex flex-column">

        <!-- Email -->
        <input pInputText class="mb-4" type="email" formControlName="email" placeholder="Adresse email" required />

        <!-- Mot de passe -->
        <p-password class="mb-4" inputStyleClass="w-full" [feedback]="false" [toggleMask]="true"
            formControlName="password" placeholder="Mot de passe" required />

        <!-- Garder connexion -->
        <div class="flex justify-content-between align-items-center mb-4">
            <span class="text-sm">Se souvenir de moi</span>
            <p-inputSwitch formControlName="remember"></p-inputSwitch>
        </div>

        <!-- Connexion / mot de passe oublié -->
        <div class="grid mb-3">
            <div class="col-6">
                <p-button styleClass="w-full" type="submit" label="Connexion" icon="pi pi-arrow-right" iconPos="right" [rounded]="true"
                    [disabled]="form.invalid || loading"></p-button>
            </div>
            <div class="col-6 flex align-items-center justify-content-end">
                <a routerLink="/lost-password" class="text-sm text-color no-underline hover:text-primary">Mot de passe
                    oublié ?</a>
            </div>
        </div>

        <!-- Lien d'inscription -->
        <div class="grid">
            <div class="col">
                <p-button styleClass="w-full surface-900 text-0 border-900" type="button" label="Créer un compte" [rounded]="true"
                    routerLink="/subscribe"></p-button>
            </div>
        </div>
    </form>
</div>
import moment from "moment";
import { ModelBase } from '../abstract/model-base.model';

export class EmailEvent extends ModelBase {

    static override CLASS_NAME: string = 'EmailEvent';

    private _event!: string;
    private _eventDatetime!: moment.Moment;
    private _to!: string;

    public get event(): string {
        return this._event;
    }

    public set event(value: any) {
        this._setValue('_event', value, 'string');
    }

    public get eventDatetime(): moment.Moment {
        return this._eventDatetime;
    }

    public set eventDatetime(value: any) {
        this._setValue('_eventDatetime', value, 'datetime');
    }

    public get label(): string {
        switch (this.event) {
            case 'blocked':
                return 'Bloqué';
            case 'click':
                return 'Lien cliqué';
            case 'deferred':
                return 'Reporté';
            case 'delivered':
                return 'Délivré';
            case 'error':
                return 'Erreur';
            case 'hard_bounce':
                return 'Rebond hard';
            case 'invalid_email':
                return 'Email invalide';
            case 'opened':
            case 'proxy_open':
            case 'unique_opened':
            case 'unique_proxy_open':
                return 'Ouvert';
            case 'request':
                return 'Envoyé';
            case 'soft_bounce':
                return 'Rebond soft';
            case 'spam':
                return 'Mis en spam';
            case 'unsubscribed':
                return 'Désabonné';
            default:
                return this.event;
        }
    }

    public get severity(): string | undefined {
        switch (this.event) {
            case 'blocked':
            case 'error':
            case 'hard_bounce':
            case 'invalid_email':
            case 'spam':
            case 'unsubscribed':
                return 'danger';
            case 'deferred':
            case 'soft_bounce':
                return 'warn';
            case 'opened':
            case 'proxy_open':
            case 'unique_opened':
            case 'unique_proxy_open':
                return 'success';
            case 'click':
                return 'secondary';
            case 'request':
            case 'delivered':
            default:
                return 'info';
        }
    }

    public get to(): string {
        return this._to;
    }

    public set to(value: any) {
        this._setValue('_to', value, 'string');
    }
}

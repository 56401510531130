import moment from 'moment';
import { FilterMetadata, SelectItem } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';

export interface FilterParam {
    filters?: Filter[];
    and?: boolean;
    not?: boolean;
    comparator?: string;
    property?: string;
    value?: any;
}

export class Filter {
    static readonly URL_KEY: string = '$filter=';
    static readonly COMPARATOR = {
        // Value comparators
        EQ: 'eq',
        NE: 'ne',
        GE: 'ge',
        GT: 'gt',
        LE: 'le',
        LT: 'lt',
        // String functions
        Contains: 'substringof',
        EndsWith: 'endswith',
        StartsWith: 'startswith'
    };
    static readonly OPERATOR = {
        AND: ' and ',
        NOT: 'not ',
        OR: ' or '
    };
    static readonly SEPARATOR: string = ',';

    // Comparateur autorisés selon type de donnée
    static readonly TextComparators: SelectItem[] = [
        {
            label: 'Contient',
            value: Filter.COMPARATOR.Contains,
        },
        {
            label: 'Commence par',
            value: Filter.COMPARATOR.StartsWith,
        },
        {
            label: 'Finit par',
            value: Filter.COMPARATOR.EndsWith,
        },
        {
            label: 'Est égal à',
            value: Filter.COMPARATOR.EQ,
        },
    ]
    static readonly NumericComparators: SelectItem[] = [
        {
            label: 'Est égal à',
            value: Filter.COMPARATOR.EQ,
        },
        {
            label: 'Est inférieur ou égal à',
            value: Filter.COMPARATOR.LE,
        },
        {
            label: 'Est supérieur ou égal à',
            value: Filter.COMPARATOR.GE,
        },
    ]
    static readonly DateComparators: SelectItem[] = [
        {
            label: 'Est égal à',
            value: Filter.COMPARATOR.EQ,
        },
        {
            label: 'Est inférieure ou égal à',
            value: Filter.COMPARATOR.LE,
        },
        {
            label: 'Est supérieure ou égal à',
            value: Filter.COMPARATOR.GE,
        },
    ]

    /**
     * ATTRIBUTES
     */

    filters: Filter[];
    and: boolean;
    not: boolean;
    comparator: string;
    property: string;
    value: any;

    /**
     * MÉTHODES STATIQUES
     */

    static fromTableLazyLoadEvent(event: TableLazyLoadEvent): Filter[] {
        let filters: Filter[] = [];

        if (!!event.filters) {
            for (let key in event.filters) {

                let filterValue = (event.filters[key] as FilterMetadata)?.value;
                if (!filterValue && filterValue !== 0) continue;

                let matchMode = (event.filters[key] as FilterMetadata)?.matchMode!;

                if (Array.isArray(filterValue)) {
                    let filter = new Filter({
                        filters: [],
                        and: false
                    })
                    for (let i = 0; i < filterValue.length; i++) {
                        let tmpFilter = new Filter({
                            property: key,
                            comparator: matchMode,
                            value: filterValue[i]
                        });
                        filter.filters.push(tmpFilter);
                    }
                    filters.push(filter);
                } else {
                    let filter = new Filter({
                        property: key,
                        comparator: matchMode,
                        value: filterValue
                    });
                    filters.push(filter);
                }

            }
        }

        return filters;
    }

    static toUrlString(filters: Filter[], withPrefix: boolean = true): string {
        let url = '';
        if (withPrefix) {
            // Init URL with keyword
            url = Filter.URL_KEY;
        }

        // Concatenate filters
        for (let key in filters) {
            url += filters[key].toString();
            url += Filter.SEPARATOR;
        }
        // Remove last separator
        url = url.substring(0, url.length - 1);

        return url;
    }

    /**
     * MÉTHODES D'INSTANCE
     */

    constructor(param: FilterParam) {
        this.filters = typeof param.filters !== 'undefined' ? param.filters : [];
        this.and = typeof param.and !== 'undefined' ? param.and : true;
        this.not = typeof param.not !== 'undefined' ? param.not : false;
        this.comparator = typeof param.comparator !== 'undefined' ? param.comparator : Filter.COMPARATOR.EQ;
        this.property = typeof param.property !== 'undefined' ? param.property : '';
        this.value = typeof param.value !== 'undefined' ? param.value : null;
    }

    public toString(): string {
        let url: string = '';

        // Not operator
        url += this.not ? Filter.OPERATOR.NOT : '';

        // Simple Filter or array of Filters ?
        if (typeof this === 'string') {
            return this;
        } else if (this.filters.length > 0) {
            url += '(';

            for (let i = 0; i < this.filters.length; i++) {
                // Recursive call
                url += this.filters[i].toString();

                // Logical operator
                if (i < this.filters.length - 1) {
                    url += this.and ? Filter.OPERATOR.AND : Filter.OPERATOR.OR;
                }
            }

            url += ')';
        } else {
            let value: string;
            if (this.value === null || typeof this.value === 'undefined') {
                value = 'null';
            } else {
                switch (typeof this.value) {
                    case 'boolean':
                        value = this.value.toString();
                        break;
                    case 'number':
                        if (this.property.endsWith('.month')) {
                            // Special handling for month as JS starts with index 0
                            let month = this.value + 1;
                            value = month.toString();
                        } else {
                            value = this.value.toString();
                        }
                        break;
                    case 'string':
                        value = "'" + this.value + "'";
                        break;
                    case 'object':
                        if (this.value instanceof Date) {
                            this.value = moment(this.value);
                        }
                        if (moment.isMoment(this.value)) {
                            value = "'" + this.value.format('YYYY-MM-DD') + "'";
                        } else {
                            throw 'Invalid filter value!';
                        }
                        break;
                    default:
                        throw 'Invalid filter value!';
                }
            }
            url += this.property.replace(/\./g, '/') + ' ' + this.comparator + ' ' + value;
        }

        return url;
    }
}

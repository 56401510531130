<div class="flex justify-content-between align-items-center p-3">
    <!-- Logo -->
    <div class="h-full flex align-items-center pl-1">

        <!-- Menu ouvert -->
        @if (appService.menuOpen) {

        <div class="h-3rem py-1" (click)="onClickLogo()">
            <img class="h-full" src="{{ appService.logoSrc }}" alt="Logo" />
        </div>

        <!-- Bouton pour fermer le menu -->
        <p-button type="button" icon="pi pi-angle-left" size="large" class="ml-3" [rounded]="true" [text]="true"
            (click)="appService.toggleMenu()"></p-button>

        <!-- Menu fermé -->
        } @else {

        <div class="h-3rem py-1" (click)="onClickLogo()">
            <img class="h-full" src="assets/images/big-icon.png" alt="Logo" />
        </div>

        <!-- Bouton pour ouvrir le menu -->
        <p-button type="button" icon="pi pi-angle-right" size="large" class="ml-3" [rounded]="true" [text]="true"
            (click)="appService.toggleMenu()"></p-button>
        }
    </div>

    @if (!Environment.production) {
    <!-- Message pour ignaler que c'est un environnement de TEST -->
    <!-- <div class="bg-red-50 border-2 border-round-xl p-2 text-2xl text-red-500 font-medium">Environnement de test</div> -->
    }
    <!-- Éléments du menu -->
    <div class="flex align-items-end">
        @if (!!appService.selectedClient && appService.selectedClient.logo) {
        <!-- Logo de la société actuelle -->
        <!-- <img class="h-3rem" src="{{ appService.selectedClient.logo }}" alt="Logo" /> -->
        }

        <!-- Liste des organisations -->
        <p-button type="button" [label]="client?.commercialName" [text]="true" icon="pi pi-angle-down" iconPos="right"
            (click)="clientMenu.toggle($event)"></p-button>

        @if (!!appService.selectedClient) {
        <!-- Lien vers les paramètres -->
        <a routerLink="settings" class="mr-2"><p-button icon="pi pi-cog" [rounded]="true" [text]="true"></p-button></a>
        }

        <!-- Avatar de l'utilisateur -->
        <p-avatar image="{{appService.user?.avatar}}" label="{{appService.user?.avatarLabel}}"
            [style]="{ 'background-color': appService.user?.avatarLabelColor, color: '#ffffff' }" styleClass="mx-2"
            size="large" shape="circle"></p-avatar>

        <!-- Nom et menu utilisateur -->
        <p-button type="button" [label]="appService.user?.fullName!" [text]="true" icon="pi pi-angle-down"
            iconPos="right" (click)="userMenu.toggle($event)"></p-button>

        <!-- Invitations -->
        @if (invites.length > 0) {
        <p-button icon="pi pi-inbox" styleClass="no-label" label="&nbsp;" [badge]="invites.length.toString()"
            badgeSeverity="danger" [rounded]="true" [text]="true" (click)="ip.toggle($event)"></p-button>
        }

        <!-- Changement de thème -->
        <!-- <p-button icon="pi {{ appService.switchThemeIcon }}" [rounded]="true" [text]="true"
            (click)="onClickTheme()"></p-button> -->
    </div>
</div>

<p-menu #clientMenu [popup]="true" [model]="clientMenuItems"></p-menu>
<p-menu #userMenu [popup]="true" [model]="userMenuItems"></p-menu>

<p-popover #ip>
    <app-invites-list [invites]="invites" (inviteAccepted)="onInviteAccepted()" (inviteDeclined)="onInviteDeclined()"></app-invites-list>
</p-popover>

<p-dialog header="Connexion Biifor Scan" [modal]="true" [(visible)]="qrCodeVisible" [style]="{ width: '25rem' }">
    <!-- Texte d'explication -->
    <span class="p-text-secondary block mb-5">Scannez ce QR code sur l'application pour vous connecter.</span>
    <!-- Image du QR code en base 64 -->
    <img class="w-full" [src]="qrCodeSrc" alt="QR Code" />
</p-dialog>
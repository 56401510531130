import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { RouteParam, ServiceBase } from '../service-base';

// Modèles
import { Onboarding } from 'src/app/@shared/models/onboarding/onboarding.model';

// Utils
import { QueryOptionsParam } from 'src/app/@shared/utilities/odata/query-params';

const CLASS_NAME = 'Onboarding';
const ENDPOINT = `/onboardings/`;
const ROUTES: RouteParam[] = [
    { key: 'mine', path: 'mine/' },
]

@Injectable({
    providedIn: 'root'
})
export class OnboardingService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addRoutes(ROUTES);
    }

    public getMine$(): Observable<Onboarding> {
        let options: QueryOptionsParam = { url: this.routes.mine };
        options.resultCardinality = 'one';
        options.resultClass = CLASS_NAME;
        options.expands = ['steps'];
        return this.getList$(options);
    }
}

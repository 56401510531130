import { Person } from "../person/abstract/person.model";

export class InvoiceContact extends Person {

    static override CLASS_NAME: string = 'InvoiceContact';

    public toReadableFormat(): string {
        let result = ``;
        if (this.firstName) {
            result += `${this.firstName} `;
            if (this.lastName) {
                result += `${this.lastName}\n`;
            }
        } else if (this.lastName) {
            result += `${this.lastName}\n`;
        }
        if (this.email) {
            result += `${this.email}\n`;
        }
        if (this.phone1AreaCode) {
            result += `${this.phone1AreaCode.code} ${this.phone1}\n`;
        }
        if (this.phone2AreaCode) {
            result += `${this.phone2AreaCode.code} ${this.phone2}`;
        }
        return result;
    }

}
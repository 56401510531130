/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Calendar } from 'primeng/calendar';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Logger } from './app/@core/services/logger/logger.service';


platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

if (environment.production) {
    enableProdMode();
    Logger.enableProductionMode();
}

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

import { Component, inject } from '@angular/core';

import { ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { finalize } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

// Composants
import { FormBaseComponent } from 'src/app/@shared/components/abstract/form-base.component';

// Services
import { AuthenticationService } from 'src/app/@core/authentication/services/authentication.service';

// Utils
import { Logger } from 'src/app/@core/services/logger/logger.service';

const LOGGER = new Logger('SubscribeFormComponent');

@Component({
    selector: 'app-subscribe-form',
    imports: [
    ReactiveFormsModule,
    RouterModule,
    ButtonModule,
    InputTextModule,
    PasswordModule
],
    templateUrl: './subscribe-form.component.html',
    styleUrls: ['./subscribe-form.component.scss']
})
export class SubscribeFormComponent extends FormBaseComponent {
    private _authenticationService = inject(AuthenticationService);


    public showSuccessMessage: boolean = false;

    protected override buildForm() {
        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        });
    }

    private _displaySuccess(): void {
        this.showSuccessMessage = true;
    }

    public subscribe(): void {
        this.loading = true;
        const register$ = this._authenticationService.register$(this.form.value);
        register$
            .pipe(
                finalize(() => {
                    this.form.markAsPristine();
                    this.loading = false;
                })
            )
            .subscribe({
                next: () => {
                    this._displaySuccess();
                },
                error: (error: any) => {
                    Object.keys(error?.details).forEach((key) => {
                        let control = this.form.get(key);
                        control?.setErrors({ invalid: true, message: error.details[key].longText });
                    })
                },
            });
    }

}

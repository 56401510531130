enum PowensSyncErrorEnum {
    ActionNeeded = "actionNeeded",
    AdditionalInformationNeeded = "additionalInformationNeeded",
    Bug = "bug",
    Decoupled = "decoupled",
    PasswordExpired = "passwordExpired",
    RateLimiting = "rateLimiting",
    SCARequired = "SCARequired",
    Validating = "validating",
    WebsiteUnavailable = "websiteUnavailable",
    WebauthRequired = "webauthRequired",
    Wrongpass = "wrongpass",
}

interface PowensSyncErrorInfo {
    action?: string; // Action à effectuer pour résoudre l'erreur
    explanation: string; // Explication de l'erreur
    userMessage: string; // Message à afficher à l'utilisateur
}

const PowensSyncErrorDetails: { [key in PowensSyncErrorEnum]: PowensSyncErrorInfo } = {
    [PowensSyncErrorEnum.ActionNeeded]: {
        explanation: "La synchronisation a échoué parce que l'utilisateur doit effectuer une action directement sur le site web ou l'appli de la banque (généralement, accepter de nouvelles CGUs ou des actions ponctuelles similaires).",
        userMessage: "Une action de votre part est attendue sur le site de votre établissement",
    },
    [PowensSyncErrorEnum.AdditionalInformationNeeded]: {
        action: 'reconnect',
        explanation: "La synchronisation est suspendue car nous avons besoin du consentement de l'utilisateur ou du SCA pour continuer.",
        userMessage: "Un code à usage unique est attendu pour valider votre authentification forte. Veuillez suivre ce lien pour le saisir.",
    },
    [PowensSyncErrorEnum.Bug]: {
        explanation: "La synchronisation a échoué en raison d'une erreur de notre part. Nous surveillons les erreurs et faisons de notre mieux pour les corriger rapidement. Les connexions dans cet état seront reprises automatiquement une fois l'erreur corrigée.",
        userMessage: "La connexion rencontre un problème technique. Veuillez réessayer plus tard ou contacter le support pour obtenir de l'aide.",
    },
    [PowensSyncErrorEnum.Decoupled]: {
        action: 'reconnect',
        explanation: "La synchronisation est suspendue car nous avons besoin du consentement de l'utilisateur ou du SCA pour continuer.",
        userMessage: "Vous devez valider une authentification forte depuis l'application de votre banque.",
    },
    [PowensSyncErrorEnum.PasswordExpired]: {
        explanation: "La synchronisation a échoué parce que l'utilisateur doit renouveler son mot de passe sur le site web de la banque.",
        userMessage: "Vous avez atteint le nombre maximal de connexion avec le même mot de passe, vous devez le renouveler sur le site de votre établissement.",
    },
    [PowensSyncErrorEnum.RateLimiting]: {
        explanation: "La synchronisation a échoué parce que le site web ou l'API cible bloque temporairement les synchronisations en raison d'une limitation du débit. Nous allons réessayer après un certain temps et les connexions dans cet état seront reprises automatiquement.",
        userMessage: "Vous avez atteint la limite de connexion autorisée par votre banque. Veuillez réessayer plus tard.",
    },
    [PowensSyncErrorEnum.SCARequired]: {
        action: 'reconnect',
        explanation: "La synchronisation est suspendue car nous avons besoin du consentement de l'utilisateur ou du SCA pour continuer.",
        userMessage: "Votre établissement demande une nouvelle étape de validation de vos identifiants. Veuillez suivre ce lien pour les mettre à jour.",
    },
    [PowensSyncErrorEnum.Validating]: {
        explanation: "La synchronisation est suspendue car nous avons besoin du consentement de l'utilisateur ou du SCA pour continuer.",
        userMessage: "Nous sommes en attente de la validation de la banque pour synchroniser la connexion.",
    },
    [PowensSyncErrorEnum.WebsiteUnavailable]: {
        explanation: "La synchronisation a échoué car l'API ou le site web ciblé est temporairement indisponible. Nous réessayerons périodiquement de synchroniser.",
        userMessage: "Le site de votre établissement est inaccessible. Veuillez réessayer plus tard.",
    },
    [PowensSyncErrorEnum.WebauthRequired]: {
        action: 'reconnect',
        explanation: "La synchronisation est suspendue car nous avons besoin du consentement de l'utilisateur ou du SCA pour continuer.",
        userMessage: "Votre établissement demande une nouvelle étape de validation de vos identifiants. Veuillez suivre ce lien pour les mettre à jour.",
    },
    [PowensSyncErrorEnum.Wrongpass]: {
        action: 'reconnect',
        explanation: "La synchronisation a échoué parce que les informations d'identification que nous possédons sont invalides ou obsolètes.",
        userMessage: "Vos identifiants sont incorrects ou obsolètes. Veuillez-vous ré-authentifier en suivant ce lien.",
    },
};

export { PowensSyncErrorEnum, PowensSyncErrorInfo, PowensSyncErrorDetails };
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Message } from 'primeng/api';

import { ODataMessage } from 'src/app/@shared/utilities/odata/message';

@Injectable({
    providedIn: 'root',
})
export class MessageServiceBroker {
    // Behaviour subjects
    private _details$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private _messages$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);

    // Observable the different components can subscrive to
    public readonly details$: Observable<any> = this._details$.asObservable();
    public readonly messages$: Observable<any> = this._messages$.asObservable();

    public sendMessage(message: Message) {
        this._messages$.next([message]);
    }

    public sendODataMessages(messages: ODataMessage[]) {
        if (messages.length === 0) {
            return;
        }
        let messagesDisp: Message[] = [];

        for (let i = 0; i < messages.length; i++) {
            let severity = '';
            switch (messages[i].type) {
                case 'S':
                    severity = 'success';
                    break;
                case 'A':
                    severity = 'contrast';
                    break;
                case 'E':
                    severity = 'error';
                    break;
                case 'I':
                    severity = 'info';
                    break;
                case 'W':
                    severity = 'warn';
                    break;
                default:
                    severity = 'secondary';
            }

            let detail: string = messages[i].longText || '';
            if (!!messages[i].uuid) {
                detail += '\nIdentifiant de la requête :\n' + messages[i].uuid;
            }

            messagesDisp.push({
                severity: severity,
                summary: messages[i].shortText || '',
                detail: detail,
                id: messages[i].number || 0,
                life: 5000,
            })
        }

        this._messages$.next(messagesDisp);
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';


const routes: Routes = [
    { path: '', component: HomeComponent, title: 'Accueil' },
    { path: 'dashboard', component: DashboardComponent, title: 'Dashboard' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class HomeRoutingModule {}

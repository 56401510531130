import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as QRCode from 'qrcode';

import { Credentials, CredentialsService } from './credentials.service';
import { MessageServiceBroker } from '../../services/message.service';
import { ODataError } from 'src/app/@shared/utilities/odata/response';
import { ODataMessage } from 'src/app/@shared/utilities/odata/message';

import { Logger } from '../../services/logger/logger.service';
import { AppService } from '../../services/app.service';

export interface ChangePasswordContext {
    oldPassword: string;
    password: string;
    confirmPassword: string;
}

export interface LoginContext {
    email: string;
    password: string;
    remember?: boolean;
}

export interface RegistrationContext {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

export interface ResetPasswordContext {
    password: string;
    confirmPassword: string;
    user_id: string,
    timestamp: string,
    signature: string,
}

const LOGGER = new Logger('AuthenticationService');

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private _appService = inject(AppService);
    private _credentialsService = inject(CredentialsService);
    private _httpClient = inject(HttpClient);
    private _messageService = inject(MessageServiceBroker);


    changePassword$(context: ChangePasswordContext): Observable<boolean | ODataError> {
        return this._httpClient
            .post(`/registration/change-password/`, {
                old_password: context.oldPassword,
                password: context.password,
                password_confirm: context.confirmPassword,
            })
            .pipe(
                map((_: any) => {
                    return true;
                }),
                catchError(err => { 
                    return this._registrationError(err);
                })
            );
    }

    getQrCode$(): Observable<string> {
        let refreshToken = this._credentialsService.credentials?.refresh;

        if (!refreshToken) {
            throw new Error('Refresh token is undefined');
        }

        return from(QRCode.toDataURL(refreshToken, { errorCorrectionLevel: 'H' }));
    }

    /**
     * Authentifie l'utilisateur
     * Demande le token de connexion qui sera ensuite utilisé pour toutes les requêtes
     * @param context Les paramètres de connexion.
     * @return Les données d'authentification de l'utilisateur.
     */
    login$(context: LoginContext): Observable<Credentials | ODataError> {
        return this._httpClient
            .post(`/token/`, {
                email: context.email,
                password: context.password
            })
            .pipe(
                map((body: any) => {
                    let tokenData = {
                        access: body.access,
                        refresh: body.refresh
                    };
                    this._credentialsService.setCredentials(tokenData, context.remember);
                    return tokenData;
                }),
                catchError(err => {
                    let error = new ODataError(err);
                    this._messageService.sendODataMessages(error.messages);
                    throw error;
                })
            );
    }

    /**
     * Logs out the user and clear credentials.
     * @return True if the user was logged out successfully.
     */
    logout$(): Observable<boolean> {
        LOGGER.debug('Déconnexion...');
        // Suppression des tokens liés à l'utilisateur
        this._credentialsService.setCredentials();
        // Ré-initialisation des données de l'application
        LOGGER.debug('Ré-initialisation des données de l\'application');
        this._appService.reset();
        LOGGER.debug('Tata bisou!');
        return of(true);
    }

    /**
     * Création du compte utilisateur
     * @param context Les paramètres de connexion.
     * @return Les données d'authentification de l'utilisateur.
     */
    register$(context: RegistrationContext): Observable<boolean | ODataError> {
        return this._httpClient
            .post(`/registration/register/`, {
                firstName: context.firstName,
                lastName: context.lastName,
                email: context.email,
                username: context.email,
                password: context.password,
                password_confirm: context.confirmPassword
            })
            .pipe(
                map((_: any) => {
                    return true;
                }),
                catchError(err => { 
                    return this._registrationError(err);
                })
            );
    }

    /**
     * Demande un nouveau token d'accès à l'application
     * @param refreshToken Le refresh token qui a été fourni lors de la dernière authent avec user/mdp
     * @returns
     */
    refreshToken$(refreshToken: string): Observable<Credentials | ODataError> {
        return this._httpClient
            .post(`/token/refresh/`, {
                refresh: refreshToken
            })
            .pipe(
                map((body: any) => {
                    let tokenData = {
                        access: body.access,
                        refresh: refreshToken
                    };
                    this._credentialsService.setCredentials(tokenData, true);
                    return tokenData;
                }),
                catchError(err => {
                    // Suppression des identifiants enregistrés
                    this._credentialsService.setCredentials();
                    // Affichage du message à l'utilisateur
                    let error = new ODataError(err);
                    this._messageService.sendODataMessages(error.messages);
                    throw error;
                })
            );
    }

    registerEmail$(email: string): Observable<any> {
        return this._httpClient
            .post(`/registration/register-email/`, {
                email: email
            });
    }

    resetPassword$(context: ResetPasswordContext): Observable<boolean | ODataError> {
        return this._httpClient
            .post(`/registration/reset-password/`, {
                password: context.password,
                password_confirm: context.confirmPassword,
                user_id: context.user_id,
                timestamp: context.timestamp,
                signature: context.signature,
            })
            .pipe(
                map((_: any) => {
                    return true;
                }),
                catchError(err => {
                    return this._registrationError(err);
                })
            );
    }

    sendResetPasswordLink$(email: string): Observable<any> {
        return this._httpClient
            .post(`/registration/send-reset-password-link/`, {
                email: email
            });
    }

    verifyEmail$(payload: any): Observable<any> {
        return this._httpClient.post(`/registration/verify-email/`, payload);
    }

    verifyUser$(payload: any): Observable<any> {
        return this._httpClient.post(`/registration/verify-registration/`, payload);
    }

    private _registrationError(err: any): Observable<boolean | ODataError> {
        let detail: ODataMessage;
        err.error.messages = [];
        err.error.error = {};
        let showMessage: boolean = true;

        // Utilisateur existant
        if (!!err?.error?.email && err?.error?.email[0] === 'Un objet utilisateur avec ce champ email existe déjà.') {
            detail = new ODataMessage({
                domain: 'user',
                longText: `Un utilisateur avec cette adresse email existe déjà.`,
                shortText: 'Utilisateur existant',
                type: 'E'
            });
            err.error.messages.push(detail);
            err.error.error.email = detail;
        }

        // Mot de passe invalide
        if (!!err?.error?.password) {
            for (let i = 0; i < err.error.password.length; i++) {
                detail = new ODataMessage({
                    domain: 'user',
                    longText: err.error.password[i],
                    shortText: 'Mot de passe invalide',
                    type: 'E'
                });
                err.error.messages.push(detail);
                err.error.error.password = detail;
            }
        }

        // Mots de passe différents
        if (!!err?.error?.passwordConfirm && err?.error?.passwordConfirm[0] === `Passwords don't match`) {
            detail = new ODataMessage({
                domain: 'user',
                longText: `Les mots de passe ne sont pas identiques.`,
                shortText: 'Mot de passe invalide',
                type: 'E'
            });
            err.error.messages.push(detail);
            err.error.error.password = detail;
            err.error.error.confirmPassword = detail;
        }

        // Message par défaut
        if (err.error.messages.length === 0) {
            detail = new ODataMessage({
                domain: 'user',
                longText: `Une erreur est survenue, veuillez réessayer ultérieurement.`,
                shortText: 'Erreur inconnue',
                type: 'E'
            });
            err.error.messages.push(detail);
            showMessage = true;
        }

        let error = new ODataError(err);
        if (showMessage) {
            this._messageService.sendODataMessages(error.messages);
        }
        throw error;
    }
}

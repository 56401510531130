import { Component, inject } from '@angular/core';

// Composants
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';
import { InvitesListComponent } from '../invites-list/invites-list.component';

// Modèles
import { Invite } from 'src/app/@shared/models/client/invite.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-invites-list-dialog',
    imports: [
        InvitesListComponent
    ],
    templateUrl: './invites-list-dialog.component.html',
    styleUrl: './invites-list-dialog.component.scss'
})
export class InvitesListDialogComponent extends BaseComponent {

    private _config = inject(DynamicDialogConfig);
    private _ref = inject(DynamicDialogRef);

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _invites: Invite[] = [];
    public get invites(): Invite[] {
        return this._invites;
    }
    public set invites(value: Invite[]) {
        let emptyArray: Invite[] = [];
        this._invites = emptyArray.concat(value);
    }

    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    constructor() {
        super();

        if (!!this._config.data.invites) {
            this.invites = this._config.data.invites;
        } else {
            this.invites = [];
        }
    }

    public onInviteAccepted(): void {
        this._ref.close();
    }

    public onInviteDeclined(): void {
        this._ref.close();
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/
}

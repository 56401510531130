import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Ajout dans les entêtes de requête l'identifiant du client
 */
@Injectable({
    providedIn: 'root'
})
export class ApiClientInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let savedClientId = sessionStorage.getItem("Client") || localStorage.getItem("Client");
        if (!!savedClientId) {
            request = request.clone({ headers: request.headers.append('Context-client', savedClientId) });
        }
        return next.handle(request);
    }
}

import { ModelBase } from '../abstract/model-base.model';
import { AccountEntry } from '../accounting/account-entry.model';
import { BankAccountLine } from '../banking/bank-account-line.model';
import { Currency } from '../core/currency.model';
import moment from 'moment';

export class TgcDeclaration extends ModelBase {

    static override CLASS_NAME: string = 'TgcDeclaration';

    private _dateFrom!: moment.Moment;
    private _dateTo!: moment.Moment;
    private _nothing!: boolean;
    private _currency!: Currency;
    private _accountEntry!: AccountEntry;
    private _bankAccountLine!: BankAccountLine;
    private _field10!: number;
    private _field11!: number;
    private _field12!: number;
    private _field13!: number;
    private _field14!: number;
    private _field15!: number;
    private _field20!: number;
    private _field21!: number;
    private _field22!: number;
    private _field23!: number;
    private _field24!: number;
    private _field25!: number;
    private _field26!: number;
    private _field27!: number;
    private _field28!: number;
    private _field29!: number;
    private _field30!: number;
    private _field40!: number;
    private _field41!: number;
    private _field42!: number;
    private _field43!: number;
    private _field50!: number;
    private _field51!: number;
    private _field52!: number;
    private _field53!: number;
    private _field60!: number;
    private _field61!: number;
    private _field62!: number;
    private _field63!: number;
    private _field70!: number;
    private _field71!: number;
    private _field72!: number;
    private _field73!: number;
    private _field80!: number;
    private _field81!: number;
    private _field82!: number;
    private _field83!: number;
    private _field90!: number;
    private _field91!: number;

    public static statusColor(status: string): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (status === 'matched'):
                return 'success';
            case (status === 'draft'):
                return 'warning';
            default:
                return undefined;
        }
    }

    public static statusLabel(status: string): string {
        switch (true) {
            case (status === 'matched'):
                return 'Rapprochée';
            case (status === 'validated'):
                return 'Validée';
            case (status === 'draft'):
                return 'En cours';
            default:
                return '';
        }
    }

    public get accountEntry(): AccountEntry {
        return this._accountEntry;
    }

    public set accountEntry(value: any) {
        this._setValue('_accountEntry', value, 'AccountEntry');
    }

    public get bankAccountLine(): BankAccountLine {
        return this._bankAccountLine;
    }

    public set bankAccountLine(value: any) {
        this._setValue('_bankAccountLine', value, 'BankAccountLine');
    }

    public get dateFrom(): moment.Moment {
        return this._dateFrom;
    }

    public set dateFrom(value: any) {
        this._setValue('_dateFrom', value, 'date');
    }

    public get dateTo(): moment.Moment {
        return this._dateTo;
    }

    public set dateTo(value: any) {
        this._setValue('_dateTo', value, 'date');
    }

    public get nothing(): boolean {
        return this._nothing;
    }

    public set nothing(value: any) {
        this._setValue('_nothing', value, 'boolean');
    }

    public set deductible(value: any) {
        this._setValue('_deductible', value, 'float');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get field10(): number {
        return this._field10;
    }

    public set field10(value: any) {
        this._setValue('_field10', value, 'float');
    }

    public get field11(): number {
        return this._field11;
    }

    public set field11(value: any) {
        this._setValue('_field11', value, 'float');
    }

    public get field12(): number {
        return this._field12;
    }

    public set field12(value: any) {
        this._setValue('_field12', value, 'float');
    }

    public get field13(): number {
        return this._field13;
    }

    public set field13(value: any) {
        this._setValue('_field13', value, 'float');
    }

    public get field14(): number {
        return this._field14;
    }

    public set field14(value: any) {
        this._setValue('_field14', value, 'float');
    }

    public get field15(): number {
        return this._field15;
    }

    public set field15(value: any) {
        this._setValue('_field15', value, 'float');
    }

    public get field20(): number {
        return this._field20;
    }

    public set field20(value: any) {
        this._setValue('_field20', value, 'float');
    }

    public get field21(): number {
        return this._field21;
    }

    public set field21(value: any) {
        this._setValue('_field21', value, 'float');
    }

    public get field22(): number {
        return this._field22;
    }

    public set field22(value: any) {
        this._setValue('_field22', value, 'float');
    }

    public get field23(): number {
        return this._field23;
    }

    public set field23(value: any) {
        this._setValue('_field23', value, 'float');
    }

    public get field24(): number {
        return this._field24;
    }

    public set field24(value: any) {
        this._setValue('_field24', value, 'float');
    }

    public get field25(): number {
        return this._field25;
    }

    public set field25(value: any) {
        this._setValue('_field25', value, 'float');
    }

    public get field26(): number {
        return this._field26;
    }

    public set field26(value: any) {
        this._setValue('_field26', value, 'float');
    }

    public get field27(): number {
        return this._field27;
    }

    public set field27(value: any) {
        this._setValue('_field27', value, 'float');
    }

    public get field28(): number {
        return this._field28;
    }

    public set field28(value: any) {
        this._setValue('_field28', value, 'float');
    }

    public get field29(): number {
        return this._field29;
    }

    public set field29(value: any) {
        this._setValue('_field29', value, 'float');
    }

    public get field30(): number {
        return this._field30;
    }

    public set field30(value: any) {
        this._setValue('_field30', value, 'float');
    }

    public get field40(): number {
        return this._field40;
    }

    public set field40(value: any) {
        this._setValue('_field40', value, 'float');
    }

    public get field41(): number {
        return this._field41;
    }

    public set field41(value: any) {
        this._setValue('_field41', value, 'float');
    }

    public get field42(): number {
        return this._field42;
    }

    public set field42(value: any) {
        this._setValue('_field42', value, 'float');
    }

    public get field43(): number {
        return this._field43;
    }

    public set field43(value: any) {
        this._setValue('_field43', value, 'float');
    }

    public get field50(): number {
        return this._field50;
    }

    public set field50(value: any) {
        this._setValue('_field50', value, 'float');
    }

    public get field51(): number {
        return this._field51;
    }

    public set field51(value: any) {
        this._setValue('_field51', value, 'float');
    }

    public get field52(): number {
        return this._field52;
    }

    public set field52(value: any) {
        this._setValue('_field52', value, 'float');
    }

    public get field53(): number {
        return this._field53;
    }

    public set field53(value: any) {
        this._setValue('_field53', value, 'float');
    }

    public get field60(): number {
        return this._field60;
    }

    public set field60(value: any) {
        this._setValue('_field60', value, 'float');
    }

    public get field61(): number {
        return this._field61;
    }

    public set field61(value: any) {
        this._setValue('_field61', value, 'float');
    }

    public get field62(): number {
        return this._field62;
    }

    public set field62(value: any) {
        this._setValue('_field62', value, 'float');
    }

    public get field63(): number {
        return this._field63;
    }

    public set field63(value: any) {
        this._setValue('_field63', value, 'float');
    }

    public get field70(): number {
        return this._field70;
    }

    public set field70(value: any) {
        this._setValue('_field70', value, 'float');
    }

    public get field71(): number {
        return this._field71;
    }

    public set field71(value: any) {
        this._setValue('_field71', value, 'float');
    }

    public get field72(): number {
        return this._field72;
    }

    public set field72(value: any) {
        this._setValue('_field72', value, 'float');
    }

    public get field73(): number {
        return this._field73;
    }

    public set field73(value: any) {
        this._setValue('_field73', value, 'float');
    }

    public get field80(): number {
        return this._field80;
    }

    public set field80(value: any) {
        this._setValue('_field80', value, 'float');
    }

    public get field81(): number {
        return this._field81;
    }

    public set field81(value: any) {
        this._setValue('_field81', value, 'float');
    }

    public get field82(): number {
        return this._field82;
    }

    public set field82(value: any) {
        this._setValue('_field82', value, 'float');
    }

    public get field83(): number {
        return this._field83;
    }

    public set field83(value: any) {
        this._setValue('_field83', value, 'float');
    }

    public get field90(): number {
        return this._field90;
    }

    public set field90(value: any) {
        this._setValue('_field90', value, 'float');
    }

    public get field91(): number {
        return this._field91;
    }

    public set field91(value: any) {
        this._setValue('_field91', value, 'float');
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusColor(): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (this.hasStatus('matched')):
                return TgcDeclaration.statusColor('matched');
            case (this.hasStatus('validated')):
                return TgcDeclaration.statusColor('validated');
            case (this.hasStatus('draft')):
                return TgcDeclaration.statusColor('draft');
            default:
                return TgcDeclaration.statusColor('');
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusLabel(): string {
        switch (true) {
            case (this.hasStatus('matched')):
                return TgcDeclaration.statusLabel('matched');
            case (this.hasStatus('validated')):
                return TgcDeclaration.statusLabel('validated');
            case (this.hasStatus('draft')):
                return TgcDeclaration.statusLabel('draft');
            default:
                return '';
        }
    }

}
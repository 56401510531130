import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { ChargesWidgetComponent } from 'src/app/@shared/components/widgets/charges-widget/charges-widget.component';
import { MonthlySummaryWidgetComponent } from 'src/app/@shared/components/widgets/monthly-summary-widget/monthly-summary-widget.component';
import { PartnerDebtsWidgetComponent } from 'src/app/@shared/components/widgets/partner-debts-widget/partner-debts-widget.component';
import { BankAccountBalancesComponent } from 'src/app/@shared/components/widgets/bank-account-balances/bank-account-balances.component';

@Component({
    selector: 'app-dashboard',
    standalone: true,
    imports: [
        CommonModule,
        BankAccountBalancesComponent,
        ChargesWidgetComponent,
        MonthlySummaryWidgetComponent,
        PartnerDebtsWidgetComponent
    ],
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends PageBaseComponent {

    public onPurchaseInvoiceUploaded(): void {
        // Navigation vers la liste des factures d'achat
        this.router.navigate(['/purchasing/invoices']);
    }

}

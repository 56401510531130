import { Component, inject } from '@angular/core';


// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';

// Services
import { AuthenticationService } from 'src/app/@core/authentication/services/authentication.service';

@Component({
    selector: 'app-verify-user',
    imports: [],
    templateUrl: './verify-user.component.html',
    styleUrls: ['./verify-user.component.scss']
})
export class VerifyUserComponent extends PageBaseComponent {
    private _authenticationService = inject(AuthenticationService);


    message: string = '';

    override ngOnInit(): void {
        super.ngOnInit();

        this._verifyUser();
    }

    private _verifyUser(): void {
        let payload = {
            'user_id': this.queryParams.get('user_id'),
            'timestamp': this.queryParams.get('timestamp'),
            'signature': this.queryParams.get('signature')
        };

        this._authenticationService.verifyUser$(payload).subscribe(
            () => {
                this.message = `Votre compte a été validé, vous pouvez maintenant vous connecter. Vous allez être redirigé vers l'écran de connexion dans 5 secondes.`;
                setTimeout(() => {
                    this.router.navigate(['/login'])
                }, 5000);
            }
        );

    }

}

import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";

export class AccountingPeriod extends ModelBase {

    static override CLASS_NAME: string = 'AccountingPeriod';

    private _closed!: boolean;
    private _dateFrom!: moment.Moment;
    private _dateTo!: moment.Moment;

    public override toString(): string {
        let output = `Exercice du ${this._dateFrom.format('DD/MM/YYYY')} au ${this._dateTo.format('DD/MM/YYYY')}`;
        return output;
    }

    public get closed(): boolean {
        return this._closed;
    }

    public set closed(value: any) {
        this._setValue('_closed', value, 'boolean');
    }

    public get dateFrom(): moment.Moment {
        return this._dateFrom;
    }

    public set dateFrom(value: any) {
        this._setValue('_dateFrom', value, 'date');
    }

    public get dateTo(): moment.Moment {
        return this._dateTo;
    }

    public set dateTo(value: any) {
        this._setValue('_dateTo', value, 'date');
    }

}

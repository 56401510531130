import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { ServiceBase } from '../service-base';

const CLASS_NAME = '';
const ENDPOINT = `/powens/`;

@Injectable({
    providedIn: 'root'
})
export class PowensService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
    }

    public getToken$(): Observable<string> {
        return this.httpClient.get<any>(`${this.endpoint}token`).pipe(
            map(data => {
                return data.token;
            }));
    }

    public syncAccount$(id: number): Observable<any> {
        return this.httpClient.post<any>(`${this.endpoint}sync-account/`, {
            bankAccount: id
        });
    }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';

import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { SelectModule } from 'primeng/select';

// Composants
import { FormBaseComponent } from 'src/app/@shared/components/abstract/form-base.component';

// Modèles
import { Invite } from 'src/app/@shared/models/client/invite.model';
import { Profile } from 'src/app/@shared/models/authorization/profile.model';
import { User } from 'src/app/@shared/models/user/user.model';

// Utils
import { Filter } from 'src/app/@shared/utilities/models/filter';
import { Sorter } from 'src/app/@shared/utilities/models/sorter';

@Component({
    selector: 'app-invite-form',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        AvatarModule,
        ButtonModule,
        SelectModule
    ],
    templateUrl: './invite-form.component.html',
    styleUrl: './invite-form.component.scss'
})
export class InviteFormComponent extends FormBaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    @Output() inviteSaved: EventEmitter<Invite> = new EventEmitter<Invite>();

    public get buttonLabel(): string {
        if (this.user?.new) {
            return `Inviter ${this.user.email}`;
        } else {
            return this.user ? `Inviter ${this.user.fullName}` : 'Sélectionnez un collaborateur';
        }
    }

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _invite!: Invite;
    public get invite(): Invite {
        return this._invite;
    }
    @Input()
    public set invite(value: Invite) {
        this._invite = value;
        this._mapToForm();
    }

    private _profiles: Profile[] = [];
    public get profiles(): Profile[] {
        return this._profiles;
    }
    public set profiles(profiles: Profile[]) {
        let emptyArray: Profile[] = []
        this._profiles = emptyArray.concat(profiles);
    }

    private _user: User | null = null;
    public get user(): User | null {
        return this._user;
    }
    public set user(user: User | null) {
        this._user = user;
    }

    private _users: User[] = [];
    public get users(): User[] {
        return this._users;
    }
    public set users(users: User[]) {
        let emptyArray: User[] = []
        this._users = emptyArray.concat(users);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public onClickRemoveUser(): void {
        this._selectUser(null);
        this.form.reset();
    }

    public onSearch(event: AutoCompleteCompleteEvent) {
        this._searchUsers(event.query);
    }

    public onSelectUser(event: AutoCompleteSelectEvent) {
        this._selectUser(event.value);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override buildForm(): void {
        this.form = this.formBuilder.group({
            profile: [null, Validators.required],
            search: '',
        });
    }

    protected override loadData(): void {
        this._loadProfiles();
    }

    protected override save(): void {
        this._mapFromForm();
        this.inviteService.save$(this.invite).subscribe(invite => {
            this.form.reset();
            this._selectUser(null);
            this.inviteSaved.emit(invite);
        });
        this.form.markAsPristine();
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _isValidEmail(email: string): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    private _loadProfiles(): void {
        let sorter: Sorter = new Sorter({
            property: 'label',
        });
        this.profileService.getList$({
            sorters: [sorter]
        }).subscribe(profiles => this.profiles = profiles);
    }

    private _mapFromForm(): void {
        if (this.user) {
            if (this.user.new) {
                this.invite.emailAddress = this.user.email;
            } else {
                this.invite.user = this.user;
            }
        }
        this.invite.profile = this.form.get('profile')?.value;
    }

    private _mapToForm(): void {
        this.form.patchValue({
            profile: !!this.invite.profile ? this.invite.profile : null,
        });
    }

    private _searchUsers(query: string): void {
        let filter: Filter = new Filter({
            filters: [
                new Filter({
                    property: 'email',
                    comparator: Filter.COMPARATOR.Contains,
                    value: query,
                }),
                new Filter({
                    property: 'fullName',
                    comparator: Filter.COMPARATOR.Contains,
                    value: query,
                }),
            ],
            and: false
        });
        let excludeMeFilter: Filter = new Filter({
            property: 'id',
            value: this.appService.user!.id,
            comparator: Filter.COMPARATOR.NE,
        });
        this.userService.getList$({
            filters: [filter, excludeMeFilter],
        }).subscribe(users => {
            this.users = users;
            // Si on n'a pas de résultat et que la recherche est un email valide,
            // on crée un faux utilisateur avec cet email pour pouvoir l'inviter
            if (users.length === 0 && this._isValidEmail(query)) {
                let user = User.init();
                user.avatarIcon = 'pi pi-envelope';
                user.email = query;
                this.users = [user];
            }
        });
    }

    private _selectUser(user: User | null): void {
        this.user = user;
    }

}

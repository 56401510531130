<div class="h-full grid grid-nogutter">

    <!-- Colonne du formulaire de connexion -->
    <div class="col-12 lg:col-8 h-full">
        <div class="h-full flex flex-column justify-content-between">
            <!-- Logo en haut de page -->
            <div class="p-6 pb-0 pr-0">
                <img [src]="appService.logoSrc" />
            </div>

            <!-- Conteneur du formulaire de connexion -->
            <div class="flex justify-content-center mb-8">
                <div class="col-6">
                    <app-subscribe-form></app-subscribe-form>
                </div>
            </div>

            <!-- Mention légales etc... -->
            <app-legal-footer></app-legal-footer>
        </div>
    </div>

    <!-- Colonne de l'image, mais sans image -->
    <div class="col-4 h-full surface-50 justify-content-center align-items-center hidden lg:flex"></div>
</div>

<div class="h-full w-6 grid grid-nogutter absolute top-0 right-0">
    <div class="col-12 h-full justify-content-center align-items-center hidden lg:flex">
        <img src="assets/images/illustrations/subscribe.png" />
    </div>
</div>
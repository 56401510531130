import { Filter } from '../models/filter';
import { Sorter } from '../models/sorter';

export interface QueryOptionsParam {
    expands?: string[];
    filters?: Filter[];
    filtersString?: string;
    sorters?: Sorter[];
    count?: boolean;
    activityIndicator?: boolean;
    skip?: number;
    top?: number | null;
    url?: string;
    resultClass?: string;
    resultCardinality?: 'one' | 'many';
}

export class QueryOptions implements QueryOptionsParam {
    expands: string[];
    filters: Filter[];
    filtersString: string;
    sorters: Sorter[];
    count: boolean;
    activityIndicator: boolean;
    skip: number;
    top: number | null;
    url: string;
    resultClass?: string;
    resultCardinality?: 'one' | 'many';

    constructor(url: string, options?: QueryOptionsParam) {
        this.expands = !!options?.expands ? options.expands : [];
        this.filters = !!options?.filters ? options.filters : [];
        this.filtersString = !!options?.filtersString ? options.filtersString : '';
        this.sorters = !!options?.sorters ? options.sorters : [];
        this.count = !!options?.count ? options.count : false;
        this.activityIndicator = typeof options?.activityIndicator === 'undefined' ? true : options.activityIndicator;
        this.skip = !!options?.skip ? options.skip : 0;
        this.top = !!options?.top ? options.top : null;
        this.url = !!options?.url ? options.url : url;
        this.resultClass = options?.resultClass;
        this.resultCardinality = options?.resultCardinality || 'many';
    }

    public toUrlString(): string {
        let url = this.url;
        let hasOptions: boolean = false;

        if (this.expands.length > 0) {
            url += hasOptions ? '&' : '?';
            url += '$expand=';
            url += this.expands.join(',');
            hasOptions = true;
        }

        if (this.filters.length > 0) {
            url += hasOptions ? '&' : '?';
            url += Filter.toUrlString(this.filters);
            hasOptions = true;
        } else if (this.filtersString !== '') {
            url += hasOptions ? '&' : '?';
            url += '$filter=';
            url += this.filtersString;
            hasOptions = true;
        }

        if (this.sorters.length > 0) {
            url += hasOptions ? '&' : '?';
            url += Sorter.toUrlString(this.sorters);
            hasOptions = true;
        }

        if (this.skip) {
            url += hasOptions ? '&' : '?';
            url += '$skip=';
            url += this.skip;
            hasOptions = true;
        }

        if (this.top) {
            url += hasOptions ? '&' : '?';
            url += '$top=';
            url += this.top;
            hasOptions = true;
        }

        if (this.count) {
            url += hasOptions ? '&' : '?';
            url += '$count=true';
        }

        return url;
    }
}

export enum ActivitiesEnum {
    Accept = 'accept',
    Allocate = 'allocate',
    Bill = 'bill',
    Cancel = 'cancel',
    Create = 'create',
    CreditNote = 'credit_note',
    Delete = 'delete',
    Download = 'download',
    Duplicate = 'duplicate',
    Justify = 'justify',
    MarkAsSent = 'mark_as_sent',
    Merge = 'merge',
    Modify = 'modify',
    Post = 'post',
    RecordOverpayment = 'record_overpayment',
    RecordPayment = 'record_payment',
    Refuse = 'refuse',
    Remind = 'remind',
    Send = 'send',
    Unjustify = 'unjustify',
    Update = 'update',
    Validate = 'validate',
    WriteOff = 'write_off',
}
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Confirmation } from 'primeng/api';

import * as confirmations from '../../../assets/confirmations.json';

const DEFAULT = 'FALLBACK';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationServiceBroker {
    // Behaviour subjects
    private _confirmation$: BehaviorSubject<Confirmation> = new BehaviorSubject<Confirmation>({});

    // Observable the different components can subscrive to
    public readonly confirmation$: Observable<any> = this._confirmation$.asObservable();

    confirm(action: string, callback: Function) {
        let confirmation: Confirmation = this._getConfig(action);
        confirmation.accept = callback;
        this._confirmation$.next(confirmation);
    }

    private _getConfig(action: string): Confirmation {
        return {
            message: (confirmations as any)[action]['message'] || (confirmations as any)[DEFAULT]['message'],
            icon: (confirmations as any)[action]['icon'] || (confirmations as any)[DEFAULT]['icon'],
            header: (confirmations as any)[action]['header'] || (confirmations as any)[DEFAULT]['header'],
            acceptLabel: (confirmations as any)[action]['acceptLabel'] || (confirmations as any)[DEFAULT]['acceptLabel'],
            rejectLabel: (confirmations as any)[action]['rejectLabel'] || (confirmations as any)[DEFAULT]['rejectLabel'],
            acceptIcon: (confirmations as any)[action]['acceptIcon'] || (confirmations as any)[DEFAULT]['acceptIcon'],
            rejectIcon: (confirmations as any)[action]['rejectIcon'] || (confirmations as any)[DEFAULT]['rejectIcon'],
            acceptVisible: (confirmations as any)[action]['acceptVisible'] || (confirmations as any)[DEFAULT]['acceptVisible'],
            rejectVisible: (confirmations as any)[action]['rejectVisible'] || (confirmations as any)[DEFAULT]['rejectVisible'],
            blockScroll: (confirmations as any)[action]['blockScroll'] || (confirmations as any)[DEFAULT]['blockScroll'],
            closeOnEscape: (confirmations as any)[action]['closeOnEscape'] || (confirmations as any)[DEFAULT]['closeOnEscape'],
            dismissableMask: (confirmations as any)[action]['dismissableMask'] || (confirmations as any)[DEFAULT]['dismissableMask'],
            defaultFocus: (confirmations as any)[action]['defaultFocus'] || (confirmations as any)[DEFAULT]['defaultFocus'],
            acceptButtonStyleClass: (confirmations as any)[action]['acceptButtonStyleClass'] || (confirmations as any)[DEFAULT]['acceptButtonStyleClass'],
            rejectButtonStyleClass: (confirmations as any)[action]['rejectButtonStyleClass' || (confirmations as any)[DEFAULT]['rejectButtonStyleClass']]
        }
    }
}

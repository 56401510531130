export enum StatusEnum {
    Accepted = 'accepted',
    Billed = 'billed',
    Cancelled = 'cancelled',
    Draft = 'draft',
    Duplicate = 'duplicate',
    Extracted = 'extracted',
    HasOverpayment = 'has_overpayment',
    Justified = 'justified',
    New = 'new',
    Paid = 'paid',
    PartiallyBilled = 'partially_billed',
    PartiallyJustified = 'partially_justified',
    Posted = 'posted',
    Sent = 'sent',
    Unjustified = 'unjustified',
    Validated = 'validated',
    VerificationNeeded = 'verification_needed',
    WrittenOff = 'written_off',
}
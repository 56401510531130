<div class="grid pl-3 py-3 h-full w-full overflow-y-scroll no-scrollbars">
    @if (appService.selectedClient?.hasFeature(Features.WidgetSalesInvoices)) {
    <div class="col-12 dxl:col-6">
        <app-partner-debts-widget mode="sales"></app-partner-debts-widget>
    </div>
    }
    @if (appService.selectedClient?.hasFeature(Features.WidgetPurchaseInvoices)) {
    <div class="col-12 dxl:col-6">
        <app-partner-debts-widget mode="purchasing"></app-partner-debts-widget>
    </div>
    }
    @if (appService.selectedClient?.hasFeature(Features.WidgetBank)) {
    <div class="col-12">
        <app-bank-account-balances></app-bank-account-balances>
    </div>
    }
    @if (appService.selectedClient?.hasFeature(Features.WidgetCharges)) {
    <div class="col-12 txl:col-6">
        <app-monthly-summary-widget></app-monthly-summary-widget>
    </div>
    <div class="col-12 txl:col-6">
        <app-charges-widget (filesUploaded)="onPurchaseInvoiceUploaded()"></app-charges-widget>
    </div>
    }
    @if (appService.selectedClient?.hasFeature(Features.WidgetSalesInvoices)) {
    <div class="col-12 dxl:col-6">
        <app-dso-widget></app-dso-widget>
    </div>
    }
</div>

<div class="absolute bottom-0 left-0 p-3">
    <app-onboarding-card></app-onboarding-card>
</div>
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

// Composants
import { FormBaseComponent } from 'src/app/@shared/components/abstract/form-base.component';

// Services
import { AuthenticationService } from 'src/app/@core/authentication/services/authentication.service';
import { finalize } from 'rxjs';

// Utils
import { Logger } from 'src/app/@core/services/logger/logger.service';

const LOGGER = new Logger('ResetPasswordFormComponent');

@Component({
    selector: 'app-lost-password-form',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
    ],
    templateUrl: './lost-password-form.component.html',
    styleUrls: ['./lost-password-form.component.scss']
})
export class LostPasswordFormComponent extends FormBaseComponent {

    public showSuccessMessage: boolean = false;

    constructor(private _authenticationService: AuthenticationService) {
        super();
    }

    protected override buildForm() {
        // Login
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
        });
    }

    private _displaySuccess(): void {
        this.showSuccessMessage = true;
    }

    public reset(): void {
        this.loading = true;
        const reset$ = this._authenticationService.sendResetPasswordLink$(this.form.get('email')!.value);
        reset$
            .pipe(
                finalize(() => {
                    this.form.markAsPristine();
                    this.loading = false;
                    this._displaySuccess();
                })
            )
            .subscribe(() => {});
    }

}

import { ModelBase } from '../abstract/model-base.model';

export class EmailRedirection extends ModelBase {

    static override CLASS_NAME: string = 'EmailRedirection';

    private _active!: boolean;
    private _clientSlug!: string;
    private _expensesEmail!: string;
    private _purchasingEmail!: string;
    private _salesEmail!: string;

    public get active(): boolean {
        return this._active;
    }

    public set active(value: any) {
        this._setValue('_active', value, 'boolean');
    }

    public get clientSlug(): string {
        return this._clientSlug;
    }

    public set clientSlug(value: any) {
        this._setValue('_clientSlug', value, 'string');
    }

    public get expensesEmail(): string {
        return this._expensesEmail;
    }

    public set expensesEmail(value: any) {
        this._setValue('_expensesEmail', value, 'string');
    }

    public get purchasingEmail(): string {
        return this._purchasingEmail;
    }

    public set purchasingEmail(value: any) {
        this._setValue('_purchasingEmail', value, 'string');
    }

    public get salesEmail(): string {
        return this._salesEmail;
    }

    public set salesEmail(value: any) {
        this._setValue('_salesEmail', value, 'string');
    }
}

/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';

import { Calendar } from 'primeng/calendar';

import { environment } from './environments/environment';

import { Logger } from './app/@core/services/logger/logger.service';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

const LOGGER = new Logger('Main');


bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));

if (environment.production) {
    enableProdMode();
    Logger.enableProductionMode();
}

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

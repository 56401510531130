import { Person } from "../person/abstract/person.model";

export class ClientContact extends Person {

    static override CLASS_NAME: string = 'ClientContact';

    private _primary!: boolean;
    private _purchaseContact!: boolean;
    private _role!: string;
    private _salesContact!: boolean;

    public get primary(): boolean {
        return this._primary;
    }

    public set primary(value: any) {
        this._setValue('_primary', value, 'boolean');
    }

    public get purchaseContact(): boolean {
        return this._purchaseContact;
    }

    public set purchaseContact(value: any) {
        this._setValue('_purchaseContact', value, 'boolean');
    }

    public get role(): string {
        return this._role;
    }

    public set role(value: any) {
        this._setValue('_role', value, 'string');
    }

    public get salesContact(): boolean {
        return this._salesContact;
    }

    public set salesContact(value: any) {
        this._setValue('_salesContact', value, 'boolean');
    }

}
import { Injectable } from '@angular/core';

// Services
import { NavigationParam, ServiceBase } from '../service-base';

const CLASS_NAME = 'AccountEntry';
const ENDPOINT = `/account-entries/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'lines', path: 'lines/', childClass: 'AccountEntryLine' },
]

@Injectable({
    providedIn: 'root'
})
export class AccountEntryService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
    }
}

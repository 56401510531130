<div class="w-full" [ngClass]="{'dragged-over': dragover}">
    <p-card>
        <!-- Entête -->
        <ng-template pTemplate="header">
            <div class="flex align-items-center justify-content-between p-3">
                <div class="flex align-items-center">
                    <h2>{{ title }}</h2>
                    @if (!!kpi.totalAllCount) {
                    <p-badge class="ml-2" [value]="kpi.totalAllCount" severity="secondary" />
                    }
                </div>
                <h2>{{ kpi.total | currency:kpi.currency:'code' }}</h2>
            </div>
        </ng-template>

        @if (loading) {
        <!-- Spinner de chargement -->
        <div class="p-3 flex align-items-center justify-content-center" style="height: 105px;">
            <p-progressSpinner></p-progressSpinner>
        </div>
        } @else {
        <!--  Indicateur visuel -->
        @if (kpi.total !== 0) {
        <apx-chart [chart]="chart" [colors]="chartColors" [dataLabels]="chartDataLabels" [grid]="chartGrid"
            [legend]="chartLegend" [plotOptions]="chartPlotOptions" [series]="chartSeries" [tooltip]="chartTooltip"
            [xaxis]="chartXAxis" [yaxis]="chartYAxis"></apx-chart>
        } @else {
        <!-- Message indiquant qu'il n'y a rien en attente -->
        <div class="p-3 flex flex-column align-items-center justify-content-between" style="height: 105px;">
            <i class="pi pi-check-circle text-green-500 text-5xl"></i>
            <div class="text-xl">Aucune facture en attente de règlement</div>
        </div>
        }
        }

        <!-- Détail -->
        <ng-template pTemplate="footer">
            <div class="flex align-items-start justify-content-between">
                <div class="flex-grow-1 px-3">
                    <div class="flex align-items-center h-4">
                        <div class="uppercase">En cours</div>
                        @if (!!kpi.ongoingCount) {
                        <p-badge class="ml-2" [value]="kpi.ongoingCount" severity="secondary" />
                        }
                    </div>
                    <div class="text-2xl text-primary cursor-pointer mt-3" (click)="onClickOngoing()">{{ kpi.ongoing |
                        currency:kpi.currency:'code' }}</div>
                </div>
                <p-divider align="center" layout="vertical"></p-divider>
                <div class="flex-grow-1 px-3">
                    <div class="flex align-items-center h-4">
                        <div class="uppercase text-red-500">En retard</div>
                        @if (!!kpi.totalLateCount) {
                        <p-badge class="ml-2" [value]="kpi.totalLateCount" severity="secondary" />
                        }
                    </div>
                    <div class="text-2xl cursor-pointer mt-3" (click)="op.toggle($event)">
                        <span>{{ kpi.lateAll | currency:kpi.currency:'code' }}</span>
                        <span class="pi pi-chevron-down ml-3"></span>
                    </div>
                </div>
            </div>
            <div class="flex justify-content-center mt-4">
                <span class="text-sm font-italic text-color-secondary">Vous pouvez directement déposer vos facture
                    {{dragAndDropMessageInvoiceType}} ici par glisser-déposer</span>
            </div>
        </ng-template>
    </p-card>
</div>

<p-popover #op>
    <ng-template #content>
        <div id="late-details" class="flex flex-column text-xl w-25rem">
            <div class="flex justify-content-between">
                <div class="flex align-items-center">
                    <div class="text-500">Moins de 15 jours</div>
                    @if (!!kpi.late1Count) {
                    <p-badge class="ml-2" [value]="kpi.late1Count" severity="secondary" />
                    }
                </div>
                <div class="text-primary cursor-pointer" (click)="onClickLate(1, 14)">{{ kpi.late1 |
                    currency:kpi.currency:'code' }}</div>
            </div>
            <div class="flex justify-content-between">
                <div class="flex align-items-center">
                    <div class="text-500">Entre 15 et 30 jours</div>
                    @if (!!kpi.late2Count) {
                    <p-badge class="ml-2" [value]="kpi.late2Count" severity="secondary" />
                    }
                </div>
                <div class="text-primary cursor-pointer" (click)="onClickLate(15, 29)">{{ kpi.late2 |
                    currency:kpi.currency:'code' }}</div>
            </div>
            <div class="flex justify-content-between">
                <div class="flex align-items-center">
                    <div class="text-500">Entre 30 et 45 jours</div>
                    @if (!!kpi.late3Count) {
                    <p-badge class="ml-2" [value]="kpi.late3Count" severity="secondary" />
                    }
                </div>
                <div class="text-primary cursor-pointer" (click)="onClickLate(30, 44)">{{ kpi.late3 |
                    currency:kpi.currency:'code' }}</div>
            </div>
            <div class="flex justify-content-between">
                <div class="flex align-items-center">
                    <div class="text-500">Plus de 45 jours</div>
                    @if (!!kpi.late4Count) {
                    <p-badge class="ml-2" [value]="kpi.late4Count" severity="secondary" />
                    }
                </div>
                <div class="text-primary cursor-pointer" (click)="onClickLate(45)">{{ kpi.late4 |
                    currency:kpi.currency:'code' }}</div>
            </div>
        </div>
    </ng-template>
</p-popover>
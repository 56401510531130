import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageModule } from 'primeng/image';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { environment } from 'src/environments/environment';

// Composants
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';
import { BankAccountFormComponent } from '../bank-account-form/bank-account-form.component';

// Modèles
import { BankAccount } from 'src/app/@shared/models/banking/bank-account.model';

// Utils
import { Filter } from 'src/app/@shared/utilities/models/filter';

@Component({
    selector: 'app-bank-account-dialog',
    standalone: true,
    imports: [
        ButtonModule,
        ImageModule,
        ProgressSpinnerModule,
        BankAccountFormComponent,
    ],
    templateUrl: './bank-account-dialog.component.html',
    styleUrl: './bank-account-dialog.component.scss'
})
export class BankAccountDialogComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    Environment = environment;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _bankAccount!: BankAccount;
    public get bankAccount(): BankAccount {
        return this._bankAccount;
    }
    public set bankAccount(value: BankAccount) {
        this._bankAccount = value;
    }

    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    constructor(private _config: DynamicDialogConfig, private _ref: DynamicDialogRef) {
        super();
        if (!!this._config.data.bankAccount) {
            this.bankAccount = this._config.data.bankAccount;
        } else {
            this._bankAccount = BankAccount.init();
        }
    }

    public onBankAccountSaved(): void {
        this._ref.close();
    }

    public onClickNewBCI(): void {
        this._initBciAccount();
    }

    public onClickPowensBank(): void {
        this._openPowensWebview();
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _initBciAccount(): void {
        // Chargement de la BCI
        let filter: Filter = new Filter({
            property: 'bic',
            value: 'BCADNCNNXXX'
        });
        this.busy = true;
        this.bankService.getList$({
            filters: [filter]
        }).subscribe(banks => {
            this._updateWidth('50rem');
            this.bankAccount.bank = banks[0];
            this.busy = false;
        });
    }

    private _openPowensWebview(): void {
        // Obtention d'un token d'accès
        this.busy = true;
        this.powensService.getToken$().subscribe(token => {
            this.busy = false;
            window.location.href = `https://webview.powens.com/fr/connect?domain=${environment.powensDomain}.biapi.pro&client_id=${environment.powensClient}&redirect_uri=${environment.powensRedirection}/banking/accounts&code=${token}`;
        });
    }

    private _updateWidth(width: string): void {
        this._config.width = width;
    }

}

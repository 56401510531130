import { Injectable, Type } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import * as dialogs from '../../../assets/dialogs.json';

const DEFAULT = 'FALLBACK';

@Injectable({
    providedIn: 'root',
})
export class DialogServiceHelper {
    // Behaviour subjects
    private _dialogConfig$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    private _dialogRef$: BehaviorSubject<any> = new BehaviorSubject<any>({ref: new DynamicDialogRef(), uuid: ''});

    // Observable the different components can subscrive to
    public readonly dialogConfig$: Observable<any> = this._dialogConfig$.asObservable();
    public readonly dialogRef$: Observable<any> = this._dialogRef$.asObservable();

    close(): void {
        this._dialogConfig$.next(null);
    }

    show(component: Type<any>, configName: string, data: any = {}): string {
        let config: DynamicDialogConfig = this._getConfig(configName);
        config.data = data;
        // Définition d'un UUID pour chaque modale
        config.data.uuid = Math.floor(Math.random() * 1000000000).toString();
        setTimeout(() => {
            this._dialogConfig$.next({component: component, config: config});
        }, 1);
        return config.data.uuid;
    }

    setDialogRef(uuid: string, ref: DynamicDialogRef) {
        this._dialogRef$.next({ref: ref, uuid: uuid});
    }

    private _getConfig(configName: string): DynamicDialogConfig {
        return {
            header: (dialogs as any)[configName]['header'] || (dialogs as any)[DEFAULT]['header'],
            footer: (dialogs as any)[configName]['footer'] || (dialogs as any)[DEFAULT]['footer'],
            width: (dialogs as any)[configName]['width'] || (dialogs as any)[DEFAULT]['width'],
            height: (dialogs as any)[configName]['height'] || (dialogs as any)[DEFAULT]['height'],
            closeOnEscape: (dialogs as any)[configName]['closeOnEscape'] || (dialogs as any)[DEFAULT]['closeOnEscape'],
            baseZIndex: (dialogs as any)[configName]['baseZIndex'] || (dialogs as any)[DEFAULT]['baseZIndex'],
            autoZIndex: (dialogs as any)[configName]['autoZIndex'] || (dialogs as any)[DEFAULT]['autoZIndex'],
            dismissableMask: (dialogs as any)[configName]['dismissableMask'] || (dialogs as any)[DEFAULT]['dismissableMask'],
            rtl: (dialogs as any)[configName]['rtl'] || (dialogs as any)[DEFAULT]['rtl'],
            style: (dialogs as any)[configName]['style'] || (dialogs as any)[DEFAULT]['style'],
            contentStyle: (dialogs as any)[configName]['contentStyle'] || (dialogs as any)[DEFAULT]['contentStyle'],
            styleClass: (dialogs as any)[configName]['styleClass'] || (dialogs as any)[DEFAULT]['styleClass'],
            transitionOptions: (dialogs as any)[configName]['transitionOptions'] || (dialogs as any)[DEFAULT]['transitionOptions'],
            closable: (dialogs as any)[configName]['closable'] || (dialogs as any)[DEFAULT]['closable'],
            showHeader: (dialogs as any)[configName]['showHeader'] || (dialogs as any)[DEFAULT]['showHeader'],
            modal: (dialogs as any)[configName]['modal'] || (dialogs as any)[DEFAULT]['modal'],
            maskStyleClass: (dialogs as any)[configName]['maskStyleClass'] || (dialogs as any)[DEFAULT]['maskStyleClass'],
            resizable: (dialogs as any)[configName]['resizable'] || (dialogs as any)[DEFAULT]['resizable'],
            draggable: (dialogs as any)[configName]['draggable'] || (dialogs as any)[DEFAULT]['draggable'],
            keepInViewport: (dialogs as any)[configName]['keepInViewport'] || (dialogs as any)[DEFAULT]['keepInViewport'],
            minX: (dialogs as any)[configName]['minX'] || (dialogs as any)[DEFAULT]['minX'],
            minY: (dialogs as any)[configName]['minY'] || (dialogs as any)[DEFAULT]['minY'],
            maximizable: (dialogs as any)[configName]['maximizable'] || (dialogs as any)[DEFAULT]['maximizable'],
            maximizeIcon: (dialogs as any)[configName]['maximizeIcon'] || (dialogs as any)[DEFAULT]['maximizeIcon'],
            minimizeIcon: (dialogs as any)[configName]['minimizeIcon'] || (dialogs as any)[DEFAULT]['minimizeIcon'],
            position: (dialogs as any)[configName]['position'] || (dialogs as any)[DEFAULT]['position'],
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { Quotation } from 'src/app/@shared/models/sales/quotation/quotation.model';

const CLASS_NAME = 'Quotation';
const ENDPOINT = `/quotations/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'attachments', path: 'attachments/', childClass: 'File' },
    { key: 'downpayments', path: 'downpayments/', childClass: 'SalesInvoice' },
    { key: 'invoices', path: 'invoices/', childClass: 'SalesInvoice' },
]
const ROUTES: RouteParam[] = [
    { key: 'accept', path: '{id}/accept/' },
    { key: 'cancel', path: '{id}/cancel/' },
    { key: 'downloadFile', path: '{id}/pdf/' },
    { key: 'duplicate', path: '{id}/duplicate/' },
    { key: 'markAsSent', path: '{id}/mark-as-sent/' },
    { key: 'refuse', path: '{id}/refuse/' },
    { key: 'remind', path: '{id}/remind/' },
    { key: 'send', path: '{id}/send/' },
    { key: 'validate', path: '{id}/validate/' },
]

@Injectable({
    providedIn: 'root'
})
export class QuotationService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public accept$(quotation: Quotation): Observable<Quotation> {
        let url = this.routes.accept.replace('{id}', quotation.id.toString());
        return this.update$(quotation, {
            url: url
        });
    }

    public cancel$(quotation: Quotation): Observable<Quotation> {
        let url = this.routes.cancel.replace('{id}', quotation.id.toString());
        return this.update$(quotation, {
            url: url
        });
    }

    public duplicate$(quotation: Quotation): Observable<Quotation> {
        let url = this.routes.duplicate.replace('{id}', quotation.id.toString());
        return this.update$(quotation, {
            url: url
        });
    }

    public markAsSent$(quotation: Quotation): Observable<Quotation> {
        let url = this.routes.markAsSent.replace('{id}', quotation.id.toString());
        return this.update$(quotation, {
            url: url
        });
    }

    public refuse$(quotation: Quotation): Observable<Quotation> {
        let url = this.routes.refuse.replace('{id}', quotation.id.toString());
        return this.update$(quotation, {
            url: url
        });
    }

    public remind$(quotation: Quotation): Observable<Quotation> {
        let url = this.routes.remind.replace('{id}', quotation.id.toString());
        return this.update$(quotation, {
            url: url
        });
    }

    public send$(quotation: Quotation): Observable<Quotation> {
        let url = this.routes.send.replace('{id}', quotation.id.toString());
        return this.update$(quotation, {
            url: url
        });
    }

}

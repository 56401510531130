import { SalesInvoiceItemBase } from '../abstract/sales-invoice-item-base.model';
import { SalesInvoiceItemAllocation } from './sales-invoice-item-allocation.model';

export class SalesInvoiceItem extends SalesInvoiceItemBase {

    static override CLASS_NAME: string = 'SalesInvoiceItem';

    constructor(data: any) {
        super(data);
        this._allocations = this._allocations || [];
    }

    private _allocations!: SalesInvoiceItemAllocation[];

    public get allocations(): SalesInvoiceItemAllocation[] {
        return this._allocations;
    }

    public set allocations(value: any) {
        this._setValue('_allocations', value, 'SalesInvoiceItemAllocation');
    }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { Cheque } from 'src/app/@shared/models/banking/cheque.model';

const CLASS_NAME = 'Cheque';
const ENDPOINT = `/cheques/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'payments', path: 'payments/', childClass: 'SalesInvoicePayment' },
]
const ROUTES: RouteParam[] = [
    { key: 'post', path: '{id}/post/' },
]

@Injectable({
    providedIn: 'root'
})
export class ChequeService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public post$(cheque: Cheque): Observable<Cheque> {
        let url = this.routes.post.replace('{id}', cheque.id.toString());
        return this.update$(cheque, {
            url: url
        });
    }
}

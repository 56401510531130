import { ModelBase } from '../abstract/model-base.model';

export class Journal extends ModelBase {

    static override CLASS_NAME: string = 'Journal';

    private _canExport!: boolean;
    private _code!: string;
    private _label!: string;
    private _numberRange!: string;

    public get canExport(): boolean {
        return this._canExport;
    }

    public set canExport(value: any) {
        this._setValue('_canExport', value, 'boolean');
    }

    public set code(value: any) {
        this._setValue('_code', value, 'string');
    }

    public get code(): string {
        return this._code;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get label(): string {
        return this._label;
    }

    public set numberRange(value: any) {
        this._setValue('_numberRange', value, 'string');
    }

    public get numberRange(): string {
        return this._numberRange;
    }

    public override toString(): string {
        return `${this.code} - ${this.label}`;
    }
}

<!-- ÉTAPE 1 : REMPLISSAGE DU FORMULAIRE -->
@if (!showSuccessMessage) {
  <div class="h-29rem">
    <div class="text-xl font-medium mb-3">
      Saisissez un nouveau mot de passe
    </div>
    <form (ngSubmit)="reset()" [formGroup]="form" novalidate class="flex flex-column">
      <!-- Mot de passe -->
      <div class="mb-4 w-full flex flex-column">
        <p-password inputStyleClass="w-full" [feedback]="true" formControlName="password" [toggleMask]="true"
          placeholder="Mot de passe" promptLabel="Robustesse du mot de passe" weakLabel="Faible"
          mediumLabel="Moyen" strongLabel="Fort" required />
        @if (form.get('password')?.errors && form.get('password')?.hasError('invalid')) {
          <small
            class="p-error mt-1">{{
          form.get('password')?.getError('message') }}</small>
        }
      </div>
      <!-- Confirmations du mot de passe -->
      <div class="mb-4 w-full flex flex-column">
        <p-password inputStyleClass="w-full" [feedback]="false" formControlName="confirmPassword"
          [toggleMask]="true" placeholder="Confirmer mot de passe" required />
        @if (form.get('confirmPassword')?.errors && form.get('confirmPassword')?.hasError('invalid')) {
          <small
            class="p-error mt-1">{{
          form.get('confirmPassword')?.getError('message') }}</small>
        }
      </div>
      <!-- Validation -->
      <p-button class="mb-4" styleClass="w-full" type="submit" label="Enregistrer" [rounded]="true"
      [disabled]="form.invalid || loading"></p-button>
      <!-- Retour connexion -->
      <div class="grid">
        <div class="col">
          <p-button styleClass="w-full surface-900 text-0 border-900" type="button" label="Revenir à la connexion"
          [rounded]="true" routerLink="/login"></p-button>
        </div>
      </div>
    </form>
  </div>
}

<!-- ÉTAPE 2 : MESSAGE POUR UTILISATEUR -->
@if (showSuccessMessage) {
  <div class="h-29rem flex flex-column">
    <div class="text-xl font-medium mb-3">
      Mot de passe modifié
    </div>
    <div class="flex h-16rem flex-column justify-content-between">
      <div class="text-sm">
        Vous pouvez maintenant l'utiliser pour vous connecter. Vous allez être redirigé dans 5 secondes.
      </div>
      <!-- Retour connexion -->
      <div class="grid">
        <div class="col">
          <button pButton class="w-full surface-900 text-0 border-900" type="button"
          label="Revenir à la connexion" routerLink="/login"></button>
        </div>
      </div>
    </div>
  </div>
}
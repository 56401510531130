<div class="h-screen flex flex-column">
    <header class="flex-grow-0">
        <app-header></app-header>
    </header>
    <main class="flex overflow-hidden h-full">
        @if (!!appService.selectedClient) {
        <div id="side-menu" class="overflow-auto no-scrollbars" [ngClass]="menuWidthClass">
            <app-side-menu></app-side-menu>
        </div>
        }
        <div id="main-content" class="flex flex-column h-full main-shadow surface-ground" [ngClass]="mainContentClass">
            @if (appService.breadcrumbItems.length > 0) {
            <p-breadcrumb class="flex-grow-0 text-lg" [model]="appService.breadcrumbItems"
                [home]="appService.homeBreadcrumbItem"></p-breadcrumb>
            }
            <div class="flex-grow-1 overflow-hidden p-3">
                <router-outlet></router-outlet>
            </div>
        </div>
    </main>
</div>
<p-blockUI [blocked]="appService.uiBlocked">
    <p-progressSpinner></p-progressSpinner>
</p-blockUI>
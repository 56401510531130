import { ModelBase } from '../abstract/model-base.model';
import { SalesDocumentType } from './sales-document-type.model';

export class SalesDocumentTemplate extends ModelBase {

    static override CLASS_NAME: string = 'SalesDocumentTemplate';

    private _acceptanceConditionsLabel!: string;
    private _bankDetailsLabel!: string;
    private _billingAddressLabel!: string;
    private _chequeLabel!: string;
    private _clientReferenceLabel!: string;
    private _clientSignatureLabel!: string;
    private _companyIdentifierLabel!: string;
    private _contactEmailLabel!: string;
    private _contactFaxLabel!: string;
    private _contactLabel!: string;
    private _contactMobileLabel!: string;
    private _contactPhoneLabel!: string;
    private _deliveryAddressLabel!: string;
    private _deliveryNoteLabel!: string;
    private _discountLabel!: string;
    private _documentDateLabel!: string;
    private _documentLabel!: string;
    private _documentReferenceLabel!: string;
    private _documentType!: SalesDocumentType;
    private _downpaymentsLabel!: string;
    private _downpaymentAmountLabel!: string;
    private _downpaymentDateLabel!: string;
    private _downpaymentNumberLabel!: string;
    private _dueAmountLabel!: string;
    private _dueDateLabel!: string;
    private _ecoTaxLabel!: string;
    private _generalConditionsLabel!: string;
    private _invoiceLabel!: string;
    private _name!: string;
    private _noteLabel!: string;
    private _objectLabel!: string;
    private _paymentMethodLabel!: string;
    private _paymentTermLabel!: string;
    private _paymentsLabel!: string;
    private _productCountLabel!: string;
    private _productDescriptionLabel!: string;
    private _productDiscountLabel!: string;
    private _productGrossAmountLabel!: string;
    private _productGrossPriceLabel!: string;
    private _productNetAmountLabel!: string;
    private _productNetPriceLabel!: string;
    private _productQuantityLabel!: string;
    private _productReferenceLabel!: string;
    private _productTaxAmountLabel!: string;
    private _productTaxRateLabel!: string;
    private _purchaseOrderLabel!: string;
    private _projectLabel!: string;
    private _quotationLabel!: string;
    private _renderer!: string;
    private _shippingFeesLabel!: string;
    private _signatureLabel!: string;
    private _situationInvoicesLabel!: string;
    private _taxAmountLabel!: string;
    private _taxBaseLabel!: string;
    private _taxLabel!: string;
    private _taxRateLabel!: string;
    private _taxesLabel!: string;
    private _totalAmountLabel!: string;
    private _totalGrossAmountLabel!: string;
    private _totalNetAmountLabel!: string;
    private _validityPeriodLabel!: string;
    private _vendorReferenceLabel!: string;

    public get isForCreditNote(): boolean {
        return this.documentType?.code === 'A';
    }

    public get isForInvoice(): boolean {
        return this.documentType?.code === 'F';
    }

    public get isForQuotation(): boolean {
        return this.documentType?.code === 'D';
    }

    public get acceptanceConditionsLabel(): string {
        return this._acceptanceConditionsLabel;
    }

    public set acceptanceConditionsLabel(value: any) {
        this._setValue('_acceptanceConditionsLabel', value, 'string');
    }

    public get bankDetailsLabel(): string {
        return this._bankDetailsLabel;
    }

    public set bankDetailsLabel(value: any) {
        this._setValue('_bankDetailsLabel', value, 'string');
    }

    public get billingAddressLabel(): string {
        return this._billingAddressLabel;
    }

    public set billingAddressLabel(value: any) {
        this._setValue('_billingAddressLabel', value, 'string');
    }

    public get chequeLabel(): string {
        return this._chequeLabel;
    }

    public set chequeLabel(value: any) {
        this._setValue('_chequeLabel', value, 'string');
    }

    public get clientReferenceLabel(): string {
        return this._clientReferenceLabel;
    }

    public set clientReferenceLabel(value: any) {
        this._setValue('_clientReferenceLabel', value, 'string');
    }

    public get clientSignatureLabel(): string {
        return this._clientSignatureLabel;
    }

    public set clientSignatureLabel(value: any) {
        this._setValue('_clientSignatureLabel', value, 'string');
    }

    public get companyIdentifierLabel(): string {
        return this._companyIdentifierLabel;
    }

    public set companyIdentifierLabel(value: any) {
        this._setValue('_companyIdentifierLabel', value, 'string');
    }

    public get contactEmailLabel(): string {
        return this._contactEmailLabel;
    }

    public set contactEmailLabel(value: any) {
        this._setValue('_contactEmailLabel', value, 'string');
    }

    public get contactFaxLabel(): string {
        return this._contactFaxLabel;
    }

    public set contactFaxLabel(value: any) {
        this._setValue('_contactFaxLabel', value, 'string');
    }

    public get contactLabel(): string {
        return this._contactLabel;
    }

    public set contactLabel(value: any) {
        this._setValue('_contactLabel', value, 'string');
    }

    public get contactMobileLabel(): string {
        return this._contactMobileLabel;
    }

    public set contactMobileLabel(value: any) {
        this._setValue('_contactMobileLabel', value, 'string');
    }

    public get contactPhoneLabel(): string {
        return this._contactPhoneLabel;
    }

    public set contactPhoneLabel(value: any) {
        this._setValue('_contactPhoneLabel', value, 'string');
    }

    public get deliveryAddressLabel(): string {
        return this._deliveryAddressLabel;
    }

    public set deliveryAddressLabel(value: any) {
        this._setValue('_deliveryAddressLabel', value, 'string');
    }

    public get deliveryNoteLabel(): string {
        return this._deliveryNoteLabel;
    }

    public set deliveryNoteLabel(value: any) {
        this._setValue('_deliveryNoteLabel', value, 'string');
    }

    public get discountLabel(): string {
        return this._discountLabel;
    }

    public set discountLabel(value: any) {
        this._setValue('_discountLabel', value, 'string');
    }

    public get documentDateLabel(): string {
        return this._documentDateLabel;
    }

    public set documentDateLabel(value: any) {
        this._setValue('_documentDateLabel', value, 'string');
    }

    public get documentLabel(): string {
        return this._documentLabel;
    }

    public set documentLabel(value: any) {
        this._setValue('_documentLabel', value, 'string');
    }

    public get documentReferenceLabel(): string {
        return this._documentReferenceLabel;
    }

    public set documentReferenceLabel(value: any) {
        this._setValue('_documentReferenceLabel', value, 'string');
    }

    public get documentType(): SalesDocumentType {
        return this._documentType;
    }

    public set documentType(value: any) {
        this._setValue('_documentType', value, 'SalesDocumentType');
    }

    public get downpaymentsLabel(): string {
        return this._downpaymentsLabel;
    }

    public set downpaymentsLabel(value: any) {
        this._setValue('_downpaymentsLabel', value, 'string');
    }

    public get downpaymentAmountLabel(): string {
        return this._downpaymentAmountLabel;
    }

    public set downpaymentAmountLabel(value: any) {
        this._setValue('_downpaymentAmountLabel', value, 'string');
    }

    public get downpaymentDateLabel(): string {
        return this._downpaymentDateLabel;
    }

    public set downpaymentDateLabel(value: any) {
        this._setValue('_downpaymentDateLabel', value, 'string');
    }

    public get downpaymentNumberLabel(): string {
        return this._downpaymentNumberLabel;
    }

    public set downpaymentNumberLabel(value: any) {
        this._setValue('_downpaymentNumberLabel', value, 'string');
    }

    public get dueAmountLabel(): string {
        return this._dueAmountLabel;
    }

    public set dueAmountLabel(value: any) {
        this._setValue('_dueAmountLabel', value, 'string');
    }

    public get dueDateLabel(): string {
        return this._dueDateLabel;
    }

    public set dueDateLabel(value: any) {
        this._setValue('_dueDateLabel', value, 'string');
    }

    public get ecoTaxLabel(): string {
        return this._ecoTaxLabel;
    }

    public set ecoTaxLabel(value: any) {
        this._setValue('_ecoTaxLabel', value, 'string');
    }

    public get generalConditionsLabel(): string {
        return this._generalConditionsLabel;
    }

    public set generalConditionsLabel(value: any) {
        this._setValue('_generalConditionsLabel', value, 'string');
    }

    public get invoiceLabel(): string {
        return this._invoiceLabel;
    }

    public set invoiceLabel(value: any) {
        this._setValue('_invoiceLabel', value, 'string');
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: any) {
        this._setValue('_name', value, 'string');
    }

    public get noteLabel(): string {
        return this._noteLabel;
    }

    public set noteLabel(value: any) {
        this._setValue('_noteLabel', value, 'string');
    }

    public get objectLabel(): string {
        return this._objectLabel;
    }

    public set objectLabel(value: any) {
        this._setValue('_objectLabel', value, 'string');
    }

    public get paymentMethodLabel(): string {
        return this._paymentMethodLabel;
    }

    public set paymentMethodLabel(value: any) {
        this._setValue('_paymentMethodLabel', value, 'string');
    }

    public get paymentTermLabel(): string {
        return this._paymentTermLabel;
    }

    public set paymentTermLabel(value: any) {
        this._setValue('_paymentTermLabel', value, 'string');
    }

    public get paymentsLabel(): string {
        return this._paymentsLabel;
    }

    public set paymentsLabel(value: any) {
        this._setValue('_paymentsLabel', value, 'string');
    }

    public get productCountLabel(): string {
        return this._productCountLabel;
    }

    public set productCountLabel(value: any) {
        this._setValue('_productCountLabel', value, 'string');
    }

    public get productDescriptionLabel(): string {
        return this._productDescriptionLabel;
    }

    public set productDescriptionLabel(value: any) {
        this._setValue('_productDescriptionLabel', value, 'string');
    }

    public get productDiscountLabel(): string {
        return this._productDiscountLabel;
    }

    public set productDiscountLabel(value: any) {
        this._setValue('_productDiscountLabel', value, 'string');
    }

    public get productGrossAmountLabel(): string {
        return this._productGrossAmountLabel;
    }

    public set productGrossAmountLabel(value: any) {
        this._setValue('_productGrossAmountLabel', value, 'string');
    }

    public get productGrossPriceLabel(): string {
        return this._productGrossPriceLabel;
    }

    public set productGrossPriceLabel(value: any) {
        this._setValue('_productGrossPriceLabel', value, 'string');
    }

    public get productNetAmountLabel(): string {
        return this._productNetAmountLabel;
    }

    public set productNetAmountLabel(value: any) {
        this._setValue('_productNetAmountLabel', value, 'string');
    }

    public get productNetPriceLabel(): string {
        return this._productNetPriceLabel;
    }

    public set productNetPriceLabel(value: any) {
        this._setValue('_productNetPriceLabel', value, 'string');
    }

    public get productQuantityLabel(): string {
        return this._productQuantityLabel;
    }

    public set productQuantityLabel(value: any) {
        this._setValue('_productQuantityLabel', value, 'string');
    }

    public get productReferenceLabel(): string {
        return this._productReferenceLabel;
    }

    public set productReferenceLabel(value: any) {
        this._setValue('_productReferenceLabel', value, 'string');
    }

    public get productTaxAmountLabel(): string {
        return this._productTaxAmountLabel;
    }

    public set productTaxAmountLabel(value: any) {
        this._setValue('_productTaxAmountLabel', value, 'string');
    }

    public get productTaxRateLabel(): string {
        return this._productTaxRateLabel;
    }

    public set productTaxRateLabel(value: any) {
        this._setValue('_productTaxRateLabel', value, 'string');
    }

    public get purchaseOrderLabel(): string {
        return this._purchaseOrderLabel;
    }

    public set purchaseOrderLabel(value: any) {
        this._setValue('_purchaseOrderLabel', value, 'string');
    }

    public get projectLabel(): string {
        return this._projectLabel;
    }

    public set projectLabel(value: any) {
        this._setValue('_projectLabel', value, 'string');
    }

    public get quotationLabel(): string {
        return this._quotationLabel;
    }

    public set quotationLabel(value: any) {
        this._setValue('_quotationLabel', value, 'string');
    }

    public get renderer(): string {
        return this._renderer;
    }

    public set renderer(value: any) {
        this._setValue('_renderer', value, 'string');
    }

    public get shippingFeesLabel(): string {
        return this._shippingFeesLabel;
    }

    public set shippingFeesLabel(value: any) {
        this._setValue('_shippingFeesLabel', value, 'string');
    }

    public get signatureLabel(): string {
        return this._signatureLabel;
    }

    public set signatureLabel(value: any) {
        this._setValue('_signatureLabel', value, 'string');
    }

    public get situationInvoicesLabel(): string {
        return this._situationInvoicesLabel;
    }

    public set situationInvoicesLabel(value: any) {
        this._setValue('_situationInvoicesLabel', value, 'string');
    }

    public get taxAmountLabel(): string {
        return this._taxAmountLabel;
    }

    public set taxAmountLabel(value: any) {
        this._setValue('_taxAmountLabel', value, 'string');
    }

    public get taxBaseLabel(): string {
        return this._taxBaseLabel;
    }

    public set taxBaseLabel(value: any) {
        this._setValue('_taxBaseLabel', value, 'string');
    }

    public get taxLabel(): string {
        return this._taxLabel;
    }

    public set taxLabel(value: any) {
        this._setValue('_taxLabel', value, 'string');
    }

    public get taxRateLabel(): string {
        return this._taxRateLabel;
    }

    public set taxRateLabel(value: any) {
        this._setValue('_taxRateLabel', value, 'string');
    }

    public get taxesLabel(): string {
        return this._taxesLabel;
    }

    public set taxesLabel(value: any) {
        this._setValue('_taxesLabel', value, 'string');
    }

    public get totalAmountLabel(): string {
        return this._totalAmountLabel;
    }

    public set totalAmountLabel(value: any) {
        this._setValue('_totalAmountLabel', value, 'string');
    }

    public get totalGrossAmountLabel(): string {
        return this._totalGrossAmountLabel;
    }

    public set totalGrossAmountLabel(value: any) {
        this._setValue('_totalGrossAmountLabel', value, 'string');
    }

    public get totalNetAmountLabel(): string {
        return this._totalNetAmountLabel;
    }

    public set totalNetAmountLabel(value: any) {
        this._setValue('_totalNetAmountLabel', value, 'string');
    }

    public get validityPeriodLabel(): string {
        return this._validityPeriodLabel;
    }

    public set validityPeriodLabel(value: any) {
        this._setValue('_validityPeriodLabel', value, 'string');
    }

    public get vendorReferenceLabel(): string {
        return this._vendorReferenceLabel;
    }

    public set vendorReferenceLabel(value: any) {
        this._setValue('_vendorReferenceLabel', value, 'string');
    }

}

import { AppConfigModel } from "../abstract/app-config.model";

export class Unit extends AppConfigModel {

    static override CLASS_NAME: string = 'Unit';

    readonly baseUnit!: Unit;
    readonly factor!: number;
    readonly symbol!: string;

    override toString(): string {
        return `${this.label} (${this.symbol})`;
    }
}

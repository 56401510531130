import { Component, ElementRef, inject, Input } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import moment from 'moment';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexPlotOptions, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent, NgApexchartsModule } from 'ng-apexcharts';

import { BadgeModule } from 'primeng/badge';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { PopoverModule } from 'primeng/popover';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { $dt } from '@primeng/themes';

// Composants
import { BaseComponent } from '../../abstract/base.component';

// Modèles
import { Client } from 'src/app/@shared/models/client/client.model';
import { KpiPartnerDebts } from 'src/app/@shared/models/kpi/partner-debts.model';

// Utils
import { Observable } from 'rxjs';
import { Partner } from 'src/app/@shared/models/partner/partner.model';
import { Filter } from 'src/app/@shared/utilities/models/filter';

@Component({
    selector: 'app-partner-debts-widget',
    imports: [
        CommonModule,
        NgApexchartsModule,
        BadgeModule,
        CardModule,
        DividerModule,
        PopoverModule,
        ProgressSpinnerModule,
    ],
    templateUrl: './partner-debts-widget.component.html',
    styleUrls: ['./partner-debts-widget.component.scss']
})
export class PartnerDebtsWidgetComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    public override loading: boolean = true;

    @Input()
    public mode: 'sales' | 'purchasing' = 'purchasing'; // Ou sales selon le cas

    // Gestions de l'intersection observer (pour le lazy loading)
    private _isVisible = false;
    private _elementRef = inject(ElementRef);
    private _observer: IntersectionObserver | null = null;

    /*----------------------------------------------------------------------------------------
     * Gestion du graph
     ----------------------------------------------------------------------------------------*/

    public chart: ApexChart = {
        type: 'bar',
        stacked: true,
        stackType: "100%",
        height: 90,
        events: {
            dataPointSelection: (this.onClickChart).bind(this),
            dataPointMouseEnter: function (event) {
                event.currentTarget.style.cursor = "pointer";
            },
            legendClick: (this.onClickLegend).bind(this)
        },
        toolbar: {
            show: false
        }
    };
    public chartColors: string[] = [
        $dt('blue.400').value,
        $dt('slate.400').value,
        $dt('yellow.400').value,
        $dt('orange.400').value,
        $dt('red.400').value,
    ];
    public chartDataLabels: ApexDataLabels = {
        enabled: false
    }
    public chartGrid: ApexGrid = {
        show: false,
        xaxis: {
            lines: {
                show: false
            }
        },
        yaxis: {
            lines: {
                show: false
            }
        }
    };
    public chartLabels: string[] = [];
    public chartLegend: ApexLegend = {
        show: true,
        position: 'top',
        showForZeroSeries: false,
    };
    public chartPlotOptions: ApexPlotOptions = {
        bar: {
            horizontal: true,
        },
    };
    public chartSeries: ApexAxisChartSeries = [];
    public chartTooltip: ApexTooltip = {
        x: {
            show: false
        },
        y: {
            formatter: (this._formatYTooltipValue).bind(this)
        },
    };
    public chartXAxis: ApexXAxis = {
        labels: {
            show: false
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    }
    public chartYAxis: ApexYAxis = {
        labels: {
            show: false
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    }

    public get dragAndDropMessageInvoiceType(): string {
        return this.mode === 'sales' ? 'de vente' : 'd\'achat';
    }

    public get title(): string {
        return this.mode === 'sales' ? 'Total des créances client' : 'Total des dettes fournisseur';
    }

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _kpi: KpiPartnerDebts = new KpiPartnerDebts({
        currency: '',
        ongoing: 0,
        ongoingCount: 0,
        late1: 0,
        late1Count: 0,
        late2: 0,
        late2Count: 0,
        late3: 0,
        late3Count: 0,
        late4: 0,
        late4Count: 0,
        total: 0,
    });
    public get kpi(): KpiPartnerDebts {
        return this._kpi;
    }
    public set kpi(kpi: KpiPartnerDebts) {
        this._kpi = kpi;
        this._updateChartSeries();
    }

    private _partner: Partner | null = null;
    public get partner(): Partner | null {
        return this._partner;
    }
    @Input()
    public set partner(partner: Partner) {
        this._partner = partner;
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    ngAfterViewInit() {
        // Configuration de l'observer
        const options = {
            root: null, // observera le viewport
            rootMargin: '0px',
            threshold: 0.1 // déclenché quand au moins 10% est visible
        };

        this._observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                this._isVisible = entry.isIntersecting;

                // Si le composant devient visible, vous pouvez initialiser/rafraîchir le graphique
                if (this._isVisible) {
                    this._loadKpi(this.appService.selectedClient!);
                }
            });
        }, options);

        // Observer l'élément hôte du composant
        this._observer.observe(this._elementRef.nativeElement);
    }

    public override ngOnDestroy() {
        super.ngOnDestroy();
        if (this._observer) {
            this._observer.disconnect();
        }
    }

    public onClickChart(event: any, chart?: any, options?: any) {
        this._navFromGraph(options.seriesIndex);
    }

    /**
     * Clic le montant des factures en retard
     *
     * Entraîne une navigation vers la liste des factures non payées dont la date d'échéance est
     * passée, et dans le délai spécifié
     * @param minDelay délai minimum en jours de dépassement de la date d'échéance
     * @param maxDelay délai maximum en jours de dépassement de la date d'échéance
     */
    public onClickLate(minDelay: number, maxDelay?: number) {
        this._navToInvoices({
            maxDelay: maxDelay,
            minDelay: minDelay,
        });
    }

    public onClickLegend(chartContext: any, seriesIndex: number, config: any) {
        this._navFromGraph(seriesIndex);
    }

    /**
     * Clic sur le montant des factures en cours
     *
     * Entraîne une navigation vers la liste des factures non payées dont la date d'échéance est
     * dans le futur
     */
    public onClickOngoing() {
        this._navToInvoices({
            maxDelay: 0
        });
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override subscribeToObservables(): void {

        // Envoi d'une facture d'achat
        this.subscriptions.add(
            this.purchaseInvoiceService.uploading$.subscribe(uploading => this.appService.uiBlocked = uploading)
        );

        // Envoi d'une facture de vente
        this.subscriptions.add(
            this.salesInvoiceService.uploading$.subscribe(uploading => this.appService.uiBlocked = uploading)
        );

        // Changement de l'ouverture du menu
        this.subscriptions.add(
            this.appService.menuState$.subscribe(
                () => {
                    this._updateChartSeries();
                }
            )
        );
    }

    protected override uploadFile$(file: File): Observable<any> {
        let service = this.mode === 'sales' ? this.salesInvoiceService : this.purchaseInvoiceService;
        return service.upload$(file);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _formatYTooltipValue(val: any) {
        let pipe = new CurrencyPipe('fr');
        return pipe.transform(val, this.kpi?.currency, 'code')!;
    }

    private _loadKpi(client: Client) {
        this.loading = true;
        let navigation = this.mode === 'sales' ? 'sales-debts' : 'purchasing-debts';

        // Si le partenaire est défini, on charge les données pour ce partenaire
        let filters: Filter[] = [];
        if (!!this.partner) {
            filters.push(new Filter({
                property: 'partner',
                value: this.partner.id
            }));
        }

        this.kpiService.getRelated$(client, navigation, {
            filters: filters
        }).subscribe(
            kpi => {
                this.kpi = kpi;
                this.loading = false;
            }
        );
    }

    private _navFromGraph(seriesIndex: number) {
        switch (seriesIndex) {
            case 0:
                this._navToInvoices({
                    maxDelay: 0
                });
                break;
            case 1:
                this._navToInvoices({
                    minDelay: 1,
                    maxDelay: 14
                });
                break;
            case 2:
                this._navToInvoices({
                    minDelay: 15,
                    maxDelay: 29
                });
                break;
            case 3:
                this._navToInvoices({
                    minDelay: 30,
                    maxDelay: 44
                });
                break;
            case 4:
                this._navToInvoices({
                    minDelay: 45
                });
                break;
        }
    }

    private _navToInvoices({ minDelay, maxDelay }: { minDelay?: number, maxDelay?: number }) {

        let dateFrom: string | null = null;
        let dateTo: string | null = null;

        // Ajout des filtres de délais
        if (!!maxDelay || maxDelay === 0) {
            let dueDateMin = moment();
            dueDateMin.subtract(maxDelay, 'days');
            dateFrom = dueDateMin.format('YYYY-MM-DD');
        }
        if (!!minDelay || minDelay === 0) {
            let dueDateMax = moment();
            dueDateMax.subtract(minDelay, 'days');
            dateTo = dueDateMax.format('YYYY-MM-DD');
        }

        let queryParams: any = {
            dateFrom: dateFrom,
            dateTo: dateTo
        };

        if (!!this.partner) {
            queryParams['partner'] = this.partner.id;
        }

        this.router.navigate([`/${this.mode}/overdue-invoices`], { queryParams: queryParams });
    }

    private _updateChartSeries() {
        this.chartSeries = [
            {
                name: 'En cours',
                data: [
                    this.kpi.ongoing
                ]
            },
            {
                name: 'Moins de 15 jours',
                data: [
                    this.kpi.late1
                ]
            },
            {
                name: 'Entre 15 et 30 jours',
                data: [
                    this.kpi.late2
                ]
            },
            {
                name: 'Entre 30 et 45 jours',
                data: [
                    this.kpi.late3
                ]
            },
            {
                name: 'Plus de 45 jours',
                data: [
                    this.kpi.late4
                ]
            }
        ];
    }
}

import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';

import { RouterModule } from '@angular/router';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TieredMenu, TieredMenuModule } from 'primeng/tieredmenu';
import { TooltipModule } from 'primeng/tooltip';

// Composants
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';

// Modèles
import { Quotation } from 'src/app/@shared/models/sales/quotation/quotation.model';
import { SalesInvoice } from 'src/app/@shared/models/sales/invoice/sales-invoice.model';

@Component({
    selector: 'app-side-menu',
    imports: [
        CommonModule,
        RouterModule,
        ButtonModule,
        TieredMenuModule,
        TooltipModule,
    ],
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    @ViewChild('tieredMenu') tieredMenu!: TieredMenu;

    public menuItems: any[] = [];
    public helpMenuItems: any[] = [];
    public tieredMenuItems: MenuItem[] = [];

    private _lockedLinkClass = 'text-gray-300';
    private _showAccountingMenuItems = false;
    private _showBankMenuItems = false;
    private _showLegalMenuItems = false;
    private _showPurchasingMenuItems = false;
    private _showSalesMenuItems = false;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/

    get showAccountingMenuItems(): boolean {
        let forceOpen = this.router.url.includes('accounting');
        return (forceOpen || this._showAccountingMenuItems) && this.appService.menuOpen;
    }

    get showBankMenuItems(): boolean {
        // Si la route contient 'banking'
        let forceOpen = this.router.url.includes('banking');
        return (forceOpen || this._showBankMenuItems) && this.appService.menuOpen;
    }

    get showLegalMenuItems(): boolean {
        // Si la route contient 'legal'
        let forceOpen = this.router.url.includes('legal');
        return (forceOpen || this._showLegalMenuItems) && this.appService.menuOpen;
    }

    get showPurchasingMenuItems(): boolean {
        let forceOpen = this.router.url.includes('purchasing');
        return (forceOpen || this._showPurchasingMenuItems) && this.appService.menuOpen;
    }

    get showSalesMenuItems(): boolean {
        let forceOpen = this.router.url.includes('sales');
        return (forceOpen || this._showSalesMenuItems) && this.appService.menuOpen;
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    getTooltip(menuItem: any): string {
        return !this.appService.menuOpen && !menuItem.subItems ? menuItem.title : '';
    }

    onClickSectionTitle(section: string): void {
        // Si le menu est ouvert
        if (this.appService.menuOpen) {
            switch (section) {
                case 'accounting':
                    this._toggleAccountingMenuItems();
                    break;
                case 'bank':
                    this._toggleBankMenuItems();
                    break;
                case 'legal':
                    this._toggleLegalMenuItems();
                    break;
                case 'purchasing':
                    this._togglePurchasingMenuItems();
                    break;
                case 'sales':
                    this._toggleSalesMenuItems();
                    break;
            }
            this._buildMenu();
        }
    }

    onHoverSectionTitle(section: string, event: MouseEvent): void {
        // Si le menu est ouvert
        if (!this.appService.menuOpen) {
            // Ouverture du tiered menu avec les sous items
            let menuItem = this.menuItems.find(item => item.section === section);

            if (!menuItem) {
                menuItem = this.helpMenuItems.find(item => item.section === section);
            }

            if (menuItem.subItems?.length) {
                this.tieredMenuItems = [].concat(menuItem.subItems);
                let target = this._getHoverTarget(event.target as HTMLElement);
                this.tieredMenu.show(event);
                setTimeout(() => {
                    let tieredMenuTop = this._getTieredMenuTop(target);
                    (this.tieredMenu.container as HTMLElement).style.top = tieredMenuTop;
                }, 1);
            } else {
                this.tieredMenuItems = [];
                this.tieredMenu.hide();
            }

        }
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override subscribeToObservables(): void {
        this.subscriptions.add(
            this.appService.clientSelected$.subscribe(() => {
                this._buildMenu();
            })
        );
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _buildMenu(): void {
        this.menuItems = [
            {
                section: 'dashboard',
                link: '/home/dashboard',
                title: 'Dashboard',
                icon: 'fa-gauge',
                showSubItems: false,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Dashboard) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.Dashboard)
            },
            {
                section: 'bank',
                link: '/banking/accounts',
                title: 'Banque',
                icon: this.appService.selectedClient?.hasFeature(this.Features.Banking) ? 'fa-building-columns' : 'fa-lock',
                showSubItems: this.showBankMenuItems,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Banking) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.Banking),
                subItems: [
                    {
                        link: '/banking/accounts',
                        title: 'Comptes bancaires',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Banking) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.Banking),
                        action: this._createBankAccount.bind(this),
                        actionIcon: PrimeIcons.PLUS,
                        actionTooltip: 'Ajouter un compte bancaire',
                        authorization: this.Authorizations.BankAccountCreate
                    },
                    {
                        link: '/banking/cheques',
                        title: 'Chèques reçus',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Cheques) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.Cheques),
                        action: this._createCheque.bind(this),
                        actionIcon: PrimeIcons.PLUS,
                        actionTooltip: 'Saisir un chèque',
                        authorization: this.Authorizations.ChequeCreate
                    },
                    {
                        link: '/banking/deposits',
                        title: 'Remises de chèque',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Cheques) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.Cheques),
                        action: this._createDeposit.bind(this),
                        actionIcon: PrimeIcons.PLUS,
                        actionTooltip: 'Saisir une remise de chèque',
                        authorization: this.Authorizations.DepositCreate
                    },
                    // {
                    //     link: '/banking/loans',
                    //     title: 'Emprunts',
                    //     ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Loans) },
                    //     locked: !this.appService.selectedClient?.hasFeature(this.Features.Loans)
                    // }
                ]
            },
            {
                section: 'sales',
                link: '/sales/invoices',
                title: 'Ventes',
                icon: this.appService.selectedClient?.hasFeature(this.Features.Banking) ? 'fa-arrow-trend-up' : 'fa-lock',
                showSubItems: this.showSalesMenuItems,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices),
                subItems: [
                    {
                        link: '/sales/quotations',
                        title: 'Devis',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesQuotations) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesQuotations),
                        action: this._createQuotation.bind(this),
                        actionIcon: PrimeIcons.PLUS,
                        actionTooltip: 'Créer un devis',
                        authorization: this.Authorizations.QuotationCreate
                    },
                    {
                        link: '/sales/invoices',
                        title: 'Factures',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices),
                        action: this._createSalesInvoice.bind(this),
                        actionIcon: PrimeIcons.PLUS,
                        actionTooltip: 'Créer une facture de vente',
                        authorization: this.Authorizations.SalesInvoiceCreate
                    },
                    {
                        link: '/sales/clients',
                        title: 'Clients',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices),
                        action: this._createClient.bind(this),
                        actionIcon: PrimeIcons.PLUS,
                        actionTooltip: 'Ajouter un client',
                        authorization: this.Authorizations.PatnerCreate
                    },
                    {
                        link: '/sales/products',
                        title: 'Articles',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.SalesInvoices),
                        action: this._createProduct.bind(this),
                        actionIcon: PrimeIcons.PLUS,
                        actionTooltip: 'Ajouter un article',
                        authorization: this.Authorizations.ProductCreate
                    }
                ]
            },
            {
                section: 'purchasing',
                link: '/purchasing/invoices',
                title: 'Achats',
                icon: this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices) ? 'fa-arrow-trend-down' : 'fa-lock',
                showSubItems: this.showPurchasingMenuItems,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices),
                subItems: [
                    {
                        link: '/purchasing/invoices',
                        title: 'Factures',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices)
                    },
                    {
                        link: '/purchasing/vendors',
                        title: 'Fournisseurs',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.PurchaseInvoices),
                        action: this._createVendor.bind(this),
                        actionIcon: PrimeIcons.PLUS,
                        actionTooltip: 'Ajouter un fournisseur',
                        authorization: this.Authorizations.PatnerCreate
                    }
                ]
            },
            {
                section: 'accounting',
                link: '/accounting/journals',
                title: 'Comptabilité',
                icon: this.appService.selectedClient?.hasFeature(this.Features.Accounting) ? 'fa-euro-sign' : 'fa-lock',
                showSubItems: this.showAccountingMenuItems,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Accounting) },
                showSection: true,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.Accounting),
                subItems: [
                    {
                        link: '/accounting/journals',
                        title: 'Journaux',
                        ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Accounting) },
                        locked: !this.appService.selectedClient?.hasFeature(this.Features.Accounting)
                    }
                ]
            },
            {
                section: 'taxation',
                link: '/taxation/tgc-declarations',
                title: `Déclarations de ${this.taxNameLabel}`,
                icon: this.appService.selectedClient?.hasFeature(this.Features.Tgc) ? 'fa-coins' : 'fa-lock',
                showSubItems: false,
                ngClass: { [this._lockedLinkClass]: !this.appService.selectedClient?.hasFeature(this.Features.Tgc) },
                showSection: !!this.appService.selectedClient?.isCaledonian && !!this.appService.selectedClient?.taxationSettings?.vatSubject,
                locked: !this.appService.selectedClient?.hasFeature(this.Features.Tgc)
            }
        ];

        this.helpMenuItems = [
            // {
            //     section: 'documentation',
            //     link: '/help/docs',
            //     title: 'Documentation',
            //     icon: 'fa-book',
            //     showSubItems: false,
            //     showSection: true
            // },
            {
                section: 'faq',
                link: '/help/faq',
                title: 'FAQ',
                icon: 'fa-question-circle',
                showSubItems: false,
                showSection: true
            },
            {
                section: 'legal',
                title: 'Légal',
                icon: 'fa-gavel',
                showSubItems: this.showLegalMenuItems,
                showSection: true,
                subItems: [
                    { link: '/legal/privacy', title: 'Politique de confidentialité', newTab: true },
                    { link: '/legal/legal', title: 'Mentions légales', newTab: true },
                    { link: '/legal/cookies', title: 'Cookies', newTab: true },
                    { link: '/legal/cgv', title: 'CGV', newTab: true },
                    { link: '/legal/cgu', title: 'CGU', newTab: true }
                ]
            }
        ];
    }

    private async _createBankAccount(): Promise<void> {
        try {
            const { BankAccountDialogComponent } = await import('src/app/banking/components/bank-accounts/bank-account-dialog/bank-account-dialog.component');
            //  Ouverture de la fenêtre de création de compte bancaire
            this.openDialog(BankAccountDialogComponent, 'BANK_ACCOUNT_CREATE');
        } catch (error) {
            console.error('Erreur lors du chargement du composant BankAccountDialogComponent', error);
            // Gérer l'erreur (afficher un message à l'utilisateur, etc.)
        }
    }

    private async _createCheque(): Promise<void> {
        try {
            const { ChequeDialogComponent } = await import('src/app/banking/components/cheques/cheque-dialog/cheque-dialog.component');
            //  Ouverture de la fenêtre de création de chèque
            this.openDialog(ChequeDialogComponent, 'CHEQUE_DIALOG');
        } catch (error) {
            console.error('Erreur lors du chargement du composant ChequeDialogComponent', error);
            // Gérer l'erreur (afficher un message à l'utilisateur, etc.)
        }
    }

    private async _createClient(): Promise<void> {
        try {
            const { PartnerDialogComponent } = await import('src/app/partners/components/partners/partner-dialog/partner-dialog.component');
            //  Ouverture de la fenêtre de création de client
            this.openDialog(PartnerDialogComponent, 'PARTNER', { mode: 'client' });
        } catch (error) {
            console.error('Erreur lors du chargement du composant PartnerDialogComponent', error);
            // Gérer l'erreur (afficher un message à l'utilisateur, etc.)
        }
    }

    private async _createDeposit(): Promise<void> {
        try {
            const { DepositDialogComponent } = await import('src/app/banking/components/deposits/deposit-dialog/deposit-dialog.component');
            //  Ouverture de la fenêtre de création de client
            this.openDialog(DepositDialogComponent, 'DEPOSIT_DIALOG');
        } catch (error) {
            console.error('Erreur lors du chargement du composant DepositDialogComponent', error);
            // Gérer l'erreur (afficher un message à l'utilisateur, etc.)
        }
    }

    private async _createProduct(): Promise<void> {
        try {
            const { ProductDialogComponent } = await import('src/app/products/components/product-dialog/product-dialog.component');
            this.openDialog(ProductDialogComponent, 'PRODUCT');
        } catch (error) {
            console.error('Erreur lors du chargement du composant ProductDialogComponent', error);
            // Gérer l'erreur (afficher un message à l'utilisateur, etc.)
        }
    }

    private _createQuotation(): void {
        //  Création du devis et redirection vers la page de modification
        let quotation = Quotation.init();
        this.quotationService.save$(quotation).subscribe(
            (quotation: Quotation) => this.router.navigate(['/sales/quotation', quotation.id])
        );
    }

    private _createSalesInvoice(): void {
        //  Création de la facture de vente et redirection vers la page de modification
        let salesInvoice = SalesInvoice.init();
        this.salesInvoiceService.save$(salesInvoice).subscribe(
            (salesInvoice: SalesInvoice) => this.router.navigate(['/sales/invoice', salesInvoice.id])
        );
    }

    private async _createVendor(): Promise<void> {
        try {
            const { PartnerDialogComponent } = await import('src/app/partners/components/partners/partner-dialog/partner-dialog.component');
            //  Ouverture de la fenêtre de création de client
            this.openDialog(PartnerDialogComponent, 'PARTNER', { mode: 'vendor' });
        } catch (error) {
            console.error('Erreur lors du chargement du composant PartnerDialogComponent', error);
            // Gérer l'erreur (afficher un message à l'utilisateur, etc.)
        }
    }

    /**
     * Récupéartion de l'élément survolé pour afficher le menu contextuel.#item
     *
     * On remonte jusqu'à l'élément <a> parent si l'élément survolé est un enfant
     * @param target L'élément survolé
     * @returns Le lien parent
     */
    private _getHoverTarget(target: HTMLElement): HTMLElement {
        while (target.tagName !== 'DIV') {
            target = target.parentElement as HTMLElement;
        }
        return target;
    }

    private _getTieredMenuTop(target: HTMLElement): string {
        // Si le menu ouvert dépasse le bas de la fenêtre
        if (target.offsetTop + (this.tieredMenu.container as HTMLElement).offsetHeight > window.innerHeight) {
            return `${target.offsetTop - (this.tieredMenu.container as HTMLElement).offsetHeight + target.offsetHeight}px`;
        } else {
            return `${target.offsetTop}px`;
        }
    }

    private _toggleAccountingMenuItems(): void {
        this._showAccountingMenuItems = !this._showAccountingMenuItems;
    }

    private _toggleBankMenuItems(): void {
        this._showBankMenuItems = !this._showBankMenuItems;
    }

    private _toggleLegalMenuItems(): void {
        this._showLegalMenuItems = !this._showLegalMenuItems;
    }

    private _togglePurchasingMenuItems(): void {
        this._showPurchasingMenuItems = !this._showPurchasingMenuItems;
    }

    private _toggleSalesMenuItems(): void {
        this._showSalesMenuItems = !this._showSalesMenuItems;
    }
}

import { ModelBase } from "../../abstract/model-base.model";
import { Account } from "../account.model";
import { AllocationType } from "../allocation-type.model";

export abstract class AllocationBase extends ModelBase {

    // ATTRIBUTS

    private _account!: Account;
    private _allocationType!: AllocationType;
    private _amount!: number;
    private _baseAmount!: number;
    private _percentage!: number;

    // MÉTHODES

    constructor(data: any) {
        super(data);
        this._amount = this._amount || 0;
        this._baseAmount = this._baseAmount || 0;
        this._percentage = this._percentage || 0;
        this._allocationType = this._allocationType || new AllocationType({
            code: "amount",
            label: "Montant"
        });
    }

    public calculatePercentage(): void {
        this.percentage = (this.amount / this.baseAmount * 100).toFixed(2);
    }

    // GETTERS / SETTERS

    public get account(): Account {
        return this._account;
    }

    public set account(value: any) {
        this._setValue('_account', value, 'Account');
    }

    public get allocationType(): AllocationType {
        return this._allocationType;
    }

    public set allocationType(value: any) {
        this._setValue('_allocationType', value, 'AllocationType');
    }

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get baseAmount(): number {
        return this._baseAmount;
    }

    public set baseAmount(value: any) {
        const hasChanged = this._setValue('_baseAmount', value, 'float');
        if (hasChanged) {
            this.calculatePercentage();
        }
    }

    public get percentage(): number {
        return this._percentage;
    }

    public set percentage(value: any) {
        this._setValue('_percentage', value, 'float');
    }


}
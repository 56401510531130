<div class="flex justify-content-between p-3">
    <div class="flex flex-column flex-grow-1 align-items-center">
        <div class="text-lg mb-3">
            À partir d'un fichier de reprise
        </div>
        <div class="mb-5 text-center">
            Importez un plan comptable sur mesure à partir d'Excel ou d'un fichier CSV.
        </div>
        <p-fileUpload #fu1 class="ml-3" styleClass="p-button-rounded" mode="basic"
            accept="text/csv,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" chooseLabel="Importer votre plan comptable" chooseIcon="pi pi-cloud-upload" [auto]="true"
            [customUpload]="true" (uploadHandler)="onUploadPlan($event);fu1.clear()"></p-fileUpload>
        <div class="text-sm mt-2"><a href="https://biifor.com/templates/import_comptes_template.xlsx">Cliquez ici pour télécharger le modèle du fichier à importer</a></div>
        <div class="text-sm mt-2"><a routerLink="/settings/accounting/accounts/import-history" (click)="onClickHistory()">Cliquez ici pour voir l'historique des imports</a></div>
    </div>
    <p-divider layout="vertical"></p-divider>
    <div class="flex flex-column flex-grow-1 align-items-center">
        <div class="text-lg mb-3">
            À partir d'un FEC
        </div>
        <div class="mb-5 text-center">
            Importez un fichier FEC existant pour mettre à jour les comptes et les journaux comptables.
        </div>
        <p-fileUpload #fu2 class="ml-3" styleClass="p-button-rounded" mode="basic"
            accept="text/csv,text/plain" chooseLabel="Importer un FEC" chooseIcon="pi pi-cloud-upload" [auto]="true"
            [customUpload]="true" (uploadHandler)="onUploadFEC($event);fu2.clear()"></p-fileUpload>
    </div>
</div>
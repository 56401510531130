import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Ajout du content type dans les entêtes de requête
 */
@Injectable({
    providedIn: 'root'
})
export class ApiContentTypeInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Si l'entête content-type n'est pas défini, alors on le rajoute
        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.append('Content-Type', 'application/json') });
        }
        return next.handle(request);
    }
}

<form (ngSubmit)="save()" [formGroup]="form" novalidate class="flex flex-column">
    <div class="grid">

        <div class="col-12">

            @if (!!user) {
            <!-- Utilisateur sélectionné -->
            <div class="flex align-items-center justify-content-between border-1 border-300 border-round-lg p-3">
                <div class="flex align-items-center">
                    <div class="mr-3">
                        @if (user.new) {
                        <p-avatar icon="{{user.avatarIcon}}" size="large" shape="circle"></p-avatar>
                        } @else {
                        <p-avatar image="{{user.avatar}}" label="{{user.avatarLabel}}"
                            [style]="{ 'background-color': user.avatarLabelColor, color: '#ffffff' }" size="large"
                            shape="circle"></p-avatar>
                        }
                    </div>
                    <div class="flex flex-column">
                        <div class="font-bold">{{ user.firstName }} {{ user.lastName }}</div>
                        <div class="text-sm text-500 font-italic">{{ user.email }}</div>
                    </div>
                </div>
                <div>
                    <p-select [options]="profiles" formControlName="profile" appendTo="body" dataKey="code"
                        placeholder="Profil">
                        <ng-template let-profile #selectedItem>
                            @if (!!profile) {
                            {{profile.label}}
                            }
                        </ng-template>
                        <ng-template let-profile #item>
                            <div class="flex flex-column px-1">
                                <span class="py-1">{{profile.label}}</span>
                                <span class="py-1 text-sm">{{profile.description}}</span>
                            </div>
                        </ng-template>
                    </p-select>
                </div>
                <div>
                    <p-button type="button" icon="pi pi-times" severity="danger" [rounded]="true" [text]="true"
                        (onClick)="onClickRemoveUser()"></p-button>
                </div>
            </div>

            } @else {

            <!-- Champ de recherche -->
            <div class="mb-5">
                Entrez l'email de l'utilisateur à inviter.
            </div>
            <div>
                <p-autoComplete formControlName="search" appendTo="body" dataKey="id" [minLength]="5"
                    [suggestions]="users" [showEmptyMessage]="true"
                    emptyMessage="Aucun utilisateur ne correspond à la recherche" [showClear]="true"
                    (completeMethod)="onSearch($event)" (onSelect)="onSelectUser($event)" placeholder="Ex : marie.durand@biifor.com">
                    <ng-template let-user pTemplate="item">
                        <div class="flex align-items-center">
                            <div class="mr-3">
                                @if (user.new) {
                                <p-avatar icon="{{user.avatarIcon}}" size="large" shape="circle"></p-avatar>
                                } @else {
                                <p-avatar image="{{user.avatar}}" label="{{user.avatarLabel}}"
                                    [style]="{ 'background-color': user.avatarLabelColor, color: '#ffffff' }"
                                    size="large" shape="circle"></p-avatar>
                                }
                            </div>
                            <div class="flex flex-column">
                                <div class="font-bold">{{ user.firstName }} {{ user.lastName }}</div>
                                <div class="text-sm text-500 font-italic">{{ user.email }}</div>
                            </div>
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
            }

        </div>

        <div class="col-12 flex justify-content-end">
            <p-button class="w-100" type="submit" severity="success" [label]="buttonLabel" [rounded]="true" [loading]="invite.busy"
                [disabled]="!user || form.invalid"></p-button>
        </div>
    </div>
</form>
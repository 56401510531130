import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { ImageModule } from 'primeng/image';
import { MenuModule } from 'primeng/menu';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';

import { environment } from 'src/environments/environment';

// Composants
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';

// Modèles
import { Client } from 'src/app/@shared/models/client/client.model';
import { Invite } from 'src/app/@shared/models/client/invite.model';
import { DialogModule } from 'primeng/dialog';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        CommonModule,
        AvatarModule,
        ButtonModule,
        DialogModule,
        DividerModule,
        ImageModule,
        MenuModule,
        OverlayPanelModule,
        ToolbarModule,
    ],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Vue
     ----------------------------------------------------------------------------------------*/

    // Menu
    clientMenuItems: MenuItem[] = [];
    userMenuItems: MenuItem[] = [];

    qrCodeSrc: string = '';
    qrCodeVisible: boolean = false;

    Environment = environment;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    client?: Client;

    private _invites: Invite[] = [];
    public get invites(): Invite[] {
        return this._invites;
    }
    public set invites(value: Invite[]) {
        let emptyArray: Invite[] = [];
        this._invites = emptyArray.concat(value);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public logout() {
        this.userService.logout$()
            .subscribe(() => {
                this.router.navigate(['/login']);
            })
    }

    public onClickAcceptInvite(invite: Invite): void {
        this._inviteAccept(invite);
    }

    public onClickDeclineInvite(invite: Invite): void {
        this._inviteDecline(invite);
    }

    public onClickLogo(): void {
        if (this.appService.selectedClient) {
            this.router.navigate(['/home/dashboard']);
        } else {
            this.router.navigate(['/home']);
        }
    }

    public onClickTheme(): void {
        this._switchTheme();
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    override ngOnInit() {
        this._initMenuItems();
        super.ngOnInit();
    }

    protected override subscribeToObservables(): void {
        // Organisation sélectionnée
        this.subscriptions.add(
            this.clientService.selected$.subscribe((client: Client | undefined) => this.client = client)
        );
        // Organisations visibles par l'utilisateur
        this.subscriptions.add(
            this.appService.clientsLoaded$.subscribe(clients => {
                if (!clients) return;
                // Ré-initialisation du menu
                this._initMenuItems();
                // Vérification du nombre d'organisations, s'il n'y en a qu'une on la sélectionne
                let length = clients.length;
                if (length === 1 && !this.clientService.hasSelection()) {
                    this.appService.selectClient(clients[0]);
                }
                // Tri des organisations dans l'ordre alphabétique
                clients.sort((a, b) => a.commercialName.localeCompare(b.commercialName));
                // Construction du menu de sélection de l'organisation
                for (let i = 0; i < length; i++) {
                    this.clientMenuItems.push({
                        label: clients[i].commercialName,
                        command: _ => this.appService.selectClient(clients[i])
                    });
                };
            })
        );
        // Invitations reçues
        this.subscriptions.add(
            this.appService.userLoaded$.subscribe((user) => {
                if (!!user) this._loadInvites();
            })
        );
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _createNewClient(): void {
        // Déselection du client actuellement sélectionné
        this.appService.selectedClient = null;
        this.clientService.select(new Client({}), false);
        // Navigation vers le formulaire de création d'un nouveau client
        this.router.navigate(['/client']);
    }

    private _initMenuItems() {
        this.clientMenuItems = [
            { label: 'Ajouter une organisation', icon: PrimeIcons.PLUS, command: () => this._createNewClient() },
            { separator: true },
        ];

        this.userMenuItems = [
            {
                icon: PrimeIcons.USER,
                label: 'Voir mon profil',
                routerLink: ['/user']
            },
            { separator: true },
            {
                icon: PrimeIcons.QRCODE,
                label: 'Connexion Bfor Scan',
                command: () => this._showQrCode()
            },
            { separator: true },
            {
                icon: PrimeIcons.POWER_OFF,
                label: 'Déconnexion',
                command: () => this.logout()
            },
        ]
    }

    private _inviteAccept(invite: Invite): void {
        this.inviteService.accept$(invite).subscribe(() => {
            this.userService.loadClients();
            this._loadInvites();
        });
    }

    private _inviteDecline(invite: Invite): void {
        this.inviteService.refuse$(invite).subscribe(() => {
            this._loadInvites();
        });
    }

    private _loadInvites() {
        this.inviteService.getMine$().subscribe((invites: Invite[]) => {
            this.invites = invites;
        });
    }

    private _showQrCode(): void {
        this.authenticationService.getQrCode$().subscribe((qrCode: string) => {
            this.qrCodeSrc = qrCode;
            this.qrCodeVisible = true;
        });
        // Affichage du QR code dans une fenêtre modale
    }

    private _switchTheme(): void {
        this.appService.switchTheme();
    }

}

import { ModelBase } from "../abstract/model-base.model";
import { AllocationBase } from "../accounting/abstract/allocation-base.model";
import { Shareholder } from "../client/shareholder.model";
import { Currency } from "../core/currency.model";
import { Employee } from "../human-resources/employee.model";
import { Partner } from "../partner/partner.model";

export class BusinessEvent extends ModelBase {

    private _allocations!: AllocationBase[];
    private _amount!: number;
    private _currency!: Currency;
    private _date!: moment.Moment;
    private _employee!: Employee;
    private _label!: string;
    private _nature!: string;
    private _partner!: Partner;
    private _reference!: string;
    private _shareholder!: Shareholder;
    private _subLabel!: string;

    public get allocations(): AllocationBase[] {
        return this._allocations;
    }

    public set allocations(value: any) {
        if ((this._nature === 'purchase_invoice' || this._nature === 'purchase_credit_note')) {
            this._setValue('_allocations', value, 'PurchaseInvoiceAllocation');
        } else if ((this._nature === 'sales_invoice' || this._nature === 'sales_credit_note')) {
            this._setValue('_allocations', value, 'SalesInvoiceAllocation');
        } else if (this._nature === 'bank_transaction') {
            this._setValue('_allocations', value, 'BankAccountLineAllocation');
        } else if (this._nature === 'miscellaneous_operation') {
            this._setValue('_allocations', value, 'BankAccountLineAllocation');
        }
    }

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'string');
    }

    public get date(): moment.Moment {
        return this._date;
    }

    public set date(value: any) {
        this._setValue('_date', value, 'date');
    }

    public get employee(): Employee {
        return this._employee;
    }

    public set employee(value: any) {
        this._setValue('_employee', value, 'Employee');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get nature(): string {
        return this._nature;
    }

    public set nature(value: any) {
        this._setValue('_nature', value, 'string');
    }

    public get natureLabel(): string {
        return this._nature === 'purchase_invoice' ? 'Facture fournisseur' :
            this._nature === 'purchase_credit_note' ? 'Avoir fournisseur' :
                this._nature === 'sales_invoice' ? 'Facture client' :
                    this._nature === 'sales_credit_note' ? 'Avoir client' :
                        this._nature === 'bank_transaction' ? 'Transaction bancaire' :
                            this._nature === 'miscellaneous_operation' ? 'Opération diverse' :
                                this._nature;
    }

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

    public get reference(): string {
        return this._reference;
    }

    public set reference(value: any) {
        this._setValue('_reference', value, 'string');
    }

    public get stakeholder(): string {
        let stakeholder = '';
        if (this._partner) {
            stakeholder = this._partner.toString();
            if (!!this._shareholder || !!this._employee) {
                stakeholder += ', payé par ';
            }
        }
        if (this._shareholder) {
            stakeholder += this._shareholder.toString();
        } else if (this._employee) {
            stakeholder += this._employee.toString();
        }
        return stakeholder;
    }

    public get shareholder(): Shareholder {
        return this._shareholder;
    }

    public set shareholder(value: any) {
        this._setValue('_shareholder', value, 'Shareholder');
    }

    public get subLabel(): string {
        return this._subLabel;
    }

    public set subLabel(value: any) {
        this._setValue('_subLabel', value, 'string');
    }

}
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';

// Composants
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';

@Component({
    selector: 'app-accounts-import-dialog',
    imports: [
        RouterModule,
        DividerModule,
        FileUploadModule
    ],
    templateUrl: './accounts-import-dialog.component.html',
    styleUrl: './accounts-import-dialog.component.scss'
})
export class AccountsImportDialogComponent extends BaseComponent {
    private _config = inject(DynamicDialogConfig);
    private _ref = inject(DynamicDialogRef);


    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    private _uploading: boolean = false;

    public onClickHistory(): void {
        this._ref.close();
    }

    public onUploadFEC(event: any): void {
        this.onUpload(event, this._uploadFEC$.bind(this));
    }

    public onUploadPlan(event: any): void {
        this.onUpload(event, this._uploadPlan$.bind(this));;
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/


     protected override subscribeToObservables(): void {
        this.subscriptions.add(
            this.accountService.uploading$.subscribe(uploading => {
                this.appService.uiBlocked = uploading;
                if (!this._uploading && uploading) {
                    this._uploading = uploading;
                } else if (this._uploading && !uploading) {
                    this._ref.close();
                }
            })
        );
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _uploadFEC$(file: File): Observable<any> {
        return this.accountService.uploadFEC$(file);
    }

    private _uploadPlan$(file: File): Observable<any> {
        return this.accountService.uploadPlan$(file);
    }

}


import { environment } from "src/environments/environment";
import { ODataMessage } from "./message";

export class ODataListResponse {
    count: number;
    results: any[] = [];

    constructor(jsonResponse: any) {
        if (!!jsonResponse.results) {
            this.results = jsonResponse.results;
            this.count = jsonResponse.count;
        } else {
            this.results = jsonResponse;
            this.count = this.results.length;
        }
    }
}

export class ODataResponse {
    messages: ODataMessage[] = [];
    value: any;

    constructor(jsonResponse: any) {
        for (var key in jsonResponse.messages) {
            this.messages.push(new ODataMessage(jsonResponse.messages[key]));
        }
        this.value = jsonResponse.value;
    }
}

export class ODataError {
    messages: ODataMessage[] = [];
    details: any;
    status: number;
    statusText: string;
    url: string;

    constructor(jsonResponse: any) {
        this.details = {};
        this.messages = [];
        // Error technical info
        this.status = jsonResponse.status;
        this.statusText = jsonResponse.statusText;
        this.url = jsonResponse.url;

        let uuid: string = jsonResponse.error?.uuid;

        // 401, as there is no message
        if (this.status === 401) {
            let longText = jsonResponse?.error?.detail.startsWith('No active') ? 'Identifiants de connexion incorrects' : 'Veuillez vous connecter';
            let detail = new ODataMessage({
                domain: 'http',
                longText: longText,
                number: 401,
                shortText: 'Non autorisé',
                type: 'E',
                uuid: uuid
            });
            this.details = { authorization: detail };
            this.messages.push(detail);
            return;
        }

        // 403, as there is no message
        if (this.status === 403) {
            let detail = new ODataMessage({
                domain: 'http',
                longText: `Vous n'êtes pas autorisé à effectuer cette action`,
                number: 403,
                shortText: 'Non autorisé',
                type: 'E',
                uuid: uuid
            });
            this.details = { authorization: detail };
            this.messages.push(detail);
            return;
        }

        // 404, as there is no message
        if (this.status === 404) {
            let detail = new ODataMessage({
                domain: 'http',
                longText: `L'élément demandé n'a pu être trouvé`,
                number: 404,
                shortText: 'Ressource indisponible',
                type: 'E',
                uuid: uuid
            });
            this.details = { authorization: detail };
            this.messages.push(detail);
            return;
        }

        // 500, as there is no message
        if (this.status === 500) {
            let detail = new ODataMessage({
                domain: 'http',
                longText: !environment.production ? jsonResponse.message : '',
                number: 500,
                shortText: jsonResponse.statusText,
                type: 'E',
                uuid: uuid
            });
            this.details = { server: detail };
            this.messages.push(detail);
            return;
        }

        // 504, as there is no message
        if (this.status === 504) {
            let detail = new ODataMessage({
                domain: 'network',
                longText:
                    "Une erreur est survenue lors de l'envoi de votre document à cause d'une erreur de réseau. Veuillez réessayer ultérieurement.",
                number: 504,
                shortText: "Erreur lors de l'envoi du document",
                type: 'E',
                uuid: uuid
            });
            this.details = { timeout: detail };
            this.messages.push(detail);
            return;
        }

        // Backend messages
        if (!!jsonResponse.error && !!jsonResponse.error.messages) {
            for (var key in jsonResponse.error.messages) {
                let message: ODataMessage = new ODataMessage(jsonResponse.error.messages[key]);
                message.uuid = uuid;
                this.messages.push(message);
            }
        }
        if (!!jsonResponse.error && !!jsonResponse.error.error) {
            for (var key in jsonResponse.error.error) {
                let message: ODataMessage = new ODataMessage(jsonResponse.error.error[key]);
                message.uuid = uuid;
                this.details[key] = message;
            }
        }
    }
}

<div class="w-full">
    <p-card>
        <!-- Entête -->
        <ng-template #header>
            <div class="flex align-items-center justify-content-between p-3">
                <div class="flex align-items-center col-9">
                    <h2>Délais moyen de paiement (DMP)</h2>
                </div>
                <div class="col-3">
                    <p-select [options]="accountingPeriods" [(ngModel)]="selectedPeriod"
                        (onChange)="onChangeAccountingPeriod($event)">
                        <ng-template let-period pTemplate="selectedItem">
                            @if (!!period) {
                            <span>{{period.dateFrom | date:'shortDate'}} - {{period.dateTo |
                                date:'shortDate'}}</span>
                            }
                        </ng-template>
                        <ng-template let-period pTemplate="item">
                            <span>{{period.dateFrom | date:'shortDate'}} - {{period.dateTo | date:'shortDate'}}</span>
                        </ng-template>
                    </p-select>
                </div>
            </div>
        </ng-template>

        <!-- Graph -->
        @if (showChart) {
        <div class="relative">
            <apx-chart [series]="chartSeries" [chart]="chart" [dataLabels]="chartDataLabels"
                [plotOptions]="chartPlotOptions" [stroke]="chartStroke" [tooltip]="chartTooltip" [xaxis]="chartXAxis"
                [yaxis]="chartYAxis"></apx-chart>
        </div>
        } @else {
        <div class="p-3 flex align-items-center justify-content-center" [style.height.px]="height">
            <p-progressSpinner></p-progressSpinner>
        </div>
        }
    </p-card>
</div>
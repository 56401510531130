import * as moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { AccountEntryLine } from './account-entry-line.model';
import { AccountEntryType } from './account-entry-type.model';
import { Currency } from '../core/currency.model';
import { Journal } from './journal.model';

export class AccountEntry extends ModelBase {

    static override CLASS_NAME: string = 'AccountEntry';

    private _conversionDate!: moment.Moment;
    private _currency!: Currency;
    private _currencyOrg!: Currency;
    private _exchangeRage!: number;
    private _journal!: Journal;
    private _label!: string;
    private _lines!: AccountEntryLine[];
    private _number!: string;
    private _operationDate!: moment.Moment;
    private _postingDate!: moment.Moment;
    private _reference!: string;
    private _type!: AccountEntryType;
    private _year!: number;

    public get conversionDate(): moment.Moment {
        return this._conversionDate;
    }

    public set conversionDate(value: any) {
        this._setValue('_conversionDate', value, 'date');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get currencyOrg(): Currency {
        return this._currencyOrg;
    }

    public set currencyOrg(value: any) {
        this._setValue('_currencyOrg', value, 'Currency');
    }

    public get exchangeRage(): number {
        return this._exchangeRage;
    }

    public set exchangeRage(value: any) {
        this._setValue('_exchangeRage', value, 'float');
    }

    public get journal(): Journal {
        return this._journal;
    }

    public set journal(value: any) {
        this._setValue('_journal', value, 'Journal');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get lines(): AccountEntryLine[] {
        return this._lines;
    }

    public set lines(value: any) {
        this._setValue('_lines', value, 'AccountEntryLine');
    }

    public get number(): string {
        return this._number;
    }

    public set number(value: any) {
        this._setValue('_number', value, 'string');
    }

    public get operationDate(): moment.Moment {
        return this._operationDate;
    }

    public set operationDate(value: any) {
        this._setValue('_operationDate', value, 'date');
    }

    public get postingDate(): moment.Moment {
        return this._postingDate;
    }

    public set postingDate(value: any) {
        this._setValue('_postingDate', value, 'date');
    }

    public get reference(): string {
        return this._reference;
    }

    public set reference(value: any) {
        this._setValue('_reference', value, 'string');
    }

    public get type(): AccountEntryType {
        return this._type;
    }

    public set type(value: any) {
        this._setValue('_type', value, 'AccountEntryType');
    }

    public get year(): number {
        return this._year;
    }

    public set year(value: any) {
        this._setValue('_year', value, 'integer');
    }

}

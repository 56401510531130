const tagAccents = {
    fontWeight: '500',
    colorScheme: {
        light: {
            primary: {
                background: '{primary.500}',
                color: '{primary.contrast.color}'
            },
            secondary: {
                background: '{surface.500}',
                color: '{primary.contrast.color}'
            },
            success: {
                background: '{green.500}',
                color: '{primary.contrast.color}'
            },
            info: {
                background: '{sky.500}',
                color: '{primary.contrast.color}'
            },
            warn: {
                background: '{yellow.500}',
                color: '{primary.contrast.color}'
            },
            danger: {
                background: '{red.500}',
                color: '{primary.contrast.color}'
            },
            contrast: {
                background: '{surface.500}',
                color: '{primary.contrast.color}'
            }
        },
        dark: {
            primary: {
                background: '{primary.500}',
                color: '{primary.contrast.color}'
            },
            secondary: {
                background: '{surface.500}',
                color: '{primary.contrast.color}'
            },
            success: {
                background: '{green.500}',
                color: '{primary.contrast.color}'
            },
            info: {
                background: '{sky.500}',
                color: '{primary.contrast.color}'
            },
            warn: {
                background: '{yellow.500}',
                color: '{primary.contrast.color}'
            },
            danger: {
                background: '{red.500}',
                color: '{primary.contrast.color}'
            },
            contrast: {
                background: '{surface.500}',
                color: '{primary.contrast.color}'
            }
        }
    }
};

export default tagAccents;
import { Component, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageModule } from 'primeng/image';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

// Composants
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';
import { BankAccountFormComponent } from '../bank-account-form/bank-account-form.component';

// Modèles
import { BankAccount } from 'src/app/@shared/models/banking/bank-account.model';

@Component({
    selector: 'app-bank-account-dialog',
    imports: [
        ButtonModule,
        ImageModule,
        ProgressSpinnerModule,
        BankAccountFormComponent,
    ],
    templateUrl: './bank-account-dialog.component.html',
    styleUrl: './bank-account-dialog.component.scss'
})
export class BankAccountDialogComponent extends BaseComponent {
    private _config = inject(DynamicDialogConfig);
    private _ref = inject(DynamicDialogRef);


    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _bankAccount!: BankAccount;
    public get bankAccount(): BankAccount {
        return this._bankAccount;
    }
    public set bankAccount(value: BankAccount) {
        this._bankAccount = value;
    }

    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    constructor() {
        super();
        if (!!this._config.data.bankAccount) {
            this.bankAccount = this._config.data.bankAccount;
        } else {
            this._bankAccount = BankAccount.init();
        }
    }

    public onBankAccountSaved(): void {
        this._ref.close();
    }

    public onClickPowensBank(): void {
        this._openPowensWebview();
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _openPowensWebview(): void {
        this.busy = true;
        // Obtention d'un token d'accès temporaire
        this.powensService.getToken$().subscribe(token => {
            this.busy = false;
            window.location.href = `https://webview.powens.com/fr/connect?domain=${this.Environment.powensDomain}.biapi.pro&client_id=${this.Environment.powensClient}&redirect_uri=${this.Environment.powensRedirection}/banking/accounts&code=${token}&connector_capabilities=bank`;
        });
    }

}

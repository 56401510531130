import moment from "moment";
import { Account } from "../accounting/account.model";
import { Person } from "../person/abstract/person.model";

export class Employee extends Person {

    static override CLASS_NAME: string = 'Employee';

    private _account!: Account;
    private _arrivalDate!: moment.Moment;
    private _job!: string;

    public get account(): Account {
        return this._account;
    }

    public set account(value: any) {
        this._setValue('_account', value, 'Account');
    }

    public get arrivalDate(): moment.Moment {
        return this._arrivalDate;
    }

    public set arrivalDate(value: any) {
        this._setValue('_arrivalDate', value, 'date');
    }

    public get job(): string {
        return this._job;
    }

    public set job(value: any) {
        this._setValue('_job', value, 'string');
    }

}
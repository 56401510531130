import { ModelBase } from "../abstract/model-base.model";
import { Account } from "../accounting/account.model";

export class KpiCharges extends ModelBase {

    private _account!: Account;
    private _balance!: number;
    private _currency!: string;
    private _percentage!: number;

    public get account(): any {
        return this._account;
    }

    public set account(value: Account) {
        this._setValue('_account', value, 'Account');
    }

    public get balance(): number {
        return this._balance;
    }

    public set balance(value: any) {
        this._setValue('_balance', value, 'float');
    }

    public get currency(): string {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'string');
    }

    public get percentage(): number {
        return this._percentage;
    }

    public set percentage(value: any) {
        this._setValue('_percentage', value, 'float');
    }

}
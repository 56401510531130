import { Component } from '@angular/core';
import { map, merge, Observable } from 'rxjs';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressBarModule } from 'primeng/progressbar';
import { $dt } from '@primeng/themes';

// Composants
import { BaseComponent } from '../../abstract/base.component';
import { BankAccountDialogComponent } from 'src/app/banking/components/bank-accounts/bank-account-dialog/bank-account-dialog.component';

// Modèles
import { Onboarding } from 'src/app/@shared/models/onboarding/onboarding.model';
import { OnboardingStep } from 'src/app/@shared/models/onboarding/step.model';
import { Quotation } from 'src/app/@shared/models/sales/quotation/quotation.model';
import { SalesInvoice } from 'src/app/@shared/models/sales/invoice/sales-invoice.model';
import { AccountsImportDialogComponent } from 'src/app/accounting/settings/components/accounts/accounts-import-dialog/accounts-import-dialog.component';
import { InviteDialogComponent } from 'src/app/client/components/invites/invite-dialog/invite-dialog.component';

@Component({
    selector: 'app-onboarding-card',
    imports: [
        AccordionModule,
        ButtonModule,
        CardModule,
        FileUploadModule,
        ProgressBarModule
    ],
    templateUrl: './onboarding-card.component.html',
    styleUrl: './onboarding-card.component.scss'
})
export class OnboardingCardComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    public active: string = '';
    public progress: number = 0;
    public showSteps: boolean = false;
    public stepsDone: number = 0;
    public stepsTotal: number = 0;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _onboarding: Onboarding | null = null;
    public get onboarding(): Onboarding | null {
        return this._onboarding;
    }
    public set onboarding(value: Onboarding | null) {
        this._onboarding = value;
        this.stepsDone = value ? value.steps.filter(step => step.done).length : 0;
        this.stepsTotal = value ? value.steps.length : 0;

        if (!!value) {
            // Nombre de steps 'done' sur le nombre total de steps
            this.progress = Math.round((this.stepsDone / this.stepsTotal) * 100);
        } else {
            this.progress = 0;
        }
    }

    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/

    public get progressBarColor(): string {
        return $dt('blue.500').value;
        // switch (true) {
        //     case this.progress <= 25:
        //         return $dt('red.500').value;
        //     case this.progress <= 50:
        //         return $dt('orange.500').value;
        //     case this.progress <= 75:
        //         return $dt('yellow.500').value;
        //     case this.progress <= 100:
        //         return $dt('green.500').value;
        //     default:
        //         return $dt('gray.500').value;
        // }
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public onClickAction(step: OnboardingStep): void {
        switch (step.code) {
            case 'connect_bank_account':
                this._createBankAccount();
                break;
            case 'create_quotation':
                this._createQuotation();
                break;
            case 'create_sales_invoice':
                this._createSalesInvoice();
                break;
            case 'import_chart_of_accounts':
                this._importAccounts();
                break;
            case 'install_mobile_app':
                this._validateStep(step);
                break;
            case 'invite_accountant':
                this._inviteAccountant();
                break;
            default:
                break;
        }
    }

    public onClickSkipOnboarding(): void {
        this.onboarding!.skipped = true;
        this.onboardingService.save$(this.onboarding!).subscribe(() => {
            this._loadOnboarding();
        });
    }

    public onClickSkipStep(step: OnboardingStep): void {
        this._skipStep(step);
    }

    public toggleSteps(): void {
        this.showSteps = !this.showSteps;
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override loadData(): void {
        this._loadOnboarding();
    }

    protected override subscribeToObservables(): void {
        this.subscriptions.add(
            merge(
                this.accountService.uploaded$,
                this.bankAccountService.created$,
                this.inviteService.created$,
                this.purchaseInvoiceService.uploaded$,
            ).subscribe(() => {
                this._loadOnboarding();
                this._openNextStep();
            })
        );
    }

    protected override uploadFile$(file: File): Observable<any> {
        return this.purchaseInvoiceService.upload$(file);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _createBankAccount(): void {
        this.openDialog(BankAccountDialogComponent, 'BANK_ACCOUNT_CREATE');
    }

    private _createSalesInvoice(): void {
        //  Création d'une facture et redirection vers la page de modification
        let invoice = SalesInvoice.init();
        this.salesInvoiceService.save$(invoice).subscribe(
            (invoice: SalesInvoice) => this.router.navigate(['/sales/invoice', invoice.id])
        );
    }

    private _createQuotation(): void {
        let quotation = Quotation.init();
        this.quotationService.save$(quotation).subscribe(
            (quotation: Quotation) => this.router.navigate(['/sales/quotation', quotation.id])
        );
    }

    private _importAccounts() {
        this.openDialog(AccountsImportDialogComponent, 'ACCOUNTS_IMPORT_DIALOG');
    }

    private _inviteAccountant(): void {
        this.openDialog(InviteDialogComponent, 'INVITE_DIALOG', { profile: 'accountant'});
    }

    private _loadOnboarding(): void {
        this.onboardingService.getMine$().subscribe(onboarding => {
            this.onboarding = onboarding;
        });
    }

    private _openNextStep(current?: OnboardingStep): void {

        if (!current) {
            current = this.onboarding!.steps.find(step => step.code === this.active);
            if (!current) {
                return;
            }
        }

        let nextStep = this.onboarding!.steps.find(step => !step.done && !step.skipped && step.order > current.order);
        if (nextStep) {
            this.active = nextStep.code;
        }
    }

    private _skipStep(step: OnboardingStep): void {
        step.skipped = true;
        this.onboardingStepService.save$(step).subscribe(() => {
            this._loadOnboarding();
            this._openNextStep(step);
        });
    }

    private _validateStep(step: OnboardingStep): void {
        step.done = true;
        this.onboardingStepService.save$(step).subscribe(() => {
            this._loadOnboarding();
        });
    }
}

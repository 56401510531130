import { ModelBase } from "./model-base.model";


export abstract class NumberRangeBase extends ModelBase {

    static override CLASS_NAME = '';

    private _numberCurrent!: number;
    private _numberFrom!: string;
    private _numberTo!: string;
    private _numberRange!: string;
    private _year!: number;

    public get numberCurrent(): number {
        return this._numberCurrent;
    }

    public set numberCurrent(value: any) {
        this._setValue('_numberCurrent', value, 'integer');
    }

    public get numberFrom(): string {
        return this._numberFrom;
    }

    public set numberFrom(value: any) {
        this._setValue('_numberFrom', value, 'string');
    }

    public get numberTo(): string {
        return this._numberTo;
    }

    public set numberTo(value: any) {
        this._setValue('_numberTo', value, 'string');
    }

    public get numberRange(): string {
        return this._numberRange;
    }

    public set numberRange(value: any) {
        this._setValue('_numberRange', value, 'string');
    }

    public get year(): number {
        return this._year;
    }

    public set year(value: any) {
        this._setValue('_year', value, 'integer');
    }

}
import { Component } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexXAxis, ApexYAxis, NgApexchartsModule } from 'ng-apexcharts';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { SelectChangeEvent, SelectModule } from 'primeng/select';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

// Composants
import { BaseComponent } from '../../abstract/base.component';
import { BankAccountDialogComponent } from 'src/app/banking/components/bank-accounts/bank-account-dialog/bank-account-dialog.component';

// Modèles
import { AccountingPeriod } from 'src/app/@shared/models/accounting/accounting-period.model';
import { BankAccount } from 'src/app/@shared/models/banking/bank-account.model';
import { KpiBankAccountBalances } from 'src/app/@shared/models/kpi/bank-account-balances.model';

// Utils
import { Filter } from 'src/app/@shared/utilities/models/filter';
import { Sorter } from 'src/app/@shared/utilities/models/sorter';

@Component({
    selector: 'app-bank-account-balances',
    imports: [
        CommonModule,
        FormsModule,
        NgApexchartsModule,
        ButtonModule,
        CardModule,
        DividerModule,
        SelectModule,
        ProgressSpinnerModule
    ],
    templateUrl: './bank-account-balances.component.html',
    styleUrls: ['./bank-account-balances.component.scss']
})
export class BankAccountBalancesComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    public showChart: boolean = false;

    public chart: ApexChart = {
        type: 'area',
        height: '300',
        zoom: {
            enabled: false
        },
    };
    public chartSeries: ApexAxisChartSeries = [
        {
            name: "Solde bancaire",
            data: []
        },
    ];
    public chartDataLabels: ApexDataLabels = {
        enabled: false
    }
    public chartFill: ApexFill = {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
        }
    }
    public chartXAxis: ApexXAxis = {
        type: "datetime"
    }
    public chartYAxis: ApexYAxis = {
        labels: {
            formatter: (this._formatYLabel).bind(this)
        }
    }

    public selectedBankAccount: BankAccount | undefined;
    public selectedPeriod: AccountingPeriod | undefined;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _kpi: KpiBankAccountBalances = new KpiBankAccountBalances({
        dailyBalances: []
    });
    public get kpi(): KpiBankAccountBalances {
        return this._kpi;
    }
    public set kpi(kpi: KpiBankAccountBalances) {
        this._kpi = kpi;
        this._buildChartData();
    }

    private _accountingPeriods: AccountingPeriod[] = [];
    public get accountingPeriods(): AccountingPeriod[] {
        return this._accountingPeriods;
    }
    public set accountingPeriods(accountingPeriods: AccountingPeriod[]) {
        let emptyArray: AccountingPeriod[] = []
        this._accountingPeriods = emptyArray.concat(accountingPeriods);
    }

    private _bankAccounts: BankAccount[] = [];
    public get bankAccounts(): BankAccount[] {
        return this._bankAccounts;
    }
    public set bankAccounts(bankAccounts: BankAccount[]) {
        let emptyArray: BankAccount[] = []
        this._bankAccounts = emptyArray.concat(bankAccounts);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public onChangeAccountingPeriod(event: SelectChangeEvent) {
        this._loadKpi();
    }

    public onChangeBankAccount(event: SelectChangeEvent) {
        this._loadKpi();
    }

    public onClickAddBankAccount() {
        this._createBankAccount();
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override subscribeToObservables(): void {

        // Sélection d'un client
        this.subscriptions.add(
            this.appService.clientSelected$.subscribe(
                client => {
                    if (!!client) {
                        this._loadAccountingPeriods();
                        this._loadBankAccounts();
                    }
                }
            )
        );

        // Création d'un compte bancaire
        this.subscriptions.add(
            this.bankAccountService.created$.subscribe(
                () => {
                    this._loadBankAccounts();
                    this._loadKpi();
                }
            )
        );

        // Changement de l'ouverture du menu
        this.subscriptions.add(
            this.appService.menuState$.subscribe(
                () => {
                    this._buildChartData();
                }
            )
        );

    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _buildChartData() {

        let data = [];

        for (let i = 0; i < this.kpi.dailyBalances.length; i++) {
            const element = this.kpi.dailyBalances[i];
            data.push([element.date.unix() * 1000, element.balance])
        }
        this.chartSeries[0].data = data;

        let emptyArray: ApexAxisChartSeries = [];
        this.chartSeries = emptyArray.concat(this.chartSeries);
        if (data.length > 0) {
            this.showChart = true;
        }
    }

    private _createBankAccount(): void {
        this.openDialog(BankAccountDialogComponent, 'BANK_ACCOUNT_CREATE');
    }

    private _formatYLabel(val: any, index: any) {
        let pipe = new CurrencyPipe('fr');
        return pipe.transform(val, this.kpi?.currency, 'code')!;
    }

    private _loadAccountingPeriods() {
        this.accountingPeriodService.getList$({
            sorters: [
                new Sorter({
                    property: 'dateFrom',
                    descending: true
                })
            ]
        }).subscribe(accountingPeriods => {
            this.accountingPeriods = accountingPeriods;
            if (!!accountingPeriods[0]) {
                // Sélection de la première période pour le select
                this.selectedPeriod = accountingPeriods[0];
                this._loadKpi();
            }
        });
    }

    private _loadBankAccounts() {
        let filter = new Filter({
            property: 'dashboard',
            value: true
        });
        let sorter = new Sorter({
            property: 'label'
        });
        this.bankAccountService.getList$({
            filters: [filter],
            sorters: [sorter]
        }).subscribe(bankAccounts => {
            // Ajout d'un premier compte "Tous" pour le select
            bankAccounts.unshift(new BankAccount({
                id: null,
                label: 'Tous les comptes'
            }));
            this.bankAccounts = bankAccounts;
            this.selectedBankAccount = bankAccounts[0];
        });
    }

    private _loadKpi() {
        this.showChart = false;
        let filter = new Filter({
            filters: [
                new Filter({
                    property: 'dateFrom',
                    value: this.selectedPeriod?.dateFrom,
                }),
                new Filter({
                    property: 'dateTo',
                    value: this.selectedPeriod?.dateTo,
                })
            ]
        });
        if (!!this.selectedBankAccount?.id) {
            filter.filters.push(new Filter({
                property: 'bankAccount',
                value: this.selectedBankAccount.id
            }));
        }
        this.kpiService.getRelated$(this.appService.selectedClient, 'bank-account-balances', {
            filters: [filter]
        }).subscribe(
            kpi => {
                this.kpi = kpi;
            }
        );
    }

}

@if (!bankAccount || !bankAccount.bank) {
<span class="p-text-secondary block text-lg font-bold">Connectez vos comptes bancaires en toute simplicité !</span>
<div class="flex flex-column align-items-start justify-content-between">
    <p>Avant de continuer, voici ce qui va se passer :</p>

    <ul>
        <li class="mb-3">
            <strong>Automatisation sécurisée :</strong> Nous allons vous rediriger vers une plateforme sécurisée pour
            connecter vos comptes bancaires à notre application. Fini les importations manuelles fastidieuses !
        </li>
        <li class="mb-3">
            <strong>Visibilité complète :</strong> Une fois connectés, vos transactions bancaires seront automatiquement
            mises à jour, vous offrant une vue d'ensemble claire de vos finances.
        </li>
        <li>
            <strong>Gain de temps assuré :</strong> En moyenne, nos utilisateurs gagnent plus de 30 minutes par mois
            grâce à cette connexion automatisée.
        </li>
    </ul>

    <p class="mb-5">En utilisant cette connexion via la plateforme sécurisée Powens, vous profitez d'une méthode fiable et
        performante, bien plus rapide et précise que l'importation de fichiers CSV.</p>

    <p-button class="w-full" styleClass="w-full flex flex-column align-item-center justify-content-betwwen"
        label="Se connecter en toute sécurité" severity="success" [raised]="true" [rounded]="true" [disabled]="busy"
        (click)="onClickPowensBank()"></p-button>
</div>
} @else {
<app-bank-account-form [bankAccount]="bankAccount" (bankAccountSaved)="onBankAccountSaved()"></app-bank-account-form>
}
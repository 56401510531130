import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'Journal';
const ENDPOINT = `/journals/`;

@Injectable({
    providedIn: 'root'
})
export class JournalService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { MessageServiceBroker } from '../message.service';
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'PartnerGroup';
const ENDPOINT = `/partner-groups/`;

@Injectable({
    providedIn: 'root'
})
export class PartnerGroupService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
    }
}

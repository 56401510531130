export abstract class AppConfigModel {

    static CLASS_NAME = '';
    readonly code!: string;
    readonly label?: string;

    constructor(data: any) {
        this.hydrate(data);
    }

    hydrate(data: any) {
        for (let key in data) {
            // Appel dynamique des setters, si une propriété non existante est appelée,
            // elle sera présente tout de même sur l'objet même si l'application n'est pas censée l'utiliser
            (this as any)[key] = data[key];
        }
    }
}

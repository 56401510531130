export enum FeaturesEnum {
    Accounting = 'accounting',
    AutoBanking = 'auto_banking',
    Banking = 'banking',
    BankMatching = 'bank_matching',
    EmailCollection = 'email_collection',
    Expenses = 'expenses',
    Ocr = 'ocr',
    ProductCategories = 'product_categories',
    PurchaseInvoices = 'purchase_invoices',
    PurchasePayments = 'purchase_payments',
    SalesEmails = 'sales_emails',
    SalesEmailsNoLogo = 'sales_emails_no_logo',
    SalesInvoices = 'sales_invoices',
    SalesPayments = 'sales_payments',
    SalesQuotations = 'sales_quotations',
    Tgc = 'tgc',
    WidgetBank = 'widget_bank',
    WidgetCharges = 'widget_charges',
    WidgetPurchaseInvoices = 'widget_purchase_invoices',
    WidgetSalesInvoices = 'widget_sales_invoices',
}
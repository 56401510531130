import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { RouteParam, ServiceBase } from '../service-base';
import { QuotationItem } from 'src/app/@shared/models/sales/quotation/quotation-item.model';

const CLASS_NAME = 'QuotationItem';
const ENDPOINT = `/quotation-items/`;

@Injectable({
    providedIn: 'root'
})
export class QuotationItemService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

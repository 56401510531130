import { AppConfigModel } from "../abstract/app-config.model";
import { PaymentMethod } from "../core/payment-method.model";
import { TransactionDirection } from "./transaction-direction.model";

export class BankAccountLineType extends AppConfigModel {

    static override CLASS_NAME: string = 'BankAccountLineType';

    readonly allocations!: boolean;
    readonly client!: boolean;
    readonly debitCredit!: TransactionDirection;
    readonly deposit!: boolean;
    readonly description!: string;
    readonly employee!: boolean;
    readonly final!: boolean;
    readonly invoices!: boolean;
    readonly loan!: boolean;
    readonly overpayment!: boolean;
    readonly overpaymentReimbursement!: boolean;
    readonly parent!: BankAccountLineType;
    readonly paymentMethod!: boolean;
    readonly paymentMethods!: PaymentMethod[];
    readonly profitLoss!: boolean;
    readonly shareholder!: boolean;
    readonly taxDeclaration!: boolean;
    readonly taxes!: boolean;
    readonly vendor!: boolean;
}

import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'SellingInfoRecord';
const ENDPOINT = `/selling-info-records/`;

@Injectable({
    providedIn: 'root'
})
export class SellingInfoRecordService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

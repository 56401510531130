import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { RouteParam, ServiceBase } from '../service-base';

// Modèles
import { Account } from 'src/app/@shared/models/accounting/account.model';

const CLASS_NAME = 'Account';
const ENDPOINT = `/accounts/`;
const ROUTES: RouteParam[] = [
    { key: 'merge', path: '{id}/merge/' },
    { key: 'uploadFEC', path: 'upload-fec/' },
]

@Injectable({
    providedIn: 'root'
})
export class AccountService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addRoutes(ROUTES);
    }

    public merge$(sourceAccount: Account, targetAccount: Account): Observable<any> {
        return this.update$(sourceAccount, {
            select: false,
            url: this.routes.merge.replace('{id}', targetAccount.id.toString())
        });
    }

    public uploadFEC$(file: File): Observable<any> {
        let url = this.routes.uploadFEC;
        return this.uploadFile$(file, url);
    }
}

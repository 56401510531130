import moment from 'moment';
import { SalesInvoiceBase } from '../abstract/sales-invoice-base.model';
import { QuotationItem } from './quotation-item.model';
import { QuotationTax } from './quotation-tax.model';

export class Quotation extends SalesInvoiceBase {

    static override CLASS_NAME: string = 'Quotation';

    private _acceptanceConditions!: string;
    private _amountBilled!: number;
    private _amountBilledOrg!: number;
    private _validityDate!: moment.Moment;
    private _validationDate!: moment.Moment;

    private _items!: QuotationItem[];
    private _taxes!: QuotationTax[];

    constructor(data: any) {
        super(data);
        this._items = this._items || [];
        this._taxes = this._taxes || [];
    }

    public static override statusColor(status: string): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (status === 'accepted'):
                return 'success';
            case (status === 'billed'):
                return 'success';
            case (status === 'cancelled'):
                return 'contrast';
            case (status === 'closed'):
                return 'contrast';
            case (status === 'draft'):
                return 'danger';
            case (status === 'partially_billed'):
                return 'warning';
            case (status === 'refused'):
                return 'contrast';
            case (status === 'sent'):
                return 'warning';
            case (status === 'validated'):
                return undefined;
            default:
                return undefined;
        }
    }

    public static override statusLabel(status: string): string {
        switch (true) {
            case (status === 'accepted'):
                return 'Accepté';
            case (status === 'billed'):
                return 'Facturé';
            case (status === 'cancelled'):
                return 'Annulé';
            case (status === 'closed'):
                return 'Clôturé';
            case (status === 'draft'):
                return 'Brouillon';
            case (status === 'partially_billed'):
                return 'Partiellement facturé';
            case (status === 'refused'):
                return 'Refusé';
            case (status === 'sent'):
                return 'Envoyée';
            case (status === 'validated'):
                return 'Validé';
            default:
                return '';
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public override get statusColor(): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (this.hasStatus('billed')):
                return Quotation.statusColor('billed');
            case (this.hasStatus('closed')):
                return Quotation.statusColor('closed');
            case (this.hasStatus('partially_billed')):
                return Quotation.statusColor('partially_billed');
            case (this.hasStatus('accepted')):
                return Quotation.statusColor('accepted');
            case (this.hasStatus('refused')):
                return Quotation.statusColor('refused');
            case (this.hasStatus('cancelled')):
                return Quotation.statusColor('cancelled');
            case (this.hasStatus('sent')):
                return Quotation.statusColor('sent');
            case (this.hasStatus('validated')):
                return Quotation.statusColor('validated');
            case (this.hasStatus('draft')):
                return Quotation.statusColor('draft');
            default:
                return Quotation.statusColor('');
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public override get statusLabel(): string {
        switch (true) {
            case (this.hasStatus('billed')):
                return Quotation.statusLabel('billed');
            case (this.hasStatus('closed')):
                return Quotation.statusLabel('closed');
            case (this.hasStatus('partially_billed')):
                return Quotation.statusLabel('partially_billed');
            case (this.hasStatus('accepted')):
                return Quotation.statusLabel('accepted');
            case (this.hasStatus('refused')):
                return Quotation.statusLabel('refused');
            case (this.hasStatus('cancelled')):
                return Quotation.statusLabel('cancelled');
            case (this.hasStatus('sent')):
                return Quotation.statusLabel('sent');
            case (this.hasStatus('validated')):
                return Quotation.statusLabel('validated');
            case (this.hasStatus('draft')):
                return Quotation.statusLabel('draft');
            default:
                return Quotation.statusLabel('');
        }
    }

    public get acceptanceConditions(): string {
        return this._acceptanceConditions;
    }

    public set acceptanceConditions(value: any) {
        this._setValue('_acceptanceConditions', value, 'string');
    }

    public get amountBilled(): number {
        return this._amountBilled;
    }

    public set amountBilled(value: any) {
        this._setValue('_amountBilled', value, 'float');
    }

    public get amountBilledOrg(): number {
        return this._amountBilledOrg;
    }

    public set amountBilledOrg(value: any) {
        this._setValue('_amountBilledOrg', value, 'float');
    }

    public get allocations(): any[] {
        return [];
    }

    public set allocations(_: any) { }

    public override get grossAmountDisp(): number {
        return this.grossAmount;
    }

    public get items(): QuotationItem[] {
        return this._items;
    }

    public set items(value: any) {
        this._setValue('_items', value, 'QuotationItem');
    }

    public override get netAmountDisp(): number {
        return this.netAmount;
    }

    public get taxes(): QuotationTax[] {
        return this._taxes;
    }

    public set taxes(value: any) {
        this._setValue('_taxes', value, 'QuotationTax');
    }

    public get validityDate(): moment.Moment {
        return this._validityDate;
    }

    public set validityDate(value: any) {
        this._setValue('_validityDate', value, 'date');
    }

    public get validationDate(): moment.Moment {
        return this._validationDate;
    }

    public set validationDate(value: any) {
        this._setValue('_validationDate', value, 'date');
    }

}

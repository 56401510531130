<div class="w-full">
    <p-card>
        <!-- Entête -->
        <ng-template pTemplate="header">
            <div class="flex align-items-center justify-content-between p-3">
                <div>
                    <h2>Flux de trésorerie</h2>
                </div>
                <div class="flex align-items-center justify-content-between">
                    <p-select [options]="bankAccounts" [(ngModel)]="selectedBankAccount"
                        (onChange)="onChangeBankAccount($event)">
                        <ng-template let-bankAccount pTemplate="selectedItem">
                            @if (!!bankAccount) {
                            <span>{{bankAccount.label}}</span>
                            }
                        </ng-template>
                        <ng-template let-bankAccount pTemplate="item">
                            <span>{{bankAccount.label}}</span>
                        </ng-template>
                    </p-select>
                    <p-select class="ml-3" [options]="accountingPeriods" [(ngModel)]="selectedPeriod"
                        (onChange)="onChangeAccountingPeriod($event)">
                        <ng-template let-period pTemplate="selectedItem">
                            @if (!!period) {
                            <span>{{period.dateFrom | date:'shortDate'}} - {{period.dateTo |
                                date:'shortDate'}}</span>
                            }
                        </ng-template>
                        <ng-template let-period pTemplate="item">
                            <span>{{period.dateFrom | date:'shortDate'}} - {{period.dateTo | date:'shortDate'}}</span>
                        </ng-template>
                    </p-select>
                </div>
            </div>
        </ng-template>

        <!-- Graph -->
        @if (showChart) {
        <div class="relative">
            <div class="flex align-items-start justify-content-between">
                <div class="flex-grow-1">
                    <apx-chart [series]="chartSeries" [chart]="chart" [dataLabels]="chartDataLabels" [fill]="chartFill"
                        [xaxis]="chartXAxis" [yaxis]="chartYAxis"></apx-chart>
                </div>
                <div id="details">
                    <div class="h-full flex flex-column align-items-start justify-content-between">
                        <div class="flex-grow-1">
                            <div class="uppercase mb-3">Solde fin de période</div>
                            <div class="text-2xl">{{ kpi.dailyBalances[kpi.dailyBalances.length - 1].balance |
                                currency:kpi.currency:'code' }}</div>
                        </div>
                        <div class="flex-grow-1">
                            <div class="uppercase text-green-500 mb-3">Entrant</div>
                            <div class="text-2xl">{{ kpi.totalIn| currency:kpi.currency:'code' }}</div>
                        </div>
                        <div class="flex-grow-1">
                            <div class="uppercase text-red-500 mb-3">Total des dépenses</div>
                            <div class="text-2xl">{{ kpi.totalOut| currency:kpi.currency:'code' }}</div>
                        </div>
                        <div class="flex-grow-1">
                            <div class="uppercase mb-3">Solde début de période</div>
                            <div class="text-2xl">{{ kpi.dailyBalances[0].balance | currency:kpi.currency:'code' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            @if (bankAccounts.length === 1) {
            <div
                class="w-full h-full absolute top-0 flex flex-column align-items-center justify-content-center blurred-background">
                <!-- Message indiquant qu'il n'y a pas de compte -->
                <div class="text-xl mb-5">Vous n'avez pas encore de compte bancaire synchronisé dans Biifor</div>
                <!-- Bouton pour ajouter un premier compte -->
                <p-button label="Ajouter un compte" [raised]="true" [rounded]="true" (click)="onClickAddBankAccount()"></p-button>
            </div>
            }
        </div>
        } @else {
        <div class="p-3 flex align-items-center justify-content-center" style="height: 315px;">
            <p-progressSpinner></p-progressSpinner>
        </div>
        }
    </p-card>
</div>
import { AppConfigModel } from "../abstract/app-config.model";
import { Country } from "./country.model";
import { TaxDetail } from "./taxDetail.model";

export class Tax extends AppConfigModel {

    static override CLASS_NAME: string = 'Tax';

    private _country!: Country;
    private _details!: TaxDetail[];
    private _rate!: number;

    constructor(data: any) {
        super(data);
        this._details = this._details || [];
    }

    public get country(): Country {
        return this._country;
    }

    public set country(value: any) {
        this._country = new Country(value);
    }

    public get details(): TaxDetail[] {
        return this._details;
    }

    public set details(details: any[]) {
        this._details = [];
        for (let i = 0; i < details.length; i++) {
            this._details.push(new TaxDetail(details[i]));
        }
    }

    public get rate(): number {
        return this._rate;
    }

    public set rate(value: any) {
        this._rate = parseFloat(value);
    }

    override toString(): string {
        return `${this.rate} %`;
    }
}

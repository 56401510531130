<div class="w-full">
    <p-card>
        <!-- Entête -->
        <ng-template pTemplate="header">
            <div class="flex align-items-center justify-content-between p-3">
                <div class="flex align-items-center col-9">
                    <h2>{{ title }}</h2>
                </div>
                <div class="col-3">
                    <p-select [options]="accountingPeriods" [(ngModel)]="selectedPeriod"
                        (onChange)="onChangeAccountingPeriod($event)">
                        <ng-template let-period pTemplate="selectedItem">
                            @if (!!period) {
                            <span>{{period.dateFrom | date:'shortDate'}} - {{period.dateTo |
                                date:'shortDate'}}</span>
                            }
                        </ng-template>
                        <ng-template let-period pTemplate="item">
                            <span>{{period.dateFrom | date:'shortDate'}} - {{period.dateTo | date:'shortDate'}}</span>
                        </ng-template>
                    </p-select>
                </div>
            </div>
        </ng-template>

        <!-- Graph -->
        @if (showChart) {
        <div class="relative">
            <apx-chart [series]="chartSeries" [chart]="chart" [dataLabels]="chartDataLabels" [colors]="chartColors"
                [plotOptions]="chartPlotOptions" [xaxis]="chartXAxis" [yaxis]="chartYAxis"></apx-chart>
            @if (!kpi.totalIn && !kpi.totalOut) {
            <div
                class="w-full h-full absolute top-0 flex flex-column align-items-center justify-content-center blurred-background">
                <!-- Message indiquant qu'il n'y a pas de compte -->
                <div class="text-xl">{{ noDataMessage }}</div>
            </div>
            }
        </div>
        } @else {
        <div class="p-3 flex align-items-center justify-content-center" [style.height.px]="height">
            <p-progressSpinner></p-progressSpinner>
        </div>
        }

        <!-- Détail -->
        <ng-template pTemplate="footer">
            <div class="flex align-items-start justify-content-between">
                @if (income) {
                <div class="flex-grow-1 px-3">
                    <div class="uppercase text-green-500 mb-3">Revenu total</div>
                    <div class="text-2xl">{{ kpi.totalIn | currency:kpi.currency:'code' }}</div>
                </div>
                }
                @if (income && expenses) {
                <p-divider align="center" layout="vertical"></p-divider>
                }
                @if (expenses) {
                <div class="flex-grow-1 px-3">
                    <div class="uppercase text-red-500 mb-3">Total des dépenses</div>
                    <div class="text-2xl">{{ kpi.totalOut | currency:kpi.currency:'code' }}</div>
                </div>
                }
            </div>
        </ng-template>
    </p-card>
</div>
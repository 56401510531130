import { ModelBase } from '../abstract/model-base.model';
import { Account } from '../accounting/account.model';
import { Currency } from '../core/currency.model';
import { PartnerGroup } from '../partner/partner-group.model';
import { Partner } from '../partner/partner.model';
import { Product } from '../product/product.model';

export class SellingInfoRecord extends ModelBase {

    static override CLASS_NAME: string = 'SellingInfoRecord';

    private _account!: Account;
    private _currency!: Currency;
    private _partnerGroup!: PartnerGroup;
    private _partner!: Partner;
    private _price!: number;
    private _product!: Product;

    public get account(): Account {
        return this._account;
    }

    public set account(value: any) {
        this._setValue('_account', value, 'Account');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get partnerGroup(): PartnerGroup {
        return this._partnerGroup;
    }

    public set partnerGroup(value: any) {
        this._setValue('_partnerGroup', value, 'PartnerGroup');
    }

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

    public get price(): number {
        return this._price;
    }

    public set price(value: any) {
        this._setValue('_price', value, 'float');
    }

    public get product(): Product {
        return this._product;
    }

    public set product(value: any) {
        this._setValue('_product', value, 'Product');
    }

}

import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'Package';
const ENDPOINT = `/packages/`;

@Injectable({
    providedIn: 'root'
})
export class PackageService extends ServiceBase {

    protected override useCache = true;

    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

import { ModelBase } from '../abstract/model-base.model';
import { User } from '../user/user.model';
import { Client } from './client.model';
import { UserProfile } from './user-profile.model';

export class ClientUser extends ModelBase {

    static override CLASS_NAME: string = 'ClientUser';

    private _client!: Client;
    private _user!: User;
    private _userProfiles!: UserProfile[];

    public get client(): Client {
        return this._client;
    }

    public set client(value: any) {
        this._setValue('_client', value, 'Client');
    }

    public get user(): User {
        return this._user;
    }

    public set user(value: any) {
        this._setValue('_user', value, 'User');
    }

    public get userProfiles(): UserProfile[] {
        return this._userProfiles;
    }

    public set userProfiles(value: any) {
        this._setValue('_userProfiles', value, 'UserProfile');
    }

    public get userProfilesString(): string {
        return this.userProfiles.map((userProfile: UserProfile) => userProfile.profile.label).join(', ');
    }
}

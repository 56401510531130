import { ModelBase } from '../abstract/model-base.model';

export class File extends ModelBase {

    static override CLASS_NAME: string = 'File';

    private _fileName!: string;
    private _mediaBase64!: string;
    private _mediaLoaded!: boolean;
    private _mimeType!: string;

    public get fileName(): string {
        return this._fileName;
    }

    public set fileName(value: string) {
        this._setValue('_fileName', value, 'string');
    }

    public get isPdf(): boolean {
        return this.mimeType === 'application/pdf';
    }

    public get mediaBase64(): string {
        return this._mediaBase64;
    }

    public set mediaBase64(value: string) {
        this._setValue('_mediaBase64', value, 'string');
    }

    public get mediaLoaded(): boolean {
        return this._mediaLoaded;
    }

    public set mediaLoaded(value: boolean) {
        this._setValue('_mediaLoaded', value, 'boolean');
    }

    public get mimeType(): string {
        return this._mimeType;
    }

    public set mimeType(value: string) {
        this._setValue('_mimeType', value, 'string');
    }

    public get mimeTypeIcon(): string {
        let icon: string = 'fa-regular fa-file';
        if (this.mimeType.includes('image')) {
            icon = 'fa-regular fa-file-image'
        } else if (this.mimeType.includes('pdf')) {
            icon = 'fa-regular fa-file-pdf'
        } else if (this.mimeType.includes('word')) {
            icon = 'fa-regular fa-file-word'
        } else if (this.mimeType.includes('excel')) {
            icon = 'fa-regular fa-file-excel'
        } else if (this.mimeType.includes('csv')) {
            icon = 'fa-regular fa-file-csv'
        } else if (this.mimeType.includes('powerpoint')) {
            icon = 'fa-regular fa-file-powerpoint'
        } else if (this.mimeType.includes('zip')) {
            icon = 'fa-regular fa-file-zipper'
        } else if (this.mimeType.includes('audio')) {
            icon = 'fa-regular fa-file-audio'
        } else if (this.mimeType.includes('video')) {
            icon = 'fa-regular fa-file-video'
        } else if (this.mimeType.includes('text')) {
            icon = 'fa-regular fa-file-lines'
        }
        return icon;
    }
}

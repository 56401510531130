import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FocusService {
    private lastFocusedElementId: string | null = null;

    setLastFocusedElementId(id: string | null): void {
        this.lastFocusedElementId = id;
    }

    getLastFocusedElementId(): string | null {
        return this.lastFocusedElementId;
    }

    clearLastFocusedElementId(): void {
        this.lastFocusedElementId = null;
    }
}
@if (!bankAccount || !bankAccount.bank) {
<span class="p-text-secondary block mb-5">Comment souhaitez vous intégrer vos données dans Bfor ?</span>
<div class="flex flex-column align-items-start justify-content-between">
    <p-button class="w-full" styleClass="w-full flex flex-column align-item-center justify-content-betwwen" [outlined]="true"
        [raised]="true" [disabled]="busy || Environment.production" (click)="onClickPowensBank()">
        <!-- Pour l'instant en production Powens n'est pas disponible, donc on grise le bouton -->
        <div class="mb-4">
            Synchronisation automatique avec Powens
        </div>
        <div class="w-full flex align-items-center justify-content-around">
            <p-image imageClass="border-round-xl" src="assets/images/images/logo-amex.png" alt="Image" height="37" />
            <p-image imageClass="border-round-xl" src="assets/images/images/logo-bnc.png" alt="Image" height="37" />
            <p-image src="assets/images/images/logo-bnp.png" alt="Image" height="37" />
            <p-image src="assets/images/images/logo-sg.png" alt="Image" height="37" />
        </div>
        @if (busy) {
        <p-progressSpinner styleClass="w-3rem h-3rem"></p-progressSpinner>
        }
    </p-button>
    <p-button class="mt-3 w-full" styleClass="w-full h-5rem flex align-items-center justify-content-around" [outlined]="true"
        [raised]="true" [disabled]="busy" (click)="onClickNewBCI()">
        <span>Import manuel des transaction bancaires</span>
    </p-button>
</div>
} @else {
    <app-bank-account-form [bankAccount]="bankAccount" (bankAccountSaved)="onBankAccountSaved()"></app-bank-account-form>
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { Cheque } from 'src/app/@shared/models/banking/cheque.model';

const CLASS_NAME = 'Cheque';
const ENDPOINT = `/cheques/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'payments', path: 'payments/', childClass: 'SalesInvoicePayment' },
]
const ROUTES: RouteParam[] = [
    { key: 'post', path: '{id}/post/' },
]

@Injectable({
    providedIn: 'root'
})
export class ChequeService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public post(cheque: Cheque): void {
        let url = this.routes.post.replace('{id}', cheque.id.toString());
        this.update(cheque, {
            url: url
        });
    }
}

import moment from 'moment';
import { ModelBase } from '../../abstract/model-base.model';

export class Downpayment extends ModelBase {

    static override CLASS_NAME: string = 'Downpayment';

    private _issueDate!: moment.Moment;
    private _percentage!: boolean;
    private _value!: number;

    public get issueDate(): moment.Moment {
        return this._issueDate;
    }

    public set issueDate(value: moment.Moment) {
        this._setValue('_issueDate', value, 'date');
    }

    public get percentage(): boolean {
        return this._percentage;
    }

    public set percentage(value: boolean) {
        this._setValue('_percentage', value, 'boolean');
    }

    public get value(): number {
        return this._value;
    }

    public set value(value: number) {
        this._setValue('_value', value, 'float');
    }
}

import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'Organization';
const ENDPOINT = `/organizations/`;

@Injectable({
    providedIn: 'root'
})
export class OrganizationService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

<form (ngSubmit)="save()" [formGroup]="form" novalidate class="flex flex-column">

    <div class="grid">
        <div class="col-12 p-0">

            @if (!bankAccount.new) {
            <!-- Libellé de la banque -->
            <div class="flex align-items-center">
                <div class="col-4">
                    <label>Banque</label>
                </div>
                <div class="col-8">
                    <div class="p-2">{{ bankAccount.bank.name }}</div>
                </div>
            </div>

            <!-- IBAN du compte -->
            <div class="flex align-items-center">
                <div class="col-4">
                    <label>IBAN</label>
                </div>
                <div class="col-8">
                    <div class="p-2">{{ bankAccount.iban }}</div>
                </div>
            </div>
            }

            <!-- Libellé du compte -->
            <div class="flex align-items-center">
                <div class="col-4">
                    <label>Libellé *</label>
                </div>
                <div class="col-8">
                    <input type="text" pInputText formControlName="label" required />
                </div>
            </div>

            @if (bankAccount.new) {
            <!-- Banque -->
            <div class="flex align-items-center">
                <div class="col-4">
                    <label>Banque *</label>
                </div>
                <div class="col-8">
                    <p-dropdown [options]="banks" formControlName="bank" appendTo="body" dataKey="id"
                        placeholder="Sélectionnez une banque">
                        <ng-template let-bank pTemplate="selectedItem">
                            @if (!!bank) {
                            <span>{{bank.commercialName}}</span>
                            }
                        </ng-template>
                        <ng-template let-bank pTemplate="item">
                            <span>{{bank.commercialName}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>

            <!--  IBAN -->
            <div class="flex align-items-center">
                <div class="col-4">
                    <label>IBAN</label>
                </div>
                <div class="col-8">
                    <input type="text" pInputText formControlName="iban" />
                </div>
            </div>
            }

            <!-- Date de reprise -->
            <div class="flex align-items-center">
                <div class="col-4 flex align-items-center justify-content-between">
                    <label>Date de reprise *</label>
                    <p-button icon="pi pi-info-circle" type="button" [rounded]="true" [text]="true"
                        (click)="op.toggle($event)"></p-button>
                </div>
                <div class="col-8">
                    <p-calendar formControlName="initialBalanceDate" [showIcon]="true" appendTo="body"
                        [required]="true"></p-calendar>
                </div>
            </div>

            @if (!bankAccount.powens) {
            <!-- Solde -->
            <div class="flex align-items-center">
                <div class="col-4">
                    <label>Solde initial</label>
                </div>
                <div class="col-8">
                    <div class="grid align-items-center">
                        <!-- Montant -->
                        <div class="col-9">
                            <p-inputNumber formControlName="initialBalance" mode="decimal"
                                [minFractionDigits]="currency?.decimalPlaces || 0"></p-inputNumber>
                        </div>
                        <!-- Devise -->
                        <div class="col-3">
                            @if (bankAccount.new) {
                            <p-dropdown [options]="currencies" formControlName="currency" appendTo="body" dataKey="code"
                                placeholder="Sélectionnez une devise" [filter]="true" filterBy="code"
                                (onChange)="onChangeCurrency($event)">
                                <ng-template let-currency pTemplate="selectedItem">
                                    <span *ngIf="!!currency">{{currency.code}}</span>
                                </ng-template>
                                <ng-template let-currency pTemplate="item">
                                    <span>{{currency.code}}</span>
                                </ng-template>
                            </p-dropdown>
                            } @else {
                            <label>{{ bankAccount.currency.code }}</label>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }

            <!-- Afficher sur le dashboard ? -->
            <div class="flex align-items-center">
                <div class="col-4">
                    <label>Afficher sur le dashboard</label>
                </div>
                <div class="col-8">
                    <p-inputSwitch formControlName="dashboard"></p-inputSwitch>
                </div>
            </div>

            @if (bankAccount.new) {
            <p-fieldset legend="Paramètres avancés" [toggleable]="true" [collapsed]="true" class="mb-3">

                <!-- Compte comptable -->
                <div class="flex align-items-center">
                    <div class="col-4">
                        <label>Compte comptable</label>
                    </div>
                    <div class="col-4">
                        <p-dropdown [options]="accounts" formControlName="account" appendTo="body" dataKey="id"
                            placeholder="Sélectionnez un compte comptable" [showClear]="true">
                            <ng-template let-account pTemplate="selectedItem">
                                @if (!!account) {
                                <span>{{account.toString()}}</span>
                                }
                            </ng-template>
                            <ng-template let-account pTemplate="item">
                                <span>{{account.toString()}}</span>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="col-1">
                        <p-divider layout="vertical"><b>OU</b></p-divider>
                    </div>
                    <div class="col-3">
                        <input type="text" pInputText formControlName="accountNumber"
                            placeholder="Numéro du compte comptable" />
                    </div>
                </div>


                <!-- Journal comptable -->
                <div class="flex align-items-center">
                    <div class="col-4">
                        <label>Code journal</label>
                    </div>
                    <div class="col-8">
                        <input type="text" pInputText formControlName="journalCode"
                            placeholder="Code du journal comptable" maxlength="3" />
                    </div>
                </div>

            </p-fieldset>

            } @else if (showAccountingData) {
            <p-divider />
            <!-- Compte comptable -->
            <div class="flex align-items-center">
                <div class="col-4">
                    <label>Compte comptable</label>
                </div>
                <div class="col-8">
                    <div class="p-2">{{ bankAccount.account.toString() }}</div>
                </div>
            </div>
            <!-- Journal -->
            <div class="flex align-items-center">
                <div class="col-4">
                    <label>Journal</label>
                </div>
                <div class="col-8">
                    <div class="p-2">{{ bankAccount.journal.toString() }}</div>
                </div>
            </div>
            }

            <div class="col-12 mt-3 flex {{bankAccount.new ? 'justify-content-end' : 'justify-content-between'}}">
                @if (!bankAccount.new) {
                <p-button type="button" label="Informations comptables" [outlined]="true" [rounded]="true"
                    (onClick)="showAccountingData = !showAccountingData"></p-button>
                }
                <p-button type="submit" label="Sauvegarder" [rounded]="true" [disabled]="form.invalid || bankAccount.busy"></p-button>
            </div>

        </div>
    </div>
</form>

<p-overlayPanel #op>
    <div class="w-25rem">
        <p>
            La date de reprise est la date à partir de laquelle les transactions sont prises en compte. Les transactions
            antérieures à cette date ne seront pas importées.
        </p>
        <p>
            Si vous avez déjà des transactions dans votre compte bancaire, et que vous modifiez cette date, celles
            antérieures à cette date seront supprimées.
        </p>
    </div>
</p-overlayPanel>
import { ModelBase } from "../abstract/model-base.model";
import { Currency } from "./currency.model";

export class Country extends ModelBase {

    static override CLASS_NAME: string = 'Country';

    private _alpha2!: string;
    private _alpha3!: string;
    private _availableSoon!: boolean;
    private _currency!: Currency;
    private _flag!: string;
    private _name!: string;

    public get alpha2(): string {
        return this._alpha2;
    }

    public set alpha2(value: any) {
        this._setValue('_alpha2', value, 'string');
    }

    public get alpha3(): string {
        return this._alpha3;
    }

    public set alpha3(value: any) {
        this._setValue('_alpha3', value, 'string');
    }

    public get availableSoon(): boolean {
        return this._availableSoon;
    }

    public set availableSoon(value: any) {
        this._setValue('_availableSoon', value, 'boolean');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get flag(): string {
        return this._flag;
    }

    public set flag(value: any) {
        this._setValue('_flag', value, 'string');
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: any) {
        this._setValue('_name', value, 'string');
    }

}
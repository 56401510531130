import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Logger } from '../services/logger/logger.service';
import { CredentialsService } from '../authentication/services/credentials.service';

const LOGGER = new Logger('ErrorHandlerInterceptor');

export function apiAuthenticationInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    // Récupérer le token d'authentification à partir du credentialsService ou d'une autre source
    let credentialsService = inject(CredentialsService);

    // Ajouter le header
    if (!!credentialsService.credentials?.access) {
        request = request.clone({ headers: request.headers.append('Authorization', `Bearer ${credentialsService.credentials.access}`) });
    }
    return next(request);
}

export function apiClientInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    let savedClientId = sessionStorage.getItem("Client") || localStorage.getItem("Client");
    if (!!savedClientId) {
        request = request.clone({ headers: request.headers.append('Context-client', savedClientId) });
    }
    return next(request);
}

export function apiContentTypeInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    // Si l'entête content-type n'est pas défini, alors on le rajoute
    if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.append('Content-Type', 'application/json') });
    }
    return next(request);
}

export function apiPrefixInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    if (!/^(http|https):/i.test(request.url)) {
        request = request.clone({ url: environment.serverUrl + request.url });
    }
    return next(request);
}

export function apiDeviceIdInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    // Récupérer l'identifiant de l'appareil (device) à partir du localStorage ou d'une autre source
    let deviceId = localStorage.getItem("device_id");

    // Ajouter le header 'x-device-id' si l'identifiant de l'appareil est présent
    if (!!deviceId) {
        request = request.clone({ headers: request.headers.append('x-device-id', deviceId) });
    }
    return next(request);
}

export function errorHandlerInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    return next(request).pipe(catchError(error => errorHandler(error)));

    function errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
        if (!environment.production) {
            // Do something with the error
            LOGGER.error('Request error', response);
        }
        throw response;
    }
}
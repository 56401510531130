import { Injectable } from '@angular/core';

// Services
import { NavigationParam, ServiceBase } from '../service-base';

const CLASS_NAME = 'BankAccountLineType';
const ENDPOINT = `/bank-account-line-types/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'accounts', path: 'accounts/', childClass: 'Account' },
]

@Injectable({
    providedIn: 'root'
})
export class BankAccountLineTypeService extends ServiceBase {

    protected override useCache = true;

    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
    }
}

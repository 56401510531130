import { ApplicationConfig, LOCALE_ID, DEFAULT_CURRENCY_CODE, provideAppInitializer, inject, importProvidersFrom } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Observable } from 'rxjs';
import { provideFirebaseApp, initializeApp as initializeFirebaseApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { providePrimeNG } from 'primeng/config';
import fr  from "primelocale/fr.json"
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

import { apiAuthenticationInterceptor, apiClientInterceptor, apiContentTypeInterceptor, apiPrefixInterceptor, apiDeviceIdInterceptor, errorHandlerInterceptor } from '../app/@core/http/interceptors';

import { AppRoutingModule } from '../app/app-routing.module';
import { HomeModule } from '../app/home/home.module';

import { AppService } from '../app/@core/services/app.service';
import { UserService } from '../app/@core/services/user/user.service';

import { environment } from '../environments/environment';
import { Logger } from '../app/@core/services/logger/logger.service';

import BiiforBluePreset from 'src/assets/themes/biifor/biifor-blue';

const LOGGER = new Logger('Main');

// Import des locales
registerLocaleData(localeFr);

// Fonction d'initialisation
function initializeApp(userService: UserService): () => Observable<boolean> {
    LOGGER.debug('Initialisation de l\'application');
    return () => userService.loadUserData$();
}

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(BrowserModule, ConfirmDialogModule, ToastModule, AppRoutingModule, HomeModule),
        provideHttpClient(withInterceptors([
            apiAuthenticationInterceptor,
            apiClientInterceptor,
            apiContentTypeInterceptor,
            apiPrefixInterceptor,
            apiDeviceIdInterceptor,
            errorHandlerInterceptor
        ])),
        provideAnimations(),
        provideAnimationsAsync(),
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'XPF' },
        provideFirebaseApp(() => initializeFirebaseApp(environment.firebaseConfig)),
        provideMessaging(() => getMessaging()),
        AppService,
        UserService,
        provideAppInitializer(() => {
            const initializerFn = (initializeApp)(inject(UserService));
            return initializerFn();
        }),
        providePrimeNG({
            ripple: true,
            theme: {
                preset: BiiforBluePreset,
                options: {
                    cssLayer: {
                        name: 'primeng',
                        order: 'app-styles, primeng, biifor'
                    },
                    darkModeSelector: '.biifor-dark',
                    prefix: ''
                }
            },
            translation: fr.fr
        }),
    ],
};
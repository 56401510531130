import { InvoiceItemBase } from '../../invoicing/abstract/invoice-item-base.model';

export class SalesInvoiceItemBase extends InvoiceItemBase {

    static override CLASS_NAME: string = 'SalesInvoiceItemBase';

    private _ecoTaxIncluded!: boolean;
    private _ecoTaxGrossAmount!: number;
    private _ecoTaxNetAmount!: number;

    public get ecoTaxIncluded(): boolean {
        return this._ecoTaxIncluded;
    }

    public set ecoTaxIncluded(value: any) {
        this._setValue('_ecoTaxIncluded', value, 'boolean');
    }

    public get ecoTaxGrossAmount(): number {
        return this._ecoTaxGrossAmount;
    }

    public set ecoTaxGrossAmount(value: any) {
        this._setValue('_ecoTaxGrossAmount', value, 'float');
    }

    public get ecoTaxNetAmount(): number {
        return this._ecoTaxNetAmount;
    }

    public set ecoTaxNetAmount(value: any) {
        this._setValue('_ecoTaxNetAmount', value, 'float');
    }

}

import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

const BiiforBluePreset = definePreset(Aura, {
    primitive: {
        blue: {
            50: '#F0F6FF',
            100: '#D6E5FF',
            200: '#AFCFFF',
            300: '#80B3FF',
            400: '#4C89FF', // Logo - Bleu secondaire
            500: '#0057FF', // Logo - Bleu principal
            600: '#004CDB',
            700: '#003FB8',
            800: '#002E8A',
            900: '#001E5C',
            950: '#002171'
        },
        green: {
            50: '#E6F9F1',
            100: '#B3F0D9',
            200: '#80E0C1',
            300: '#4DD7A9',
            400: '#25C685',
            500: '#1BAF76',
            600: '#1A9F68',
            700: '#168759',
            800: '#136A4B',
            900: '#0F523C'
        },
        yellow: {
            50: '#FFF4D6',
            100: '#FFE199',
            200: '#FFD15C',
            300: '#FFCC39',
            400: '#FFBC25',
            500: '#FFA900',
            600: '#E59400',
            700: '#B67F00',
            800: '#8F6A00',
            900: '#705500'
        },
        red: {
            50: '#FFE6E6',
            100: '#FFB7B9',
            200: '#FFA8AA',
            300: '#FF8C8E',
            400: '#FF6163',
            500: '#FF4649',
            600: '#E63946',
            700: '#D03A3A',
            800: '#B32D2D',
            900: '#9B1F1F'
        },
        purple: {
            50: '#F3E9FF',
            100: '#E0C2FF',
            200: '#C699FF',
            300: '#9F70FF',
            400: '#8A59FF',
            500: '#7C4DFF',
            600: '#6939E6',
            700: '#582BCC',
            800: '#481DAE',
            900: '#38128F'
        },
        cyan: {
            50: '#E3F8FF',
            100: '#B8EEFF',
            200: '#80E4FF',
            300: '#5EDDFF',
            400: '#00D4FF',
            500: '#00B8E0',
            600: '#03C4EE',
            700: '#02AACD',
            800: '#016983',
            900: '#005469'
        },
        pink: {
            50: '#FFDEFA',
            100: '#FFC0F5',
            200: '#FF93EE',
            300: '#FF75E9',
            400: '#FF4FE3',
            500: '#E040C7',
            600: '#C033A9',
            700: '#A0268C',
            800: '#801C6E',
            900: '#601251'
        },
        orange: {
            50: '#FFEDDA',
            100: '#FFD1A1',
            200: '#FFB066',
            300: '#FF9B4C',
            400: '#FF8A34',
            500: '#FF7C2D',
            600: '#E57227',
            700: '#CC6722',
            800: '#B25C1D',
            900: '#9A5118'
        }
    },
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}',
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}',
            900: '{blue.900}',
            950: '{blue.950}'
        }
    },
    components: {
        badge: {
            fontSize: '0.8rem',
        },
        breadcrumb: {
            background: 'inherit',
            padding: '1.5rem 2rem .5rem 2rem',
        },
        datatable: {
            column: {
                title: {
                    font: {
                        weight: '400'
                    }
                }
            }
        },
        dataview: {
            border: {
                radius: '{border.radius.xl}'
            },
            content: {
                border: {
                    radius: '{border.radius.xl}'
                },
            }
        },
        fieldset: {
            legend: {
                border: {
                    color: '{fieldset.border.color}'
                }
            }
        },
        tabs: {
            tablist: {
                background: 'transparent',
            },
            tab: {
                background: 'transparent',
                font: {
                    weight: '400'
                }
            },
            tabpanel: {
                background: 'transparent',
            },
        },
        tag: {
            colorScheme: {
                light: {
                    warn: {
                        background: '{yellow.100}',
                        color: '{yellow.700}',
                    }
                }
            }
        }
    }
});

export default BiiforBluePreset;
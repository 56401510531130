import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { ImageModule } from 'primeng/image';

// Composants
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';

// Modèles
import { Invite } from 'src/app/@shared/models/client/invite.model';

@Component({
    selector: 'app-invites-list',
    imports: [
        AvatarModule,
        ButtonModule,
        DividerModule,
        ImageModule
    ],
    templateUrl: './invites-list.component.html',
    styleUrl: './invites-list.component.scss'
})
export class InvitesListComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    @Output() inviteAccepted: EventEmitter<Invite> = new EventEmitter<Invite>();
    @Output() inviteDeclined: EventEmitter<Invite> = new EventEmitter<Invite>();

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _invites: Invite[] = [];
    public get invites(): Invite[] {
        return this._invites;
    }
    @Input()
    public set invites(value: Invite[]) {
        let emptyArray: Invite[] = [];
        this._invites = emptyArray.concat(value);
    }

    /*----------------------------------------------------------------------------------------
     * Accesseurs
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public onClickAcceptInvite(invite: Invite): void {
        this._inviteAccept(invite);
    }

    public onClickDeclineInvite(invite: Invite): void {
        this._inviteDecline(invite);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _inviteAccept(invite: Invite): void {
        this.inviteService.accept$(invite).subscribe(() => {
            this.userService.loadClients();
            this.inviteAccepted.emit(invite);
        });
    }

    private _inviteDecline(invite: Invite): void {
        this.inviteService.refuse$(invite).subscribe(() => {
            this.inviteDeclined.emit(invite);
        });
    }
}

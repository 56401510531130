import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

// Modèles


const CLASS_NAME = 'SalesInvoicePayment';
const ENDPOINT = `/sales-invoice-payments/`;

@Injectable({
    providedIn: 'root'
})
export class SalesInvoicePaymentService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

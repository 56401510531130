import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";
import { Account } from "../accounting/account.model";
import { Bank } from "./bank.model";
import { Currency } from "../core/currency.model";

export class Loan extends ModelBase {

    static override CLASS_NAME: string = 'Loan';

    private _bank!: Bank;
    private _capital!: number;
    private _capitalAccount!: Account;
    private _currency!: Currency;
    private _duration!: number;
    private _insuranceAccount!: Account;
    private _insuranceRate!: number;
    private _label!: string;
    private _loanDate!: moment.Moment;
    private _rate!: number;
    private _reference!: string;

    public get bank(): Bank {
        return this._bank;
    }

    public set bank(value: any) {
        this._setValue('_bank', value, 'Bank');
    }

    public get capital(): number {
        return this._capital;
    }

    public set capital(value: any) {
        this._setValue('_capital', value, 'float');
    }

    public get capitalAccount(): Account {
        return this._capitalAccount;
    }

    public set capitalAccount(value: any) {
        this._setValue('_capitalAccount', value, 'Account');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get duration(): number {
        return this._duration;
    }

    public set duration(value: any) {
        this._setValue('_duration', value, 'integer');
    }

    public get insuranceAccount(): Account {
        return this._insuranceAccount;
    }

    public set insuranceAccount(value: any) {
        this._setValue('_insuranceAccount', value, 'Account');
    }

    public get insuranceRate(): number {
        return this._insuranceRate;
    }

    public set insuranceRate(value: any) {
        this._setValue('_insuranceRate', value, 'float');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get loanDate(): moment.Moment {
        return this._loanDate;
    }

    public set loanDate(value: any) {
        this._setValue('_loanDate', value, 'date');
    }

    public get rate(): number {
        return this._rate;
    }

    public set rate(value: any) {
        this._setValue('_rate', value, 'float');
    }

    public get reference(): string {
        return this._reference;
    }

    public set reference(value: any) {
        this._setValue('_reference', value, 'string');
    }

}
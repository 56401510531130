import { ModelBase } from '../abstract/model-base.model';
import { Account } from '../accounting/account.model';

export class CardType extends ModelBase {

    static override CLASS_NAME: string = 'CardType';

    private _account!: Account;
    private _label!: string;

    public get account(): Account {
        return this._account;
    }

    public set account(value: any) {
        this._setValue('_account', value, 'Account');
    }

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }
}

<div class="h-full flex flex-column align-items-center justify-content-around">

  @if (appService.isPortrait() || !appService.isPhone) {
    <div>
      <img class="logo" src="{{ appService.logoSrc }}" />
    </div>
  }

  <div>
    {{ message }}
  </div>

</div>

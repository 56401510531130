import moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { InvoiceNumbering } from './invoice-numbering.model';
import { PaymentMethod } from '../core/payment-method.model';
import { PaymentTerm } from '../invoicing/payment-term.model';

export class SalesSettings extends ModelBase {

    static override CLASS_NAME: string = 'SalesSettings';

    private _bankDetails!: string;
    private _chequeLabel!: string;
    private _creditNoteEmailBody!: string;
    private _creditNoteEmailSubject!: string;
    private _creditNoteExists!: boolean;
    private _creditNoteFirstNumber!: number;
    private _creditNoteGeneralConditions!: string;
    private _creditNoteNumbering!: InvoiceNumbering;
    private _creditNoteNumberPrefix!: string;
    private _creditNoteDedicatedNumbering!: boolean;
    private _documentsFooter!: string;
    private _documentsHeader!: string;
    private _invoiceEmailBody!: string;
    private _invoiceEmailSubject!: string;
    private _invoiceExists!: boolean;
    private _invoiceFirstNumber!: number;
    private _invoiceGeneralConditions!: string;
    private _invoiceNumbering!: InvoiceNumbering;
    private _invoiceNumberPrefix!: string;
    private _invoicePaymentTerm!: PaymentTerm;
    private _invoiceReminderEmailBody!: string;
    private _invoiceReminderEmailSubject!: string;
    private _invoicingDateFrom!: moment.Moment;
    private _paymentMethods!: PaymentMethod[];
    private _quotationAcceptanceConditions!: string;
    private _quotationEmailBody!: string;
    private _quotationEmailSubject!: string;
    private _quotationExists!: boolean;
    private _quotationFirstNumber!: number;
    private _quotationNumbering!: InvoiceNumbering;
    private _quotationNumberPrefix!: string;
    private _quotationReminderEmailBody!: string;
    private _quotationReminderEmailSubject!: string;
    private _quotationValidityPeriod!: number;

    public get bankDetails(): string {
        return this._bankDetails;
    }

    public set bankDetails(value: any) {
        this._setValue('_bankDetails', value, 'string');
    }

    public get chequeLabel(): string {
        return this._chequeLabel;
    }

    public set chequeLabel(value: any) {
        this._setValue('_chequeLabel', value, 'string');
    }

    public get creditNoteEmailBody(): string {
        return this._creditNoteEmailBody;
    }

    public set creditNoteEmailBody(value: any) {
        this._setValue('_creditNoteEmailBody', value, 'string');
    }

    public get creditNoteEmailSubject(): string {
        return this._creditNoteEmailSubject;
    }

    public set creditNoteEmailSubject(value: any) {
        this._setValue('_creditNoteEmailSubject', value, 'string');
    }

    public get creditNoteExists(): boolean {
        return this._creditNoteExists;
    }

    public set creditNoteExists(value: any) {
        this._setValue('_creditNoteExists', value, 'boolean');
    }

    public get creditNoteFirstNumber(): number {
        return this._creditNoteFirstNumber;
    }

    public set creditNoteFirstNumber(value: any) {
        this._setValue('_creditNoteFirstNumber', value, 'integer');
    }

    public get creditNoteGeneralConditions(): string {
        return this._creditNoteGeneralConditions;
    }

    public set creditNoteGeneralConditions(value: any) {
        this._setValue('_creditNoteGeneralConditions', value, 'string');
    }

    public get creditNoteNumbering(): InvoiceNumbering {
        return this._creditNoteNumbering;
    }

    public set creditNoteNumbering(value: any) {
        this._setValue('_creditNoteNumbering', value, 'InvoiceNumbering');
    }

    public get creditNoteNumberPrefix(): string {
        return this._creditNoteNumberPrefix;
    }

    public set creditNoteNumberPrefix(value: any) {
        this._setValue('_creditNoteNumberPrefix', value, 'string');
    }

    public get creditNoteDedicatedNumbering(): boolean {
        return this._creditNoteDedicatedNumbering;
    }

    public set creditNoteDedicatedNumbering(value: any) {
        this._setValue('_creditNoteDedicatedNumbering', value, 'boolean');
    }

    public get documentsFooter(): string {
        return this._documentsFooter;
    }

    public set documentsFooter(value: any) {
        this._setValue('_documentsFooter', value, 'string');
    }

    public get documentsHeader(): string {
        return this._documentsHeader;
    }

    public set documentsHeader(value: any) {
        this._setValue('_documentsHeader', value, 'string');
    }

    public get invoiceEmailBody(): string {
        return this._invoiceEmailBody;
    }

    public set invoiceEmailBody(value: any) {
        this._setValue('_invoiceEmailBody', value, 'string');
    }

    public get invoiceEmailSubject(): string {
        return this._invoiceEmailSubject;
    }

    public set invoiceEmailSubject(value: any) {
        this._setValue('_invoiceEmailSubject', value, 'string');
    }

    public get invoiceExists(): boolean {
        return this._invoiceExists;
    }

    public set invoiceExists(value: any) {
        this._setValue('_invoiceExists', value, 'boolean');
    }

    public get invoiceFirstNumber(): number {
        return this._invoiceFirstNumber;
    }

    public set invoiceFirstNumber(value: any) {
        this._setValue('_invoiceFirstNumber', value, 'integer');
    }

    public get invoiceGeneralConditions(): string {
        return this._invoiceGeneralConditions;
    }

    public set invoiceGeneralConditions(value: any) {
        this._setValue('_invoiceGeneralConditions', value, 'string');
    }

    public get invoiceNumbering(): InvoiceNumbering {
        return this._invoiceNumbering;
    }

    public set invoiceNumbering(value: any) {
        this._setValue('_invoiceNumbering', value, 'InvoiceNumbering');
    }

    public get invoiceNumberPrefix(): string {
        return this._invoiceNumberPrefix;
    }

    public set invoiceNumberPrefix(value: any) {
        this._setValue('_invoiceNumberPrefix', value, 'string');
    }

    public get invoicePaymentTerm(): PaymentTerm {
        return this._invoicePaymentTerm;
    }

    public set invoicePaymentTerm(value: any) {
        this._setValue('_invoicePaymentTerm', value, 'PaymentTerm');
    }

    public get invoiceReminderEmailBody(): string {
        return this._invoiceReminderEmailBody;
    }

    public set invoiceReminderEmailBody(value: any) {
        this._setValue('_invoiceReminderEmailBody', value, 'string');
    }

    public get invoiceReminderEmailSubject(): string {
        return this._invoiceReminderEmailSubject;
    }

    public set invoiceReminderEmailSubject(value: any) {
        this._setValue('_invoiceReminderEmailSubject', value, 'string');
    }

    public get invoicingDateFrom(): moment.Moment {
        return this._invoicingDateFrom;
    }

    public set invoicingDateFrom(value: any) {
        this._setValue('_invoicingDateFrom', value, 'date');
    }

    public get paymentMethods(): PaymentMethod[] {
        return this._paymentMethods;
    }

    public set paymentMethods(value: any) {
        this._setValue('_paymentMethods', value, 'PaymentMethod');
    }

    public get quotationAcceptanceConditions(): string {
        return this._quotationAcceptanceConditions;
    }

    public set quotationAcceptanceConditions(value: any) {
        this._setValue('_quotationAcceptanceConditions', value, 'string');
    }

    public get quotationEmailBody(): string {
        return this._quotationEmailBody;
    }

    public set quotationEmailBody(value: any) {
        this._setValue('_quotationEmailBody', value, 'string');
    }

    public get quotationEmailSubject(): string {
        return this._quotationEmailSubject;
    }

    public set quotationEmailSubject(value: any) {
        this._setValue('_quotationEmailSubject', value, 'string');
    }

    public get quotationExists(): boolean {
        return this._quotationExists;
    }

    public set quotationExists(value: any) {
        this._setValue('_quotationExists', value, 'boolean');
    }

    public get quotationFirstNumber(): number {
        return this._quotationFirstNumber;
    }

    public set quotationFirstNumber(value: any) {
        this._setValue('_quotationFirstNumber', value, 'integer');
    }

    public get quotationNumbering(): InvoiceNumbering {
        return this._quotationNumbering;
    }

    public set quotationNumbering(value: any) {
        this._setValue('_quotationNumbering', value, 'InvoiceNumbering');
    }

    public get quotationNumberPrefix(): string {
        return this._quotationNumberPrefix;
    }

    public set quotationNumberPrefix(value: any) {
        this._setValue('_quotationNumberPrefix', value, 'string');
    }

    public get quotationReminderEmailBody(): string {
        return this._quotationReminderEmailBody;
    }

    public set quotationReminderEmailBody(value: any) {
        this._setValue('_quotationReminderEmailBody', value, 'string');
    }

    public get quotationReminderEmailSubject(): string {
        return this._quotationReminderEmailSubject;
    }

    public set quotationReminderEmailSubject(value: any) {
        this._setValue('_quotationReminderEmailSubject', value, 'string');
    }

    public get quotationValidityPeriod(): number {
        return this._quotationValidityPeriod;
    }


    public set quotationValidityPeriod(value: any) {
        this._setValue('_quotationValidityPeriod', value, 'integer');
    }

}

import { Component, ElementRef, inject, Input } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectChangeEvent, SelectModule } from 'primeng/select';
import { $dt } from '@primeng/themes';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexPlotOptions, ApexXAxis, ApexYAxis, NgApexchartsModule, ApexStroke, ApexTooltip } from 'ng-apexcharts';

// Composants
import { BaseComponent } from '../../abstract/base.component';

// Modèles
import { AccountingPeriod } from 'src/app/@shared/models/accounting/accounting-period.model';
import { KpiDso } from 'src/app/@shared/models/kpi/dso.model';
import { Partner } from 'src/app/@shared/models/partner/partner.model';

// Utils
import { Sorter } from 'src/app/@shared/utilities/models/sorter';
import { Filter } from 'src/app/@shared/utilities/models/filter';

@Component({
    selector: 'app-dso-widget',
    imports: [
        CommonModule,
        FormsModule,
        NgApexchartsModule,
        CardModule,
        ProgressSpinnerModule,
        SelectModule,
    ],
    templateUrl: './dso-widget.component.html',
    styleUrl: './dso-widget.component.scss'
})
export class DsoWidgetComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    public selectedPeriod: AccountingPeriod | undefined;
    public showChart: boolean = false;

    private _height: string = '350';
    public get height(): string {
        return this._height;
    }
    @Input()
    public set height(height: string) {
        this._height = height;
        this.chart.height = height;
    }

    // Gestions de l'intersection observer (pour le lazy loading)
    private _isVisible = false;
    private _elementRef = inject(ElementRef);
    private _observer: IntersectionObserver | null = null;

    /*----------------------------------------------------------------------------------------
     * Gestion du graph
     ----------------------------------------------------------------------------------------*/

    public chart: ApexChart = {
        type: 'line',
        height: '350',
        zoom: {
            enabled: false
        },
    };
    public chartSeries: ApexAxisChartSeries = [
        {
            type: 'line',
            name: "DMP après échéance",
            color: $dt('green.400').value,
            data: [],
        },
        {
            type: 'line',
            name: "DMP des créances",
            color: $dt('red.400').value,
            data: []
        },
        {
            type: 'bar',
            name: "Montant des créances",
            color: $dt('blue.400').value,
            data: []
        }
    ];
    public chartDataLabels: ApexDataLabels = {
        enabled: false
    }
    public chartPlotOptions: ApexPlotOptions = {
        bar: {
            columnWidth: "55%",
            borderRadius: 6,
            borderRadiusApplication: 'end',
        }
    }
    public chartStroke: ApexStroke = {
        curve: [
            'smooth',
            'smooth',
            'monotoneCubic',
        ],
        width: [4, 4, 0]
    }
    public chartTooltip: ApexTooltip = {
        y: [
            {
                formatter: (this._formatYDays).bind(this)
            },
            {
                formatter: (this._formatYDays).bind(this)

            },
            {
                formatter: (this._formatYCurrency).bind(this)
            }
        ]
    }
    public chartXAxis: ApexXAxis = {
        categories: []
    }
    public chartYAxis: ApexYAxis[] = [
        {
            seriesName: [
                'DMP après échéance',
                'DMP des créances'
            ],
            title: {
                text: 'Délai'
            },
            labels: {
                formatter: (this._formatYDays).bind(this)
            }
        },
        {
            opposite: true,
            seriesName: 'Montant des créances',
            title: {
                text: 'Montant'
            },
            labels: {
                formatter: (this._formatYCurrency).bind(this)
            }
        }
    ]

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _kpi: KpiDso = new KpiDso({
        currency: '',
        months: []
    });
    public get kpi(): KpiDso {
        return this._kpi;
    }
    public set kpi(kpi: KpiDso) {
        this._kpi = kpi;
        this._buildChartData();
    }

    private _accountingPeriods: AccountingPeriod[] = [];
    public get accountingPeriods(): AccountingPeriod[] {
        return this._accountingPeriods;
    }
    public set accountingPeriods(accountingPeriods: AccountingPeriod[]) {
        let emptyArray: AccountingPeriod[] = []
        this._accountingPeriods = emptyArray.concat(accountingPeriods);
    }

    private _partner: Partner | null = null;
    public get partner(): Partner | null {
        return this._partner;
    }
    @Input()
    public set partner(partner: Partner) {
        this._partner = partner;
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    ngAfterViewInit() {
        // Configuration de l'observer
        const options = {
            root: null, // observera le viewport
            rootMargin: '0px',
            threshold: 0.1 // déclenché quand au moins 10% est visible
        };

        this._observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                this._isVisible = entry.isIntersecting;

                // Si le composant devient visible, vous pouvez initialiser/rafraîchir le graphique
                if (this._isVisible && !!this.selectedPeriod) {
                    this._loadKpi(this.selectedPeriod);
                }
            });
        }, options);

        // Observer l'élément hôte du composant
        this._observer.observe(this._elementRef.nativeElement);
    }

    public override ngOnDestroy() {
        super.ngOnDestroy();
        if (this._observer) {
            this._observer.disconnect();
        }
    }

    public onChangeAccountingPeriod(event: SelectChangeEvent) {
        this._loadKpi(event.value);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override subscribeToObservables(): void {
        this.subscriptions.add(
            this.appService.clientSelected$.subscribe(
                client => {
                    if (!!client) {
                        this._loadAccountingPeriods()
                    }
                }
            )
        );

        // Changement de l'ouverture du menu
        this.subscriptions.add(
            this.appService.menuState$.subscribe(
                () => {
                    this._buildChartData();
                }
            )
        );
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _buildChartData() {
        let outstandingReceivables: number[] = [];
        let preciseDso: number[] = [];
        let simplifiedDso: number[] = [];
        let categories: string[] = [];
        for (let i = 0; i < this.kpi.months.length; i++) {
            const month = this.kpi.months[i];
            preciseDso.push(month.preciseDso);
            simplifiedDso.push(month.simplifiedDso);
            outstandingReceivables.push(month.outstandingReceivables);
            categories.push(month.labelShort);
        }

        this.chartSeries[0].data = preciseDso;
        this.chartSeries[1].data = simplifiedDso;
        this.chartSeries[2].data = outstandingReceivables;

        this.chartXAxis.categories = categories;

        let emptyArray: ApexAxisChartSeries = [];
        this.chartSeries = emptyArray.concat(this.chartSeries);
        if (preciseDso.length > 0) {
            this.showChart = true;
        }
    }

    private _formatYCurrency(val: any) {
        let pipe = new CurrencyPipe('fr');
        return pipe.transform(val, this.kpi.currency, 'code')!;
    }

    /**
     * Arrondi de la valeur à l'entier le plus proche
     * @param val Nombre de jours à arrondir
     */
    private _formatYDays(val: any) {
        return `${Math.round(val)}j`;
    }

    private _loadAccountingPeriods() {
        this.accountingPeriodService.getList$({
            sorters: [
                new Sorter({
                    property: 'dateFrom',
                    descending: true
                })
            ]
        }).subscribe(accountingPeriods => {
            this.accountingPeriods = accountingPeriods;
            if (!!accountingPeriods[0]) {
                this._loadKpi(accountingPeriods[0]);
                // Sélectionne la première période
                this.selectedPeriod = accountingPeriods[0];
            }
        });
    }

    private _loadKpi(accountingPeriod: AccountingPeriod) {
        if (!this._isVisible) return;

        this.showChart = false;
        let filter = new Filter({
            filters: [
                new Filter({
                    property: 'dateFrom',
                    value: accountingPeriod.dateFrom,
                }),
                new Filter({
                    property: 'dateTo',
                    value: accountingPeriod.dateTo,
                })
            ]
        });

        // Si le partenaire est défini, on charge les données pour ce partenaire
        if (!!this.partner) {
            filter.filters.push(new Filter({
                property: 'partner',
                value: this.partner.id
            }));
        }

        this.kpiService.getRelated$(this.appService.selectedClient, 'dso', {
            filters: [filter]
        }).subscribe(
            kpi => {
                this.kpi = kpi;
            }
        );
    }

}

import moment from 'moment';
import { SalesInvoiceBase } from '../abstract/sales-invoice-base.model';
import { QuotationItem } from './quotation-item.model';
import { QuotationTax } from './quotation-tax.model';
import { SalesInvoice } from '../invoice/sales-invoice.model';
import tagAccents from 'src/assets/themes/biifor/components/tag';

export class Quotation extends SalesInvoiceBase {

    static override CLASS_NAME: string = 'Quotation';

    private _acceptanceConditions!: string;
    private _amountBilled!: number;
    private _amountBilledOrg!: number;
    private _validityDate!: moment.Moment;
    private _validationDate!: moment.Moment;

    private _items!: QuotationItem[];
    private _taxes!: QuotationTax[];

    private _invoices!: SalesInvoice[];

    constructor(data: any) {
        super(data);
        this._invoices = this._invoices || [];
        this._items = this._items || [];
        this._taxes = this._taxes || [];
    }

    public static override statusSeverity(status: string): 'success' | 'secondary' | 'info' | 'warn' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (status === 'accepted'):
                return undefined;
            case (status === 'billed'):
                return 'success';
            case (status === 'cancelled'):
                return 'secondary';
            case (status === 'draft'):
                return 'secondary';
            case (status === 'partially_billed'):
                return 'success';
            case (status === 'refused'):
                return 'danger';
            case (status === 'sent'):
                return undefined;
            case (status === 'validated'):
                return 'warn';
            default:
                return undefined;
        }
    }

    public static override statusLabel(status: string): string {
        switch (true) {
            case (status === 'accepted'):
                return 'Accepté';
            case (status === 'billed'):
                return 'Facturé';
            case (status === 'cancelled'):
                return 'Annulé';
            case (status === 'closed'):
                return 'Clôturé';
            case (status === 'draft'):
                return 'Brouillon';
            case (status === 'partially_billed'):
                return 'Acompte';
            case (status === 'refused'):
                return 'Refusé';
            case (status === 'sent'):
                return 'Envoyé';
            case (status === 'validated'):
                return 'Finalisé';
            default:
                return '';
        }
    }

    public static override tagAccent(status: string): Object | undefined {
        switch (true) {
            case (status === 'accepted'):
            case (status === 'billed'):
            case (status === 'cancelled'):
            case (status === 'refused'):
                return tagAccents;
            default:
                return undefined;
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public override get statusSeverity(): 'success' | 'secondary' | 'info' | 'warn' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (this.hasStatus('billed')):
                return Quotation.statusSeverity('billed');
            case (this.hasStatus('closed')):
                return Quotation.statusSeverity('closed');
            case (this.hasStatus('partially_billed')):
                return Quotation.statusSeverity('partially_billed');
            case (this.hasStatus('accepted')):
                return Quotation.statusSeverity('accepted');
            case (this.hasStatus('refused')):
                return Quotation.statusSeverity('refused');
            case (this.hasStatus('cancelled')):
                return Quotation.statusSeverity('cancelled');
            case (this.hasStatus('sent')):
                return Quotation.statusSeverity('sent');
            case (this.hasStatus('validated')):
                return Quotation.statusSeverity('validated');
            case (this.hasStatus('draft')):
                return Quotation.statusSeverity('draft');
            default:
                return Quotation.statusSeverity('');
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public override get statusLabel(): string {
        switch (true) {
            case (this.hasStatus('billed')):
                return Quotation.statusLabel('billed');
            case (this.hasStatus('closed')):
                return Quotation.statusLabel('closed');
            case (this.hasStatus('partially_billed')):
                return Quotation.statusLabel('partially_billed');
            case (this.hasStatus('accepted')):
                return Quotation.statusLabel('accepted');
            case (this.hasStatus('refused')):
                return Quotation.statusLabel('refused');
            case (this.hasStatus('cancelled')):
                return Quotation.statusLabel('cancelled');
            case (this.hasStatus('sent')):
                return Quotation.statusLabel('sent');
            case (this.hasStatus('validated')):
                return Quotation.statusLabel('validated');
            case (this.hasStatus('draft')):
                return Quotation.statusLabel('draft');
            default:
                return Quotation.statusLabel('');
        }
    }

    public override get tagAccent(): Object | undefined {
        switch (true) {
            case (this.hasStatus('billed')):
                return Quotation.tagAccent('billed');
            case (this.hasStatus('closed')):
                return Quotation.tagAccent('closed');
            case (this.hasStatus('partially_billed')):
                return Quotation.tagAccent('partially_billed');
            case (this.hasStatus('accepted')):
                return Quotation.tagAccent('accepted');
            case (this.hasStatus('refused')):
                return Quotation.tagAccent('refused');
            case (this.hasStatus('cancelled')):
                return Quotation.tagAccent('cancelled');
            case (this.hasStatus('sent')):
                return Quotation.tagAccent('sent');
            case (this.hasStatus('validated')):
                return Quotation.tagAccent('validated');
            case (this.hasStatus('draft')):
                return Quotation.tagAccent('draft');
            default:
                return undefined;
        }
    }

    public get acceptanceConditions(): string {
        return this._acceptanceConditions;
    }

    public set acceptanceConditions(value: any) {
        this._setValue('_acceptanceConditions', value, 'string');
    }

    public get amountBilled(): number {
        return this._amountBilled;
    }

    public set amountBilled(value: any) {
        this._setValue('_amountBilled', value, 'float');
    }

    public get amountBilledOrg(): number {
        return this._amountBilledOrg;
    }

    public set amountBilledOrg(value: any) {
        this._setValue('_amountBilledOrg', value, 'float');
    }

    public get allocations(): any[] {
        return [];
    }

    public set allocations(_: any) { }

    public override get grossAmountDisp(): number {
        return this.grossAmount;
    }

    public get invoices(): SalesInvoice[] {
        return this._invoices;
    }

    public set invoices(value: any) {
        this._setValue('_invoices', value, 'SalesInvoice');
    }

    public get items(): QuotationItem[] {
        return this._items;
    }

    public set items(value: any) {
        this._setValue('_items', value, 'QuotationItem');
    }

    public override get netAmountDisp(): number {
        return this.netAmount;
    }

    public get taxes(): QuotationTax[] {
        return this._taxes;
    }

    public set taxes(value: any) {
        this._setValue('_taxes', value, 'QuotationTax');
    }

    public get validityDate(): moment.Moment {
        return this._validityDate;
    }

    public set validityDate(value: any) {
        this._setValue('_validityDate', value, 'date');
    }

    public get validationDate(): moment.Moment {
        return this._validationDate;
    }

    public set validationDate(value: any) {
        this._setValue('_validationDate', value, 'date');
    }

}

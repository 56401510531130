import { Component, inject } from '@angular/core';

import { finalize } from 'rxjs';

import { ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';

// Composants
import { FormBaseComponent } from 'src/app/@shared/components/abstract/form-base.component';

// Services
import { AuthenticationService } from 'src/app/@core/authentication/services/authentication.service';

// Utils
import { Logger } from 'src/app/@core/services/logger/logger.service';

const LOGGER = new Logger('SubscribeFormComponent');

@Component({
    selector: 'app-reset-password-form',
    imports: [
    ReactiveFormsModule,
    ButtonModule,
    PasswordModule
],
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent extends FormBaseComponent {
    private _authenticationService = inject(AuthenticationService);


    public showSuccessMessage: boolean = false;

    protected override buildForm() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        });
    }

    private _displaySuccess(): void {
        this.showSuccessMessage = true;
    }

    public reset(): void {
        this.loading = true;
        let context = this.form.value;
        context.user_id = this.queryParams.get('user_id');
        context.timestamp = this.queryParams.get('timestamp');
        context.signature = this.queryParams.get('signature');
        const reset$ = this._authenticationService.resetPassword$(context);
        reset$
            .pipe(
                finalize(() => {
                    this.form.markAsPristine();
                    this.loading = false;
                })
            )
            .subscribe({
                next: () => {
                    this._displaySuccess();
                    setTimeout(() => {
                        this.router.navigate(['/login'])
                    }, 5000);
                },
                error: (error: any) => {
                    Object.keys(error?.details).forEach((key) => {
                        let control = this.form.get(key);
                        control?.setErrors({ invalid: true, message: error.details[key].longText });
                    })
                },
            });
    }

}

import { Injectable } from '@angular/core';

// Services
import { RouteParam, ServiceBase } from '../service-base';

const CLASS_NAME = 'SalesDocumentTemplate';
const ENDPOINT = `/templates/`;
const ROUTES: RouteParam[] = [
    { key: 'downloadFile', path: '{id}/preview/' },
]

@Injectable({
    providedIn: 'root'
})
export class SalesDocumentTemplatesService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addRoutes(ROUTES);
    }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';

// Composants
import { PageBaseComponent } from 'src/app/@shared/components/abstract/page-base.component';
import { LoadingComponent } from '../loading/loading.component';

// Modèles
import { Client } from 'src/app/@shared/models/client/client.model';

@Component({
    selector: 'app-home',
    imports: [
        CommonModule,
        AvatarModule,
        ButtonModule,
        DataViewModule,
        LoadingComponent
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent extends PageBaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    public showContent: boolean = false;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _clients: Client[] = [];
    public get clients(): Client[] {
        return this._clients;
    }
    public set clients(value: Client[]) {
        let emptyArray: Client[] = [];
        this._clients = emptyArray.concat(value);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public onClickClient(client: Client): void {
        this.appService.selectClient(client);
    }

    public onClickCreateOrganization(): void {
        this._createNewClient();
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override subscribeToObservables(): void {
        // Organisations visibles par l'utilisateur
        this.subscriptions.add(
            this.appService.clientsLoaded$.subscribe((clients: Client[] | null) => {
                if (!clients) return;
                // Tri des organisations dans l'ordre alphabétique
                clients.sort((a, b) => a.commercialName.localeCompare(b.commercialName));
                this.clients = clients;
                this.showContent = true;
            })
        );
        // Organisation sélectionnée
        this.subscriptions.add(
            // Sélection de l'organisation
            this.clientService.selected$.subscribe((client: Client) => {
                if (!!client) {
                    this.appService.navToDashboard();
                }
            })
        )
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _createNewClient(): void {
        // Déselection du client actuellement sélectionné
        this.appService.selectedClient = null;
        this.clientService.select(new Client({}), false);
        // Navigation vers le formulaire de création d'un nouveau client
        this.router.navigate(['/client']);
    }

}

import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'ClientAccountEntryType';
const ENDPOINT = `/client-account-entry-types/`;

@Injectable({
    providedIn: 'root'
})
export class ClientAccountEntryTypeService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, Type } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Observable, Subscription, combineLatest, finalize, of } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TableLazyLoadEvent } from 'primeng/table';

// Injector
import { AppInjector } from 'src/app/app.module';

// Services
import { AppService } from 'src/app/@core/services/app.service';
import { AccountEntryExportService } from 'src/app/@core/services/accounting/account-entry-export.service';
import { AccountEntryExportFormatService } from 'src/app/@core/services/accounting/account-entry-export-format.service';
import { AccountEntryService } from 'src/app/@core/services/accounting/account-entry.service';
import { AccountEntryLineService } from 'src/app/@core/services/accounting/account-entry-line.service';
import { AccountEntryNumberRangeService } from 'src/app/@core/services/accounting/account-entry-number-range.service';
import { AccountingMethodService } from 'src/app/@core/services/accounting/accounting-method.service';
import { AccountingPeriodService } from 'src/app/@core/services/accounting/accounting-period.service';
import { AccountingSettingsService } from 'src/app/@core/services/accounting/accounting-settings.service';
import { AccountService } from 'src/app/@core/services/accounting/account.service';
import { AddressService } from 'src/app/@core/services/address/address.service';
import { AuthenticationService } from 'src/app/@core/authentication/services/authentication.service';
import { BankService } from 'src/app/@core/services/banking/bank.service';
import { BankAccountService } from 'src/app/@core/services/banking/bank-account.service';
import { BankAccountLineService } from 'src/app/@core/services/banking/bank-account-line.service';
import { BankAccountLineTypeService } from 'src/app/@core/services/banking/bank-account-line-type.service';
import { BankAccountRuleService } from 'src/app/@core/services/banking/bank-account-rule.service';
import { ChequeService } from 'src/app/@core/services/banking/cheque.service';
import { CityService } from 'src/app/@core/services/address/city.service';
import { CivilityService } from 'src/app/@core/services/user/civility.service';
import { ClientService } from 'src/app/@core/services/client/client.service';
import { ClientAccountEntryTypeService } from 'src/app/@core/services/accounting/client-account-entry-type.service';
import { ClientContactService } from 'src/app/@core/services/client/contact.service';
import { ClientTaxService } from 'src/app/@core/services/accounting/client-tax.service';
import { ClientUserService } from 'src/app/@core/services/client/user.service';
import { ConfirmationServiceBroker } from 'src/app/@core/services/confirmation.service';
import { CountryService } from 'src/app/@core/services/core/country.service';
import { CurrencyService } from 'src/app/@core/services/core/currency.service';
import { DepositService } from 'src/app/@core/services/banking/deposit.service';
import { DialogServiceHelper } from 'src/app/@core/services/dialog.service';
import { DiscountTypeService } from 'src/app/@core/services/sales/discount-type.service';
import { EcoTaxSectorService } from 'src/app/@core/services/product/eco-tax-sector.service';
import { EmailService } from 'src/app/@core/services/messaging/email.service';
import { EmailRedirectionService } from 'src/app/@core/services/interfaces/email-redirection.service';
import { EmployeeService } from 'src/app/@core/services/human-resources/employee.service';
import { FileService } from 'src/app/@core/services/files/file.service';
import { InviteService } from 'src/app/@core/services/client/invite.service';
import { InvoiceContactService } from 'src/app/@core/services/sales/contact.service';
import { InvoiceNumberingService } from 'src/app/@core/services/sales/invoice-numbering.service';
import { InvoiceTypeService } from 'src/app/@core/services/invoicing/invoice-type.service';
import { JournalService } from 'src/app/@core/services/accounting/journal.service';
import { KpiService } from 'src/app/@core/services/kpi/kpi.service';
import { LegacyPaymentService } from 'src/app/@core/services/invoicing/legacy-payment.service';
import { LegalStatusService } from 'src/app/@core/services/taxation/legal-status.service';
import { LoanService } from 'src/app/@core/services/banking/loan.service';
import { NumericComparatorService } from 'src/app/@core/services/core/numeric-comparator.service';
import { OrganizationService } from 'src/app/@core/services/legal-entity/organization.service';
import { OrganizationActivityService } from 'src/app/@core/services/legal-entity/organization-activity.service';
import { OrganizationIdentifierTypeService } from 'src/app/@core/services/legal-entity/organization-identifier-type.service';
import { OverpaymentService } from 'src/app/@core/services/banking/overpayment.service';
import { OverpaymentReimbursementService } from 'src/app/@core/services/banking/overpayment-reimbursement.service';
import { PackageService } from 'src/app/@core/services/subscription/package.service';
import { PartnerService } from 'src/app/@core/services/partner/partner.service';
import { PartnerContactService } from 'src/app/@core/services/partner/contact.service';
import { PartnerGroupService } from 'src/app/@core/services/partner/partner-group.service';
import { PartnerTypeService } from 'src/app/@core/services/partner/partner-type.service';
import { PaymentMethodService } from 'src/app/@core/services/core/payment-method.service';
import { PaymentTermService } from 'src/app/@core/services/invoicing/payment-term.service';
import { PhoneAreaCodeService } from 'src/app/@core/services/address/phone-area-code.service';
import { PowensService } from 'src/app/@core/services/interfaces/powens.service';
import { ProductService } from 'src/app/@core/services/product/product.service';
import { ProductGroupService } from 'src/app/@core/services/product/product-group.service';
import { ProductTypeService } from 'src/app/@core/services/product/product-type.service';
import { ProfileService } from 'src/app/@core/services/authorizations/profile.service';
import { PurchaseInvoiceService } from 'src/app/@core/services/purchasing/purchase-invoice.service';
import { PurchaseInvoicePaymentService } from 'src/app/@core/services/purchasing/purchase-invoice-payment.service';
import { QuotationService } from 'src/app/@core/services/sales/quotation.service';
import { QuotationItemService } from 'src/app/@core/services/sales/quotation-item.service';
import { SalesDocumentTemplatesService } from 'src/app/@core/services/sales/templates.service';
import { SalesInvoiceService } from 'src/app/@core/services/sales/sales-invoice.service';
import { SalesInvoicePaymentService } from 'src/app/@core/services/sales/sales-invoice-payment.service';
import { SalesSettingsService } from 'src/app/@core/services/sales/sales-settings.service';
import { SellingInfoRecordService } from 'src/app/@core/services/sales/selling-info-record.service';
import { ShareholderService } from 'src/app/@core/services/client/shareholder.service';
import { TaxesService } from 'src/app/@core/services/core/tax.service';
import { TaxationRegimeService } from 'src/app/@core/services/accounting/taxation-regime.service';
import { TaxationSettingsService } from 'src/app/@core/services/taxation/taxation-settings.service';
import { TaxationTypeService } from 'src/app/@core/services/accounting/taxation-type.service';
import { TgcDeclarationService } from 'src/app/@core/services/taxation/tgc-declaration.service';
import { TimezoneService } from 'src/app/@core/services/core/timezone.service';
import { TransactionDirectionService } from 'src/app/@core/services/core/transaction-direction.service';
import { UnitService } from 'src/app/@core/services/core/unit.service';
import { UserService } from 'src/app/@core/services/user/user.service';
import { VatChargeabilityService } from 'src/app/@core/services/accounting/vat-chargeability.service';
import { VatFrequencyService } from 'src/app/@core/services/accounting/vat-frequency.service';

// Enums
import { ActivitiesEnum } from '../../enums/activities.enum';
import { AuthorizationsEnum } from 'src/app/@shared/enums/authorizations.enum';
import { FeaturesEnum } from '../../enums/features.enum';
import { PackagesEnum } from '../../enums/packages.enum';
import { QuotasEnum } from '../../enums/quotas.enum';
import { StatusEnum } from 'src/app/@shared/enums/status.enum';

// Utilities
import { Filter } from '../../utilities/models/filter';
import { Sorter } from '../../utilities/models/sorter';
import { Logger } from 'src/app/@core/services/logger/logger.service';
import { QueryOptionsParam } from '../../utilities/odata/query-params';

const LOGGER = new Logger('BaseComponent');

@Component({
    selector: 'b4cash-app-base',
    template: `
        NO UI TO BE FOUND HERE!
    `,
    host: {
        class: 'h-full w-full block'
    },
})
export abstract class BaseComponent implements OnInit, OnDestroy {
    // Appareil
    public deviceService: DeviceDetectorService;
    public titleService: Title;
    // Routing
    protected navBackUrl: string | null = null;
    protected location: Location;
    protected router: Router;
    private _route: ActivatedRoute;
    // URL params
    protected queryParams!: ParamMap;
    protected routeParams!: ParamMap;
    // Enums
    public Activities = ActivitiesEnum;
    public Authorizations = AuthorizationsEnum;
    public Features = FeaturesEnum;
    public Packages = PackagesEnum;
    public Quotas = QuotasEnum;
    public Status = StatusEnum;
    // Services
    public appService: AppService;
    protected accountEntryExportService: AccountEntryExportService;
    protected accountEntryExportFormatService: AccountEntryExportFormatService;
    protected accountEntryService: AccountEntryService;
    protected accountEntryLineService: AccountEntryLineService;
    protected accountEntryNumberRangeService: AccountEntryNumberRangeService;
    protected accountingMethodService: AccountingMethodService;
    protected accountingPeriodService: AccountingPeriodService;
    protected accountingSettingsService: AccountingSettingsService;
    protected accountService: AccountService;
    protected addressService: AddressService;
    protected authenticationService: AuthenticationService;
    protected bankService: BankService;
    protected bankAccountService: BankAccountService;
    protected bankAccountLineService: BankAccountLineService;
    protected bankAccountLineTypeService: BankAccountLineTypeService;
    protected bankAccountRuleService: BankAccountRuleService;
    protected chequeService: ChequeService;
    protected cityService: CityService;
    protected civilityService: CivilityService;
    protected clientService: ClientService;
    protected clientAccountEntryTypeService: ClientAccountEntryTypeService;
    protected clientContactService: ClientContactService;
    protected clientTaxService: ClientTaxService;
    protected clientUserService: ClientUserService;
    protected countryService: CountryService;
    protected currencyService: CurrencyService;
    protected depositService: DepositService;
    protected discountTypeService: DiscountTypeService;
    protected ecoTaxSectorService: EcoTaxSectorService;
    protected emailService: EmailService;
    protected emailRedirectionService: EmailRedirectionService;
    protected employeeService: EmployeeService;
    protected fileService: FileService;
    protected inviteService: InviteService;
    protected invoiceContactService: InvoiceContactService;
    protected invoiceNumberingService: InvoiceNumberingService;
    protected invoiceTypeService: InvoiceTypeService;
    protected journalService: JournalService;
    protected kpiService: KpiService;
    protected legacyPaymentService: LegacyPaymentService;
    protected legalStatusService: LegalStatusService;
    protected loanService: LoanService;
    protected numericComparatorService: NumericComparatorService;
    protected organizationService: OrganizationService;
    protected organizationActivityService: OrganizationActivityService;
    protected organizationIdentifierTypeService: OrganizationIdentifierTypeService;
    protected overpaymentService: OverpaymentService;
    protected overpaymentReimbursementService: OverpaymentReimbursementService;
    protected packageService: PackageService;
    protected partnerService: PartnerService;
    protected partnerContactService: PartnerContactService;
    protected partnerGroupService: PartnerGroupService;
    protected partnerTypeService: PartnerTypeService;
    protected paymentMethodService: PaymentMethodService;
    protected paymentTermService: PaymentTermService;
    protected phoneAreaCodeService: PhoneAreaCodeService;
    protected powensService: PowensService;
    protected productService: ProductService;
    protected productGroupService: ProductGroupService;
    protected productTypeService: ProductTypeService;
    protected profileService: ProfileService;
    protected purchaseInvoiceService: PurchaseInvoiceService;
    protected purchaseInvoicePaymentService: PurchaseInvoicePaymentService;
    protected quotationService: QuotationService;
    protected quotationItemService: QuotationItemService;
    protected salesDocumentTemplatesService: SalesDocumentTemplatesService;
    protected salesInvoiceService: SalesInvoiceService;
    protected salesInvoicePaymentService: SalesInvoicePaymentService;
    protected salesSettingsService: SalesSettingsService;
    protected sellingInfoRecordService: SellingInfoRecordService;
    protected shareholderService: ShareholderService;
    protected taxService: TaxesService;
    protected taxationRegimeService: TaxationRegimeService;
    protected taxationSettingsService: TaxationSettingsService;
    protected taxationTypeService: TaxationTypeService;
    protected tgcDeclarationService: TgcDeclarationService;
    protected timezoneService: TimezoneService;
    protected transactionDirectionService: TransactionDirectionService;
    protected unitService: UnitService;
    protected userService: UserService;
    protected vatChargeabilityService: VatChargeabilityService;
    protected vatFrequencyService: VatFrequencyService;
    protected dialogService: DialogServiceHelper;
    private _confirmationService: ConfirmationServiceBroker;
    // Subscriptions
    public subscriptions: Subscription = new Subscription();
    // Options de requête
    public filtersString: string = '';
    protected queryOptions: QueryOptionsParam = {};
    public totalRecords: number = 0;
    private _lazyLoadingParams!: TableLazyLoadEvent;
    // View attributes
    public busy: boolean = false;  // Action en cours côté front
    public loading: boolean = false;  // Action en cours côté back
    public tooltipDelay = 100;
    public tooltipPosition = 'bottom';
    public uploadCurrent: number = 0;
    public uploadTotal: number = 0;
    // Gestion du drag and drop
    public dragover = false;
    private _keepDragover = false;
    // Évènement
    @Output()
    filesUploaded = new EventEmitter();

    constructor() {
        // Device
        this.deviceService = AppInjector.get(DeviceDetectorService);
        this.titleService = AppInjector.get(Title);
        // Routing
        this.location = AppInjector.get(Location);
        this.router = AppInjector.get(Router);
        this._route = AppInjector.get(ActivatedRoute);
        // Services
        this.appService = AppInjector.get(AppService);
        this.accountEntryExportService = AppInjector.get(AccountEntryExportService);
        this.accountEntryExportFormatService = AppInjector.get(AccountEntryExportFormatService);
        this.accountEntryService = AppInjector.get(AccountEntryService);
        this.accountEntryLineService = AppInjector.get(AccountEntryLineService);
        this.accountEntryNumberRangeService = AppInjector.get(AccountEntryNumberRangeService);
        this.accountingMethodService = AppInjector.get(AccountingMethodService);
        this.accountingPeriodService = AppInjector.get(AccountingPeriodService);
        this.accountingSettingsService = AppInjector.get(AccountingSettingsService);
        this.accountService = AppInjector.get(AccountService);
        this.addressService = AppInjector.get(AddressService);
        this.authenticationService = AppInjector.get(AuthenticationService);
        this.bankService = AppInjector.get(BankService);
        this.bankAccountService = AppInjector.get(BankAccountService);
        this.bankAccountLineService = AppInjector.get(BankAccountLineService);
        this.bankAccountLineTypeService = AppInjector.get(BankAccountLineTypeService);
        this.bankAccountRuleService = AppInjector.get(BankAccountRuleService);
        this.chequeService = AppInjector.get(ChequeService);
        this.cityService = AppInjector.get(CityService);
        this.civilityService = AppInjector.get(CivilityService);
        this.clientService = AppInjector.get(ClientService);
        this.clientAccountEntryTypeService = AppInjector.get(ClientAccountEntryTypeService);
        this.clientContactService = AppInjector.get(ClientContactService);
        this.clientTaxService = AppInjector.get(ClientTaxService);
        this.clientUserService = AppInjector.get(ClientUserService);
        this.countryService = AppInjector.get(CountryService);
        this.currencyService = AppInjector.get(CurrencyService);
        this.depositService = AppInjector.get(DepositService);
        this.discountTypeService = AppInjector.get(DiscountTypeService);
        this.ecoTaxSectorService = AppInjector.get(EcoTaxSectorService);
        this.emailService = AppInjector.get(EmailService);
        this.emailRedirectionService = AppInjector.get(EmailRedirectionService);
        this.employeeService = AppInjector.get(EmployeeService);
        this.fileService = AppInjector.get(FileService);
        this.inviteService = AppInjector.get(InviteService);
        this.invoiceContactService = AppInjector.get(InvoiceContactService);
        this.invoiceNumberingService = AppInjector.get(InvoiceNumberingService);
        this.invoiceTypeService = AppInjector.get(InvoiceTypeService);
        this.journalService = AppInjector.get(JournalService);
        this.kpiService = AppInjector.get(KpiService);
        this.legacyPaymentService = AppInjector.get(LegacyPaymentService);
        this.legalStatusService = AppInjector.get(LegalStatusService);
        this.loanService = AppInjector.get(LoanService);
        this.numericComparatorService = AppInjector.get(NumericComparatorService);
        this.organizationService = AppInjector.get(OrganizationService);
        this.organizationActivityService = AppInjector.get(OrganizationActivityService);
        this.organizationIdentifierTypeService = AppInjector.get(OrganizationIdentifierTypeService);
        this.overpaymentService = AppInjector.get(OverpaymentService);
        this.overpaymentReimbursementService = AppInjector.get(OverpaymentReimbursementService);
        this.packageService = AppInjector.get(PackageService);
        this.partnerService = AppInjector.get(PartnerService);
        this.partnerContactService = AppInjector.get(PartnerContactService);
        this.partnerGroupService = AppInjector.get(PartnerGroupService);
        this.partnerTypeService = AppInjector.get(PartnerTypeService);
        this.paymentMethodService = AppInjector.get(PaymentMethodService);
        this.paymentTermService = AppInjector.get(PaymentTermService);
        this.phoneAreaCodeService = AppInjector.get(PhoneAreaCodeService);
        this.powensService = AppInjector.get(PowensService);
        this.productService = AppInjector.get(ProductService);
        this.productGroupService = AppInjector.get(ProductGroupService);
        this.productTypeService = AppInjector.get(ProductTypeService);
        this.profileService = AppInjector.get(ProfileService);
        this.purchaseInvoiceService = AppInjector.get(PurchaseInvoiceService);
        this.purchaseInvoicePaymentService = AppInjector.get(PurchaseInvoicePaymentService);
        this.quotationService = AppInjector.get(QuotationService);
        this.quotationItemService = AppInjector.get(QuotationItemService);
        this.salesDocumentTemplatesService = AppInjector.get(SalesDocumentTemplatesService);
        this.salesInvoiceService = AppInjector.get(SalesInvoiceService);
        this.salesInvoicePaymentService = AppInjector.get(SalesInvoicePaymentService);
        this.salesSettingsService = AppInjector.get(SalesSettingsService);
        this.sellingInfoRecordService = AppInjector.get(SellingInfoRecordService);
        this.shareholderService = AppInjector.get(ShareholderService);
        this.taxService = AppInjector.get(TaxesService);
        this.taxationRegimeService = AppInjector.get(TaxationRegimeService);
        this.taxationSettingsService = AppInjector.get(TaxationSettingsService);
        this.taxationTypeService = AppInjector.get(TaxationTypeService);
        this.tgcDeclarationService = AppInjector.get(TgcDeclarationService);
        this.timezoneService = AppInjector.get(TimezoneService);
        this.transactionDirectionService = AppInjector.get(TransactionDirectionService);
        this.unitService = AppInjector.get(UnitService);
        this.userService = AppInjector.get(UserService);
        this.vatChargeabilityService = AppInjector.get(VatChargeabilityService);
        this.vatFrequencyService = AppInjector.get(VatFrequencyService);
        this.dialogService = AppInjector.get(DialogServiceHelper);
        this._confirmationService = AppInjector.get(ConfirmationServiceBroker);
    }


    // Tri par défaut
    protected defaultSorters: Sorter[] = [
        new Sorter({
            property: 'id'
        })
    ];

    /*----------------------------------------------------------------------------------------
     * Getters / setters
     ----------------------------------------------------------------------------------------*/

    public get metaKey(): string {
        if (navigator.platform.match('Mac')) {
            return 'cmd';
        } else {
            return 'ctrl';
        }
    }

    public get route(): ActivatedRoute {
        let route = this._route;
        while (!!route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }

    public get taxNameLabel(): string {
        return this.appService.selectedClient?.isCaledonian ? 'TGC' : 'TVA';
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    ngOnInit() {
        // Gestion des paramètres de route
        combineLatest([this.route.paramMap, this.route.queryParamMap]).subscribe(
            ([routeParams, queryParams]: [ParamMap, ParamMap]) => {
                this.routeParams = routeParams;
                this.queryParams = queryParams;
                this.mapParams();
                this.loadData();
            }
        );
        // Souscription aux observables
        this.subscribeToObservables();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Returns true if application is used on an Android device
     */
    public isAndroid(): boolean {
        const ANDROID: string = 'android';
        let os: string = this.deviceService.os.toLowerCase();
        return os.includes(ANDROID);
    }

    public onClickNavBack() {
        this.navBack();
    }

    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.dragover = true;
        this._keepDragover = true;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this._keepDragover = false;
        setTimeout(() => {
            if (!this._keepDragover) {
                this.dragover = false;
            }
        }, 10);
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.dragover = false;
        let files = event.dataTransfer!.files;
        // Conversion en liste de File
        let filesArray = Array.from(files) as File[];
        let count = filesArray.length;
        if (filesArray.length > 0) {
            this._uploadFiles(filesArray, 1, count);
        }
    }

    /**
     * Évènement déclenché par l'utilisation d'un fileupload
     * @param event
     */
    public onUpload(event: any) {
        const files = event.files;
        let count = files.length;
        this._uploadFiles(files, 1, count);
    }

    public stopPropagation(event: Event) {
        event.stopPropagation();
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected buildQueryOptions(event?: TableLazyLoadEvent): void {
        this.queryOptions = {
            count: true
        }

        if (!!event) {
            this._lazyLoadingParams = event;
        }

        // Filtres
        this.queryOptions.filters = [];
        if (!!this._lazyLoadingParams?.filters) {
            this.queryOptions.filters.push(...Filter.fromTableLazyLoadEvent(this._lazyLoadingParams));
        }
        this.queryOptions.filtersString = this.filtersString;

        // Tri
        if (!!this._lazyLoadingParams?.sortField) {
            this.defaultSorters = [
                new Sorter({
                    property: this._lazyLoadingParams.sortField.toString(),
                    descending: this._lazyLoadingParams.sortOrder! < 0
                })
            ];
        }
        this.queryOptions.sorters = this.defaultSorters;

        // Pagination
        if (!!this._lazyLoadingParams) {
            this.queryOptions.skip = this._lazyLoadingParams.first;
            this.queryOptions.top = this._lazyLoadingParams.rows;
        }
    }

    protected confirmAction(action: string, callback: Function) {
        this._confirmationService.confirm(action, callback);
    }

    protected loadData() { }

    protected mapParams(): void {
        if (this.queryParams.has('$filter')) {
            this.filtersString = this.queryParams.get('$filter')!;
        } else {
            this.filtersString = '';
        }
    }

    protected navBack(): void {
        if (this.navBackUrl) {
            this.router.navigate([this.navBackUrl])
        } else {
            this.location.back();
        }
    }

    /**
     * Méthode permettant l'affichage d'une fenêtre de dialogue dynamique
     * avec pour contenu le composant passé en paramètre
     * @param component Le composant à afficher
     * @param config Le nom de la configuration à utiliser
     * @param data Les données à passer au composant
     * @returns L'id de la fenêtre de dialogue, cet identifiant peut être utilisé pour capter les évènements de la fenêtre
     */
    protected openDialog(component: Type<any>, config: string, data: any = {}): string {
        return this.dialogService.show(component, config, data);
    }

    protected subscribeToObservables() { }

    protected uploadFile$(file: File): Observable<any> {
        LOGGER.info('Méthode uploadFile$ non implémentée dans le composant', this.constructor.name);
        LOGGER.info('Fichier', file);
        return of(false);
    }

    /**
     * Méthodes privées
     */

    private _uploadFiles(files: File[], current: number, total: number) {
        this.uploadCurrent = current;
        this.uploadTotal = total;
        const file = files.shift();
        this.uploadFile$(file!).pipe(
            finalize(() => {
                if (files.length > 0) {
                    this._uploadFiles(files, current +  1, total);
                } else {
                    this.uploadCurrent = 0;
                    this.uploadTotal = 0;
                    this.filesUploaded.emit();
                }
            })
        ).subscribe(() => { });
    }
}

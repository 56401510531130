import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

// Services
import { AppService } from '../app.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { CredentialsService } from '../../authentication/services/credentials.service';
import { Logger } from '../logger/logger.service';

// Modèles
import { Client } from 'src/app/@shared/models/client/client.model';
import { User } from 'src/app/@shared/models/user/user.model';

// Services
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';
import { MessageServiceBroker } from '../message.service';
import { ClientService } from '../client/client.service';

const CLASS_NAME = 'User';
const ENDPOINT = `/users/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'avatar', path: 'avatar/', childClass: 'User' },
]
const ROUTES: RouteParam[] = [
    { key: 'logout', path: '{id}/logout/' },
    { key: 'me', path: 'me/' },
]
const LOGGER = new Logger('User service');

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {

    constructor(
        httpClient: HttpClient,
        messageService: MessageServiceBroker,
        private _appService: AppService,
        private _authenticationService: AuthenticationService,
        private _credentialsService: CredentialsService,
        private _clientService: ClientService,
        private _router: Router
    ) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    /**
     * Appel des données de l'utilisateur connecté
     */
    getMe() {
        LOGGER.debug('Chargement des données utilisateur');
        this._authenticationService.refreshToken$(
            this._credentialsService.credentials!.refresh
        ).pipe(
            map((response) => {return response}),
            catchError(response => {
                if (response.error?.code === 'token_not_valid') {
                    this._authenticationService.logout$().subscribe(() => {
                        // Reouverture de la page de connexion
                        this._router.navigate(['/login']);
                    });
                }
                throw response;
            })
        ).subscribe(() =>
            this.httpClient.get(this.routes.me).subscribe(data => {
                let user = new User(data);
                if (user?.id !== this._appService.user?.id) {
                    // Chargement de l'organisation en mémoire
                    this.loadSelectedClient$().subscribe(client => {
                        // Chargement des organisations gérées
                        this.loadClients();
                    });
                }
                // Mise à jour du contexte de l'application avec l'utilisateur chargé
                this._appService.user = user;
            }));
    }

    public loadClients() {
        this._clientService.getMine$().subscribe((clients: Client[]) => {
            this._appService.clients = clients;
        });
    }

    public loadSelectedClient$(): Observable<Client | null>{
        return this._clientService.loadSelected$();
    }

    /**
     * Chargement des données de l'utilisateur
     *
     * @returns boolean Retourne vrai si l'utilisateur est connecté
     */
    public loadUserData(): boolean {
        if (this._credentialsService.isAuthenticated()) {
            // Utilisateur connecté
            this.getMe();
            return true;
        } else {
            // Utilisateur non connecté
            return false;
        }
    }

    public logout$(): Observable<any> {
        return this.httpClient.post(this.routes.logout.replace('{id}', this._appService.user?.id.toString()), {}).pipe(
            switchMap(() => this._authenticationService.logout$())
        );
    }

    public uploadAvatar$(user: User, file: File): Observable<any> {
        let url = this.routes['single'].replace('{id}', user.id.toString()) + this.navigations['avatar'].path;
        return this.uploadFile$(file, url);
    }
}

import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";
import { Profile } from "../authorization/profile.model";


export class UserProfile extends ModelBase {

    static override CLASS_NAME: string = 'UserProfile';

    private _dateFrom!: moment.Moment;
    private _dateTo!: moment.Moment;
    private _profile!: Profile;

    public get dateFrom(): moment.Moment {
        return this._dateFrom;
    }

    public set dateFrom(value: any) {
        this._setValue('_dateFrom', value, 'date');
    }

    public get dateTo(): moment.Moment {
        return this._dateTo;
    }

    public set dateTo(value: any) {
        this._setValue('_dateTo', value, 'date');
    }

    public get profile(): Profile {
        return this._profile;
    }

    public set profile(value: any) {
        this._setValue('_profile', value, 'Profile');
    }
}
import { InvoiceBase } from "./abstract/invoice-base.model";
import { InvoicePaymentBase } from "./abstract/invoice-payment-base.model";


export class LegacyPayment extends InvoicePaymentBase {

    static override CLASS_NAME: string = 'LegacyPayment';

    protected _creditNote!: InvoiceBase;
    protected _invoice!: InvoiceBase;

    public get creditNote(): InvoiceBase {
        return this._creditNote;
    }

    public set creditNote(value: InvoiceBase) {
        this._creditNote = value;
    }

    public get invoice(): InvoiceBase {
        return this._invoice;
    }

    public set invoice(value: InvoiceBase) {
        this._invoice = value;
    }

}

import { Component, ElementRef, inject, Input } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import moment from 'moment';

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexPlotOptions, ApexXAxis, ApexYAxis, NgApexchartsModule } from 'ng-apexcharts';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { SelectChangeEvent, SelectModule } from 'primeng/select';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { $dt } from '@primeng/themes';

// Composants
import { BaseComponent } from '../../abstract/base.component';

// Modèles
import { AccountingPeriod } from 'src/app/@shared/models/accounting/accounting-period.model';
import { KpiMonthlySummary } from 'src/app/@shared/models/kpi/monthly-summary.model';

// Utils
import { Sorter } from 'src/app/@shared/utilities/models/sorter';
import { Filter } from 'src/app/@shared/utilities/models/filter';
import { Partner } from 'src/app/@shared/models/partner/partner.model';

@Component({
    selector: 'app-monthly-summary-widget',
    imports: [
        CommonModule,
        FormsModule,
        NgApexchartsModule,
        CardModule,
        DividerModule,
        SelectModule,
        ProgressSpinnerModule,
    ],
    templateUrl: './monthly-summary-widget.component.html',
    styleUrls: ['./monthly-summary-widget.component.scss']
})
export class MonthlySummaryWidgetComponent extends BaseComponent {

    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    public selectedPeriod: AccountingPeriod | undefined;
    public showChart: boolean = false;

    private _expenses: boolean = true;
    public get expenses(): boolean {
        return this._expenses;
    }
    @Input()
    public set expenses(expenses: boolean) {
        this._expenses = expenses;
        if (!expenses) {
            // Suppression de la série nommée "Dépenses"
            this.chartSeries = this.chartSeries.filter(s => s.name !== "Dépenses");
            // Suppression de la couleur associée
            this.chartColors = this.chartColors.filter(c => c !== "#FF6163");
        }
    }
    private _income: boolean = true;
    public get income(): boolean {
        return this._income;
    }
    @Input()
    public set income(income: boolean) {
        this._income = income;
        if (!income) {
            // Suppression de la série nommée "Revenus"
            this.chartSeries = this.chartSeries.filter(s => s.name !== "Revenus");
            // Suppression de la couleur associée
            this.chartColors = this.chartColors.filter(c => c !== "#25C685");
        }
    }
    private _height: string = '350';
    public get height(): string {
        return this._height;
    }
    @Input()
    public set height(height: string) {
        this._height = height;
        this.chart.height = height;
    }

    // Gestions de l'intersection observer (pour le lazy loading)
    private _isVisible = false;
    private _elementRef = inject(ElementRef);
    private _observer: IntersectionObserver | null = null;

    /*----------------------------------------------------------------------------------------
     * Gestion du graph
     ----------------------------------------------------------------------------------------*/

    public chart: ApexChart = {
        type: 'bar',
        height: '350',
        events: {
            dataPointSelection: this.onClickChart.bind(this),
            dataPointMouseEnter: function (event) {
                event.currentTarget.style.cursor = "pointer";
            },
            legendClick: this.onLegendClick.bind(this)
        }
    };
    public chartSeries: ApexAxisChartSeries = [
        {
            name: "Revenus",
            data: []
        },
        {
            name: "Dépenses",
            data: []
        },
    ];
    public chartColors: string[] = [
        $dt('green.400').value,
        $dt('red.400').value,
    ];
    public chartDataLabels: ApexDataLabels = {
        enabled: false
    }
    public chartPlotOptions: ApexPlotOptions = {
        bar: {
            columnWidth: "55%",
            borderRadius: 6,
            borderRadiusApplication: 'end',
        }
    }
    public chartXAxis: ApexXAxis = {
        categories: []
    }
    public chartYAxis: ApexYAxis = {
        title: {
            text: ''
        },
        labels: {
            formatter: (this._formatYLabel).bind(this)
        }
    }

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/

    private _kpi: KpiMonthlySummary = new KpiMonthlySummary({
        currency: '',
        totalIn: 0,
        totalOut: 0,
        months: []
    });
    public get kpi(): KpiMonthlySummary {
        return this._kpi;
    }
    public set kpi(kpi: KpiMonthlySummary) {
        this._kpi = kpi;
        this._buildChartData();
    }

    private _accountingPeriods: AccountingPeriod[] = [];
    public get accountingPeriods(): AccountingPeriod[] {
        return this._accountingPeriods;
    }
    public set accountingPeriods(accountingPeriods: AccountingPeriod[]) {
        let emptyArray: AccountingPeriod[] = []
        this._accountingPeriods = emptyArray.concat(accountingPeriods);
    }

    private _partner: Partner | null = null;
    public get partner(): Partner | null {
        return this._partner;
    }
    @Input()
    public set partner(partner: Partner) {
        this._partner = partner;
    }

    public get noDataMessage(): string {
        if (this.income && this.expenses) {
            return 'Vous n\'avez ni revenu ni dépense d\'enregistré';
        } else if (this.income) {
            return 'Aucune donnée de revenus pour cette période';
        }
        return 'Aucune donnée de dépenses pour cette période';
    }

    public get title(): string {
        if (this.income && this.expenses) {
            return 'Revenus et dépenses';
        } else if (this.income) {
            return 'Revenus';
        } else {
            return 'Dépenses';
        }
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    ngAfterViewInit() {
        // Configuration de l'observer
        const options = {
            root: null, // observera le viewport
            rootMargin: '0px',
            threshold: 0.1 // déclenché quand au moins 10% est visible
        };

        this._observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                this._isVisible = entry.isIntersecting;

                // Si le composant devient visible, vous pouvez initialiser/rafraîchir le graphique
                if (this._isVisible && !!this.selectedPeriod) {
                    this._loadKpi(this.selectedPeriod);
                }
            });
        }, options);

        // Observer l'élément hôte du composant
        this._observer.observe(this._elementRef.nativeElement);
    }

    public override ngOnDestroy() {
        super.ngOnDestroy();
        if (this._observer) {
            this._observer.disconnect();
        }
    }

    public onChangeAccountingPeriod(event: SelectChangeEvent) {
        this._loadKpi(event.value);
    }

    public onClickChart(event: any, chart?: any, options?: any) {
        let monthClicked = this.kpi.months[options.dataPointIndex];
        let charge = false;
        // On détermine si on cherche à afficher les revenus et les dépenses en fonction des séries actives
        if (this.income && this.expenses) {
            charge = options.seriesIndex === 1;
        } else if (this.income) {
            charge = false;
        } else {
            charge = true;
        }
        this._navigateToEvents(monthClicked.dateFrom, monthClicked.dateTo, charge);
    }

    public onLegendClick(chartContext: any, seriesIndex: number, config: any) {
        // On détermine si on cherche à afficher les revenus et les dépenses en fonction des séries actives
        let charge = false;
        if (this.income && this.expenses) {
            charge = seriesIndex === 1;
        } else if (this.income) {
            charge = false;
        } else {
            charge = true;
        }
        this._navigateToEvents(this.selectedPeriod!.dateFrom, this.selectedPeriod!.dateTo, charge);
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/

    protected override subscribeToObservables(): void {
        this.subscriptions.add(
            this.appService.clientSelected$.subscribe(
                client => {
                    if (!!client) {
                        this._loadAccountingPeriods()
                    }
                }
            )
        );

        // Changement de l'ouverture du menu
        this.subscriptions.add(
            this.appService.menuState$.subscribe(
                () => {
                    this._buildChartData();
                }
            )
        );
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _buildChartData() {
        let inArray: number[] = [];
        let outArray: number[] = [];
        let categories: string[] = [];
        for (let i = 0; i < this.kpi.months.length; i++) {
            const month = this.kpi.months[i];
            inArray.push(month.in);
            outArray.push(month.out);
            categories.push(month.labelShort);
        }

        if (this.income) {
            // Ajout des données à la série nommée "Revenus"
            this.chartSeries.find(s => s.name === "Revenus")!.data = inArray;
        }
        if (this.expenses) {
            // Ajout des données à la série nommée "Dépenses"
            this.chartSeries.find(s => s.name === "Dépenses")!.data = outArray;
        }

        this.chartYAxis.title = { text: `${this.kpi.currency} (milliers)` };
        this.chartXAxis.categories = categories;

        let emptyArray: ApexAxisChartSeries = [];
        this.chartSeries = emptyArray.concat(this.chartSeries);
        if (inArray.length > 0 || outArray.length > 0) {
            this.showChart = true;
        }
    }

    private _formatYLabel(val: any) {
        let pipe = new CurrencyPipe('fr');
        return pipe.transform(val, this.kpi?.currency, 'code')!;
    }

    private _loadAccountingPeriods() {
        this.accountingPeriodService.getList$({
            sorters: [
                new Sorter({
                    property: 'dateFrom',
                    descending: true
                })
            ]
        }).subscribe(accountingPeriods => {
            this.accountingPeriods = accountingPeriods;
            if (!!accountingPeriods[0]) {
                this._loadKpi(accountingPeriods[0]);
                // Sélectionne la première période
                this.selectedPeriod = accountingPeriods[0];
            }
        });
    }

    private _loadKpi(accountingPeriod: AccountingPeriod) {
        if (!this._isVisible) return;

        this.showChart = false;
        let filter = new Filter({
            filters: [
                new Filter({
                    property: 'dateFrom',
                    value: accountingPeriod.dateFrom,
                }),
                new Filter({
                    property: 'dateTo',
                    value: accountingPeriod.dateTo,
                })
            ]
        });

        // Si le partenaire est défini, on charge les données pour ce partenaire
        if (!!this.partner) {
            filter.filters.push(new Filter({
                property: 'partner',
                value: this.partner.id
            }));
        }

        this.kpiService.getRelated$(this.appService.selectedClient, 'monthly-summary', {
            filters: [filter]
        }).subscribe(
            kpi => {
                this.kpi = kpi;
            }
        );
    }

    private _navigateToEvents(dateFrom: moment.Moment, dateTo: moment.Moment, charge: boolean) {
        let filter: Filter = new Filter({
            filters: [
                new Filter({
                    property: 'dateFrom',
                    value: dateFrom.format('YYYY-MM-DD')
                }),
                new Filter({
                    property: 'dateTo',
                    value: dateTo.format('YYYY-MM-DD')
                })
            ]
        });
        if (charge) {
            filter.filters.push(new Filter({
                property: 'charges',
                value: 'true'
            }));
        } else {
            filter.filters.push(new Filter({
                property: 'revenues',
                value: 'true'
            }));
        }
        if (this.partner) {
            filter.filters.push(new Filter({
                property: 'partner',
                value: this.partner.id
            }));
        }
        this.router.navigate(
            ['/reports/business-events'],
            {
                queryParams: {
                    $filter: Filter.toUrlString([filter], false)
                }
            }
        );
    }
}

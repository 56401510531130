import { ModelBase } from '../abstract/model-base.model';
import { Account } from '../accounting/account.model';
import { ProductType } from './product-type.model';
import { Tax } from '../core/tax.model';

export class ProductGroup extends ModelBase {

    static override CLASS_NAME: string = 'ProductGroup';

    private _label!: string;
    private _chargeAccount!: Account;
    private _chargeTax!: Tax;
    private _productAccount!: Account;
    private _productTax!: Tax;
    private _productType!: ProductType;

    public set chargeAccount(value: any) {
        this._setValue('_chargeAccount', value, 'Account');
    }

    public get chargeAccount(): Account {
        return this._chargeAccount;
    }

    public set chargeTax(value: any) {
        this._setValue('_chargeTax', value, 'Tax');
    }

    public get chargeTax(): Tax {
        return this._chargeTax;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get label(): string {
        return this._label;
    }

    public set productAccount(value: any) {
        this._setValue('_productAccount', value, 'Account');
    }

    public get productAccount(): Account {
        return this._productAccount;
    }

    public set productTax(value: any) {
        this._setValue('_productTax', value, 'Tax');
    }

    public get productTax(): Tax {
        return this._productTax;
    }

    public set productType(value: any) {
        this._setValue('_productType', value, 'ProductType');
    }

    public get productType(): ProductType {
        return this._productType;
    }
}

import { ModelBase } from '../abstract/model-base.model';
import { Currency } from '../core/currency.model';
import { ProductGroup } from './product-group.model';
import { ProductType } from './product-type.model';
import { Unit } from '../core/unit.model';
import { EcoTaxSector } from './eco-tax-sector.model';

export class Product extends ModelBase {

    static override CLASS_NAME: string = 'Product';

    private _name!: string;
    private _reference!: string;
    private _description!: string;
    private _ecoTax!: number;
    private _ecoTaxIncluded!: boolean;
    private _ecoTaxSector!: EcoTaxSector;
    private _productType!: ProductType;
    private _productGroup!: ProductGroup;
    private _sellingPrice!: number;
    private _currency!: Currency;
    private _baseUnit!: Unit;

    public get baseUnit(): Unit {
        return this._baseUnit;
    }

    public set baseUnit(value: any) {
        this._setValue('_baseUnit', value, 'Unit');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get description(): string {
        return this._description;
    }

    public set description(value: any) {
        this._setValue('_description', value, 'string');
    }

    public get ecoTax(): number {
        return this._ecoTax;
    }

    public set ecoTax(value: any) {
        this._setValue('_ecoTax', value, 'float');
    }

    public get ecoTaxIncluded(): boolean {
        return this._ecoTaxIncluded;
    }

    public set ecoTaxIncluded(value: any) {
        this._setValue('_ecoTaxIncluded', value, 'boolean');
    }

    public get ecoTaxSector(): EcoTaxSector {
        return this._ecoTaxSector;
    }

    public set ecoTaxSector(value: any) {
        this._setValue('_ecoTaxSector', value, 'EcoTaxSector');
    }

    public get name(): string {
        return this._name;
    }

    public set name(value: any) {
        this._setValue('_name', value, 'string');
    }

    public get productGroup(): ProductGroup {
        return this._productGroup;
    }

    public set productGroup(value: any) {
        this._setValue('_productGroup', value, 'ProductGroup');
    }

    public get productType(): ProductType {
        return this._productType;
    }

    public set productType(value: any) {
        this._setValue('_productType', value, 'ProductType');
    }

    public get reference(): string {
        return this._reference;
    }

    public set reference(value: any) {
        this._setValue('_reference', value, 'string');
    }

    public get sellingPrice(): number {
        return this._sellingPrice;
    }

    public set sellingPrice(value: any) {
        this._setValue('_sellingPrice', value, 'float');
    }

}

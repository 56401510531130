<div class="h-full grid grid-nogutter">

    <!-- Colonne du formulaire de connexion -->
    <div class="col-12 lg:col-5 h-full">
        <div class="h-full flex flex-column justify-content-between">
            <!-- Logo en haut de page -->
            <div class="p-6 pb-0 pr-0">
                <img src="{{ appService.logoSrc }}" />
            </div>

            <!-- Conteneur du formulaire de connexion -->
            <div class="flex justify-content-center mb-8">
                <div class="col-8">
                    <app-login-form></app-login-form>
                </div>
            </div>

            <!-- Mention légales etc... -->
            <div class="flex justify-content-center p-3 text-sm">
                <div>Conditions d'utilisation</div>
                <div class="mx-3">•</div>
                <div>Politique de confidentialité</div>
            </div>
        </div>
    </div>

    <!-- Colonne de l'image -->
    <div class="col-7 h-full surface-50 justify-content-center align-items-center hidden lg:flex">
        <img src="assets/images/illustrations/login.png" />
    </div>
</div>
import moment from "moment";
import { ModelBase } from "../../abstract/model-base.model";
import { Currency } from "../../core/currency.model";
import { DiscountType } from "../../sales/discount-type.model";
import { InvoiceOrigin } from "../invoice-origin.model";
import { InvoiceType } from "../invoice-type.model";
import { Partner } from "../../partner/partner.model";
import { PaymentMethod } from "../../core/payment-method.model";
import { Shareholder } from "../../client/shareholder.model";

import { StatusEnum } from "src/app/@shared/enums/status.enum";
import { InvoiceTypeEnum } from "src/app/@shared/enums/invoice-type.enum";

export abstract class InvoiceBase extends ModelBase {

    private _accountEntryId!: number;
    private _accountEntryProfitLossId!: number;
    private _amount!: number;
    private _amountDue!: number;
    private _amountDueOrg!: number;
    private _comment!: string;
    private _creationDate!: moment.Moment;
    private _currency!: Currency;
    private _currencyOrg!: Currency;
    private _discount!: number;
    private _discountType!: DiscountType;
    private _dueDate!: moment.Moment;
    private _exchangeRate!: number;
    private _grossAmount!: number;
    private _grossAmountOrg!: number;
    private _headerOnly!: boolean;
    private _isExpense!: boolean;
    private _issueDate!: moment.Moment;
    private _label!: string;
    private _manageTax!: boolean;
    private _manualExchangeRate!: boolean;
    private _netAmount!: number;
    private _ocrResult!: object;
    private _origin!: InvoiceOrigin;
    private _partner!: Partner;
    private _paymentDate!: moment.Moment;
    private _paymentMethod!: PaymentMethod;
    private _paymentReference!: string;
    private _reference!: string;
    private _shareholder!: Shareholder;
    private _taxAmount!: number;
    private _type!: InvoiceType;

    public static ribbonColor(status: string): string {
        switch (true) {
            case (status === 'draft'):
                return 'rgba(215, 49, 57, 1)';
            case (status === 'cancelled'):
                return 'rgba(31, 41, 55, 1)';
            case (status === 'paid'):
                return 'rgba(25, 176, 115, 1)';
            case (status === 'partially_paid'):
                return 'rgba(241, 167, 0, 1)';
            case (status === 'validated'):
            default:
                return 'rgba(0, 87, 255, 1)';
        }
    }

    public static statusColor(status: string): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (status === 'draft'):
                return 'danger';
            case (status === 'cancelled'):
                return 'contrast';
            case (status === 'paid'):
                return 'success';
            case (status === 'partially_paid'):
                return 'warning';
            case (status === 'validated'):
                return undefined;
            default:
                return undefined;
        }
    }

    public static statusLabel(status: string): string {
        switch (true) {
            case (status === 'draft'):
                return 'À traiter';
            case (status === 'cancelled'):
                return 'Annulée';
            case (status === 'paid'):
                return 'Payée';
            case (status === 'partially_paid'):
                return 'Part. payée';
            case (status === 'validated'):
                return 'Validée';
            default:
                return '';
        }
    }

    public get isCreditNote(): boolean {
        return this._type.code === 'credit_note';
    }

    /**
     * Méthode pour voir si la facture est interne ou externe
     */
    public get isInternal(): boolean {
        return this.origin.code === 'internal';
    }

    public get isLate(): boolean {
        return this.hasStatus(StatusEnum.Validated) && this.amountDue > 0 && this.dueDate.isBefore(moment());
    }

    public get lateText(): string {
        return 'En retard de ' + this.dueDate.fromNow(true);
    }


    /**
     * Couleur de ruban selon le statut de la facture
     */
    public get ribbonColor(): string {
        switch (true) {
            case (this.hasStatus('cancelled')):
                return InvoiceBase.ribbonColor('cancelled');
            case (this.hasStatus('paid')):
                return InvoiceBase.ribbonColor('paid');
            case (this.hasStatus('partially_paid')):
                return InvoiceBase.ribbonColor('partially_paid');
            case (this.hasStatus('validated')):
                return InvoiceBase.ribbonColor('validated');
            case (this.hasStatus('draft')):
                return InvoiceBase.ribbonColor('draft');
            default:
                return InvoiceBase.ribbonColor('');
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusColor(): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
        switch (true) {
            case (this.hasStatus('cancelled')):
                return InvoiceBase.statusColor('cancelled');
            case (this.hasStatus('paid')):
                return InvoiceBase.statusColor('paid');
            case (this.hasStatus('partially_paid')):
                return InvoiceBase.statusColor('partially_paid');
            case (this.hasStatus('validated')):
                return InvoiceBase.statusColor('validated');
            case (this.hasStatus('draft')):
                return InvoiceBase.statusColor('draft');
            default:
                return InvoiceBase.statusColor('');
        }
    }

    /**
     * Les statuts sont testés par ordre de priorité
     */
    public get statusLabel(): string {
        switch (true) {
            case (this.hasStatus('cancelled')):
                return InvoiceBase.statusLabel('cancelled');
            case (this.hasStatus('paid')):
                return InvoiceBase.statusLabel('paid');
            case (this.hasStatus('partially_paid')):
                return InvoiceBase.statusLabel('partially_paid');
            case (this.hasStatus('validated')):
                return InvoiceBase.statusLabel('validated');
            case (this.hasStatus('draft')):
                return InvoiceBase.statusLabel('draft');
            default:
                return '';
        }
    }

    public get title(): string {
        let title = '';
        if (!!this.reference) {
            let invoiceTypeLabel = this.isCreditNote ? 'Avoir' : 'Facture';
            title = invoiceTypeLabel + ' ' + this.reference
        } else if (this.isCreditNote) {
            title = 'Nouvel avoir';
        } else {
            title = 'Nouvelle facture';
        }
        return title;
    }

    public override toString(): string {
        return `${this.partner.name} - ${this.isCreditNote ? 'Avoir' : 'Facture'} ${this.reference} du ${this.issueDate.format('DD/MM/YYYY')} - ${this.label}`;
    }

    /**
     * Getters et setters
     */

    public get accountEntryId(): number {
        return this._accountEntryId;
    }

    public set accountEntryId(value: any) {
        this._setValue('_accountEntryId', value, 'integer');
    }

    public get accountEntryProfitLossId(): number {
        return this._accountEntryProfitLossId;
    }

    public set accountEntryProfitLossId(value: any) {
        this._setValue('_accountEntryProfitLossId', value, 'integer');
    }

    public get amount(): number {
        return this._amount;
    }

    public set amount(value: any) {
        this._setValue('_amount', value, 'float');
    }

    public get amountDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.amount;
        } else {
            return this.amount;
        }
    }

    public get amountDue(): number {
        return this._amountDue;
    }

    public set amountDue(value: any) {
        this._setValue('_amountDue', value, 'float');
    }

    public get amountDueDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.amountDue;
        } else {
            return this.amountDue;
        }
    }

    public get amountDueOrg(): number {
        return this._amountDueOrg;
    }

    public set amountDueOrg(value: any) {
        this._setValue('_amountDueOrg', value, 'float');
    }

    public get amountDueOrgDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.amountDueOrg;
        } else {
            return this.amountDueOrg;
        }
    }

    public abstract get allocations();

    public abstract set allocations(value: any);

    public get comment(): string {
        return this._comment;
    }

    public set comment(value: any) {
        this._setValue('_comment', value, 'string');
    }

    public get creationDate(): moment.Moment {
        return this._creationDate;
    }

    public set creationDate(value: any) {
        this._setValue('_creationDate', value, 'date');
    }

    public get currency(): Currency {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'Currency');
    }

    public get currencyOrg(): Currency {
        return this._currencyOrg;
    }

    public set currencyOrg(value: any) {
        this._setValue('_currencyOrg', value, 'Currency');
    }

    public get discount(): number {
        return this._discount;
    }

    public set discount(value: any) {
        this._setValue('_discount', value, 'float');
    }

    public get discountType(): DiscountType {
        return this._discountType;
    }

    public set discountType(value: any) {
        this._setValue('_discountType', value, 'DiscountType');
    }

    public get dueDate(): moment.Moment {
        return this._dueDate;
    }

    public set dueDate(value: any) {
        this._setValue('_dueDate', value, 'date');
    }

    public get exchangeRate(): number {
        return this._exchangeRate;
    }

    public set exchangeRate(value: any) {
        let newValue = parseFloat(value) * 100000 / 100000;
        if (this.loaded && newValue !== this._exchangeRate) {
            // Modification survient après que l'objet a été instancié
            this.hasChanged = true;
        }
        this._exchangeRate = newValue;
    }

    public get grossAmount(): number {
        return this._grossAmount;
    }

    public set grossAmount(value: any) {
        this._setValue('_grossAmount', value, 'float');
        if (this._headerOnly) {
            this.netAmount = this.grossAmount - this.taxAmount;
        }
    }

    public get grossAmountDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.grossAmount;
        } else {
            return this.grossAmount;
        }
    }

    public get grossAmountOrg(): number {
        return this._grossAmountOrg;
    }

    public set grossAmountOrg(value: any) {
        this._setValue('_grossAmountOrg', value, 'float');
    }

    public get grossAmountOrgDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.grossAmountOrg;
        } else {
            return this.grossAmountOrg;
        }
    }

    public get hasOcrResult(): boolean {
        return Object.keys(this.ocrResult).length > 0;
    }

    public get headerOnly(): boolean {
        return this._headerOnly;
    }

    public set headerOnly(value: any) {
        this._setValue('_headerOnly', value, 'boolean');
    }

    public get isExpense(): boolean {
        return this._isExpense;
    }

    public set isExpense(value: any) {
        this._setValue('_isExpense', value, 'boolean');
    }

    public get issueDate(): moment.Moment {
        return this._issueDate;
    }

    public set issueDate(value: any) {
        this._setValue('_issueDate', value, 'date');
    }

    public abstract get items();

    public abstract set items(value: any);

    public get label(): string {
        return this._label;
    }

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get manageTax(): boolean {
        return this._manageTax;
    }

    public set manageTax(value: any) {
        this._setValue('_manageTax', value, 'boolean');
    }

    public get manualExchangeRate(): boolean {
        return this._manualExchangeRate;
    }

    public set manualExchangeRate(value: any) {
        this._setValue('_manualExchangeRate', value, 'boolean');
    }

    public get netAmount(): number {
        return this._netAmount;
    }

    public set netAmount(value: any) {
        this._setValue('_netAmount', value, 'float');
    }

    public get netAmountDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.netAmount;
        } else {
            return this.netAmount;
        }
    }

    public get ocrResult(): object {
        return this._ocrResult;
    }

    public set ocrResult(value: any) {
        this._setValue('_ocrResult', value, 'json');
    }

    public get origin(): InvoiceOrigin {
        return this._origin;
    }

    public set origin(value: any) {
        this._setValue('_origin', value, 'InvoiceOrigin');
    }

    public get partner(): Partner {
        return this._partner;
    }

    public set partner(value: any) {
        this._setValue('_partner', value, 'Partner');
    }

    public get paymentDate(): moment.Moment {
        return this._paymentDate;
    }

    public set paymentDate(value: any) {
        this._setValue('_paymentDate', value, 'date');
    }

    public get paymentMethod(): PaymentMethod {
        return this._paymentMethod;
    }

    public set paymentMethod(value: any) {
        this._setValue('_paymentMethod', value, 'PaymentMethod');
    }

    public get paymentReference(): string {
        return this._paymentReference;
    }

    public set paymentReference(value: any) {
        this._setValue('_paymentReference', value, 'string');
    }

    public get reference(): string {
        return this._reference;
    }

    public set reference(value: any) {
        this._setValue('_reference', value, 'string');
    }

    public get shareholder(): Shareholder {
        return this._shareholder;
    }

    public set shareholder(value: any) {
        this._setValue('_shareholder', value, 'Shareholder');
    }

    public abstract get taxes();

    public abstract set taxes(value: any);

    public get taxAmount(): number {
        return this._taxAmount;
    }

    public set taxAmount(value: any) {
        this._setValue('_taxAmount', value, 'float');
        if (this._headerOnly) {
            this.netAmount = this.grossAmount - this.taxAmount;
        }
    }

    public get taxAmountDisp(): number {
        if (this.type.code === InvoiceTypeEnum.CreditNote) {
            return -this.taxAmount;
        } else {
            return this.taxAmount;
        }
    }

    public get type(): InvoiceType {
        return this._type;
    }

    public set type(value: any) {
        this._setValue('_type', value, 'InvoiceType');
    }

}
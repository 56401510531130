@if (showContent) {
<div class="w-full h-full flex flex-column align-items-center justify-content-center">

    <div class="text-5xl font-semibold mb-5 text-primary">Bonjour et bienvenue sur Bfor !</div>

    <!-- Liste des organisations -->
    @if (clients.length > 0) {
    <div class="text-2xl font-bold mb-4">Sélectionnez une organisation.</div>

    <p-dataView #dv [value]="clients" styleClass="border-round-2xl">
        <ng-template pTemplate="list" let-clients>
            <div class="grid grid-nogutter h-full overflow-y-scroll">
                @for (client of clients; track client.id; let first = $first; let last = $last) {
                <div class="col-12">
                    <div class="flex flex-column sm:flex-row sm:align-items-center p-4 gap-3 cursor-pointer hover:bg-primary-200"
                        [ngClass]="{ 'border-top-1 surface-border': !first, 'border-round-top-2xl': first, 'border-round-bottom-2xl': last }"
                        (click)="onClickClient(client)">
                        <div class="md:w-10rem relative">
                            @if (!!client.logo) {
                            <img class="block xl:block mx-auto border-round w-full" [src]="client.logo"
                                [alt]="client.commercialName">
                            } @else {
                            <p-avatar label="{{client.commercialName.toUpperCase()[0]}}" styleClass="mr-2" size="xlarge"
                                shape="circle" />
                            }
                        </div>
                        <div
                            class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
                            <div class="flex flex-row md:flex-column justify-content-between align-items-start gap-2">
                                <div>
                                    <div class="text-lg font-medium text-900 mt-2">{{ client.commercialName }}</div>
                                    <span class="font-medium text-secondary text-sm">{{ client.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </ng-template>
    </p-dataView>

    <p-button class="mt-4" [rounded]="true" [raised]="true" (click)="onClickCreateOrganization()">Ajouter une nouvelle organisation</p-button>
    } @else {
    <div class="text-2xl font-semibold mb-4">Vous n'avez pas encore d'organisation.</div>
    <p-button [rounded]="true" [raised]="true" (click)="onClickCreateOrganization()">Ajouter ma première organisation</p-button>
    }
</div>
} @else {
<app-loading></app-loading>
}
import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'Question';
const ENDPOINT = `/questions/`;

@Injectable({
    providedIn: 'root'
})
export class QuestionService extends ServiceBase {

    protected override useCache = false;

    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

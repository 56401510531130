import { ModelBase } from '../abstract/model-base.model';
import { OrganizationIdentifierType } from '../legal-entity/organization-identifier-type.model';

export class PartnerIdentifier extends ModelBase {

    static override CLASS_NAME: string = 'PartnerIdentifier';

    private _type!: OrganizationIdentifierType;
    private _value!: string;

    public get type(): OrganizationIdentifierType {
        return this._type;
    }

    public set type(value: any) {
        this._setValue('_type', value, 'OrganizationIdentifierType');
    }

    public get value(): string {
        return this._value;
    }

    public set value(value: any) {
        this._setValue('_value', value, 'string');
    }
}

import moment from "moment";
import { ModelBase } from "../abstract/model-base.model";

export class KpiBankAccountBalances extends ModelBase {

    private _currency!: string;
    private _currentBalance!: number;
    private _dailyBalances!: KpiBankAccountDailyBalance[];
    private _totalIn!: number;
    private _totalOut!: number;

    public get currency(): string {
        return this._currency;
    }

    public set currency(value: any) {
        this._setValue('_currency', value, 'string');
    }

    public get currentBalance(): number {
        return this._currentBalance;
    }

    public set currentBalance(value: any) {
        this._setValue('_currentBalance', value, 'float');
    }

    public get dailyBalances(): KpiBankAccountDailyBalance[] {
        return this._dailyBalances;
    }

    public set dailyBalances(value: any) {
        this._setValue('_dailyBalances', value, 'KpiBankAccountDailyBalance');
    }

    public get totalIn(): number {
        return this._totalIn;
    }

    public set totalIn(value: any) {
        this._setValue('_totalIn', value, 'float');
    }

    public get totalOut(): number {
        return this._totalOut;
    }

    public set totalOut(value: any) {
        this._setValue('_totalOut', value, 'float');
    }

}

export class KpiBankAccountDailyBalance extends ModelBase {

    private _date!: moment.Moment;
    private _balance!: number;

    public get date(): moment.Moment {
        return this._date;
    }

    public set date(value: any) {
        this._setValue('_date', value, 'date');
    }

    public get balance(): number {
        return this._balance;
    }

    public set balance(value: any) {
        this._setValue('_balance', value, 'float');
    }

}
<ul class="list-none m-0 px-0 py-3">

    <!-- LIEN VERS DASHBOARD -->
    <li>
        <a class="menu-section-title" routerLink="/home/dashboard" routerLinkActive="active-link">
            <div>
                <i class="fa-solid fa-gauge"></i>
                <span>Dashboard</span>
            </div>
        </a>
    </li>

    <!-- BANQUE -->
    <li>
        <a class="menu-section-title flex justify-content-between"
            (click)="onClickSectionTitle('bank')"
            [routerLink]="appService.menuOpen ? null : '/banking/accounts'" routerLinkActive="active-link">
            <div class="with-items">
                <i class="fa-solid fa-building-columns"></i>
                <span class="flex-grow-1">Banque</span>
                @if (!showBankMenuItems) {
                <i class="pi pi-angle-down"></i>
                } @else {
                <i class="pi pi-angle-up"></i>
                }
            </div>
        </a>
        @if (showBankMenuItems) {
        <div>
            <a class="menu-item" routerLink="/banking/accounts" routerLinkActive="active-link">
                <div>Comptes bancaires</div>
            </a>
            <a class="menu-item" routerLink="/banking/cheques" routerLinkActive="active-link">
                <div>Chèques</div>
            </a>
            <a class="menu-item" routerLink="/banking/deposits" routerLinkActive="active-link">
                <div>Remises de chèque</div>
            </a>
            <a class="menu-item" routerLink="/banking/loans" routerLinkActive="active-link">
                <div>Emprunts</div>
            </a>
            <!-- <a class="menu-item" routerLink="/banking/overpayments" routerLinkActive="active-link">
                <div>Erreurs de paiement</div>
            </a> -->
        </div>
        }
    </li>

    <!-- VENTES -->
    <li>
        <a class="menu-section-title flex justify-content-between" (click)="onClickSectionTitle('sales')"
            [routerLink]="appService.menuOpen ? null : '/sales/invoices'" routerLinkActive="active-link">
            <div class="with-items">
                <i class="fa-solid fa-arrow-trend-up"></i>
                <span class="flex-grow-1">Ventes</span>
                @if (!showSalesMenuItems) {
                <i class="pi pi-angle-down"></i>
                } @else {
                <i class="pi pi-angle-up"></i>
                }
            </div>
        </a>
        @if (showSalesMenuItems) {
        <div>
            <a class="menu-item" routerLink="/sales/quotations" routerLinkActive="active-link">
                <div>Devis</div>
            </a>
            <a class="menu-item" routerLink="/sales/invoices" routerLinkActive="active-link">
                <div>Factures</div>
            </a>
            <a class="menu-item" routerLink="/sales/clients" routerLinkActive="active-link">
                <div>Clients</div>
            </a>
            <a class="menu-item" routerLink="/sales/products" routerLinkActive="active-link">
                <div>Articles</div>
            </a>
        </div>
        }
    </li>

    <!-- ACHATS -->
    <li>
        <a class="menu-section-title flex justify-content-between" (click)="onClickSectionTitle('purchasing')"
            [routerLink]="appService.menuOpen ? null : '/purchasing/invoices'" routerLinkActive="active-link">
            <div class="with-items">
                <i class="fa-solid fa-arrow-trend-down"></i>
                <span class="flex-grow-1">Achats</span>
                @if (!showPurchasingMenuItems) {
                <i class="pi pi-angle-down"></i>
                } @else {
                <i class="pi pi-angle-up"></i>
                }
            </div>
        </a>
        @if (showPurchasingMenuItems) {
        <div>
            <a class="menu-item" routerLink="/purchasing/invoices" routerLinkActive="active-link">
                <div>Factures</div>
            </a>
            <a class="menu-item" routerLink="/purchasing/vendors" routerLinkActive="active-link">
                <div>Fournisseurs</div>
            </a>
        </div>
        }
    </li>

    <!-- COMPTABILITÉ -->
    <li>
        <a class="menu-section-title flex justify-content-between" (click)="onClickSectionTitle('accounting')"
            [routerLink]="appService.menuOpen ? null : '/accounting/journals'" routerLinkActive="active-link">
            <div class="with-items">
                <i class="fa-solid fa-euro-sign"></i>
                <span class="flex-grow-1">Comptabilité</span>
                @if (!showAccountingMenuItems) {
                <i class="pi pi-angle-down"></i>
                } @else {
                <i class="pi pi-angle-up"></i>
                }
            </div>
        </a>
        @if (showAccountingMenuItems) {
        <div>
            <a class="menu-item" routerLink="/accounting/journals" routerLinkActive="active-link">
                <div>Journaux</div>
            </a>
            <!-- <a class="menu-item" routerLink="/accounting/balances" routerLinkActive="active-link">
                <div>Balances</div>
            </a>
            <a class="menu-item" routerLink="/accounting/global-ledger" routerLinkActive="active-link">
                <div>Grand livre</div>
            </a> -->
        </div>
        }
    </li>

    <!-- {{ Déclaration de TGC }} -->
    @if (!!appService.selectedClient?.isCaledonian && !!appService.selectedClient?.taxationSettings?.vatSubject) {
    <li>
        <a class="menu-section-title flex justify-content-start" routerLink="/taxation/tgc-declarations"
            routerLinkActive="active-link">
            <div>
                <i class="fa-solid fa-coins"></i>
                <span>Déclarations de {{ taxNameLabel }}</span>
            </div>
        </a>
    </li>
    }
</ul>
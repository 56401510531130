import { ModelBase } from '../abstract/model-base.model';
import { PhoneAreaCode } from '../address/phone-area-code.model';

export class User extends ModelBase {

    static override CLASS_NAME: string = 'User';

    private _avatar!: string;
    private _avatarIcon!: string;
    private _avatarLabelColor!: string;
    private _email!: string;
    private _firstName!: string;
    private _isStaff!: boolean;
    private _lastName!: string;
    private _phone1!: string;
    private _phone1AreaCode!: PhoneAreaCode;
    private _phone2!: string;
    private _phone2AreaCode!: PhoneAreaCode;
    private _authorizations!: string[];

    constructor(data?: any) {
        super(data);

        if (!this._authorizations) {
            this._authorizations = [];
        }
    }

    public hasAuhtorization(authorization: string): boolean {
        return this.authorizations.includes(authorization);
    }

    public override toString(): string {
        return this.fullName;
    }

    public get authorizations(): string[] {
        return this._authorizations;
    }

    public set authorizations(value: any) {
        this._setValue('_authorizations', value, 'string');
    }

    public set avatar(value: any) {
        this._setValue('_avatar', value, 'string');
    }

    public get avatar(): string {
        return this._avatar;
    }

    public get avatarIcon(): string {
        if (!this.avatar && !this.avatarLabel) {
            return !!this._avatarIcon ? this._avatarIcon : 'pi pi-user';
        } else {
            return '';
        }
    }

    public set avatarIcon(value: any) {
        this._setValue('_avatarIcon', value, 'string');
    }

    public get avatarLabel(): string {
        if (!this.avatar && !!this.firstName && !!this.lastName) {
            return `${this.firstName.toUpperCase()[0]}${this.lastName.toUpperCase()[0]}`
        } else {
            return '';
        }
    }

    public set avatarLabelColor(value: any) {
        this._setValue('_avatarLabelColor', value, 'string');
    }

    public get avatarLabelColor(): string {
        return this._avatarLabelColor;
    }

    public get email(): string {
        return this._email;
    }

    public set email(value: any) {
        this._setValue('_email', value, 'string');
    }

    public get firstName(): string {
        return this._firstName;
    }

    public set firstName(value: any) {
        this._setValue('_firstName', value, 'string');
    }

    public get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    public get isStaff(): boolean {
        return this._isStaff;
    }

    public set isStaff(value: any) {
        this._setValue('_isStaff', value, 'boolean');
    }

    public get lastName(): string {
        return this._lastName;
    }

    public set lastName(value: any) {
        this._setValue('_lastName', value, 'string');
    }

    public get phone1(): string {
        return this._phone1;
    }

    public set phone1(value: any) {
        this._setValue('_phone1', value, 'string');
    }

    public get phone1AreaCode(): PhoneAreaCode {
        return this._phone1AreaCode;
    }

    public set phone1AreaCode(value: any) {
        this._setValue('_phone1AreaCode', value, 'PhoneAreaCode');
    }

    public get phone2(): string {
        return this._phone2;
    }

    public set phone2(value: any) {
        this._setValue('_phone2', value, 'string');
    }

    public get phone2AreaCode(): PhoneAreaCode {
        return this._phone2AreaCode;
    }

    public set phone2AreaCode(value: any) {
        this._setValue('_phone2AreaCode', value, 'PhoneAreaCode');
    }
}

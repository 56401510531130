import { ModelBase } from "../../models/abstract/model-base.model";

export class ODataMessage extends ModelBase {

    private _domain!: string;
    private _number!: number;
    private _type!: string;
    private _code!: string;
    private _shortText!: string;
    private _longText!: string;
    private _uuid!: string;

    public get code(): string {
        return this._code;
    }

    public set code(value: string) {
        this._code = value;
    }

    public get domain(): string {
        return this._domain;
    }

    public set domain(value: string) {
        this._domain = value;
    }

    public get longText(): string {
        return this._longText;
    }

    public set longText(value: string) {
        this._longText = value;
    }

    public get number(): number {
        return this._number;
    }

    public set number(value: number) {
        this._number = value;
    }

    public get shortText(): string {
        return this._shortText;
    }

    public set shortText(value: string) {
        this._shortText = value;
    }

    public get type(): string {
        return this._type;
    }

    public set type(value: string) {
        this._type = value;
    }

    public get uuid(): string {
        return this._uuid;
    }

    public set uuid(value: string) {
        this._uuid = value;
    }
}

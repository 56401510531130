import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-legal-footer',
    imports: [
        RouterModule
    ],
    templateUrl: './legal-footer.component.html',
    styleUrl: './legal-footer.component.scss'
})
export class LegalFooterComponent {

}

import moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { Tax } from '../core/tax.model';
import { TaxationRegime } from './taxation-regime.model';
import { TaxationType } from './taxation-type.model';
import { VatChargeability } from './vat-chareability.model';
import { VatFrequency } from './vat-frequency.model';
import { LegalStatus } from './legal-status.model';

export class TaxationSettings extends ModelBase {

    static override CLASS_NAME: string = 'TaxationSettings';

    private _legalStatus!: LegalStatus;
    private _taxationRegime!: TaxationRegime;
    private _taxationType!: TaxationType;
    private _vatChargeability!: VatChargeability;
    private _vatDateFrom!: moment.Moment;
    private _vatDefault!: Tax;
    private _vatFrequency!: VatFrequency;
    private _vatSubject!: boolean;

    public get legalStatus(): LegalStatus {
        return this._legalStatus;
    }

    public set legalStatus(value: any) {
        this._setValue('_legalStatus', value, 'LegalStatus');
    }

    public get taxationRegime(): TaxationRegime {
        return this._taxationRegime;
    }

    public set taxationRegime(value: any) {
        this._setValue('_taxationRegime', value, 'TaxationRegime');
    }

    public get taxationType(): TaxationType {
        return this._taxationType;
    }

    public set taxationType(value: any) {
        this._setValue('_taxationType', value, 'TaxationType');
    }

    public get vatChargeability(): VatChargeability {
        return this._vatChargeability;
    }

    public set vatChargeability(value: any) {
        this._setValue('_vatChargeability', value, 'VatChargeability');
    }

    public get vatDateFrom(): moment.Moment {
        return this._vatDateFrom;
    }

    public set vatDateFrom(value: any) {
        this._setValue('_vatDateFrom', value, 'date');
    }

    public get vatDefault(): Tax {
        return this._vatDefault;
    }

    public set vatDefault(value: any) {
        this._setValue('_vatDefault', value, 'Tax');
    }

    public get vatFrequency(): VatFrequency {
        return this._vatFrequency;
    }

    public set vatFrequency(value: any) {
        this._setValue('_vatFrequency', value, 'VatFrequency');
    }

    public get vatSubject(): boolean {
        return this._vatSubject;
    }

    public set vatSubject(value: any) {
        this._setValue('_vatSubject', value, 'boolean');
    }

}

import { Injectable } from '@angular/core';

// Services
import { ServiceBase } from '../service-base';

const CLASS_NAME = 'AccountEntryNumberRange';
const ENDPOINT = `/account-entry-number-ranges/`;

@Injectable({
    providedIn: 'root'
})
export class AccountEntryNumberRangeService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
    }
}

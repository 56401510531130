import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { BankAccountRule } from 'src/app/@shared/models/banking/bank-account-rule.model';

const CLASS_NAME = 'BankAccountRule';
const ENDPOINT = `/bank-account-rules/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'matches', path: 'matches/', childClass: 'BankAccountLine' },
]
const ROUTES: RouteParam[] = [
    { key: 'apply', path: '{id}/apply/' },
]

@Injectable({
    providedIn: 'root'
})
export class BankAccountRuleService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public applyRule(rule: BankAccountRule): void {
        let url = this.routes.apply.replace('{id}', rule.id.toString());
        this.update(rule, {
            url: url
        });
    }
}

// Angular
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Validators, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { finalize } from 'rxjs/operators';

// Prime NG
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

// Services
import { AuthenticationService } from 'src/app/@core/authentication/services/authentication.service';
import { Logger } from 'src/app/@core/services/logger/logger.service';

// Composants
import { FormBaseComponent } from 'src/app/@shared/components/abstract/form-base.component';

const LOGGER = new Logger('LoginFormComponent');

@Component({
    selector: 'app-login-form',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        ButtonModule,
        InputSwitchModule,
        InputTextModule,
        PasswordModule,
    ],
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent extends FormBaseComponent {

    constructor(private _authenticationService: AuthenticationService) {
        super();
    }

    protected override buildForm() {
        // Login
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            remember: true
        });
    }

    public login(): void {
        this.loading = true;
        const login$ = this._authenticationService.login$(this.form.value);
        login$
            .pipe(
                finalize(() => {
                    this.form.markAsPristine();
                    this.loading = false;
                })
            )
            .subscribe({
                next: () => {
                    this.router.navigate([this.route.snapshot.queryParams['redirect'] || '/home'], { replaceUrl: true });
                    this.userService.loadUserData();
                },
                error: (error: any) => {
                    LOGGER.info(`Erreur de connexion: ${error}`);
                },
            });
    }
}

import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, map, finalize, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
// Interfaces
import { SaveOptions } from '../interfaces/save-options';
// Classes
import { GeneralFactory } from 'src/app/@shared/models/factories/general-factory';
import { ModelBase } from 'src/app/@shared/models/abstract/model-base.model';
import { AuthorizedStatus } from 'src/app/@shared/models/core/authorized-status.model';
import { File as FileModel } from 'src/app/@shared/models/file/file.model';
// Utilities
import { QueryOptions, QueryOptionsParam } from 'src/app/@shared/utilities/odata/query-params';
import { ODataListResponse, ODataResponse, ODataError } from 'src/app/@shared/utilities/odata/response';
import { UploadProgress } from 'src/app/@shared/utilities/http/upload-progress';
// Services
import { MessageServiceBroker } from './message.service';
import { Logger } from './logger/logger.service';
import { inject } from '@angular/core';

const LOGGER = new Logger("ServiceBase");

export interface NavigationParam {
    key: string,
    childClass: string | null
    path: string,
    resultCardinality?: 'one' | 'many'
}

export interface RouteParam {
    key: string,
    path: string
}

export class ServiceBase {

    // Behaviour subjects
    // Contient l'état actuel du service et permettent des appels HTTP ou des actions de l'application
    // 1. Objet contenant l'instance en cours de traitement
    protected _selected$: any = null;
    // 2. Évènements
    protected _archived$: Subject<any> = new Subject<any>();
    protected _authorizedStatusLoaded$: Subject<any> = new Subject<AuthorizedStatus[]>();
    protected _created$: Subject<any> = new Subject<any>();
    protected _deleted$: Subject<number> = new Subject<number>();
    protected _updated$: Subject<any> = new Subject<any>();
    protected _uploaded$: Subject<any> = new Subject<any>();
    protected _validated$: Subject<number> = new Subject<number>();
    // 3. Indicateurs d'activité
    protected _counting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _creating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _deleting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _downloading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _fetching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _reading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _updating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _uploading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // Obeservables pour les éléments définis au dessus
    public readonly selected$: any = null;
    public readonly archived$ = this._archived$.asObservable();
    public readonly authorizedStatusLoaded$ = this._authorizedStatusLoaded$.asObservable();
    public readonly counting$ = this._counting$.asObservable();
    public readonly created$ = this._created$.asObservable();
    public readonly creating$ = this._creating$.asObservable();
    public readonly deleted$ = this._deleted$.asObservable();
    public readonly deleting$ = this._deleting$.asObservable();
    public readonly downloading$ = this._downloading$.asObservable();
    public readonly fetching$ = this._fetching$.asObservable();
    public readonly reading$ = this._reading$.asObservable();
    public readonly updated$ = this._updated$.asObservable();
    public readonly updating$ = this._updating$.asObservable();
    public readonly uploaded$ = this._uploaded$.asObservable();
    public readonly uploading$ = this._uploading$.asObservable();
    public readonly validated$ = this._validated$.asObservable();

    // Services
    protected httpClient: HttpClient;
    protected messageService: MessageServiceBroker;

    // Indicateurs de progrès
    private _progressArray$: BehaviorSubject<UploadProgress[]> = new BehaviorSubject<UploadProgress[]>([]);
    public readonly progressArray$: Observable<UploadProgress[]> = this._progressArray$.asObservable();

    // Routes
    protected routes: any = {};
    protected navigations: any = {};

    // Gestion du cache
    protected useCache: boolean = false;
    protected cacheDuration: number = 60 * 60 * 24 * 1000; // 24 heures
    protected cachedQueries: { [key: string]: { request: Observable<any | any[]>, expiry: number } } = {};

    constructor(
        protected className: string,
        protected endpoint: string
    ) {
        this.httpClient = inject(HttpClient);
        this.messageService = inject(MessageServiceBroker);
        // Éléments sélectionné, cela doit être défini dynamiquement
        this._selected$ = new BehaviorSubject<any>(null);
        this.selected$ = this._selected$.asObservable();

        // Construction des routes par défaut
        this.routes = {
            archive: this.endpoint + `{id}/archive/`,
            authorizedStatus: this.endpoint + `authorized-status/`,
            create: this.endpoint,
            delete: this.endpoint + `{id}/`,
            list: this.endpoint,
            next: this.endpoint + `{id}/next/`,
            previous: this.endpoint + `{id}/previous/`,
            single: this.endpoint + `{id}/`,
            update: this.endpoint + `{id}/`
        };
    }

    /**
     * Méthode d'ajout des propriétés de navigations
     */
    public addNavigations(navigations: NavigationParam[]): void {
        for (let i = 0; i < navigations.length; i++) {
            this.navigations[navigations[i].key] = {
                childClass: navigations[i].childClass,
                path: navigations[i].path,
                resultCardinality: navigations[i].resultCardinality || 'many'
            }
        }
    }

    /**
     * Méthode d'ajout d'une route aux routes par défaut
     */
    public addRoutes(routes: RouteParam[]): void {
        for (let i = 0; i < routes.length; i++) {
            this.routes[routes[i].key] = this.endpoint + routes[i].path;
        }
    }

    /**
     * Méthode qui appelle le service d'archivage d'un objet
     * @param {any} instance L'objet à archiver
     */
    public archive(instance: ModelBase, options?: SaveOptions) {
        // Options par défaut
        options = this.fillDefaultSaveOptions(options);
        // Construction de l'URL
        options.url = this.routes.archive.replace('{id}', instance.id.toString());
        // Appel de la méthode update
        this.update$(instance, options).subscribe(() => {
            this._archived$.next(instance);
        });
    }

    /**
     * Méthode qui supprime les requêtes en cache
     */
    public clearCache() {
        this.cachedQueries = {};
    }

    /**
     * Appel du service de comptage d'une collection
     */
    public count$(options?: QueryOptions): Observable<any> {
        // URL
        let queryOptions = new QueryOptions(this.routes.list, options);
        let queryUrl = queryOptions.toUrlString() + '/$count';

        // Activation de l'indicateur d'activité
        this._counting$.next(queryOptions.activityIndicator);
        return this.httpClient.get(queryUrl).pipe(
            finalize(() => {
                this._counting$.next(false);
            }),
            map((count: any) => {
                return count;
            })
        );
    }

    /**
     * Appel du service de création de l'objet
     * @param instance L'objet à sauvegarder
     */
    protected create$(instance: ModelBase, options?: SaveOptions): Observable<any> {
        options = this.fillDefaultSaveOptions(options);
        // Indiquer à l'application que le service est en train de créer un objet
        this._creating$.next(true);
        instance.busy = true;

        let url = !!options.url ? options.url : this.routes.create;

        return this.httpClient.post(url, this._toApi(instance)).pipe(
            finalize(() => {
                instance.busy = false;
                this._creating$.next(false);
            }),
            map((body: any) => {
                let response = new ODataResponse(body);
                if (options?.showMessages) {
                    // Afficher les messages reçus
                    this.messageService.sendODataMessages(response.messages);
                }
                // Création d'une instance de l'objet
                instance = (GeneralFactory.get(!!options?.resultClass ? options.resultClass : this.className, response.value) as ModelBase);
                // Envoyer un évènement pour informer l'application de la création réussie de l'objet
                if (!!options?.emitEvent) {
                    this._created$.next(instance);
                }
                // Sélectionner l'instance pour la rendre disponible dans l'application
                if (!!options?.select) {
                    this.select(instance);
                }
                // Retourner l'instance
                return instance;
            }),
            catchError(
                (error: any) => {
                    this.handleError(error);
                    return of(false);
                }
            )
        );
    }

    /**
     * Appel de la méthode de création, sans nécessiter de souscrire à l'observable
     * @param instance L'objet à sauvegarder
     */
    protected create(instance: ModelBase, options?: SaveOptions) {
        this.create$(instance, this.fillDefaultSaveOptions(options)).subscribe(() => { });
    }

    /**
     * Crée un éléments lié à un objet
     * @param {any} instance Objet à créer
     * @param {any} parent Objet parent
     * @param {string} navName Propriété de navigation
     */
    public createRelated$(instance: any, parent: any, navName: string, options?: SaveOptions): Observable<any> {
        // Complete options
        let url = this.routes['single'].replace('{id}', parent.id.toString()) + this.navigations[navName].path;
        options = this.fillDefaultSaveOptions(options);
        options.resultClass = this.navigations[navName].childClass;
        options.emitEvent = false;
        options.select = false;
        options.url = url;
        return this.create$(instance, options).pipe(
            map(instance => {
                this._updated$.next(parent);
                return instance;
            })
        );
    }

    /**
     * Crée un éléments lié à un objet, sans souscription
     * @param {any} instance Objet à créer
     * @param {any} parent Objet parent
     * @param {string} navName Propriété de navigation
     */
    public createRelated(instance: any, parent: any, navName: string, options?: SaveOptions) {
        try {
            this.createRelated$(instance, parent, navName, options).subscribe(() => { });
        } catch (_) { }
    }

    /**
     * Appel du service de suppression de l'objet
     * @param instance L'objet à supprimer
     */
    private _delete$(instance: ModelBase, options?: SaveOptions): Observable<boolean> {
        if (!instance?.id) {
            // Aucun ID de fourni
            return of(false);
        }
        // Options par défaut
        options = this.fillDefaultSaveOptions(options);
        // Indicate to the application that service is deleting an object
        // Indicateurs d'activité
        this._deleting$.next(true);
        instance.busy = true;

        let url = this.routes.delete.replace('{id}', instance.id.toString());

        // Appel HTTP
        return this.httpClient.delete(url, { body: this._toApi(instance) }).pipe(
            finalize(() => {
                instance.busy = false;
                this._deleting$.next(false);
            }),
            map((body: any) => {
                let response = new ODataResponse(body);
                if (options?.showMessages) {
                    // Afficher les messages de la réponse
                    this.messageService.sendODataMessages(response.messages);
                }
                // Évènement pour informer l'application de la suppression de l'objet
                this._deleted$.next(instance.id);
                return true;
            }),
            catchError(
                (error: any) => {
                    // Manage error
                    this.handleError(error);
                    return of(false);
                }
            )
        );
    }

    /**
     * Suppression, sans besoin de souscrire à l'observable
     * @param instance L'objet à supprimer
     */
    private _delete(instance: ModelBase, options?: SaveOptions) {
        this._delete$(instance, this.fillDefaultSaveOptions(options)).subscribe(() => { });
    }

    /**
     * Télécharge un fichier et renvoie un Blob
     * @param id Identifiant du fichier ou de l'objet auquel le fichier est lié.
     * Cet identifiant viendra en remplacement du marqueur sur l'URI.
     * Une route 'downloadFile' doit exister sur le service
     */
    public downloadFile$(id: number, emitEvent: boolean = true): Observable<Blob> {
        let url = this.routes.downloadFile.replace('{id}', id.toString());
        if (emitEvent) {
            this._downloading$.next(true);
        }
        return this.httpClient.get(url, {
            responseType: 'blob'
        }).pipe(
            finalize(() => {
                if (emitEvent) {
                    this._downloading$.next(false)
                }
            }),
            map(response => { return response })
        );
    }

    /**
     * Télécharge un fichier depuis une URL en utilisant l'API Fetch et retourne une Observable.
     * @param url L'URL du fichier à télécharger.
     * @returns Une Observable résolvant avec le contenu du fichier sous forme de Blob.
     */
    public downloadFromUrl$(url: string, emitEvent: boolean = true): Observable<Blob> {
        if (emitEvent) {
            this._downloading$.next(true);
        }
        return new Observable(observer => {
            fetch(url)
                .then(response => {
                    if (emitEvent) {
                        this._downloading$.next(false)
                    }
                    if (!response.ok) {
                        throw new Error(`Erreur de téléchargement : ${response.statusText}`);
                    }
                    return response.blob();
                })
                .then(blob => {
                    observer.next(blob);
                    observer.complete();
                })
                .catch(error => {
                    observer.error(error);
                });
        });
    }

    /**
     * Méthode permettant de lancer un évènement depuis l'extérieur de la classe
     *
     * Pour l'instant ce n'est utilisé que pour les évènements de création, dans le cas où la création
     * aura été faite dans le programme appelant par la méthode createRelated
     */
    public emitEvent(event: string, data: any) {
        switch (event) {
            case 'created':
                this._created$.next(data);
                break;
            default:
                break;
        }
    }

    /**
     * Remplissage des options par défaut
     * @param {SaveOptions} options
     * @return {SaveOptions} Les options complétées
     */
    protected fillDefaultSaveOptions(options?: SaveOptions): SaveOptions {
        options = !!options ? options : {};
        options.showMessages = typeof options.showMessages === 'undefined' ? true : options.showMessages;
        options.emitEvent = typeof options.emitEvent === 'undefined' ? true : options.emitEvent;
        options.select = typeof options.select === 'undefined' ? true : options.select;
        options.callbacks = typeof options.callbacks === 'undefined' ? [] : options.callbacks;
        options.resultClass = options?.resultClass;

        return options;
    }

    /**
     * Méthode qui retourne une seule entité lue sur le serveur
     * @param {number} id Instance ID
     * @param {boolean} [select=true] Faut-il sélectionner directement l'entité ?
     */
    public get$(id: number, select: boolean = true): Observable<any> {
        // Vérification qu'un ID est fourni
        if (!id) {
            // Non fourni
            return of(null);
        }
        // Indicateur d'activité
        this._reading$.next(true);

        let url = this.routes.single.replace('{id}', id.toString());
        return this.httpClient.get(url).pipe(
            finalize(() => {
                this._reading$.next(false);
            }),
            map((result: any) => {
                // Instanciation de l'objet lu
                let instance = (GeneralFactory.get(this.className, result) as ModelBase);
                if (select) {
                    // Select the instance
                    this.select(instance);
                }
                return instance;
            }),
            catchError(
                (error: any) => {
                    // Manage error
                    this.handleError(error);
                    return of(false);
                }
            )
        );
    }

    /**
     * Lecture d'un objet sans besoin de souscrire
     * @param {number} id Instance ID
     * @param {boolean} [select=true] Faut-il sélectionner directement l'entité ?
     */
    public get(id: number, select: boolean = true) {
        this.get$(id, select).subscribe(() => { });
    }

    public getAttachments$(instance: any, options?: QueryOptionsParam): Observable<FileModel[]> {
        // Complete options
        return this.getRelated$(instance, 'attachments', options);
    }

    public getAuthorizedStatus$(options?: QueryOptionsParam): Observable<any | any[]> {
        // URL
        let queryOptions = new QueryOptions(this.routes.authorizedStatus, options);
        let queryUrl = queryOptions.toUrlString();

        return this.httpClient
            .get(queryUrl)
            .pipe(
                map((json: any) => {
                    let response = new ODataListResponse(json);
                    // Construction des instances
                    let instances: AuthorizedStatus[] = [];
                    for (let result of response.results) {
                        instances.push(new AuthorizedStatus(result));
                    }
                    // Remplacement des résultats bruts par les instances
                    response.results = instances;
                    this._authorizedStatusLoaded$.next(instances);
                    return instances;
                }),
                catchError(
                    (error: any) => {
                        // Manage error
                        this.handleError(error);
                        return of(false);
                    }
                )
            )
    }

    /**
     * Renvoie une collection d'objets
     * @param {QueryOptions} options Paramètres de requête
     */
    public getList$(options?: QueryOptionsParam): Observable<any | any[]> {
        // URL
        let queryOptions = new QueryOptions(this.routes.list, options);
        let queryUrl = queryOptions.toUrlString();

        if (this.cachedQueries[queryUrl] && this.cachedQueries[queryUrl].expiry > Date.now()) {
            return this.cachedQueries[queryUrl].request;
        }

        // Indicateur d'activité
        this._fetching$.next(queryOptions.activityIndicator);
        // Appel du service
        const request$ = this.httpClient
            .get(queryUrl)
            .pipe(
                finalize(() => {
                    this._fetching$.next(false);
                }),
                map((json: any) => {
                    let response = new ODataListResponse(json);
                    // Construction des instances
                    let data: any | any[];
                    if (queryOptions.resultCardinality === 'many') {
                        data = [];
                        for (let result of response.results) {
                            if (!!queryOptions.resultClass || queryOptions.resultClass === undefined) {
                                data.push(GeneralFactory.get(!!queryOptions.resultClass ? queryOptions.resultClass : this.className, result));
                            } else {
                                data.push(result);
                            }
                        }
                    } else {
                        // Ce cas se produire dans le cas des navigations entre objets
                        data = GeneralFactory.get(!!queryOptions.resultClass ? queryOptions.resultClass : this.className, response.results);
                    }
                    if (queryOptions.count) {
                        return {
                            count: response.count,
                            results: data
                        };
                    } else {
                        return data;
                    }
                }),
                catchError(
                    (error: any) => {
                        // Gestions des erreurs
                        try {
                            this.handleError(error);
                        } catch (_) { }
                        // Retourner une valeur par défaut
                        if (queryOptions.count) {
                            return of({
                                count: 0,
                                results: []
                            });
                        } else {
                            return of(queryOptions.resultCardinality === 'many' ? [] : null);
                        }
                    }
                ),
                shareReplay(1)
            );

        // Mise en cache
        if (this.useCache) {
            this.cachedQueries[queryUrl] = {
                request: request$,
                expiry: Date.now() + this.cacheDuration
            };
        }

        return request$;
    }

    /**
     * Renvoie une collection d'objets, sans souscrire
     * @param {QueryOptions} options Paramètres de requête
     */
    public getList(options?: QueryOptionsParam) {
        this.getList$(options).subscribe(() => { });
    }

    /**
     * Retrouve les données d'une collection à partir de l'objet parent
     * @param {any} parent Objet parent
     * @param {string} navName Propriété de navigation
     */
    public getRelated$(parent: any, navName: string, optionsIn?: QueryOptionsParam) {
        // Complete options
        let url = '';
        try {
            url = this.routes['single'].replace('{id}', parent.id.toString()) + this.navigations[navName].path;
        } catch (e) {
            url = this.routes['single'].replace('{id}', parent.code.toString()) + this.navigations[navName].path;
        }
        let options = new QueryOptions(url, optionsIn);
        options.resultClass = this.navigations[navName].childClass;
        options.resultCardinality = this.navigations[navName].resultCardinality || 'many';
        if (!optionsIn?.activityIndicator) {
            options.activityIndicator = false;
        }
        return this.getList$(options);
    }

    /**
     * Retrouve les données d'une collection à partir de l'objet parent, sans souscription
     * @param {any} parent Objet parent
     * @param {string} navName Propriété de navigation
     */
    public getRelated(navName: string, parent: any, options?: QueryOptionsParam) {
        try {
            this.getRelated$(parent, navName, options).subscribe(() => { });
        } catch (_) { }
    }

    /**
     * Méthode générique de gestion des erreurs
     *
     * Elle transforme l'erreur HTTP standard reçue en un format unique, et la propage
     *
     * @param {any} error L'objet d'erreur
     * @throws {ODataError} L'erreur au format ODataError. L'exception est toujours lancée !
     */
    protected handleError(e: any) {
        let error = new ODataError(e);
        this.messageService.sendODataMessages(error.messages);
        throw error;
    }

    /**
     * Méthode qui informe si un objet est sélectionné
     * @returns {boolean}
     */
    public hasSelection(): boolean {
        return !!this._selected$.getValue();
    }

    public massDelete$(instances: ModelBase[], options?: SaveOptions): Observable<null> {
        options = this.fillDefaultSaveOptions(options);
        // Indicate to the application that service is creating an object
        this._deleting$.next(true);
        // Les indicateurs d'activité de toutes les instances sont à mettre à jour
        for (let i = 0; i < instances.length; i++) {
            instances[i].busy = true;
        }

        // L'url doit obligatoirement est renseignée dans les options
        if (!options.url) {
            throw new Error(`L'URL de suppression doit être renseignée`);
        }

        // Conversion des instances pour l'API
        let data = [];
        for (let i = 0; i < instances.length; i++) {
            data.push(this._toApi(instances[i]));
        }

        return this.httpClient.post(options.url, data).pipe(
            finalize(() => {
                this._deleting$.next(false);
            }),
            map((body: any) => {
                if (!!body) {
                    let response = new ODataResponse(body);
                    if (options?.showMessages) {
                        // Afficher les messages reçus
                        this.messageService.sendODataMessages(response.messages);
                    }
                }
                return null;
            }),
            catchError(
                (error: any) => {
                    this.handleError(error);
                    return of(null);
                }
            )
        );
    }

    public massUpdate$(instances: ModelBase[], options?: SaveOptions): Observable<null> {
        options = this.fillDefaultSaveOptions(options);
        // Indicate to the application that service is creating an object
        this._updating$.next(true);
        // Les indicateurs d'activité de toutes les instances sont à mettre à jour
        for (let i = 0; i < instances.length; i++) {
            instances[i].busy = true;
        }

        // L'url doit obligatoirement est renseignée dans les options
        if (!options.url) {
            throw new Error(`L'URL de mise à jour doit être renseignée`);
        }

        // Conversion des instances pour l'API
        let data = [];
        for (let i = 0; i < instances.length; i++) {
            data.push(this._toApi(instances[i]));
        }

        return this.httpClient.post(options.url, data).pipe(
            finalize(() => {
                this._updating$.next(false);
            }),
            map((body: any) => {
                if (!!body) {
                    let response = new ODataResponse(body);
                    if (options?.showMessages) {
                        // Afficher les messages reçus
                        this.messageService.sendODataMessages(response.messages);
                    }
                }
                return null;
            }),
            catchError(
                (error: any) => {
                    this.handleError(error);
                    return of(null);
                }
            )
        );
    }

    /**
     * Méthode qui appelle l'instance suivante d'un objet, sans souscrire
     */
    public next(instance: ModelBase) {
        this.next$(instance).subscribe(() => { });
    }

    /**
     * Méthode qui appelle l'instance suivante d'un objet
     * L'instance suivante est celle qui a été créée après l'instance actuelle
     */
    public next$(instance: ModelBase): Observable<any> {
        // Construction de l'URL
        let url = this.routes.next.replace('{id}', instance.id.toString());
        return this.httpClient.get(url).pipe(
            finalize(() => {
                this._reading$.next(false);
            }),
            map((result: any) => {
                // Instanciation de l'objet lu
                let instance = (GeneralFactory.get(this.className, result) as ModelBase);
                return instance;
            })
        );
    }

    /**
     * Méthode qui appelle l'instance précédente d'un objet, sans souscrire
     */
    public previous(instance: ModelBase) {
        this.previous$(instance).subscribe(() => { });
    }

    /**
     * Méthode qui appelle l'instance précédente d'un objet
     * L'instance précédente est celle qui a été créée avant l'instance actuelle
     */
    public previous$(instance: ModelBase): Observable<any> {
        // Construction de l'URL
        let url = this.routes.previous.replace('{id}', instance.id.toString());
        return this.httpClient.get(url).pipe(
            finalize(() => {
                this._reading$.next(false);
            }),
            map((result: any) => {
                // Instanciation de l'objet lu
                let instance = (GeneralFactory.get(this.className, result) as ModelBase);
                return instance;
            })
        );
    }

    public reload() {
        let id = this._selected$.getValue().id;
        this.unselect();
        setTimeout(() => {
            this.get(id);
        }, 10);
    }

    /**
     * Méthode générique de sauvegarde d'un objet.
     * Elle va détecter quelle opération CRUD doit être effectuée
     * @param {any} instance L'instance à sauvegarder
     */
    public save$(instance: ModelBase, options?: SaveOptions): Observable<any> {
        // Vérification des flags
        if (instance.new) {
            // Cette instance est nouvelle, appel de la méthode de création
            return this.create$(instance, this.fillDefaultSaveOptions(options));
        } else if (instance.deleted) {
            // L'instance est marquée pour suppression
            return this._delete$(instance, this.fillDefaultSaveOptions(options));
        } else {
            // Mise à jour
            return this.update$(instance, this.fillDefaultSaveOptions(options));
        }
    }

    /**
     * Méthode générique de sauvegarde d'un objet, sans souscription
     * Elle va détecter quelle opération CRUD doit être effectuée
     * @param {any} instance L'instance à sauvegarder
     */
    public save(instance: ModelBase, options?: SaveOptions) {
        // Check instance flags
        if (instance.new) {
            // Cette instance est nouvelle, appel de la méthode de création
            this.create(instance, this.fillDefaultSaveOptions(options));
        } else if (instance.deleted) {
            // L'instance est marquée pour suppression
            this._delete(instance, this.fillDefaultSaveOptions(options));
        } else {
            // Mise à jour
            this.update(instance, this.fillDefaultSaveOptions(options));
        }
    }

    /**
     * Méthode de sélection d'un objet et d'information de l'application
     * @param {MainObject} instance L'objet (dé)sélectionné
     * @param {boolean} selected VRAI pour sélectionner, FAUX pour désélectionner
     */
    public select(instance: ModelBase, selected: boolean = true) {
        // Check that an instance is provided
        if (!instance) {
            return;
        }
        // Marqueur de sélection
        instance.selected = selected;
        if (selected) {
            this._selected$.next(instance);
        } else {
            // This is equivalent to unselecting object
            this._selected$.next(null);
        }
    }

    /**
     * Transformation d'une instance en objet pouvant être envoyé à l'API
     * Cela consiste principalement à enlever les _ au début des attributs
     * @param instance
     */
    private _toApi(instance: any): any {
        let data: any = {};
        Object.entries(instance)
            .forEach(([key, value]) => {
                if (!([
                    '__meta__',
                    '_authorizedActivities',
                    '_status'
                ].includes(key))) {
                    if (value instanceof Array) {
                        let keyTmp = key[0] === '_' ? key.substring(1) : data[key];
                        data[keyTmp] = [];
                        for (let i = 0; i < value.length; i++) {
                            if (value[i] instanceof Object && !moment.isMoment(value[i])) {
                                data[keyTmp].push(this._toApi(value[i]));
                            } else {
                                data[keyTmp].push(value[i]);
                            }
                        }
                    } else {
                        if (value instanceof Object && !moment.isMoment(value)) {
                            value = this._toApi(value);
                        }
                        if (key[0] === '_') {
                            data[key.substring(1)] = value;
                        } else {
                            data[key] = value;
                        }
                    }
                } else if (key === '__meta__') {
                    data[key] = value;
                }
            })

        return data;
    }

    /**
     * Désélection d'un objet
     * @param {boolean} async Faut-il le faire en asynchrone ?
     */
    public unselect(async: boolean = false) {
        if (async) {
            setTimeout(
                () => {
                    this.unselect();
                },
                1
            );
        } else {
            this.select(this._selected$.getValue(), false);
        }
    }

    /**
     * Appel du service de mise à jour
     * @param {MainObject} instance Instance à mettre à jour
     */
    protected update$(instance: ModelBase, options?: SaveOptions): Observable<any> {
        options = this.fillDefaultSaveOptions(options);
        // On vérifie qu'on a asser d'info pour effectuer l'action
        if (!instance?.id && !!options?.url) {
            // Pas d'identifiant fourni
            return of(null);
        }
        // Indicateurs d'activité
        this._updating$.next(true);
        instance.busy = true;

        // Ne pas envoyer les données des médias liés
        if (!!instance.file && !!instance.file.mediaBase64) {
            instance.file.mediaBase64 = '';
        }

        let url = !!options.url ? options.url : this.routes.update.replace('{id}', instance.id.toString());

        // Appel du service
        return this.httpClient.put(url, this._toApi(instance)).pipe(
            finalize(() => {
                instance.busy = false;
                this._updating$.next(false);
            }),
            map((body: any) => {
                let response = new ODataResponse(body);
                if (options?.showMessages) {
                    // Affichage des messages du serveur
                    this.messageService.sendODataMessages(response.messages);
                }
                // Information de l'application
                instance = (GeneralFactory.get(this.className, response.value) as ModelBase);
                this._updated$.next(instance);
                if (!!options?.select) {
                    this.select(instance);
                }
                // Appel des callbacks
                if (!!options?.callbacks) {
                    for (let i = 0; i < options?.callbacks.length; i++) {
                        options?.callbacks[i]();
                    }
                }
                // Retour de l'instance
                return instance;
            }),
            catchError(
                (error: any) => {
                    // Handle errors
                    this.handleError(error);
                    return of(false);
                }
            )
        );
    }

    /**
     * Appel du service de mise à jour, sans souscription
     * @param {MainObject} instance Instance à mettre à jour
     */
    protected update(instance: ModelBase, options?: SaveOptions) {
        this.update$(instance, this.fillDefaultSaveOptions(options)).subscribe(() => { });
    }

    public uploadAttachment$(parent: any, file: File): Observable<any> {
        let url = this.routes['single'].replace('{id}', parent.id.toString()) + this.navigations['attachments'].path;
        return this.uploadFile$(file, url);
    }

    protected uploadFile$(file: File, url: string, async = false): Observable<any> {
        // HTTP headers
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Disposition':
                    'attachment; filename="' + file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '"',
                'Content-Type': file.type,
                'X-Background': async.toString()
            }),
            reportProgress: true,
            observe: 'events' as 'events'
        };

        this._uploading$.next(true);
        return this.httpClient.post<any>(url, file, httpOptions).pipe(
            finalize(() => {
                this._uploading$.next(false);
            }),
            map((event: HttpEvent<any>) => {
                let progressArray = this._progressArray$.getValue();
                switch (event.type) {
                    case HttpEventType.Sent:
                        LOGGER.debug('Début de l\'upload');
                        // Add file to the upload progress table
                        let progress: UploadProgress = {
                            loaded: 0,
                            progress: 0,
                            total: 0,
                            fileName: file.name
                        };
                        progressArray.push(progress);
                        break;
                    case HttpEventType.ResponseHeader:
                        LOGGER.debug('Fin de l\'upload');
                        // Remove progress indicator from table
                        for (let i = 0; i < progressArray.length; i++) {
                            if (progressArray[i].fileName === file.name) {
                                progressArray.splice(i, 1);
                                break;
                            }
                        }
                        break;
                    case HttpEventType.UploadProgress:
                        let progressPct = Math.round((event.loaded / (event.total || event.loaded)) * 100);
                        LOGGER.debug(`Avancement : ${progressPct}%`);
                        // Update progress indicator
                        for (let i = 0; i < progressArray.length; i++) {
                            if (progressArray[i].fileName === file.name) {
                                progressArray[i].loaded = event.loaded;
                                progressArray[i].progress = progressPct;
                                progressArray[i].total = event.total || event.loaded;
                                break;
                            }
                        }
                        break;
                    case HttpEventType.Response:
                        let response = new ODataResponse(event.body);
                        this.messageService.sendODataMessages(response.messages);
                        this._uploaded$.next(file.name);
                        // Retour de l'instance
                        return response;
                }
                this._progressArray$.next(progressArray);
                return false;
            }),
            catchError(
                (error: any) => {
                    // Handle errors
                    this.handleError(error);
                    return of(false);
                }
            )
        );
    }

    /**
     * Méthode de validation d'un objet, c'est en fait un proxy qui appel la
     * méthode de mise à jour avec une autre URL
     * @param {any} instance L'objet à valider
     */
    public validate$(instance: ModelBase, options?: SaveOptions): Observable<any> {
        options = this.fillDefaultSaveOptions(options);
        // URL à utiliser
        options.url = this.routes.validate.replace('{id}', instance.id.toString());
        // Appel de la mise à jour
        return this.update$(instance, options).pipe(
            map(instance => {
                this._validated$.next(instance.id);
                return instance;
            })
        );
    };

    /**
     * Méthode de validation d'un objet, c'est en fait un proxy qui appel la
     * méthode de mise à jour avec une autre URL
     * @param {any} instance L'objet à valider
     */
    public validate(instance: ModelBase, options?: SaveOptions) {
        options = this.fillDefaultSaveOptions(options);
        // URL à utiliser
        options.url = this.routes.validate.replace('{id}', instance.id.toString());
        // Appel de la mise à jour
        this.validate$(instance, options).subscribe(() => { });
    }
}

import { ModelBase } from '../abstract/model-base.model';
import { Account } from './account.model';
import { Tax } from '../core/tax.model';

export class ClientTax extends ModelBase {

    static override CLASS_NAME: string = 'ClientTax';

    private _assetAccount!: Account;
    private _collectedAccount!: Account;
    private _deductibleAccount!: Account;
    private _collectedReverseChargeAccount!: Account;
    private _deductibleReverseChargeAccount!: Account;
    private _tax!: Tax

    public get assetAccount(): Account {
        return this._assetAccount;
    }

    public set assetAccount(value: any) {
        this._setValue('_assetAccount', value, 'Account');
    }

    public set collectedAccount(value: any) {
        this._setValue('_collectedAccount', value, 'Account');
    }

    public get collectedAccount(): Account {
        return this._collectedAccount;
    }

    public set deductibleAccount(value: any) {
        this._setValue('_deductibleAccount', value, 'Account');
    }

    public get deductibleAccount(): Account {
        return this._deductibleAccount;
    }

    public get collectedReverseChargeAccount(): Account {
        return this._collectedReverseChargeAccount;
    }

    public set collectedReverseChargeAccount(value: any) {
        this._setValue('_collectedReverseChargeAccount', value, 'Account');
    }

    public get deductibleReverseChargeAccount(): Account {
        return this._deductibleReverseChargeAccount;
    }

    public set deductibleReverseChargeAccount(value: any) {
        this._setValue('_deductibleReverseChargeAccount', value, 'Account');
    }

    public set tax(value: any) {
        this._setValue('_tax', value, 'Tax');
    }

    public get tax(): Tax {
        return this._tax;
    }
}

<!-- ÉTAPE 1 : REMPLISSAGE DU FORMULAIRE -->
<div *ngIf="!showSuccessMessage" class="h-29rem">
    <div class="text-xl font-medium mb-3">
        Inscrivez-vous
    </div>
    <form (ngSubmit)="subscribe()" [formGroup]="form" novalidate class="flex flex-column">

        <!-- Nom et prénom -->
        <div class="flex justify-content-between mb-4">
            <input pInputText class="w-6 mr-2" type="text" formControlName="firstName" placeholder="Prénom" required />
            <input pInputText class="w-6 ml-2" type="text" formControlName="lastName" placeholder="Nom" required />
        </div>

        <!-- Email -->
        <div class="mb-4 w-full flex flex-column">
            <input pInputText type="email" formControlName="email" placeholder="Adresse email" required />
            <small *ngIf="form.get('email')?.errors && form.get('email')?.hasError('invalid')" class="p-error mt-1">{{
                form.get('email')?.getError('message') }}</small>
        </div>

        <!-- Mot de passe -->
        <div class="mb-4 w-full flex flex-column">
            <p-password inputStyleClass="w-full" [feedback]="true" formControlName="password" [toggleMask]="true"
                placeholder="Mot de passe" promptLabel="Robustesse du mot de passe" weakLabel="Faible"
                mediumLabel="Moyen" strongLabel="Fort" required />
            <small *ngIf="form.get('password')?.errors && form.get('password')?.hasError('invalid')"
                class="p-error mt-1">{{
                form.get('password')?.getError('message') }}</small>
        </div>

        <!-- Confirmations du mot de passe -->
        <div class="mb-4 w-full flex flex-column">
            <p-password inputStyleClass="w-full" [feedback]="false" formControlName="confirmPassword"
                [toggleMask]="true" placeholder="Confirmer mot de passe" required />
            <small *ngIf="form.get('confirmPassword')?.errors && form.get('confirmPassword')?.hasError('invalid')"
                class="p-error mt-1">{{
                form.get('confirmPassword')?.getError('message') }}</small>
        </div>

        <!-- Validation -->
        <p-button class="mb-4" styleClass="w-full" type="submit" label="Créer mon compte" [rounded]="true"
            [disabled]="form.invalid || loading"></p-button>

        <!-- Retour connexion -->
        <div class="grid">
            <div class="col">
                <p-button styleClass="w-full surface-900 text-0 border-900" type="button" label="Déjà inscrit ?"
                    [rounded]="true" routerLink="/login"></p-button>
            </div>
        </div>
    </form>
</div>

<!-- ÉTAPE 2 : MESSAGE POUR UTILISATEUR -->
<div *ngIf="showSuccessMessage" class="h-29rem flex flex-column">
    <div class="text-xl font-medium mb-3">
        Confirmer votre compte
    </div>
    <div class="flex flex-grow-1 flex-column justify-content-between">
        <div class="text-sm">
            Consultez vos emails afin de confirmer votre inscription.
        </div>
        <!-- Retour connexion -->
        <div class="grid">
            <div class="col">
                <button pButton class="w-full surface-900 text-0 border-900" type="button"
                    label="Revenir à la connexion" routerLink="/login"></button>
            </div>
        </div>
    </div>
</div>
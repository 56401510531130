import moment from 'moment';
import { ModelBase } from '../abstract/model-base.model';
import { Account } from './account.model';
import { AccountingMethod } from './accounting-method.model';
import { TaxationRegime } from '../taxation/taxation-regime.model';
import { TaxationType } from '../taxation/taxation-type.model';
import { VatChargeability } from '../taxation/vat-chareability.model';
import { VatFrequency } from '../taxation/vat-frequency.model';

export class AccountingSettings extends ModelBase {

    static override CLASS_NAME: string = 'AccountingSettings';

    private _accountingMethod!: AccountingMethod;
    private _accountNumberLength!: number;
    private _vendorAccount!: Account;
    private _vendorDownpaymentAccount!: Account;
    private _clientAccount!: Account;
    private _clientDownpaymentAccount!: Account;
    private _employeeAccount!: Account;
    private _expensesAccount!: Account;
    private _chargeAccount!: Account;
    private _lossAccount!: Account;
    private _productAccount!: Account;
    private _profitAccount!: Account;
    private _collectedTaxAccount!: Account;
    private _deductibleTaxAccount!: Account;
    private _taxPaymentAccount!: Account;
    private _taxReimbursementAccount!: Account;
    private _socialContributionAccount!: Account;
    private _taxesAccount!: Account;
    private _cashAccount!: Account;
    private _chequeWalletAccount!: Account;
    private _chequeDepositAccount!: Account;
    private _creditCardDepositAccount!: Account;
    private _interestReceivedAccount!: Account;
    private _internalTransferAccount!: Account;
    private _taxationRegime!: TaxationRegime;
    private _taxationType!: TaxationType;
    private _vatChargeability!: VatChargeability;
    private _vatDateFrom!: moment.Moment;
    private _vatFrequency!: VatFrequency;
    private _vatSubject!: boolean;

    public get accountingMethod(): AccountingMethod {
        return this._accountingMethod;
    }

    public set accountingMethod(value: any) {
        this._setValue('_accountingMethod', value, 'AccountingMethod');
    }

    public get accountNumberLength(): number {
        return this._accountNumberLength;
    }

    public set accountNumberLength(value: any) {
        this._setValue('_accountNumberLength', value, 'integer');
    }

    public get vendorAccount(): Account {
        return this._vendorAccount;
    }

    public set vendorAccount(value: any) {
        this._setValue('_vendorAccount', value, 'Account');
    }

    public get vendorDownpaymentAccount(): Account {
        return this._vendorDownpaymentAccount;
    }

    public set vendorDownpaymentAccount(value: any) {
        this._setValue('_vendorDownpaymentAccount', value, 'Account');
    }

    public get clientAccount(): Account {
        return this._clientAccount;
    }

    public set clientAccount(value: any) {
        this._setValue('_clientAccount', value, 'Account');
    }

    public get clientDownpaymentAccount(): Account {
        return this._clientDownpaymentAccount;
    }

    public set clientDownpaymentAccount(value: any) {
        this._setValue('_clientDownpaymentAccount', value, 'Account');
    }

    public get creditCardDepositAccount(): Account {
        return this._creditCardDepositAccount;
    }

    public set creditCardDepositAccount(value: any) {
        this._setValue('_creditCardDepositAccount', value, 'Account');
    }

    public get employeeAccount(): Account {
        return this._employeeAccount;
    }

    public set employeeAccount(value: any) {
        this._setValue('_employeeAccount', value, 'Account');
    }

    public get chargeAccount(): Account {
        return this._chargeAccount;
    }

    public set chargeAccount(value: any) {
        this._setValue('_chargeAccount', value, 'Account');
    }

    public get lossAccount(): Account {
        return this._lossAccount;
    }

    public set lossAccount(value: any) {
        this._setValue('_lossAccount', value, 'Account');
    }

    public get productAccount(): Account {
        return this._productAccount;
    }

    public set productAccount(value: any) {
        this._setValue('_productAccount', value, 'Account');
    }

    public get profitAccount(): Account {
        return this._profitAccount;
    }

    public set profitAccount(value: any) {
        this._setValue('_profitAccount', value, 'Account');
    }

    public get collectedTaxAccount(): Account {
        return this._collectedTaxAccount;
    }

    public set collectedTaxAccount(value: any) {
        this._setValue('_collectedTaxAccount', value, 'Account');
    }

    public get deductibleTaxAccount(): Account {
        return this._deductibleTaxAccount;
    }

    public set deductibleTaxAccount(value: any) {
        this._setValue('_deductibleTaxAccount', value, 'Account');
    }

    public get taxPaymentAccount(): Account {
        return this._taxPaymentAccount;
    }

    public set taxPaymentAccount(value: any) {
        this._setValue('_taxPaymentAccount', value, 'Account');
    }

    public get taxReimbursementAccount(): Account {
        return this._taxReimbursementAccount;
    }

    public set taxReimbursementAccount(value: any) {
        this._setValue('_taxReimbursementAccount', value, 'Account');
    }


    public get cashAccount(): Account {
        return this._cashAccount;
    }

    public set cashAccount(value: any) {
        this._setValue('_cashAccount', value, 'Account');
    }

    public get chequeWalletAccount(): Account {
        return this._chequeWalletAccount;
    }

    public set chequeWalletAccount(value: any) {
        this._setValue('_chequeWalletAccount', value, 'Account');
    }

    public get chequeDepositAccount(): Account {
        return this._chequeDepositAccount;
    }

    public set chequeDepositAccount(value: any) {
        this._setValue('_chequeDepositAccount', value, 'Account');
    }

    public get interestReceivedAccount(): Account {
        return this._interestReceivedAccount;
    }

    public set interestReceivedAccount(value: any) {
        this._setValue('_interestReceivedAccount', value, 'Account');
    }

    public get internalTransferAccount(): Account {
        return this._internalTransferAccount;
    }

    public set internalTransferAccount(value: any) {
        this._setValue('_internalTransferAccount', value, 'Account');
    }

    public get taxationRegime(): TaxationRegime {
        return this._taxationRegime;
    }

    public set taxationRegime(value: any) {
        this._setValue('_taxationRegime', value, 'TaxationRegime');
    }

    public get taxationType(): TaxationType {
        return this._taxationType;
    }

    public set taxationType(value: any) {
        this._setValue('_taxationType', value, 'TaxationType');
    }

    public get vatChargeability(): VatChargeability {
        return this._vatChargeability;
    }

    public set vatChargeability(value: any) {
        this._setValue('_vatChargeability', value, 'VatChargeability');
    }

    public get vatDateFrom(): moment.Moment {
        return this._vatDateFrom;
    }

    public set vatDateFrom(value: any) {
        this._setValue('_vatDateFrom', value, 'date');
    }

    public get vatFrequency(): VatFrequency {
        return this._vatFrequency;
    }

    public set vatFrequency(value: any) {
        this._setValue('_vatFrequency', value, 'VatFrequency');
    }

    public get vatSubject(): boolean {
        return this._vatSubject;
    }

    public set vatSubject(value: any) {
        this._setValue('_vatSubject', value, 'boolean');
    }

    public get expensesAccount(): Account {
        return this._expensesAccount;
    }

    public set expensesAccount(value: any) {
        this._setValue('_expensesAccount', value, 'Account');
    }

    public get socialContributionAccount(): Account {
        return this._socialContributionAccount;
    }

    public set socialContributionAccount(value: any) {
        this._setValue('_socialContributionAccount', value, 'Account');
    }

    public get taxesAccount(): Account {
        return this._taxesAccount;
    }

    public set taxesAccount(value: any) {
        this._setValue('_taxesAccount', value, 'Account');
    }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { Shell } from './shell/services/shell/shell.service';

import { LoginComponent } from './login/pages/login/login.component';
import { LostPasswordComponent } from './login/pages/lost-password/lost-password.component';
import { SubscribeComponent } from './login/pages/subscribe/subscribe.component';
import { VerifyUserComponent } from './login/pages/verify-user/verify-user.component';
import { NotFoundComponent } from './@shared/pages/not-found/not-found.component';
import { ResetPasswordComponent } from './login/pages/reset-password/reset-password.component';
import { VerifyEmailComponent } from './login/pages/verify-email/verify-email.component';

const routes: Routes = [
    Shell.childRoutes([
        { path: 'accounting', loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule) },
        { path: 'banking', loadChildren: () => import('./banking/banking.module').then(m => m.BankingModule) },
        { path: 'client', loadChildren: () => import('./client/client.module').then(m => m.ClientModule) },
        { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
        { path: 'interfaces', loadChildren: () => import('./interfaces/interfaces.module').then(m => m.InterfacesModule) },
        { path: 'invoicing', loadChildren: () => import('./invoicing/invoicing.module').then(m => m.InvoicingModule) },
        { path: 'messaging', loadChildren: () => import('./messaging/messaging.module').then(m => m.MessagingModule) },
        { path: 'products', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
        { path: 'purchasing', loadChildren: () => import('./purchasing/purchasing.module').then(m => m.PurchasingModule) },
        { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
        { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule) },
        { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
        { path: 'taxation', loadChildren: () => import('./taxation/taxation.module').then(m => m.TaxationModule) },
        { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
        { path: '', redirectTo: 'home', pathMatch: 'full' },
    ]),
    { path: 'login', component: LoginComponent, title: 'Se connecter' },
    { path: 'lost-password', component: LostPasswordComponent, title: 'Mot de passe oublié' },
    { path: 'reset-password', component: ResetPasswordComponent, title: 'Nouveau mot de passe' },
    { path: 'subscribe', component: SubscribeComponent, title: 'S\'inscrire' },
    { path: 'verify-email', component: VerifyEmailComponent, title: 'Vérification de l\'email' },
    { path: 'verify-user', component: VerifyUserComponent, title: 'Vérification du compte' },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="grid pl-3 py-3 h-full w-full overflow-y-scroll no-scrollbars">
    <div class="col-6">
        <app-partner-debts-widget mode="sales"></app-partner-debts-widget>
    </div>
    <div class="col-6">
        <app-partner-debts-widget mode="purchasing"></app-partner-debts-widget>
    </div>
    <div class="col-12">
        <app-bank-account-balances></app-bank-account-balances>
    </div>
    <div class="col-12 txl:col-6">
        <app-monthly-summary-widget></app-monthly-summary-widget>
    </div>
    <div class="col-12 txl:col-6">
        <app-charges-widget (filesUploaded)="onPurchaseInvoiceUploaded()"></app-charges-widget>
    </div>
</div>
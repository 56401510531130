import { ModelBase } from "../abstract/model-base.model";
import { Currency } from "../core/currency.model";
import { Tax } from "../core/tax.model";

export class Pricing extends ModelBase {

    static override CLASS_NAME: string = 'Pricing';

    readonly price!: number;
    readonly currency!: Currency;
    readonly tax!: Tax;
}
import { Injectable } from '@angular/core';

import { Logger } from '../../services/logger/logger.service';

export interface Credentials {
    access: string;
    refresh: string;
}

const CREDENTIALS_KEY = 'credentials';

const LOGGER = new Logger('CredentialsService');

@Injectable({
    providedIn: 'root'
})
export class CredentialsService {
    private _credentials: Credentials | null = null;

    constructor() {
        const savedCredentials = sessionStorage.getItem(CREDENTIALS_KEY) || localStorage.getItem(CREDENTIALS_KEY);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
    }

    /**
     * Vérifie que l'utilisateur est connecté
     * @return True si l'utilisateur est connecté
     */
    isAuthenticated(): boolean {
        return !!this.credentials;
    }

    /**
     * Renvoie les identifiants de l'utilisateur
     * @return Les identifiants ou null si l'utilisateur n'est pas connecté
     */
    get credentials(): Credentials | null {
        return this._credentials;
    }

    /**
     * Définit les identifiants au niveau de l'application
     * Les identifiants peuvent être persisté en utilisant le flag 'remember'
     * @param credentials The user credentials.
     * @param remember True to remember credentials across sessions.
     */
    setCredentials(credentials?: Credentials, remember?: boolean) {
        this._credentials = credentials || null;

        if (credentials) {
            LOGGER.debug('Sauvegarde des tokens', remember ? 'localement' : 'pour la session uniquement');
            const storage = remember ? localStorage : sessionStorage;
            storage.setItem(CREDENTIALS_KEY, JSON.stringify(credentials));
        } else {
            LOGGER.debug('Suppression des tokens');
            sessionStorage.removeItem(CREDENTIALS_KEY);
            localStorage.removeItem(CREDENTIALS_KEY);
        }
    }
}

import { ModelBase } from '../abstract/model-base.model';

export class PaymentTerm extends ModelBase {

    static override CLASS_NAME: string = 'PaymentTerm';

    private _label!: string;
    private _days!: number;
    private _endOfMonth!: boolean;

    public set label(value: any) {
        this._setValue('_label', value, 'string');
    }

    public get label(): string {
        return this._label;
    }

    public set days(value: any) {
        this._setValue('_days', value, 'integer');
    }

    public get days(): number {
        return this._days;
    }

    public set endOfMonth(value: any) {
        this._setValue('_endOfMonth', value, 'boolean');
    }

    public get endOfMonth(): boolean {
        return this._endOfMonth;
    }
}

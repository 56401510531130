import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TieredMenuModule } from 'primeng/tieredmenu';
import { BaseComponent } from 'src/app/@shared/components/abstract/base.component';

@Component({
    selector: 'app-side-menu',
    standalone: true,
    imports: [
        CommonModule,
        TieredMenuModule,
    ],
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent extends BaseComponent {


    /*----------------------------------------------------------------------------------------
     * Gestion de la vue
     ----------------------------------------------------------------------------------------*/

    private _showAccountingMenuItems: boolean = false;
    private _showBankMenuItems: boolean = false;
    private _showPurchasingMenuItems: boolean = false;
    private _showSalesMenuItems: boolean = false;

    /*----------------------------------------------------------------------------------------
     * Données
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Méthodes publiques
     ----------------------------------------------------------------------------------------*/

    public onClickSectionTitle(section: string) {
        // Si le menu est ouvert
        if (this.appService.menuOpen) {
            switch (section) {
                case 'accounting':
                    this._toggleAccountingMenuItems();
                    break;
                case 'bank':
                    this._toggleBankMenuItems();
                    break;
                case 'purchasing':
                    this._togglePurchasingMenuItems();
                    break;
                case 'sales':
                    this._toggleSalesMenuItems();
                    break;
            }
        } else {
            // Vanigation directe
            switch (section) {
                case 'accounting':
                    this.router.navigate(['/accounting/journals']);
                    break;
                case 'bank':
                    this.router.navigate(['/banking/accounts']);
                    break;
                case 'purchasing':
                    this.router.navigate(['/purchasing/invoices']);
                    break;
                case 'sales':
                    this.router.navigate(['/sales/invoices']);
                    break;
            }
        }
    }

    public get showAccountingMenuItems(): boolean {
        // Si la route contient 'accounting'
        let forceOpen = this.router.url.includes('accounting');
        return (forceOpen || this._showAccountingMenuItems) && this.appService.menuOpen;
    }

    public get showBankMenuItems(): boolean {
        // Si la route contient 'banking'
        let forceOpen = this.router.url.includes('banking');
        return (forceOpen || this._showBankMenuItems) && this.appService.menuOpen;
    }

    public get showPurchasingMenuItems(): boolean {
        // Si la route contient 'purchasing'
        let forceOpen = this.router.url.includes('purchasing');
        return (forceOpen || this._showPurchasingMenuItems) && this.appService.menuOpen;
    }

    public get showSalesMenuItems(): boolean {
        // Si la route contient 'sales'
        let forceOpen = this.router.url.includes('sales');
        return (forceOpen || this._showSalesMenuItems) && this.appService.menuOpen;
    }

    /*----------------------------------------------------------------------------------------
     * Méthodes protégées
     ----------------------------------------------------------------------------------------*/



    /*----------------------------------------------------------------------------------------
     * Méthodes privées
     ----------------------------------------------------------------------------------------*/

    private _toggleAccountingMenuItems(): void {
        this._showAccountingMenuItems = !this._showAccountingMenuItems;
    }

    private _toggleBankMenuItems(): void {
        this._showBankMenuItems = !this._showBankMenuItems;
    }

    private _togglePurchasingMenuItems(): void {
        this._showPurchasingMenuItems = !this._showPurchasingMenuItems;
    }

    private _toggleSalesMenuItems(): void {
        this._showSalesMenuItems = !this._showSalesMenuItems;
    }

}

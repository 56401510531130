import { ModelBase } from "../abstract/model-base.model";
import { AddressFormat } from "./address-format.model";
import { City } from "./city.model";
import { Civility } from "../user/civility.model";

export class Address extends ModelBase {

    static override CLASS_NAME: string = 'Address';

    private _civility!: Civility;
    private _firstName!: string;
    private _lastName!: string;
    private _company!: string;
    private _service!: string;
    private _cedexCode!: string;
    private _cedexLabel!: string;
    private _appartment!: string;
    private _building!: string;
    private _city!: City;
    private _format!: AddressFormat;
    private _street1!: string;
    private _street2!: string;
    private _zipCode!: string;

    public toReadableFormat(withName: boolean = true): string | undefined {
        let address = '';

        if (withName) {
            // Si c'est l'adresse d'un particulier
            if (this._format.code === 'individual') {
                if (this._civility?.label) {
                    address += `${this._civility.label} `;
                }
                if (this._firstName) {
                    address += `${this._firstName} `;
                }
                if (this._lastName) {
                    address += `${this._lastName}\n`;
                } else if (address.length > 0) {
                    address += `\n`;
                }
            } else {
                if (this._company && this._company.length > 0) {
                    address += `${this._company}`;
                }
                if (this._service && this._service.length > 0) {
                    address += `, service ${this._service}\n`;
                } else if (address.length > 0) {
                    address += `\n`;
                }
            }
        }

        // Données complémentaires
        if (this._street1 && this._street1.length > 0) {
            address += `${this._street1}\n`;
        }
        if (this._street2 && this._street2.length > 0) {
            address += `${this._street2}\n`;
        }
        if (this._building && this._building.length > 0) {
            address += `Bâtiment ${this._building} Apt ${this._appartment}\n`;
        } else if (this._appartment && this._appartment.length > 0) {
            address += `Appartement ${this._appartment}\n`;
        }

        // Ville
        if (this._cedexCode && this._cedexCode.length > 0) {
            address += `${this._cedexCode} ${this._cedexLabel}\n`;
        } else {
            if (this._zipCode && this._zipCode.length > 0) {
                address += `${this._zipCode} `;
            }
            if (this._city?.nameReal) {
                address += `${this._city?.nameReal.toUpperCase()}\n`;
            } else if (address.length > 0) {
                address += `\n`;
            }
        }

        // Pays
        if (this._city?.country?.name) {
            address += `${this._city?.country?.name}`;
        }

        if (address.length === 0) {
            return undefined;
        }
        return address;
    }

    public get civility(): Civility {
        return this._civility;
    }

    public set civility(value: any) {
        this._setValue('_civility', value, 'Civility');
    }

    public get firstName(): string {
        return this._firstName;
    }

    public set firstName(value: any) {
        this._setValue('_firstName', value, 'string');
    }

    public get lastName(): string {
        return this._lastName;
    }

    public set lastName(value: any) {
        this._setValue('_lastName', value, 'string');
    }

    public get company(): string {
        return this._company;
    }

    public set company(value: any) {
        this._setValue('_company', value, 'string');
    }

    public get service(): string {
        return this._service;
    }

    public set service(value: any) {
        this._setValue('_service', value, 'string');
    }

    public get cedexCode(): string {
        return this._cedexCode;
    }

    public set cedexCode(value: any) {
        this._setValue('_cedexCode', value, 'string');
    }

    public get cedexLabel(): string {
        return this._cedexLabel;
    }

    public set cedexLabel(value: any) {
        this._setValue('_cedexLabel', value, 'string');
    }

    public get appartment(): string {
        return this._appartment;
    }

    public set appartment(value: any) {
        this._setValue('_appartment', value, 'string');
    }

    public get building(): string {
        return this._building;
    }

    public set building(value: any) {
        this._setValue('_building', value, 'string');
    }

    public get city(): City {
        return this._city;
    }

    public set city(value: any) {
        this._setValue('_city', value, 'City');
    }

    public get format(): AddressFormat {
        return this._format;
    }

    public set format(value: any) {
        this._setValue('_format', value, 'AddressFormat');
    }

    public get street1(): string {
        return this._street1;
    }

    public set street1(value: any) {
        this._setValue('_street1', value, 'string');
    }

    public get street2(): string {
        return this._street2;
    }

    public set street2(value: any) {
        this._setValue('_street2', value, 'string');
    }

    public get zipCode(): string {
        return this._zipCode;
    }

    public set zipCode(value: any) {
        this._setValue('_zipCode', value, 'string');
    }

}
import moment from 'moment';
import { GeneralFactory } from '../factories/general-factory';
import { AppConfigModel } from './app-config.model';
import { ModelMeta } from 'src/app/@core/interfaces/model-meta';
import { BinarySearch } from '../../utilities/array/binary-search';
import { File } from '../file/file.model';

export abstract class ModelBase {

    static CLASS_NAME = '';
    private _id!: number;
    private _file!: File;
    private _authorizedActivities: string[] = [];
    private _status: string[] = [];

    __meta__: ModelMeta = {
        busy: false,
        deleted: false,
        hasChanged: false,
        hasError: false,
        loaded: false,
        local: false,
        new: false,
        selected: false,
        url: ''
    };

    constructor(data: any) {
        this.authorizedActivities = [];
        this.status = [];
        this.hydrate(data);
        this.loaded = true;
    }

    hydrate(data: any) {
        for (let key in data) {
            // Appel dynamique des setters, si une propriété non existante est appelée,
            // elle sera présente tout de même sur l'objet même si l'application n'est pas censée l'utiliser
            (this as any)[key] = data[key];
        }
    }

    public get authorizedActivities(): string[] {
        return this._authorizedActivities;
    }
    public set authorizedActivities(activities: string[]) {
        this._authorizedActivities = [];
        if (!!activities) {
            activities.forEach(activity => {
                let result = BinarySearch.find(this._authorizedActivities, activity);
                if (!result.found) {
                    this._authorizedActivities.splice(result.index, 0, activity);
                }
            });
        }
    }

    public get busy(): boolean {
        return this.__meta__.busy;
    }

    public set busy(busy: boolean) {
        this.__meta__.busy = this.toBoolean(busy);
    }

    public canPerform(activity: string) {
        let result = BinarySearch.find(this.authorizedActivities, activity);
        return result.found;
    }

    public get canUpdate(): boolean {
        return this.new || this.canPerform('update');
    }

    public get deleted(): boolean {
        return this.__meta__.deleted;
    }

    public set deleted(deleted: boolean) {
        this.__meta__.deleted = this.toBoolean(deleted);
    }

    public get file(): File {
        return this._file;
    }

    public set file(value: File) {
        this._setValue('_file', value, 'File');
    }

    static getTempId() {
        return Math.floor(99990000 + Math.random() * 1000);
    }

    public get hasChanged(): boolean {
        return this.__meta__.hasChanged;
    }

    public set hasChanged(changed: boolean) {
        this.__meta__.hasChanged = this.loaded && this.toBoolean(changed);
    }

    public get hasError(): boolean {
        return this.__meta__.hasError;
    }

    public set hasError(hasError: boolean) {
        this.__meta__.hasError = this.toBoolean(hasError);
    }

    public hasStatus(status: string) {
        let result = BinarySearch.find(this.status, status);
        return result.found;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._setValue('_id', id, 'integer');
    }

    static init(): any {
        return GeneralFactory.get(this.CLASS_NAME, {
            id: ModelBase.getTempId(),
            local: true,
            new: true
        });
    }

    public get loaded(): boolean {
        return this.__meta__.loaded;
    }

    public set loaded(loaded: boolean) {
        this.__meta__.loaded = this.toBoolean(loaded);
    }

    public get local(): boolean {
        return this.__meta__.local;
    }

    public set local(local: boolean) {
        this.__meta__.local = this.toBoolean(local);
    }

    public set meta(meta: any) {
        this.__meta__ = meta;
    }

    public get new(): boolean {
        return this.__meta__.new;
    }

    public set new(isNew: boolean) {
        this.__meta__.new = this.toBoolean(isNew);
    }

    private _processValue(value: any, type: string, options: any = {}): any {
        // Check input type, and use corresponding method to convert value
        if (!!value || value === 0 && (type === 'integer' || type === 'float') || type === 'boolean' || value === '' && type === 'string') {
            switch (type) {
                case 'any':
                    value = value;
                    break;
                case 'boolean':
                    value = this.toBoolean(value);
                    break;
                case 'date':
                    value = this.toMoment(value, true);
                    break;
                case 'datetime':
                    value = this.toMoment(value);
                    break;
                case 'float':
                    value = this.toFloat(value, options);
                    break;
                case 'integer':
                    value = this.toInt(value);
                    break;
                case 'json':
                    value = typeof value === 'string' ? JSON.parse(value) : value;
                    break;
                case 'string':
                    value = value.toString();
                    break;
                default:
                    value = this.toInstance(type, value);
                    break;
            }
        } else {
            value = null;
        }
        return value;
    }

    public get selected(): boolean {
        return this.__meta__.selected;
    }

    public set selected(selected: boolean) {
        this.__meta__.selected = selected;
    }

    public get status(): string[] {
        return this._status;
    }

    public set status(statusList: any[]) {
        let emptyArray: string[] = []
        this._status = emptyArray.concat(statusList);
    }

    public get url(): string {
        return this.__meta__.url;
    }

    public set url(url: string) {
        this.__meta__.url = url;
    }

    /**
     * Méthode générique d'affectation de valeur à un attribut de Modèles
     *
     * Si l'attribut est modifié, la propriété hasChanged est mise à true
     * @param attribute L'attribut à modifier
     * @param value  La valeur à affecter
     * @param type  Le type de la valeur. Les types supportés sont : any, boolean, date, datetime, float, integer, json, string ou le nom d'une classe
     * @param options L'option permet de définir la précision pour les valeurs de type float
     * @returns Renvoie true si la valeur a été modifiée, false sinon
     */
    protected _setValue(attribute: string, value: any | any[], type: string, options: any = {}) {
        let valueChanged = false;
        // Selon si c'est une liste ou non
        if (!Array.isArray(value)) {
            // On vérifie que la valeur correspond au type spécifié
            let processedValue = this._processValue(value, type, options);
            // On regarde si l'attribut est déjà défini
            if (typeof this[attribute as keyof this] !== 'undefined') {
                // Est-ce que la donnée a changé ?
                if (
                    (this[attribute as keyof this] !== null && processedValue === null) ||
                    (this[attribute as keyof this] === null && processedValue !== null)
                ) {
                    valueChanged = true;
                    this[attribute as keyof this] = processedValue;
                } else if (!!this[attribute as keyof this] && typeof this[attribute as keyof this] === 'object') {
                    // Date ?
                    if (moment.isMoment(this[attribute as keyof this])) {
                        if (!(this[attribute as keyof this] as moment.Moment).isSame(processedValue)) {
                            valueChanged = true;
                            this[attribute as keyof this] = processedValue;
                        }
                    } else {
                        try {
                            // C'est un objet, comparaison des IDs
                            if (this[attribute as keyof this] instanceof ModelBase && (this[attribute as keyof this] as ModelBase).id !== processedValue.id) {
                                valueChanged = true;
                                this[attribute as keyof this] = processedValue;
                            } else if (this[attribute as keyof this] instanceof AppConfigModel && (this[attribute as keyof this] as AppConfigModel).code !== processedValue.code) {
                                valueChanged = true;
                                this[attribute as keyof this] = processedValue;
                            } else {
                                // Hydratation
                                (this[attribute as keyof this] as ModelBase).hydrate(processedValue);
                            }
                        } catch (_) { }
                    }
                } else {
                    // Attribut simple, comparaison des valeurs
                    if (this[attribute as keyof this] !== processedValue) {
                        valueChanged = true;
                        this[attribute as keyof this] = processedValue;
                    }
                }
            } else {
                if (typeof processedValue !== 'undefined') {
                    // Initialisation de l'attribut
                    this[attribute as keyof this] = processedValue;
                    if (this.loaded) {
                        // Modification survient après que l'objet a été instancié
                        valueChanged = true;
                    }
                }
            }
        } else {
            // Initialisation de la liste
            (this[attribute as keyof this] as any[]) = [];
            // Iterate
            value.forEach(element => {
                let processedValue = this._processValue(element, type);
                (this[attribute as keyof this] as any[]).push(processedValue);
            });
            valueChanged = true;
        }

        if (valueChanged) {
            this.hasChanged = true;
        }
        return valueChanged;
    }

    protected toBoolean(value: any) {
        return value === 1 || value === '1' || value === 'X' || value === true;
    }

    protected toFloat(value: any, options: any = {}) {
        if (typeof value === 'string') {
            value = parseFloat(value);
        }
        let precision = options.precision || 2;
        return Math.round(parseFloat(value) * 10 ** precision) / 10 ** precision;
    }

    protected toInstance(className: string, value?: any): any | null {
        if (!value) {
            return null;
        } else {
            return GeneralFactory.get(className, value);
        }
    }

    protected toInt(value: any) {
        if (typeof value === 'string') {
            value = parseInt(value);
        }
        return value;
    }

    protected toMoment(date: any, dateOnly: boolean = false) {
        if (typeof date === 'string') {
            date = moment(date);
        }

        if (dateOnly) {
            return ModelBase.toUTCMidnight(date);
        } else {
            return date;
        }
    }

    static toUTCMidnight(value: moment.Moment): moment.Moment | null {
        if (!value) return null;

        let offset = value.utcOffset();
        let midnight = moment([value.year(), value.month(), value.date()]);
        if (offset < 0) {
            midnight.subtract(offset, 'm');
        } else {
            midnight.add(offset, 'm');
        }
        return midnight;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { RouteParam, ServiceBase } from '../service-base';

// Modèles
import { TgcDeclaration } from 'src/app/@shared/models/taxation/tgc-declaration.models';

// Utilitaires API
import { SaveOptions } from '../../interfaces/save-options';

const CLASS_NAME = 'TgcDeclaration';
const ENDPOINT = `/tgc-declarations/`;

const ROUTES: RouteParam[] = [
    { key: 'downloadFile', path: '{id}/xml/' },
    { key: 'match', path: '{id}/match/' },
    { key: 'unmatch', path: '{id}/unmatch/' },
    { key: 'validate', path: '{id}/validate/' },
]

@Injectable({
    providedIn: 'root'
})
export class TgcDeclarationService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addRoutes(ROUTES);
    }

    public match$(instance: TgcDeclaration, options?: SaveOptions): Observable<TgcDeclaration> {
        options = this.fillDefaultSaveOptions(options);
        // URL à utiliser
        options.url = this.routes.match.replace('{id}', instance.id.toString());
        // Appel de la mise à jour
        return this.update$(instance, options);
    }

    public unmatch$(instance: TgcDeclaration, options?: SaveOptions): Observable<TgcDeclaration> {
        options = this.fillDefaultSaveOptions(options);
        // URL à utiliser
        options.url = this.routes.unmatch.replace('{id}', instance.id.toString());
        // Appel de la mise à jour
        return this.update$(instance, options);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Classes
import { Client } from 'src/app/@shared/models/client/client.model';

// Utils
import { Logger } from '../logger/logger.service';
import { QueryOptionsParam } from 'src/app/@shared/utilities/odata/query-params';

const LOGGER = new Logger('ClientService');

const CLASS_NAME = 'Client';
const ENDPOINT = `/clients/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'authorizations', path: 'authorizations/', childClass: null },
    { key: 'contacts', path: 'contacts/', childClass: 'ClientContact' },
    { key: 'logo', path: 'logo/', childClass: 'File', resultCardinality: 'one' },
    { key: 'subscription', path: 'subscription/', childClass: 'Subscription', resultCardinality: 'one' },
]
const ROUTES: RouteParam[] = [
    {key: 'mine', path: 'mine/'},
    {key: 'reset', path: '{id}/reset/'},
]

@Injectable({
    providedIn: 'root'
})
export class ClientService extends ServiceBase {
    constructor() {
        super(CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public delete$(client: Client, phrase: string | null): Observable<any> {
        client.deleted = true;
        client.deletePhrase = phrase;
        return this.save$(client, {
            url: this.routes.reset.replace('{id}', client.id.toString()),
        });
    }

    /**
     * Appel du webservice des clients auxquelles appartient l'utilisateur
     */
    public getMine$(): Observable<Client[]> {
        LOGGER.debug('Chargement de la liste des clients');
        let options: QueryOptionsParam = { url: this.routes.mine };
        return this.getList$(options).pipe(
            map((clients: Client[]) => {
                LOGGER.debug(`${clients.length} clients chargés`);
                return clients;
            })
        );
    }

    public loadSelected$(): Observable<Client | null> {
        let savedClientId = sessionStorage.getItem(CLASS_NAME) || localStorage.getItem(CLASS_NAME);
        if (!!savedClientId) {
            LOGGER.debug('Chargement du client', savedClientId);
            return this.get$(parseInt(savedClientId));
        } else {
            LOGGER.debug('Aucun client sauvegardé');
            return of(null)
        }
    }

    public reset$(client: Client, phrase: string | null): Observable<any> {
        client.resetPhrase = phrase;
        return this.update$(client, {
            url: this.routes.reset.replace('{id}', client.id.toString()),
        });
    }

    public uploadLogo$(client: Client, file: File): Observable<any> {
        let url = this.routes['single'].replace('{id}', client.id.toString()) + this.navigations['logo'].path;
        return this.uploadFile$(file, url);
    }
}

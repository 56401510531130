import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Services
import { MessageServiceBroker } from '../message.service';
import { NavigationParam, RouteParam, ServiceBase } from '../service-base';

// Modèles
import { SalesInvoice } from 'src/app/@shared/models/sales/invoice/sales-invoice.model';


const CLASS_NAME = 'SalesInvoice';
const ENDPOINT = `/sales-invoices/`;
const NAVIGATIONS: NavigationParam[] = [
    { key: 'attachments', path: 'attachments/', childClass: 'File' },
    { key: 'payments', path: 'payments/', childClass: 'SalesInvoicePayment' },
]
const ROUTES: RouteParam[] = [
    { key: 'cancel', path: '{id}/cancel/' },
    { key: 'creditNote', path: '{id}/credit-note/' },
    { key: 'downloadFile', path: '{id}/pdf/' },
    { key: 'duplicate', path: '{id}/duplicate/' },
    { key: 'markAsSent', path: '{id}/mark-as-sent/' },
    { key: 'modify', path: '{id}/modify/' },
    { key: 'remind', path: '{id}/remind/' },
    { key: 'send', path: '{id}/send/' },
    { key: 'upload', path: 'upload/' },
    { key: 'validate', path: '{id}/validate/' },
    { key: 'writeOff', path: '{id}/write-off/' },
]

@Injectable({
    providedIn: 'root'
})
export class SalesInvoiceService extends ServiceBase {
    constructor(httpClient: HttpClient, messageService: MessageServiceBroker) {
        super(httpClient, messageService, CLASS_NAME, ENDPOINT);
        this.addNavigations(NAVIGATIONS);
        this.addRoutes(ROUTES);
    }

    public cancel$(invoice: SalesInvoice): Observable<SalesInvoice> {
        let url = this.routes.cancel.replace('{id}', invoice.id.toString());
        return this.update$(invoice, {
            url: url
        });
    }

    public creditNote$(invoice: SalesInvoice): Observable<SalesInvoice> {
        let url = this.routes.creditNote.replace('{id}', invoice.id.toString());
        return this.update$(invoice, {
            url: url
        });
    }

    public duplicate$(invoice: SalesInvoice): Observable<SalesInvoice> {
        let url = this.routes.duplicate.replace('{id}', invoice.id.toString());
        return this.update$(invoice, {
            url: url
        });
    }

    public markAsSent$(invoice: SalesInvoice): Observable<SalesInvoice> {
        let url = this.routes.markAsSent.replace('{id}', invoice.id.toString());
        return this.update$(invoice, {
            url: url
        });
    }

    public modify$(invoice: SalesInvoice): Observable<SalesInvoice> {
        let url = this.routes.modify.replace('{id}', invoice.id.toString());
        return this.update$(invoice, {
            url: url
        });
    }

    public remind$(invoice: SalesInvoice): Observable<SalesInvoice> {
        let url = this.routes.remind.replace('{id}', invoice.id.toString());
        return this.update$(invoice, {
            url: url
        });
    }

    public send$(invoice: SalesInvoice): Observable<SalesInvoice> {
        let url = this.routes.send.replace('{id}', invoice.id.toString());
        return this.update$(invoice, {
            url: url
        });
    }

    public upload$(file: File): Observable<any> {
        return this.uploadFile$(file, this.routes.upload);
    }

    public writeOff(invoice: SalesInvoice): void {
        this.update(invoice, {
            url: this.routes.writeOff.replace('{id}', invoice.id.toString())
        });
    }

}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../../services/logger/logger.service';
import { CredentialsService } from '../services/credentials.service';

const LOGGER = new Logger('AuthenticationGuard');

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard {
    constructor(private _router: Router, private _credentialsService: CredentialsService) {}

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this._credentialsService.isAuthenticated()) {
            return true;
        }

        LOGGER.debug('Non authentifié, redirection vers la page de connexion avec URL de retour...');
        this._router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
        return false;
    }
}

export enum AuthorizationsEnum {
    AccountCreate = 'account_create',
    AccountUpdate = 'account_update',
    BankAccountCreate = 'bankaccount_create',
    ChequeCreate = 'cheque_create',
    ClientUserDelete = 'clientuser_delete',
    ClientUserUpdate = 'clientuser_update',
    DepositCreate = 'deposit_create',
    PatnerCreate = 'partner_create',
    ProductCreate = 'product_create',
    QuotationCreate = 'quotation_create',
    SalesInvoiceCreate = 'salesinvoice_create',
}